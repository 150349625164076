import * as React from "react";

export class LoginView extends React.Component {
  render() {
    const {
      username,
      onUsernameChanged,
      password,
      onPasswordChanged,
      isKnownInvalid,
      errorMessage,
      isSubmitting,
      onSubmit,
      passwordResetUrl,
      registrationUrl,
      termsOfService,
      logoUrl,
    } = this.props;

    const usernameInvalid = !username && isKnownInvalid;
    const passwordInvalid = !password && isKnownInvalid;
    const showTermsOfService = !!(termsOfService && termsOfService.enabled);
    const showPasswordResetLink = !!(passwordResetUrl && passwordResetUrl.enabled);
    const showRegistrationUrl = !!(registrationUrl && registrationUrl.enabled);
    return (
      <div className="d-flex h-100 align-items-center justify-content-center">
        <div className="card w-100 card-login">
          <div className="card-header">
            <h1 className="text-center card-title-lg">Dashboard Login</h1>
          </div>
          <div className="card-body card-footerless">
            <figure className={`${logoUrl ? "hasLogo" : ""} logo-container`}>
              {!!logoUrl && <img src={logoUrl} alt="" className="img-fluid" />}
            </figure>
            {!!errorMessage && (
              <div ng-show="errorMsg" className="alert alert-danger">
                <i className="fas fa-exclamation-circle fa-status"></i>
                {errorMessage}
              </div>
            )}
            <form name="f.loginForm" className="form-horizontal" role="form" onSubmit={onSubmit}>
              <span>
                <div className={`form-group ${usernameInvalid ? "has-error" : ""}`}>
                  <div className="input-group">
                    <span className="input-group-prepend" id="basic-addon1">
                      <i className="d-flex fas fa-user input-group-text"></i>
                    </span>
                    <input
                      className="form-control"
                      value={username}
                      onChange={onUsernameChanged}
                      type="text"
                      name="user"
                      id="inputUsername"
                      placeholder="Username"
                      required
                      autoFocus
                    />
                  </div>
                  <div>{usernameInvalid && <p className="help-block">Username is required</p>}</div>
                </div>
                <div className={`form-group ${passwordInvalid ? "has-error" : ""}`}>
                  <div className="input-group">
                    <span className="input-group-prepend" id="basic-addon1">
                      <i className="d-flex fas fa-lock-alt input-group-text"></i>
                    </span>
                    <input
                      className="form-control"
                      value={password}
                      onChange={onPasswordChanged}
                      type="password"
                      name="password"
                      id="inputPassword"
                      placeholder="Password"
                      required
                    />
                  </div>
                  {passwordInvalid && <p className="help-block">Password is required</p>}
                </div>
                <div className="form-group">
                  <input type="submit" className="btn btn-secondary" value="Login" />
                </div>
              </span>
              {showTermsOfService && (
                <div>
                  <span>
                    By logging in, you are agreeing to{" "}
                    <a className="" href={termsOfService.url}>
                      {termsOfService.label}
                    </a>
                  </span>
                </div>
              )}
              <div>
                {!showPasswordResetLink && <a href="/#/forgotPassword">Forgot Password?</a>}
                {showPasswordResetLink && <a href={passwordResetUrl.url}>{passwordResetUrl.label}</a>}
                {showRegistrationUrl && <a href={registrationUrl.url}>{registrationUrl.label}</a>}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
