import * as React from "react";
import ReactDOM from "react-dom";
import * as PropTypes from "prop-types";
import { useContext, useEffect, useMemo, useState } from "react";
import uuid from "uuid/v4";
import { AppNavigatorContext } from "../../modules/app/navigator/AppNavigator";

/**
 * a wrapper component to put a filter into the filter container up in the app page
 *
 * TODO -
 * Right now, most every prop except children is unused, other than immediately passing them back into the children function.
 *
 * The intention was to wrap up some "chip-selections" logic for each,
 * - so you could get a summary of the selections as chips
 * - chips would be potentially clearable
 * - clear chips
 *
 * Additionally, there was some filter layout logic so that filters could be resized, or hidden into some kind of advanced
 * section.
 *
 * For now, the chips and layout may be useful as we're making changes to the layout, and conditionally rendering
 * different layouts.
 *
 * However, with the final layout, much of this functionality may be unused, so those unused props should be deleted,
 * along with the various prop values passed in from consumers of this component
 *
 * TODO
 *  - move file to modules/app/layout
 *
 * */
export function PageFilter({ children, ...props }) {
  return children(props);
}

PageFilter.propTypes = {
  /** the filter component */
  children: PropTypes.func.isRequired,

  /** whether a single value is removable */
  required: PropTypes.bool,

  /** passed through to render. called by renderComponent to update state, or if a value is cleared */
  onValueChanged: PropTypes.func.isRequired,

  /** the value of the filter. Passed through to render method. Must be passed into this PageFilter component instead of the child filter so that summary can be rendered */
  value: PropTypes.any,

  /** render a selected value if the selector is collapsed */
  renderChipValue: PropTypes.func,

  /** controls whether the a value is considered selected for the chip renderer. Defaults to not null/undefined */
  hasChipValue: PropTypes.func,

  /** label to display with the rendered value */
  chipLabel: PropTypes.string,

  /** whether the value should be treated as an array, in which case individual values of the array will be removable */
  multi: PropTypes.bool,

  /**
   * if true, sends filter to a secondary position in some layouts
   */
  secondary: PropTypes.bool,
};
