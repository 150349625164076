import * as React from "react";
import * as PropTypes from "prop-types";
import * as mobx from "mobx";
import { SearchToSelect } from "../../components/index";
import { Label } from "reactstrap";
import { ManageSavedFilterSet } from "./ManageSavedFilterSet";
import { WithBreakpoint } from "../../components/responsive/WithBreakpoint";

export class FilterSetSelector extends React.Component {
  static propTypes = {
    onSelected: PropTypes.func.isRequired,
    selected: PropTypes.any,
    options: PropTypes.func.isRequired,
    featureGroup: PropTypes.string.isRequired,
    filterSetConfig: PropTypes.object.isRequired,

    setFilterSet: PropTypes.func.isRequired,
    updateFilterSets: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  getSearchResults = (options) => {
    return function ({ query }) {
      return options().then((sets) => {
        const matching = sets.filter((x) => {
          return !query || x.name.toLowerCase().startsWith(query.trim().toLowerCase());
        });
        return [].concat(matching);
      });
    };
  };

  resolveSelection = (options) => {
    return function (ids) {
      const idSet = new Set(ids);
      const selection = options().then((xs) => {
        xs.filter((x) => {
          return idSet.has(x._id);
        });
      });
      return selection || undefined;
    };
  };

  render() {
    const { columnWeight, options, onSelected, ...rest } = this.props;
    const filterSetConfig = mobx.toJS(this.props.filterSetConfig);
    const { filterSet } = filterSetConfig;
    const filterSetId = !!this.props.selected && this.props.selected !== null ? this.props.selected : undefined;

    /**
     * On initial load when a filter set is provided, make sure filters are persisted to the view.
     */
    if (filterSet === undefined && !!filterSetId) {
      onSelected(filterSetId);
    }

    return (
      <WithBreakpoint>
        {(size) => {
          return (
            <div className={`saved-filter-set-selector ${size}`}>
              <span
                style={{
                  flex: `${columnWeight - 1} ${columnWeight - 1}`,
                }}
              >
                <SearchToSelect
                  right={true}
                  positionFixed={true}
                  disableCaching={true}
                  eager={true}
                  key={filterSetId}
                  renderSelections={options}
                  selectByKey={true}
                  getSearchResults={this.getSearchResults(options)}
                  renderOption={(x) => {
                    return !!x ? x.default ? <span>{x.name}</span> : <span>{x.name}</span> : "All";
                  }}
                  placeholder={<span>{"Saved Filters"}</span>}
                  getKey={(x) => {
                    return x._id;
                  }}
                  renderHeader={(props) => {
                    return (
                      <div>
                        <ManageSavedFilterSet
                          filterSet={filterSet}
                          filterSetConfig={filterSetConfig}
                          onToggle={props.onToggle}
                          setFilterSet={this.props.setFilterSet}
                          updateFilterSets={this.props.updateFilterSets}
                          featureGroup={this.props.featureGroup}
                        />
                        <Label
                          style={{
                            margin: "7px",
                            fontWeight: "bold",
                          }}
                        >
                          Saved Filters
                        </Label>
                      </div>
                    );
                  }}
                  renderHeaderWithinSearchToSelect={false} /* To show the search, set to true */
                  resolveSelection={this.resolveSelection(options)}
                  onSelected={onSelected}
                  filterSetId={filterSetId}
                  filterSet={filterSet}
                  required={false}
                  closeOnClear={true}
                  {...rest}
                />
              </span>
            </div>
          );
        }}
      </WithBreakpoint>
    );
  }
}
