import React from "react";
import SentimentIcon from "../modules/pulse/components/SentimentIcon";

const TopicValue = (a) => {
  const value = a.data;
  return (
    <div className="Select-value">
      <span
        className="Select-value-icon Select-icon-remove"
        data-select-value={value.value}
        onClick={value.removeAction}
      >
        ×
      </span>
      <span className="Select-value-label">
        {value.label} <SentimentIcon classification={value.sentimentClassification}></SentimentIcon>
      </span>
      <span className="Select-value-icon Select-icon-right" data-select-value={value.value} onClick={value.editAction}>
        <span className="fas fa-pencil"></span>
      </span>
    </div>
  );
};

export default TopicValue;
