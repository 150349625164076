/**
 * type for a function that accepts any number of typed arguments, and returns a promise
 * with a typed return value.
 */
export interface PromiseFactory<Input extends any[], Result> {
  (...args: Input): Promise<Result>;
}

/**
 *
 * debounces repeated calls to the function, waiting until the function has been idle for timeout
 *
 * @param fn - function that accepts any number of arguments, and returns a value or a promise
 * @param timeout - timeout before fn is executed
 * @returns {Function}, that when invoked, returns a promise that is resolved after no calls to the function have occurred in timeout milliseconds
 */
export function debounceAsync<Input extends any[], Result>(
  fn: PromiseFactory<Input, Result>,
  timeout: number
): PromiseFactory<Input, Result> {
  let timer: ReturnType<typeof setTimeout>;
  let resolves = [] as ResolveReject<Result>[];
  return function (...input: Parameters<typeof fn>) {
    if (timer !== undefined) clearTimeout(timer);
    return new Promise((resolve, reject) => {
      resolves.push([resolve, reject]);
      timer = setTimeout(() => {
        const copies = [...resolves];
        resolves = [];
        // fire it, resolve all calls with the same result
        fn(...input)
          .then((result) => copies.forEach(([resolver]) => resolver(result)))
          .catch((ex) => copies.forEach(([resolver, rejector]) => rejector(ex)));
      }, timeout);
    });
  };
}

type ResolveReject<O> = [(output: O) => void, (rejectReason: any) => void];

/**
 * returns function that only allows the latest promise from invocations of fn to complete successfully
 * Useful for cancelling previous requests to a server if the parameters have changed
 * @param fn - function that accepts any number of arguments and returns a promise
 * @returns {Function} with same signature as fn
 * @constructor
 */
export function failPreviousUnresolvedPromises<Input extends any[], Result>(
  fn: PromiseFactory<Input, Result>
): PromiseFactory<Input, Result> {
  let currentPromise: Promise<Result>;
  return function (...input: Input) {
    const p = fn(...input);
    currentPromise = p;
    return p.then((response) => {
      if (currentPromise !== p) {
        throw {
          name: CancellationException,
          message: "Cancelled due to more recent function invocation while promise was still in flight",
        };
      } else {
        return response;
      }
    });
  };
}

/**
 * like the above, but just stalls the continuation indefinitely.
 * easier to deal with (since no uncaught exceptions),
 * NOTE: but may cause light memory leaks (depending on references to the resulting promise, or the browser being used).
 *       avoid where return value may be called in high volume
 * https://stackoverflow.com/questions/57412416/awaited-but-never-resolved-rejected-promise-memory-usage
 * @tparam {Params}
 * @tparam {Response}
 * @param {function} fn - function with any number of arguments <Params?, that returns a Promise.<Response>
 * @return {function} - that when called with <Params> returns a Promise.<Response>
 */
export function stallUnresolvedPromises<Input extends any[], Result>(
  fn: PromiseFactory<Input, Result>
): PromiseFactory<Input, Result> {
  let currentPromise: Promise<Result>;
  return function (...args: Input): Promise<Result> {
    const p = fn(...args);
    currentPromise = p;
    return p.then((response) => {
      if (currentPromise !== p) {
        return emptyPromise;
      } else {
        return response;
      }
    });
  };
}

const emptyPromise = new Promise<any>(() => {});
export const CancellationException = "CancellationException";
