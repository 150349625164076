import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { observer } from "mobx-react";
import PropTypes from "prop-types";

import LoginStore from "../../services/session/LoginStore";
import SessionStore from "../../services/session/SessionStore";

import LoginContainerView from "../../services/session/components/LoginContainerView";
import { LogoutView } from "../../services/session/components/LogoutView";
import { OAuthCallbackView } from "../../services/session/components/OAuthCallbackView";
import { SSOFailedView } from "./SSOFailedView";
import { ResetPasswordData } from "../../services/session/components/ResetPasswordData";
import { ForgotPasswordData } from "../../services/session/components/ForgotPasswordData";
import ThemeStore from "../../services/config/ThemeStore";
import MaybeAutoLogin from "../../services/session/components/MaybeAutoLogin";
import FullPageSpinner from "../../services/session/components/FullPageSpinner";

/**
 * Guard component that offers many types of logging in before rendering loggedIn component
 */
export const LoginRouteGuard = observer(
  class LoginRouteGuard extends React.Component {
    static propTypes = {
      next: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.arrayOf(PropTypes.element)]),
    };

    render() {
      const current = window.location.hash.replace(/^#/, "");
      let redirect = "";
      if (current && current !== "/") {
        redirect = `?redirect=${encodeURIComponent(current)}`;
      }
      const isSessionLoaded = SessionStore.sessionLoaded;
      const isRenderReady = ThemeStore.isLoaded;
      const isLoggedIn = !!LoginStore.token;

      return (
        <MaybeAutoLogin>
          <Switch>
            {/* /ssoFailed view (See UserAuthController.scala / old LoginController.js)*/}
            <Route path="/ssoFailed" component={SSOFailedView} />
            <Route path="/login" render={({ history }) => <LoginContainerView history={history} />} />
            <Route path="/resetPassword" component={ResetPasswordData} />
            <Route path="/forgotPassword" component={ForgotPasswordData} />
            <Route path="/user/logout" exact component={LogoutView} />
            <Route path="/wait" exact render={() => <FullPageSpinner />} />
            <Route path="/oauth_callback" component={OAuthCallbackView} />
            {!isLoggedIn ? (
              <Redirect to={`/login${redirect}`} />
            ) : (
              <Route
                default
                render={() => (!(isSessionLoaded && isRenderReady) ? <FullPageSpinner /> : this.props.next)}
              />
            )}
          </Switch>
        </MaybeAutoLogin>
      );
    }
  }
);
