import { node } from "prop-types";
import { useMemo, useState } from "react";
import {
  AppNavigatorContextValue,
  DrillInRouteNode,
  NavigationNode,
  NavigationSection,
  RouteNode,
  SiteMap,
  SiteMapNode,
} from "./RouteNode";

/** functions and data for interacting with the site's routes */
export interface AppNavigatorUtils {
  routes: RouteAction[];

  getRoute(path: string): RouteNode | undefined;

  activeContext(route: ConcreteRoute): AppNavigatorContextValue;

  /** the default path to navigate to if no matching route. Just the first node in the site map */
  defaultPath?: string;
}

/**
 *
 * This react hook does a lot of data munging in order to return nice easy to use renderable navigation.
 *
 * It expects to be provided 1 or more site maps. A site map is the general hierarchy of pages. It also expects
 * a list of routes, which provide the specific implementation of a page. If no route is provided for a particular path, the
 * route is assumed to be inaccessible.
 *
 * Some of the operations done here:
 *
 * - clean up and pair the site map and route data by path: e.g. "/reviews"
 * - group up menu sections by title
 * - remove inaccessable routes from the site map
 * - hide routes from the site map for other reasons
 * - track state about previous child sections for intuitive navigation
 * - track state about previous site maps
 *
 */
export function useAppNavigator({
  sitemap,
  secondarySitemaps,
  routes,
}: {
  sitemap: SiteMap;
  secondarySitemaps: SiteMap[];
  routes: RouteNode[];
}): AppNavigatorUtils {
  // all of the routable routes. DrillInRouteNode is a superset of RouteNode, so use that so we can extract the extra attributes
  // from the drill in routes (to do things like add titles where you would normally need a SiteMapNode)
  const routeMap = new Map<string, ExtendedRouteNode>(
    routes.flatMap((x) =>
      [x as ExtendedRouteNode]
        .concat((x.drillInPages || []).map<ExtendedRouteNode>((x) => ({ ...x, isDrillIn: true })))
        .map((x) => [x.path, x])
    )
  );
  const routeSet = new Set(routeMap.keys());
  const sitemaps = [sitemap].concat(secondarySitemaps).map((x) => onlyRoutable(x, routeSet));

  const parentRoutes: Map<ChildRoutePath, ParentRoutePath> = buildParentLookup({
    sitemaps,
    routes,
  });
  const siteMapNodes: Map<string, SiteMapNode> = siteMapNodesByRoute(sitemaps);
  const sitemapIds: Map<string, number> = indexSitemapIdByRoute(sitemaps, routeMap);

  //Persists primary sitemap location in alternate sitemaps for the sitemap back button
  const [previousPrimarySitemapLocation, setPreviousPrimarySitemapLocation] = useState("/#/");

  const { getPreviouslyActiveChildPath, noteChildActive } = useNavigationHistory(siteMapNodes, parentRoutes);

  // list of all possible routes for route matching
  // sort tp put prefix routes, such as "/parent" last so that child paths, such as "/parent/child"
  // have higher precedence
  const concreteRoutes: RouteAction[] = getConcreteRoutes(routeMap);
  const redirectRoutes: RouteAction[] = getRedirectRoutes(siteMapNodes, getPreviouslyActiveChildPath);

  return {
    routes: redirectRoutes.concat(concreteRoutes),
    getRoute(path) {
      return routeMap.get(path);
    },
    defaultPath: sitemaps.flatMap((x) => x.routes.map((y) => y.path))[0],
    activeContext(route) {
      noteChildActive(route.path);
      return deriveActiveContext({
        route,
        routeMapNodes: routeMap,
        siteMapNodes,
        parentRoutes,
        sitemapIds,
        sitemaps,
        setPreviousPrimarySitemapLocation,
        previousPrimarySitemapLocation,
      });
    },
  };
}

/** Contains additional values on top of RouteNode. For use in building up the route context */
interface ExtendedRouteNode extends RouteNode {
  title?: string; // override the title. Used for drill in routes
  isDrillIn: boolean; // is this node a drill in route?
}

interface NavigationHistoryTracker {
  getPreviouslyActiveChildPath(path: string): string | undefined;

  noteChildActive(path: string): void;
}

function useNavigationHistory(
  siteMapNodes: Map<string, SiteMapNode>,
  parentRoutes: Map<ChildRoutePath, ParentRoutePath>
): NavigationHistoryTracker {
  // active child node history. sneaky mutability here
  // SORRY! performance cludge. Mutability. No need to trigger a re-render in these cases,
  // and need to signal across components
  return useMemo<NavigationHistoryTracker>(() => {
    const activeChildNodeHistory: Map<string, string> = new Map();
    return {
      getPreviouslyActiveChildPath(path: string) {
        const last = activeChildNodeHistory.get(path);
        const node = siteMapNodes.get(path);
        return last || (node && node.children && node.children[0].path);
      },
      noteChildActive(path: string) {
        let child = path;
        let parent: string | undefined;
        while ((parent = parentRoutes.get(child))) {
          activeChildNodeHistory.set(parent, child);
          child = parent;
        }
      },
    };
  }, []);
}

function isRoutable(node: SiteMapNode): boolean {
  return !node.children || node.children.some(isRoutable);
}

function getRedirectRoutes(
  siteMapNodes: Map<string, SiteMapNode>,
  getPreviouslyActiveChildPath: (path: string) => string | undefined
): RedirectRoute[] {
  return Array.from(siteMapNodes.values())
    .filter((node) => {
      return node.children && node.children.some(isRoutable);
    })
    .flatMap((node) => {
      const child = getPreviouslyActiveChildPath(node.path);
      if (child) {
        return [
          {
            path: node.path,
            to: child,
          },
        ];
      } else {
        return [];
      }
    });
}

function getConcreteRoutes(routeMap: Map<string, RouteNode>): ConcreteRoute[] {
  return Array.from(routeMap.values())
    .sort((a, b) => {
      return b.path.localeCompare(a.path);
    })
    .map((node) => {
      return {
        path: node.path,
        exact: node.exact || false,
        eventName: node.eventName,
      };
    });
}

/** removes unnavigable routes from the sitemap */
function onlyRoutable(sitemap: SiteMap, availableRoutes: Set<string>): SiteMap {
  const routable: SiteMapNode[] = transformToOnlyNavigable(sitemap.routes, availableRoutes);
  return {
    ...sitemap,
    routes: routable,
  };
}

/** removes all nodes from the site map that have no corresponding route */
function transformToOnlyNavigable(routes: SiteMapNode[], availableRoutes: Set<string>): SiteMapNode[] {
  return routes.flatMap((node) => {
    if (!node.children) {
      if (availableRoutes.has(node.path)) {
        return [node];
      } else {
        return [];
      }
    } else {
      const children = transformToOnlyNavigable(node.children!, availableRoutes);
      if (!children.length) {
        return [];
      } else {
        return [
          {
            ...node,
            children,
          },
        ];
      }
    }
  });
}

/** conversion function to create a pared down NavigationNode from a SiteMapNode and friends */
function toNavigableNode(node: SiteMapNode, route?: RouteNode, active: boolean = false): NavigationNode {
  return {
    isSection: false,
    path: node.path,
    sectionTag: node.sectionTag,
    exact: route?.exact || false,
    title: node.title,
    altText: node.altText,
    icon: node.icon,
    active: active,
  };
}

/**
 * traverses a site map, extracting it into lists of siblings at each level of navigation
 * i.e. to extract top level nav, second level nav under the active top level nav node, etc.
 */
function resolveNavigation(
  toResolve: string[],
  routes: SiteMapNode[],
  routeMapNodes: Map<string, RouteNode>
): NavigationNode[][] {
  if (!toResolve.length) {
    return [];
  } else {
    const activePath = toResolve[0];
    const tail = toResolve.slice(1);
    const activeRoute = routes.find((route) => route.path === activePath);
    const navs = routes.map((node) => {
      const active = activeRoute === node;
      const route = routeMapNodes.get(node.path)!;
      return toNavigableNode(node, route, active);
    });
    const children = activeRoute?.children;
    if (!children) {
      return [navs];
    } else {
      return [navs].concat(resolveNavigation(tail, children, routeMapNodes));
    }
  }
}

function deriveActiveContext({
  route,
  parentRoutes,
  routeMapNodes,
  siteMapNodes,
  sitemapIds,
  sitemaps,
  setPreviousPrimarySitemapLocation,
  previousPrimarySitemapLocation,
}: {
  route: ConcreteRoute;
  parentRoutes: Map<ChildRoutePath, ParentRoutePath>;
  routeMapNodes: Map<string, ExtendedRouteNode>;
  siteMapNodes: Map<string, SiteMapNode>;
  sitemapIds: Map<string, number>;
  sitemaps: SiteMap[];
  setPreviousPrimarySitemapLocation: (location: string) => void;
  previousPrimarySitemapLocation: string;
}): AppNavigatorContextValue {
  const sitemapIndex = sitemapIds.get(route.path) ?? 0;

  const sitemap = sitemaps[sitemapIndex]!;

  const ancestry = getAncestry(parentRoutes, route.path);
  const levels = resolveNavigation(ancestry, sitemap.routes, routeMapNodes);
  let navL1: NavigationSection[] = partitionNodesIntoSections(levels[0] || []);
  let navL2: NavigationSection[] = partitionNodesIntoSections(levels[1] || []);
  let navL3: NavigationNode[] = levels[2] || [];
  const siteMapNode: SiteMapNode | undefined = siteMapNodes.get(route.path);
  const routeNode: ExtendedRouteNode | undefined = routeMapNodes.get(route.path);
  return {
    navL1,
    navL2,
    navL3,
    /** route config for the currently active route (includes stuff like page title and icon) */
    activeNode: {
      path: route.path,
      get title() {
        return typeof siteMapNode?.title === "function" ? siteMapNode.title() : siteMapNode?.title ?? routeNode?.title;
      },
      hasPageTitleOverride: siteMapNode?.hasPageTitleOverride,
      parent: parentRoutes.get(route.path),
      isDrillInPage: !!routeNode?.isDrillIn,
    } as any,
    /** Passed to be able to identify which sitemap we are using in the layout. */
    siteMapId: sitemapIndex,
    setPreviousPrimarySitemapLocation,
    previousPrimarySitemapLocation,
    isDrillInSiteMap: sitemap.drillIn || false,
    siteMapTitle: sitemap.siteMapTitle,
  };
}

/** returns navigation parent paths of the specified path. Root ancestor is at the start of the array */
function getAncestry(parentRoutes: Map<ChildRoutePath, ParentRoutePath>, path: string): string[] {
  const paths = [path];
  let child = path;
  let parent: string | undefined;
  while ((parent = parentRoutes.get(child))) {
    paths.unshift(parent);
    child = parent;
  }
  return paths;
}

/** creates a map that contains lookups from all child routes to their parent route */
function buildParentLookup({
  sitemaps,
  routes,
}: {
  sitemaps: SiteMap[];
  routes: RouteNode[];
}): Map<ChildRoutePath, ParentRoutePath> {
  const parentChild = sitemaps.flatMap(childNavRelationships).concat(drillinRelationships(routes));
  return new Map(
    parentChild.map(({ fromParent, toChild }) => {
      return [toChild, fromParent];
    })
  );
}

/**
 * derives flat description of all parent child navigation route relationships in the site map (recursively).
 * In this case, a parent route is always just a redirect to a child route
 */
function childNavRelationships(siteMap: SiteMap): ParentToChild[] {
  const results: ParentToChild[] = [];

  function appendResults(siteMapNode: SiteMapNode): void {
    for (const child of siteMapNode.children || []) {
      results.push({ fromParent: siteMapNode.path, toChild: child.path });
      appendResults(child);
    }
  }

  for (const node of siteMap.routes) {
    appendResults(node);
  }
  return results;
}

/**
 * derives flat description of all parent child drill-in route relationships in the site map (recursively)
 * In this case, a parent route is always concrete itself, but may also have multiple children that can be
 * navigated "back" to the parent in some way
 */
function drillinRelationships(routeNodes: RouteNode[]): ParentToChild[] {
  const results: ParentToChild[] = [];

  function appendResults(routeNode: RouteNode): void {
    for (const child of routeNode.drillInPages || []) {
      results.push({ fromParent: routeNode.path, toChild: child.path });
      appendResults(child);
    }
  }

  for (const node of routeNodes) {
    appendResults(node);
  }
  return results;
}

/**
 * returns recursively flat list of all concrete (leaf) routes that appear in any of the sitemaps
 * @param siteMaps
 */
function siteMapNodesByRoute(siteMaps: SiteMap[]): Map<string, SiteMapNode> {
  return new Map(
    siteMaps
      .flatMap((x) => x.routes)
      .flatMap(withAllChildren)
      .map((node) => [node.path, node])
  );
}

/** returns a lookup from route to the id of its sitemap */
function indexSitemapIdByRoute(sitemaps: SiteMap[], routeMap: Map<string, DrillInRouteNode>): Map<string, number> {
  const withAllDrillIn = (path: string, sitemapIndex: number): (readonly [string, number])[] => {
    return [[path, sitemapIndex] as const].concat(
      routeMap.get(path)?.drillInPages?.flatMap((page) => withAllDrillIn(page.path, sitemapIndex)) ?? []
    );
  };
  return new Map(
    sitemaps
      .map<[SiteMap, number]>((x, i) => [x, i])
      .flatMap(([siteMap, i]) => {
        return siteMap.routes.flatMap(withAllChildren).map((x) => [x.path, i] as const);
      })
      .flatMap(([path, sitemapIndex]) => withAllDrillIn(path, sitemapIndex))
  );
}

function withAllChildren(node: SiteMapNode): SiteMapNode[] {
  return [node].concat((node.children || []).flatMap(withAllChildren));
}

/**
 * just type a label for easier reasoning about strings
 * e.g. "/social"
 */
type ParentRoutePath = string;

/**
 * just type a label for easier reasoning about strings
 * e.g. "/social/summary"
 */
type ChildRoutePath = string;

interface ParentToChild {
  fromParent: ParentRoutePath;
  toChild: ChildRoutePath;
}

export interface ConcreteRoute {
  path: string;
  exact: boolean;
  eventName?: string;
}

export interface RedirectRoute {
  path: string;
  to: string;
}

export type RouteAction = ConcreteRoute | RedirectRoute;

export function isRedirectRoute(route: RouteAction): route is RedirectRoute {
  return "to" in (route as any);
}

// Used in L2 navigation. Makes all nodes part of a section, using a 'sectionTag' string to do so.
// If a sectionTag is not present on a node, it adds them to a "Default" section.
function partitionNodesIntoSections(nodes: NavigationNode[]): NavigationSection[] {
  if (!nodes) return [];
  const seen = new Set<string>();
  const uniqueTags = nodes
    .map((n) => (n.sectionTag ? n.sectionTag : "Default"))
    .filter((section) => {
      if (seen.has(section)) {
        return false;
      } else {
        seen.add(section);
        return true;
      }
    });
  const sections: NavigationSection[] = uniqueTags.map((tag) => {
    if (tag === "Default") {
      return {
        title: tag,
        hideTitle: false,
        isSection: true,
        children: nodes.filter((n) => !n.sectionTag || (n.sectionTag && n.sectionTag === "Default")),
      };
    } else {
      return {
        title: tag,
        hideTitle: false,
        isSection: true,
        children: nodes.filter((n) => n.sectionTag && n.sectionTag === tag),
      };
    }
  });

  // Return the Default Section on top, if present.
  const defaultSection = sections.filter((s) => s.title === "Default");
  const otherSections = sections.filter((s) => s.title !== "Default");
  return [...defaultSection, ...otherSections];
}
