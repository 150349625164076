import * as React from "react";
import PropTypes from "prop-types";

export const appPageProps = {
  children: PropTypes.any,
  includeLocationDropdown: PropTypes.bool,
  locationDropdownProps: PropTypes.shape({
    shouldNotIncludeAccounts: PropTypes.bool,
  }),
};

/** This is a placeholder layout to be rendered by the AppNavigator.
 * Generally, a custom layout should be provided to the AppNavigator  */
export function BarebonesAppPage({ children }) {
  return (
    <div className="h-100">
      <div className={"alert alert-danger"}>No App Container Configured</div>
      {children}
    </div>
  );
}
