import * as React from "react";
import * as oauthHandshake from "../oauthHandshake";

/** call back view that will accept external credentials for saving to the backend after logging in from a 3rd party.
 * Expects a parent window to exist. See oauthHandshake for more details */
export class OAuthCallbackView extends React.Component {
  state = {
    message: "Completing Authentication ...",
  };

  componentDidMount() {
    const err = oauthHandshake.extractErrorMessage(window.location.href);
    if (!err) {
      try {
        localStorage.setItem("credentialAdded", "true");
      } catch (e) {
        console.warn(e);
      }
      this.setState({
        message: "Successfully completed",
        details: "This window may now be closed.",
      });
      console.log("closing... in 1s");
      setTimeout(() => window.close(), 1000);
    } else {
      this.setState({
        message: "Oops! An error occurred",
        details: err,
      });
    }
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div className="m-auto alert alert-info" style={{ maxWidth: "450px" }}>
          <h4>{this.state.message}</h4>
          {this.state.details && (
            <div className="pt-3">
              <span className="text-subdued">{this.state.details}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}
