import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { IconToTheSide } from "..";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

export class DatePicker extends Component {
  static propTypes = {
    date: PropTypes.any,
    dateFormat: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    placeholder: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledDays: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object), PropTypes.func]),
    inputClasses: PropTypes.string,
  };

  static defaultProps = {
    dateFormat: "MM/DD/YY",
    placeholder: <span className="text-muted">Pick a date...</span>,
  };

  state = {};

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onChange = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    this.setState({ isOpen: false });
    this.props.onChange(day);
  };

  renderDeleteIcon() {
    return (
      <span
        className="far fa-times"
        onClick={(e) => {
          e.stopPropagation();
          this.props.onChange(undefined);
        }}
      />
    );
  }

  render() {
    const { dateFormat, required, date, onChange, onBlur, placeholder, disabled, disabledDays, inputClasses } =
      this.props;
    const { isOpen } = this.state;

    return (
      <Dropdown toggle={this.toggle} isOpen={isOpen} disabled={disabled}>
        <DropdownToggle
          disabled={disabled}
          tag="span"
          className={`dropdown-toggle dropdown-toggle-no-icon form-control ${inputClasses}`}
        >
          <IconToTheSide side="left" icon={<span className="far fa-calendar-alt" />}>
            <IconToTheSide
              side="right"
              icon={!required && !disabled && date ? this.renderDeleteIcon() : <span className="far fa-chevron-down" />}
            >
              {!date ? placeholder : moment(date).format(dateFormat)}
            </IconToTheSide>
          </IconToTheSide>
        </DropdownToggle>
        <DropdownMenu>
          <DayPicker
            selected={date}
            disabled={disabledDays}
            onDayClick={(day) => {
              this.onChange(day);
              onBlur && onBlur(day);
            }}
          />
        </DropdownMenu>
      </Dropdown>
    );
  }
}
