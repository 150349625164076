type RiskMetricNames =
  | "riskMonitoring_onCategoryRiskClick"
  | "riskMonitoring_onTopRiskyLocationClick"
  | "riskMonitoring_onRiskAssignmentChange"
  | "riskMonitoring_onRiskAssignmentCancel"
  | "riskMonitoring_notificationsRiskChange"
  | "riskMonitoring_onTagRemoved"
  // The ones below this comment existed prior to March 2024 in the Angular codebase
  | "Add Note" // This one used to be just an empty string
  | "Review List Task"
  | "Tag Review"
  | "Add Tag"
  | "Reivew Row Location Filter" // Intentional misspelling of "Review" so that reports are consistent
  | "Remove Review"
  | "Remove"; // Lame... This name is too generic and could clash with other events

export type RiskMetricsAnalyticsProps = {
  "data-analyticsclick": RiskMetricNames;
  "data-analyticsdata"?: string;
};

const generateAnalyticsProps = (label: RiskMetricNames, additionalProperties?: object): RiskMetricsAnalyticsProps => {
  return {
    "data-analyticsclick": label,
    "data-analyticsdata": additionalProperties ? JSON.stringify(additionalProperties) : undefined,
  };
};

const riskMetrics = {
  addTaskAnalytics: () => generateAnalyticsProps("Review List Task"),
  tagReviewAnalytics: () => generateAnalyticsProps("Tag Review"),
  addTagAnalytics: () => generateAnalyticsProps("Add Tag"),
  addNoteAnalytics: () => generateAnalyticsProps("Add Note"),
  filterToReviewAnalytics: () => generateAnalyticsProps("Reivew Row Location Filter"),
  removeReviewAnalytics: () => generateAnalyticsProps("Remove Review"),
  removeAnalytics: () => generateAnalyticsProps("Remove"),
  categoryRiskClickAnalytics: (categoryName: string, numberOfRiskyReviews: number) =>
    generateAnalyticsProps("riskMonitoring_onCategoryRiskClick", { categoryName, numberOfRiskyReviews }),
  topRiskyLocationClickAnalytics: (locationId: string) =>
    generateAnalyticsProps("riskMonitoring_onTopRiskyLocationClick", { locationId }),
  tagRemovedAnalytics: (tagRemoved: string) => generateAnalyticsProps("riskMonitoring_onTagRemoved", { tagRemoved }),
  riskAssignmentChangeAnalytics: (latestRiskAssigned: string, initialRiskAssigned?: string) =>
    generateAnalyticsProps("riskMonitoring_onRiskAssignmentChange", {
      riskModification: { initial: initialRiskAssigned, latest: latestRiskAssigned },
    }),
  riskAssignmentCancelAnalytics: () => generateAnalyticsProps("riskMonitoring_onRiskAssignmentCancel"),
  riskCategoryClickAnalytics: (riskCategory: string) =>
    generateAnalyticsProps("riskMonitoring_notificationsRiskChange", { riskCategory: riskCategory }),
};

export default riskMetrics;
