import React, { ReactNode } from "react";
import { Fade } from "reactstrap";

const sizeToFontAwesomeSize = {
  xs: "fa-xs",
  sm: "fa-sm",
  md: "",
  lg: "fa-lg",
  xl: "fa-2x",
};

interface SpinnerProps {
  center?: boolean;
  timeout?: number;
  size?: "xl" | "lg" | "md" | "sm" | "xs";
  message?: string;
  style?: React.CSSProperties;
  className?: string;
}

/**
 * Loading icon.
 **/
export const Spinner = ({ center, timeout = 1000, size = "lg", message, style, className }: SpinnerProps) => {
  // console.warn("TODO - remove className 'in' for compatibility chrome dome stylepack");
  // TODO - Standardize loaders. Some are H4's some are using this. Let's move to this. H4 isn't the right element for this.
  const Container = center ? CenterContent : "span";

  return (
    <Container style={style} className={className} data-testid={"loading-spinner"}>
      <Fade in={true} timeout={timeout} className="in">
        <div className="d-flex flex-row align-items-center">
          <i
            className={`d-flex align-items-center fas fa-spinner fa-spin ${
              sizeToFontAwesomeSize[size] || sizeToFontAwesomeSize.lg
            }`}
            style={{ textDecoration: "none" }}
          ></i>
          {!!message ? <div className="control-spacer-left font-weight-bold">{message}</div> : null}
        </div>
      </Fade>
    </Container>
  );
};

interface CenterContentProps {
  children: ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export const CenterContent = ({ children, style, className }: CenterContentProps) => (
  <div
    style={{
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        ...style,
      }}
      className={className}
    >
      {children}
    </div>
  </div>
);
