import * as React from "react";
import PropTypes from "prop-types";
import LoginStore from "../LoginStore";
import { GenericLoginWithPasswordData } from "./GenericLoginWithPasswordData";

export class LoginWithPassword extends React.Component {
  static propTypes = {
    onLoginSuccess: PropTypes.func,
    brandConfig: PropTypes.object.isRequired,
  };

  onSubmit = ({ username, password }) => {
    return LoginStore.tryLoginPassword(username, password).then((result) => {
      if (result.authenticated) {
        return true;
      } else {
        return {
          isKnownInvalid: result.credentialsInvalid,
          errorMessage: result.errorMessage,
        };
      }
    });
  };

  render() {
    return (
      <GenericLoginWithPasswordData
        onLoginSuccess={this.props.onLoginSuccess}
        brandConfig={this.props.brandConfig}
        onSubmit={this.onSubmit}
      />
    );
  }
}
