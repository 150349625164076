import axios from "./apiClient";
import { Account, AuthenticationResponse, ExtendedUser, TokenTimeoutResponse, User } from "./Authentication";
import { BrandConfig } from "../config/BrandConfig";

export function createSession(username: string, password: string): Promise<AuthenticationResponse> {
  return axios
    .post(
      "/login?jwt=true",
      { username, password },
      {
        headers: {
          "origin-service": "dashboard",
        },
      }
    )
    .then((resp) => {
      return resp.data as AuthenticationResponse;
    });
}

export function refreshJWT(refreshToken: string, updateSessionTimeout: boolean): Promise<AuthenticationResponse> {
  return axios
    .post(
      `login/jwt/refresh?updateSessionTimeout=${updateSessionTimeout}`,
      { refreshToken },
      {
        headers: {
          "origin-service": "dashboard",
        },
      }
    )
    .then((resp) => {
      return resp.data as AuthenticationResponse;
    });
}

export function logout(refreshToken: string): Promise<void> {
  return axios.post("/logout", { refreshToken }).then(() => {});
}

export function getLoginSSO(ssoToken: string): Promise<AuthenticationResponse> {
  return axios
    .get("/v5/singlesignon/authUserWithLoginToken?jwt=true", { params: { token: ssoToken } })
    .then((x) => x.data);
}

/** @returns {Promise.<User>} */
export function getCurrentUser(): Promise<ExtendedUser> {
  return axios.get("/user/info").then((response) => response.data);
}

export function getCurrentUsersAccount(): Promise<Account> {
  return axios.get("/v5/currentAccount").then((response) => response.data);
}

export function getAccountByID(id: string, shouldDisplaySanitizedData = false): Promise<Account> {
  return axios.get(`/v5/accounts/${id}`, { params: { shouldDisplaySanitizedData } }).then((response) => response.data);
}

export function getAllUsersAccounts(): Promise<Account[]> {
  return axios.get("/v5/accounts").then((response) => response.data.accounts);
}

export function getCurrentBrandConfig(): Promise<BrandConfig> {
  return axios.get("/brand/config").then((response) => response.data);
}

export function validatePasswordResetToken(token: string): Promise<unknown> {
  return axios.post("/password/validatePasswordResetToken", { pwResetToken: token }).then(() => {});
}

export function resetPassword(password: string, token: string): Promise<unknown> {
  return axios.post("/login/resetPassword", { password: password, token: token }).then((response) => response.data);
}

export function forgotPasswordEmail(username: string): Promise<unknown> {
  return axios.get("/login/forgotPasswordEmail", { params: { username: username } }).then((response) => response.data);
}

export function validateSession(): Promise<boolean> {
  return axios.get("/login/validateToken").then((x) => x.data.isValid);
}

export function extendSession(): Promise<TokenTimeoutResponse> {
  return axios.post("/login/extend").then((x) => x.data as TokenTimeoutResponse);
}

export function generateSSOToken(username: string): Promise<{ ssoToken: string }> {
  return axios
    .get("/v5/singlesignon/generateLoginToken", {
      params: { username: username },
    })
    .then((r) => r.data as { ssoToken: string });
}

export function presignDownload(url: string): Promise<unknown> {
  return axios.get("/dashboard/presign", { params: { url } });
}
