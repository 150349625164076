import React, { useState } from "react";
import { DayPicker, DateRange } from "react-day-picker";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { toDateRange, addDayToRange, valueOfDateRange, areDateRangesEqual } from "../../util/date/DateUtils";
import { format, isAfter, isBefore, isEqual, isValid, parse } from "date-fns";
import { IconToTheSide } from "../buttons/FormControlButton";
import classnames from "classnames";
import moment from "moment/moment";

export interface DateRangeSelectorProps {
  selected?: {
    from: moment.MomentInput;
    to: moment.MomentInput;
    name?: string;
  };
  placeholder?: JSX.Element;
  namedOptions?: readonly NamedDateRangeOption[];
  format?: string;
  hideSelectedRange?: boolean;
  onSelected?: (selectedRange: { from?: moment.MomentInput; to?: moment.MomentInput }) => void;
  disabled?: boolean;
  required?: boolean;
  disabledDays?: any;
  right?: boolean;
  dataTestId?: string;
}

// TODO: This should probably all be Dates. Named options should likely be something like below:
/*
interface namedDateRangeOption {
  name: string,
  range: DateRange
}
 */
// Keeping it as it was for now to minimizing refactor.
export interface NamedDateRangeOption {
  from?: moment.MomentInput;
  to?: moment.MomentInput;
  name: string;
}

export const DateRangeSelector: React.FC<DateRangeSelectorProps> = function DateRangeSelector(
  originalProps: DateRangeSelectorProps
) {
  // Using this instead of defaultProps because defaultProps is deprecated and not typeable
  const props = { ...defaultProps, ...originalProps };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSelectingRange, setIsSelectingRange] = useState<boolean>(false);
  const [selectedRange, setSelectedRange] = useState<DateRange>(
    calculateSelected() === SELECTED_RANGE ? toDateRange(props.selected) : { from: undefined }
  );
  const [fromValue, setFromValue] = useState<string>(
    selectedRange.from ? format(selectedRange.from, props.format) : ""
  );
  const [toValue, setToValue] = useState<string>(selectedRange.to ? format(selectedRange.to, props.format) : "");

  function handleDayClick(day: Date) {
    const newRange = addDayToRange(day, { ...selectedRange });
    setRangeSelector(newRange);
  }

  function setRangeSelector(newRange: DateRange) {
    setSelectedRange(newRange);
    setFromValue(newRange.from ? format(newRange.from, props.format) : "");
    setToValue(newRange.to ? format(newRange.to, props.format) : "");
  }

  function parseWithFallback(date: string) {
    // parse() requires a strict format to parse the date.  If it does not
    // match our format, it will return an invalid date.  However, we expect
    // the user to be able to enter a date in any format, so we will fallback
    // to the `new Date()` constructor if parse() fails - which matches the
    // behavior of momentjs.
    let result = parse(date, props.format, new Date());
    if (!isValid(result)) {
      return new Date(date);
    } else {
      return result;
    }
  }

  function handleFromChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFromValue(event.target.value);
    let date = parseWithFallback(event.target.value);
    if (isValid(date) && (!selectedRange.to || isBefore(date, selectedRange.to) || isEqual(date, selectedRange.to))) {
      setSelectedRange({
        ...selectedRange,
        from: date,
      });
    }
  }

  function handleToChange(event: React.ChangeEvent<HTMLInputElement>) {
    setToValue(event.target.value);
    let date = parseWithFallback(event.target.value);
    if (
      isValid(date) &&
      (!selectedRange.from || isAfter(date, selectedRange.from) || isEqual(date, selectedRange.from))
    ) {
      setSelectedRange({
        ...selectedRange,
        to: date,
      });
    }
  }

  function onCancelDateRange() {
    setIsOpen(false);
    setIsSelectingRange(false);
  }

  function onApplyDateRange() {
    setIsOpen(false);
    props.onSelected(valueOfDateRange(selectedRange));
  }

  function fromValueError() {
    if (!isValid(parseWithFallback(fromValue))) {
      return "Please input a valid date.";
    }
  }

  function toValueError() {
    if (!isValid(parseWithFallback(toValue))) {
      return "Please input a valid date.";
    }
  }

  function invalidRangeError() {
    if (isAfter(parseWithFallback(fromValue), parseWithFallback(toValue))) {
      return "The Start date cannot be after the End date.";
    }
  }

  // TODO: This should just be another component, would handle the to and from values too.
  function renderRangeSelector() {
    return (
      <div className="col-md-9">
        <div className="col-md-12">
          <form className="col form-inline d-flex flex-row">
            <div className="form-group col-6 p-1 d-flex flex-row">
              <label htmlFor="dateRangeFrom" className={"p-2"}>
                Start
              </label>
              <input
                id="dateRageFrom"
                className={classnames("form-control", "flex-fill", { "is-invalid": !!fromValueError() })}
                size={10}
                placeholder={props.format}
                value={fromValue}
                onChange={handleFromChange}
              />
              <span className="invalid-feedback" style={{ height: 0 }}>
                {fromValueError()}
              </span>
            </div>
            <div className="form-group col-6 d-flex flex-row">
              <label htmlFor="dateRangeTo" className={"p-2"}>
                End
              </label>
              <input
                id="dateRangeTo"
                className={classnames("form-control", "flex-fill", { "is-invalid": !!toValueError() })}
                size={10}
                placeholder={props.format}
                value={toValue}
                onChange={handleToChange}
              />
              <span className="invalid-feedback" style={{ height: 0 }}>
                {toValueError()}
              </span>
            </div>
          </form>
          <div className="row">
            <DayPicker
              mode="range"
              selected={selectedRange}
              numberOfMonths={2}
              onDayClick={handleDayClick}
              disabled={props.disabledDays}
            />
          </div>
        </div>
        <div className={classnames("text-right", "col-md-12", { "is-invalid": !!invalidRangeError() })}>
          <Button outline={true} color="secondary" onClick={onCancelDateRange}>
            Cancel
          </Button>
          <Button
            disabled={!!fromValueError() || !!toValueError() || !!invalidRangeError()}
            className="control-spacer-left"
            color="secondary"
            onClick={onApplyDateRange}
          >
            Apply
          </Button>
        </div>
        <div className="invalid-feedback text-right">{invalidRangeError()}</div>
      </div>
    );
  }

  function toggle() {
    setIsOpen(!isOpen);
  }

  function addSelection(item: NamedDateRangeOption) {
    setIsSelectingRange(false);
    props.onSelected(item);
  }

  const removeSelection: React.MouseEventHandler<HTMLSpanElement> = (e) => {
    if (!props.disabled) {
      e.stopPropagation();
      props.onSelected({});
    }
  };

  function renderActive(active?: "SELECTED_RANGE" | NamedDateRangeOption | null): JSX.Element {
    // in case the range set from the props is not a named option, get the selected range on the fly
    let { from, to } = toDateRange(props.selected);
    let selectedDisplay;
    if (!active) {
      selectedDisplay = props.placeholder;
    } else if (active === SELECTED_RANGE) {
      const toDisplay = to ? format(to, props.format) : "...";
      const fromDisplay = from ? format(from, props.format) : "...";
      selectedDisplay = fromDisplay === toDisplay ? fromDisplay : `${fromDisplay} - ${toDisplay}`;
    } else {
      selectedDisplay = active.name
        ? active.name
        : `${format(active.from as Date, props.format)} - ${format(active.to as Date, props.format)}`;
    }

    let icon =
      !props.required && !!active ? (
        <span onClick={removeSelection} className="far fa-times" />
      ) : (
        <span className="far fa-chevron-down" data-testid="date-range-chevron-down" />
      );
    return (
      <IconToTheSide side="right" icon={icon}>
        {selectedDisplay}
      </IconToTheSide>
    );
  }

  function calculateSelected(): "SELECTED_RANGE" | null | NamedDateRangeOption {
    if (isSelectingRange) {
      return SELECTED_RANGE;
    }
    if (!props.selected) {
      return null;
    } else {
      return (
        props.namedOptions.find((x) => x === props.selected) ||
        props.namedOptions.find((x) => areDateRangesEqual(toDateRange(x), toDateRange(props.selected))) ||
        SELECTED_RANGE
      );
    }
  }

  function onSelectRange() {
    setIsSelectingRange(true);
    setRangeSelector(toDateRange(props.selected));
  }

  function render() {
    let active = calculateSelected();

    let menuItems = props.namedOptions
      .map((x) => {
        return (
          <DropdownItem key={x.name} active={x === active} onClick={() => addSelection(x)}>
            {x.name}
          </DropdownItem>
        );
      })
      .concat(
        props.hideSelectedRange
          ? []
          : [
              <DropdownItem
                toggle={false}
                active={isSelectingRange || active === SELECTED_RANGE}
                key="sel-date-range"
                onClick={onSelectRange}
              >
                Select Range
              </DropdownItem>,
            ]
      );

    return (
      <Dropdown isOpen={isOpen} toggle={toggle} data-testid={props.dataTestId}>
        <DropdownToggle
          disabled={props.disabled}
          tag={"span"}
          className="d-block form-control dropdown-toggle dropdown-toggle-no-icon"
        >
          {renderActive(active)}
        </DropdownToggle>
        <DropdownMenu
          style={{ maxWidth: "90vw", overflowX: "unset" }}
          positionFixed={true}
          data-testid="date-range-options"
          modifiers={{
            // Without this, selecting a custom range makes the dropdown overflow. Seems like this forces a position update to keep it fresh.
            positionUpdater: {
              enabled: true,
              fn(data) {
                return {
                  ...data,
                };
              },
            },
          }}
        >
          {isSelectingRange || active === SELECTED_RANGE ? (
            <div className="date-dropdown row" style={{ width: "668px", maxWidth: "100vw" }}>
              <div className="col-md-3">{menuItems}</div>
              {renderRangeSelector()}
            </div>
          ) : (
            menuItems
          )}
        </DropdownMenu>
      </Dropdown>
    );
  }

  return render();
};

const SELECTED_RANGE: "SELECTED_RANGE" = "SELECTED_RANGE";

const defaultProps = {
  placeholder: <span className="text-muted">Select...</span>,
  disabled: false,
  namedOptions: [],
  format: "M/d/yy",
  onSelected: () => {},
} as const;

export default DateRangeSelector;
