import * as React from "react";
import * as PropTypes from "prop-types";
import "./styledCheckbox.scss";

export function StyledCheckbox({ className, id, label, color, labelStyle, ...props }) {
  const colorClassName = color === "light" ? "styled-checkbox--light" : "";
  return (
    <React.Fragment>
      <input type="checkbox" id={id} className={`styled-checkbox ${colorClassName} ${className || ""}`} {...props} />{" "}
      <label style={labelStyle} htmlFor={id}>
        {label}
      </label>
    </React.Fragment>
  );
}

StyledCheckbox.propTypes = {
  label: PropTypes.any,
  id: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["light", "info"]),
};
