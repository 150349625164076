export const neutrals = {
  sectionName: "Neutrals",
  hideSection: true,
  variables: {
    $white: { type: "palette-color" },
    "$gray-100": { type: "palette-color" },
    "$gray-200": { type: "palette-color" },
    "$gray-300": { type: "palette-color" },
    "$gray-400": { type: "palette-color" },
    "$gray-500": { type: "palette-color" },
    "$gray-600": { type: "palette-color" },
    "$gray-700": { type: "palette-color" },
    "$gray-800": { type: "palette-color" },
    "$gray-900": { type: "palette-color" },
    $black: { type: "palette-color" },
  },
};

export const brandColors = {
  sectionName: "Brand Colors",
  variables: {
    $primary: { type: "palette-color" },
    $secondary: { type: "palette-color" },
    $success: { type: "palette-color" },
    $info: { type: "palette-color" },
    $warning: { type: "palette-color" },
    $danger: { type: "palette-color" },
    $light: { type: "palette-color" },
    $dark: { type: "palette-color" },
  },
};

export const chartColors = {
  sectionName: "Chart Colors",
  variables: {
    "$color-1": { type: "palette-color" },
    "$color-2": { type: "palette-color" },
    "$color-3": { type: "palette-color" },
    "$color-4": { type: "palette-color" },
    "$color-5": { type: "palette-color" },
    "$color-6": { type: "palette-color" },
    "$color-7": { type: "palette-color" },
    "$color-8": { type: "palette-color" },
    "$color-9": { type: "palette-color" },
    "$color-10": { type: "palette-color" },
    "$color-11": { type: "palette-color" },
    "$color-12": { type: "palette-color" },
  },
};

export const generalColors = {
  sectionName: "General Colors",
  hideSection: true,
  variables: {
    $blue: { type: "color" },
    $indigo: { type: "color" },
    $purple: { type: "color" },
    $pink: { type: "color" },
    $red: { type: "color" },
    $orange: { type: "color" },
    $yellow: { type: "color" },
    $green: { type: "color" },
    $teal: { type: "color" },
    $cyan: { type: "color" },
  },
};

export const general = {
  collapsed: true,
  sectionName: "General",
  variables: {
    "$body-color": { type: "color", title: "Body Text Color" },
    "$headings-color": { type: "color", title: "Headings Text Color" },
    "$component-active-bg": { type: "color", title: "Selected/Active Component Color" },
    "$enable-background-tinting": { type: "boolean", title: "Transparency" },
    "$enable-background-gradient": {
      type: "color-or-gradient",
      title: "Page Background Color",
      dependencies: [
        { key: "$body-bg", when: "false" },
        { key: "$body-bg-gradient", when: "true" },
      ],
    },
    "$border-color": { type: "color", title: "Content Divider Color" },
  },
};

export const cards = {
  collapsed: true,
  sectionName: "Cards",
  variables: {
    "$card-bg": { type: "color", title: "Card Body Background Color" },
    "$card-cap-bg": { type: "color", title: "Card Header Background Color" },
    "$card-cap-color": { type: "color", title: "Card Header Text Color" },
    "$card-border-color": { type: "color", title: "Card Border Color" },
    "$enable-card-shadows": { type: "boolean", title: "Card Shadows" },
    "$card-border-radius": {
      type: "options",
      supportedOpts: [
        { value: "0", label: "0px" },
        { value: "1px", label: "1px" },
        { value: "2px", label: "2px" },
        { value: "5px", label: "5px" },
        { value: "10px", label: "10px" },
        { value: "15px", label: "15px" },
      ],
    },
    "$enable-card-cap-body-padding": { type: "boolean", title: "Header and Footer Padding" },
  },
};

export const inputTheme = {
  collapsed: true,
  sectionName: "Inputs",
  variables: {
    // Inputs:
    "$input-color": { type: "color", title: "Input Text Color" },
    "$input-bg": { type: "color", title: "Input Background Color" },
    "$input-disabled-bg": { type: "color", title: "Disabled Input Background Color" },
    "$input-border-color": { type: "color", title: "Input Border Color" },
    "$input-group-addon-color": { type: "color", advanced: true },
    "$input-group-addon-bg": { type: "color", advanced: true },
    "$button-bg": { type: "color", title: "Button Background/Border Color" },
    "$btn-border-width": {
      title: "Button Border Width",
      advanced: true,
      type: "options",
      supportedOpts: [
        { value: "0", label: "0px" },
        { value: "1px", label: "1px" },
        { value: "2px", label: "2px" },
        { value: "3px", label: "3px" },
        { value: "4px", label: "4px" },
        { value: "5px", label: "5px" },
      ],
    },

    // Dropdowns Items:
    "$dropdown-link-color": { type: "color", title: "Dropdown Text Color", advanced: true },
    "$dropdown-link-hover-color": { type: "color", title: "Dropdown Text Hover Color", advanced: true },
    "$dropdown-link-hover-bg": { type: "color", title: "Dropdown Hover Background Color", advanced: true },
    // Buttons
    "$btn-font-weight": { type: "numeric", advanced: true },
    "$btn-line-height": { type: "numeric", advanced: true },
    "$custom-file-button-color": { type: "color", advanced: true },

    // Advanced
    "$dropdown-link-active-color": { type: "color", advanced: true },
    "$dropdown-link-active-bg": { type: "color", advanced: true },
    "$input-btn-padding-x": { type: "numeric", advanced: true },
    "$input-btn-padding-y-sm": { type: "numeric", advanced: true },
    "$input-btn-padding-x-sm": { type: "numeric", advanced: true },
    "$input-btn-padding-y-lg": { type: "numeric", advanced: true },
    "$input-btn-padding-x-lg": { type: "numeric", advanced: true },
    "$input-line-height": { type: "numeric", advanced: true },
    "$input-focus-border-color": { type: "color", advanced: true },
    "$input-placeholder-color": { type: "color", advanced: true },
    "$input-btn-padding-y": { type: "numeric", advanced: true },
    "$input-btn-border-width": { type: "numeric", advanced: true },
  },
};

export const fontTheme = {
  collapsed: true,
  sectionName: "Fonts",
  variables: {
    "$font-family-base": { type: "font", title: "Body Font" },
    "$headings-font-family": { type: "font", title: "Heading Font" },
    "$headings-font-weight": {
      type: "options",
      supportedOpts: [
        { value: "300", label: "Light" },
        { value: "400", label: "Regular" },
        { value: "600", label: "Semi Bold" },
        { value: "700", label: "Bold" },
      ],
    },
    "$link-color": { type: "color", title: "Link Text Color" },
    "$text-muted": { type: "color", title: "Muted/Inactive Text Color" },
    //Advanced
    "$text-shadow": { type: "text", advanced: true },
    "$font-size-base": { type: "numeric", advanced: true },
    "$h1-font-size": { type: "numeric", advanced: true },
    "$h2-font-size": { type: "numeric", advanced: true },
    "$h3-font-size": { type: "numeric", advanced: true },
    "$h4-font-size": { type: "numeric", advanced: true },
    "$h5-font-size": { type: "numeric", advanced: true },
    "$h6-font-size": { type: "numeric", advanced: true },
    "$web-font-path": { type: "text", advanced: true },
    "$font-family-serif": { type: "text", advanced: true },
    "$tooltip-font-size": { type: "numeric", advanced: true },
    "$font-weight-base": { type: "numeric", advanced: true },
    "$btn-font-family": { type: "text", advanced: true },
    "$btn-font-size": { type: "numeric", advanced: true },
    "$btn-font-size-sm": { type: "numeric", advanced: true },
    "$progress-font-size": { type: "numeric", advanced: true },
    "$blockquote-small-color": { type: "color", advanced: true },
    "$link-decoration": { type: "color", advanced: true },
  },
};

export const tableTheme = {
  collapsed: true,
  sectionName: "Tables",
  variables: {
    "$table-accent-bg": { type: "color", title: "Odd Row Background Color" },
    "$table-hover-bg": { type: "color", title: "Table Hover Background Color" },
    "$table-color": { type: "color", advanced: true },
    "$table-border-color": { type: "color", advanced: true },
    "$table-border-width": { type: "numeric", advanced: true },
  },
};

export const otherVariables = {
  collapsed: true,
  sectionName: "Other",
  hideSection: true,
  variables: {
    // Progress
    "$progress-height": { type: "numeric" },
    "$progress-bg": { type: "color" },
    "$progress-bar-bg": { type: "color" },
    "$progress-border-radius": { type: "numeric" },
    "$progress-bar-color": { type: "color" },

    // Lists
    "$list-group-bg": { type: "color" },
    "$list-group-border-color": { type: "color" },
    "$list-group-hover-bg": { type: "color" },
    "$list-group-disabled-bg": { type: "color" },
    "$list-group-action-active-bg": { type: "color" },
    "$list-group-active-color": { type: "color" },
    "$list-group-active-bg": { type: "color" },
    "$list-group-disabled-color": { type: "color" },
    "$link-hover-color": { type: "color" },
    "$list-group-active-border-color": { type: "color" },
    "$list-group-action-color": { type: "color" },
    "$list-group-action-active-color": { type: "color" },
    "$list-group-action-hover-color": { type: "color" },

    // Other
    "$pre-color": { type: "color" },
  },
};

export const ALL_VARIABLES = [
  brandColors,
  chartColors,
  generalColors,
  neutrals,
  general,
  // navigation, //TODO: uncomment when adding navigation config
  cards,
  inputTheme,
  fontTheme,
  tableTheme,
  otherVariables,
];
