const store = window.sessionStorage;
export default function (key) {
  return {
    get() {
      return JSON.parse(store.getItem(key));
    },
    getOrElse(defaultValue) {
      const cache = store.getItem(key);
      if (cache) {
        return JSON.parse(cache);
      } else {
        return defaultValue;
      }
    },
    hasValue() {
      const cache = store.getItem(key);
      return !!cache;
    },
    set(value) {
      store.setItem(key, JSON.stringify(value));
    },
    clear() {
      store.removeItem(key);
    },
  };
}
