// keep in sync with sever NotificationEventType
export const notificationTypes = [
  "ReviewReceived",
  "ReviewUpdated",
  "ReviewRespondedTo",
  "GoogleQuestionAndAnswer",
  "CredentialExpired",
  "WorkflowTaskApprovalNeeded",
  "WorkflowTaskOverdue",
  "WorkflowTaskCreated",
  "WorkflowTaskAssignedToYou",
  "WorkflowTaskCommentedOn",
  "WorkflowTaskCompleted",
  "WorkflowTaskUnapproved",
  "SocialPostFailed",
  "SocialPostPublished",
  "SocialCommentReceived",
] as const;

type NotificationEventType = (typeof notificationTypes)[number];

export function getNotificationVerb(notificationType: NotificationEventType): string {
  // TODO - needs concept of pluralization
  switch (notificationType) {
    case "ReviewReceived":
      return "has been received";
    case "ReviewUpdated":
      return "has been updated";
    case "ReviewRespondedTo":
      return "has been responded to";
    case "GoogleQuestionAndAnswer":
      return "has been posted";
    case "CredentialExpired":
      return "has expired";
    case "WorkflowTaskApprovalNeeded":
      return "needs approval";
    case "WorkflowTaskUnapproved":
      return "has been rejected";
    case "WorkflowTaskCreated":
      return "has been created";
    case "WorkflowTaskAssignedToYou":
      return "has been assigned to you";
    case "WorkflowTaskCommentedOn":
      return "has been commented on";
    case "WorkflowTaskOverdue":
      return "is now overdue";
    case "WorkflowTaskCompleted":
      return "is complete ";
    case "SocialPostFailed":
      return "encountered a publishing issue";
    case "SocialPostPublished":
      return "has published";
    case "SocialCommentReceived":
      return "has received a comment";
  }
}

export function getObjectType(eventType: NotificationEventType): string {
  switch (eventType) {
    case "ReviewReceived":
    case "ReviewUpdated":
    case "ReviewRespondedTo":
      return "review";
    case "GoogleQuestionAndAnswer":
      return "Google Q&A";
    case "CredentialExpired":
      return "credential";
    case "WorkflowTaskApprovalNeeded":
    case "WorkflowTaskCreated":
    case "WorkflowTaskAssignedToYou":
    case "WorkflowTaskCommentedOn":
    case "WorkflowTaskCompleted":
    case "WorkflowTaskOverdue":
    case "WorkflowTaskUnapproved":
      return "task";
    case "SocialPostFailed":
      return "social post";
    case "SocialPostPublished":
      return "social post";
    case "SocialCommentReceived":
      return "social post";
  }
}
