import { onceScriptLoaded } from "./scriptUtil";
import SessionStore from "../../../services/session/SessionStore";
import * as mobx from "mobx";
import AccessStore from "../../../services/locations/AccessStore";

/* Manual Surveys */
export const DEMO_SURVEY = "5ee91b61a8c95a00017168ae";

let isLoaded = false;
export function initialize() {
  const script = lazyInstall();
  const checkerFn = () => Iterate !== undefined; // Return true if script is loaded.
  onceScriptLoaded(script, checkerFn).then(() => setUpReactions());
}

export async function lazyInstall() {
  await import("./iterate.installer");
  return document.getElementById("iterate-js");
}

function setUpReactions() {
  isLoaded = true;
  mobx.reaction(
    () => {
      const user = SessionStore.user;
      const access = AccessStore.all[0];
      if (!user) return null;
      if (!access) return null;
      const count = access.totalLocations;
      const reseller = !!SessionStore.brandConfig.hostname || user.reseller;
      // Iterate API only accepts booleans, strings, or numbers.
      // An object with the user properties you want to track, e.g. email, user_id, name, etc. Can be null and props can be boolean, string, or number.
      // Special User Props: email, first_name, last_name.
      // If you send these properties you'll see the user's name or email shown on the dashboard next to their responses.
      return {
        userID: user.userId,
        username: user.username,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        userType: user.userType,
        accountID: user.accountId,
        reseller,
        billingAccountId: user.billingAccountId,
        ...(count > 0 ? { locationCount: count } : {}),
      };
    },
    (user) => {
      if (user) {
        Iterate("identify", user);
      }
    },
    {
      equals: mobx.comparer.structural,
      fireImmediately: true,
    }
  );
}

export function installSurvey(surveyId) {
  if (isLoaded) Iterate("install", surveyId);
}
export function uninstallSurvey(surveyId) {
  if (isLoaded) Iterate("uninstall", surveyId);
}
