import SessionStore from "../../../services/session/SessionStore";
import { RouteNode } from "../navigator/RouteNode";

/**
 * derives from mobx state. Consumers should be observers
 * @param {PermissionedRouteNode[]} routes
 * @param {boolean} ignoreSession
 * @returns {PermissionedRouteNode[]}
 */
export default function applyRouteAccessControl(routes: RouteNode[], ignoreSession = false): RouteNode[] {
  if (!ignoreSession && !SessionStore.sessionLoaded) {
    return [];
  } else {
    const filteredHighLevel = routes.filter((route) => {
      return route.isAccessible ? route.isAccessible() : true;
    });
    const withDrillInPagesFiltered = (filteredHighLevel || []).map((x) => {
      return {
        ...x,
        drillInPages: applyRouteAccessControl(x.drillInPages || [], ignoreSession),
      };
    });
    return withDrillInPagesFiltered;
  }
}
