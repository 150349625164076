import * as React from "react";
import * as PropTypes from "prop-types";
import OktaAuth from "@okta/okta-auth-js";
import { GenericLoginWithPasswordData } from "./GenericLoginWithPasswordData";

export class LoginWithOkta extends React.Component {
  static propTypes = {
    onLoginSuccess: PropTypes.func,
    brandConfig: PropTypes.object.isRequired,
  };

  onSubmit = ({ username, password }) => {
    const oktaAuth = new OktaAuth({
      url: this.props.brandConfig.oktaUrl,
    });
    const samlUrl = this.props.brandConfig.samlUrl;

    // here we do the okta authentication and then do an redirect.
    return oktaAuth
      .signIn({
        username,
        password,
      })
      .then((transaction) => {
        if (transaction.status === "SUCCESS") {
          oktaAuth.session.setCookieAndRedirect(transaction.sessionToken, samlUrl);
        } else {
          return {
            isKnownInvalid: true,
            errorMessage: "Could not authenticate user.",
          };
        }
      });
  };

  render() {
    return (
      <GenericLoginWithPasswordData
        brandConfig={this.props.brandConfig}
        onLoginSuccess={this.props.onLoginSuccess}
        onSubmit={this.onSubmit}
      />
    );
  }
}
