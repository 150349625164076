export default {
  duplicateListings: "This report shows any detected duplicate listings, broken down by provider",
  listingOptimization:
    "This report shows all listings which have incorrect data, aren't claimed, or have poor user participation.",
  listingSummary: "This report shows a summary of all listings found for your monitored locations",
  avgSEORankings: "This report indicates the average ranking for the keywords selected for your store(s).",
  page1SEORankings: "This report indicates the # of stores that are on Page 1 for your primary category.",
  selectReportTemplate: "Create a pre-built report - ready to go out of the box",
  competitors: `By default, we have selected competitors that have the best overall marketing metrics in your area. This allows you to benchmark against the best.\n\n- You can add competitors once you remove one from the list.\n- You can only have up to 5 competitors.\n- You can also edit the competitor to update the business details like you can for your own location.`,
  lbvScoreSummary: `The LBV score is an aggregate of the most important performance
  metrics tied to reputation (Reviews), social media activity (Social) and local search
  (Listings and Search). Customer activity levels, listing accuracy, rankings, customer ratings and
  your Competitors all factor into the score. In general, a score above 70 is reflective of an industry
  leader, a score between 50 and 70 is average and a score below 50 is poor.`
    .replace(/\n/gi, "")
    .replace(/\t/g, ""),
};
