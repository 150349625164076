import * as SessionApi from "./SessionApi";
import { action, observable, runInAction, makeObservable, makeAutoObservable, computed } from "mobx";
import { setAuthToken } from "./apiClient";
import * as localStorage from "../storage/localStorage";
import * as sessionStorage from "../storage/sessionStorage";
import localCache, { removeExcessLocalStorageCaches } from "../../util/localCache";
import {
  AuthenticationResponse,
  isJwtAuthenticationResponse,
  isTokenAuthenticationResponse,
  JWTTokenAuthenticationResponse,
  TokenTimeoutResponse,
} from "./Authentication";

const SSOTOKEN = "ssoToken";
const TOKEN = "token";
const AUTHRESPONSE = "authResponse";
const SSOAUTHRESPONSE = "ssoAuthResponse";

/**
 * I contain the login tokens, and information about the permanence of the login token
 *
 * Username+Password
 * SSO token
 * Okta
 */
export class LoginStore {
  /** set to true after attempting login with stored token or sso token.
   * Tracked so that initialization code can wait until this is true
   * (e.g. wait to fetch the default brand/theme config. Might be a token that should be used instead)
   **/
  attemptedAutomatedLogin = false;

  /** @type {string|undefined} - the authentication token for API requests*/
  token: string | null = null;

  /** ISO UTC timestamp string. Time that the current token is valid until */
  tokenTimeout: string | null = null;
  /** ISO UTC timestamp string. Time that the current token is valid until, can be extended regularly to keep session active */
  tokenInactivityTimeout: string | null = null;

  ssoSessionExpired = false;

  /**
   * is the application running in a render-only context? e.g. for rendering analytics reports.
   * mobx stores further down the dependency chain should not fetch data if this is true. (e.g. don't fetch brand config).
   * Reports will need to manually set up stores with injected data.
   **/
  renderOnly = false;

  constructor() {
    makeObservable(this, {
      attemptedAutomatedLogin: observable,
      token: observable,
      tokenTimeout: observable,
      tokenInactivityTimeout: observable,
      ssoSessionExpired: observable,
      renderOnly: observable,
      isLoggedIn: computed,
      setRenderOnly: action,
      setAttemptedAutomatedLogin: action,
      tryLoginPassword: action,
      tryLoginLocalStorage: action,
      tryLoginSSO: action,
      logout: action,
      setJWT: action,
      setToken: action,
      refreshJWT: action,
    });
  }

  /**
   * true if we're logged from an impermanent sso token (e.g. uses sessionStorage)
   * There's a fair amount of logic that checks this... seems a little overloaded. SSO != transient, so its confusing
   * @return {boolean}
   **/
  get isSSO(): boolean {
    return !!window.sessionStorage.ssoToken;
  }

  /**
   * true if a successful login is active
   * @return {boolean}
   */
  get isLoggedIn(): boolean {
    return !!this.token;
  }

  isJWTActive(authRsponse: AuthenticationResponse): boolean {
    return isJwtAuthenticationResponse(authRsponse);
  }

  setRenderOnly(): void {
    this.renderOnly = true;
  }

  setAttemptedAutomatedLogin(): void {
    this.attemptedAutomatedLogin = true;
  }
  /**
   *
   * tries logging in with the username and password.
   *
   * if authentication fails, results `authenticated` property is falsey (does not throw/fail promise)
   *
   * @param username
   * @param password
   * @return {Promise.<{authenticated: boolean, errorMessage?: string, credentialsInvalid?: boolean}>}
   */
  tryLoginPassword(username: string, password: string): Promise<AuthenticationResponse> {
    return SessionApi.createSession(username, password)
      .then((authResponse) => {
        localStorage.setObject(AUTHRESPONSE, authResponse);
        if (!authResponse.authenticated) {
          return {
            ...authResponse,
            errorMessage: authResponse.errorMessage || "Could not authenticate user.",
            credentialsInvalid: true,
          };
        } else if (isJwtAuthenticationResponse(authResponse)) {
          this.setJWT(authResponse);
        } else if (isTokenAuthenticationResponse(authResponse)) {
          this.setToken(authResponse.token, {
            timeout: authResponse.timeout,
            inactivityTimeout: authResponse.inactivityTimeout,
          });
        } else {
          throw new Error("unsupported auth response type " + authResponse.type);
        }
        return authResponse;
      })
      .catch((ex) => {
        console.error("unexpected login exception", ex);
        return {
          type: "AuthenticationResponse",
          authenticated: false,
          errorMessage: "An unknown error occurred. Is the network available? Try reloading the page",
          credentialsInvalid: false,
        } as AuthenticationResponse;
      });
  }

  /**
   * checks local/session storage for a previously authenticated token and logs in with it
   * @return {void}
   */
  tryLoginLocalStorage(): void {
    if (!this.isLoggedIn) {
      const ssoToken = sessionStorage.getItem(SSOTOKEN);
      const token = localStorage.getItem(TOKEN);
      if (ssoToken) {
        this.setToken(ssoToken, { temporary: true });
      } else if (token) {
        this.setToken(token);
      }
      this.setAttemptedAutomatedLogin();
    }
  }

  /**
   * attempts a login with the ssoToken
   * @param ssoToken - the token to validate
   * @param persistent - whether to maintain this session across new browser windows
   * @return {Promise.<void>} - promise is rejected on failure
   */
  tryLoginSSO = (ssoToken: string, { persistent = false }): Promise<AuthenticationResponse> => {
    return SessionApi.getLoginSSO(ssoToken)
      .then((authResponse) => {
        sessionStorage.setObject(SSOAUTHRESPONSE, authResponse);
        if (!authResponse.authenticated) {
          return {
            ...authResponse,
            errorMessage: authResponse.errorMessage || "Could not authenticate user.",
            credentialsInvalid: true,
          };
        } else if (isJwtAuthenticationResponse(authResponse)) {
          this.setJWT(authResponse, true);
        } else if (isTokenAuthenticationResponse(authResponse)) {
          this.setToken(authResponse.token, {
            temporary: !persistent,
            timeout: authResponse.timeout,
            inactivityTimeout: authResponse.inactivityTimeout,
          });
        } else {
          throw new Error("unsupported authentication response type " + authResponse.type);
        }
        return authResponse;
      })
      .finally(() => {
        this.setAttemptedAutomatedLogin();
      });
  };

  /**
   * Logs out by clearing out any local/session persistence and invalidating the api token server side
   * @return {*}
   */
  logout = (suppressLogoutCall = false): void => {
    let authResponse = this.getAuthResponse();
    if (window.sessionStorage.ssoToken) {
      sessionStorage.removeItem(SSOTOKEN);
      this.ssoSessionExpired = true;
    } else {
      if (authResponse && isJwtAuthenticationResponse(authResponse) && !suppressLogoutCall) {
        SessionApi.logout(authResponse.refreshToken!);
      }
      localStorage.removeItem(TOKEN);
      localStorage.removeItem(AUTHRESPONSE);
    }

    runInAction(() => {
      this.token = null;
      this.tokenTimeout = null;
      this.tokenInactivityTimeout = null;
    });
  };

  setJWT(authResponse: JWTTokenAuthenticationResponse, temporary = false): void {
    if (!authResponse.jwt) {
      throw new Error("JWT must be specified");
    }
    let jwtAuthBearer = "Bearer " + authResponse.jwt;
    removeExcessLocalStorageCaches();
    setAuthToken(jwtAuthBearer);
    if (temporary) {
      sessionStorage.setItem(SSOTOKEN, jwtAuthBearer);
      window.sessionStorage.ssoToken = jwtAuthBearer;
    } else {
      localStorage.setItem(TOKEN, jwtAuthBearer);
      window.localStorage.token = jwtAuthBearer;
    }
    this.token = jwtAuthBearer;
  }

  getAuthResponse(): JWTTokenAuthenticationResponse {
    return this.isSSO ? sessionStorage.getObject(SSOAUTHRESPONSE) : localStorage.getObject(AUTHRESPONSE);
  }

  getTokenExpiration(): number | undefined {
    return this.getAuthResponse().expiration;
  }

  /**
   *
   * considers the token valid, and stores it
   * @param {string} token - authentication token
   * @param {boolean} [temporary=false] - if true, keep the token in session storage
   * @param {string} [timeout] - timestamp the token will expire
   * @param {string} [inactivityTimeout] - timestamp the token will expire due to inactivity
   */
  setToken(
    token?: string,
    {
      temporary = false,
      timeout,
      inactivityTimeout,
    }: {
      temporary?: boolean;
      timeout?: string;
      inactivityTimeout?: string;
    } = {}
  ): void {
    if (!token) {
      throw new Error("must specify token");
    }
    removeExcessLocalStorageCaches();
    setAuthToken(token);
    if (temporary) {
      sessionStorage.setItem(SSOTOKEN, token);
      window.sessionStorage.ssoToken = token;
    } else {
      localStorage.setItem(TOKEN, token);
      window.localStorage.token = token;
    }
    this.token = token;
    this.tokenTimeout = timeout || null;
    this.tokenInactivityTimeout = inactivityTimeout || null;
  }

  async extendSession(): Promise<TokenTimeoutResponse> {
    const resp = await SessionApi.extendSession();
    const { isValid, inactivityTimeout } = resp;
    if (isValid) {
      this.tokenInactivityTimeout = inactivityTimeout || null;
    }
    return resp;
  }

  refreshJWT({ updateSessionTimeOut = true }): Promise<AuthenticationResponse> {
    let refreshToken = this.getAuthResponse().refreshToken;
    return SessionApi.refreshJWT(refreshToken!, updateSessionTimeOut).then((authResponse) => {
      if (authResponse.authenticated && isJwtAuthenticationResponse(authResponse)) {
        if (this.isSSO) {
          sessionStorage.setObject(SSOAUTHRESPONSE, authResponse);
        } else {
          localStorage.setObject(AUTHRESPONSE, authResponse);
        }
        this.setJWT(authResponse, this.isSSO);
        this.setAttemptedAutomatedLogin();
        return authResponse;
      } else {
        this.logout(true);
        this.setAttemptedAutomatedLogin();
        return {
          ...authResponse,
          errorMessage: authResponse.errorMessage || "Could not authenticate user.",
          credentialsInvalid: true,
        };
      }
    });
  }

  /**
   * @deprecated - Cache disabled due to localstorage quota exceeded and app crashed. Doesn't seem terribly useful anyways. Should be refactored away
   * Calls the specified callback with the result from the passed in promise. Also calls the callback synchronously with a persisted
   * cached result if available in order to get faster results. (cached result is keyed by the current login token to prevent sharing results)
   *
   * It's a common situation to have to run some expensive API calls to initialize some state
   * when first logging in. Often times this state is needed to start rendering useful information.
   * If the API call is slow, it halts rendering.
   *
   * @param {string} key - a unique identifier to identify this cached value
   * @param {Promise} promise - a promise returning a json stringifiable value
   * @param {Function} callback - a reaction called 1 or 2 times. once with the result of the promise, and once with a previous result if available
   */
  runUsingCachedResult<T>({
    key,
    promise,
    callback,
  }: {
    key: string;
    promise: Promise<T>;
    callback: (result: T) => void;
  }): void {
    promise.then(callback);
  }
}

export default new LoginStore();
