import * as mobx from "mobx";
import moment from "moment";
import LoginStore from "./LoginStore";
import UserActivityStore from "./UserActivityStore";

/**
 * call this function to start a monitor that keeps the session alive by regularly extending the session
 * as it gets closer to expiration, but only while the user is active. Coordinates with LoginStore and
 * UserActivityStore extend token, (and decide when to extend the tokens)
 * @returns {function} that may be called to stop the heartbeat
 */
export default function startSessionActivityHeartbeat() {
  const cancelToken = startSessionTokenHeartbeat();
  const cancelJWT = startJWTHeartbeat();

  return () => {
    cancelToken();
    cancelJWT();
  };
}

const REFRESH_WINDOW = 30000; // milliseconds before expiration to refresh at
const DELAY_RETRY = 30000;

/**
 * renews JWT token if JWT token is in use.
 * Additionally extends session duration while user is active.
 *
 *
 *  legend:
 *  - - future time
 *  x - active
 *  o - inactive
 *
 *
 *  t0
 *  B-------J----------------S------------------------R
 *  ^ begin ^ jwt timeout    ^ session timeout        ^ refresh timeout
 *
 *  While active: refresh both JWT and session. Refresh occurs right before JWT expires
 *  ============
 *
 *  t1
 *  BxxooooT-------J---------------S-----------R
 *        ^ refresh all
 *  t2
 *  BxxoooooooooxoT-------J---------------S----R
 *                ^ refresh all
 *
 *  If inactive: refresh just JWT. Refresh still occurs right before JWT expires
 *  ===========
 *
 *  t3
 *  BxxoooooooooxooooooooT-------J-------S----R
 *                       ^ refresh       ^ session timeout not extended
 *
 *
 *
 */
function startJWTHeartbeat() {
  let timer;
  let wasActive = true;

  function scheduleJWTRefreshIn(milliseconds) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(async () => {
      try {
        await LoginStore.refreshJWT({ updateSessionTimeOut: wasActive || UserActivityStore.isActive });
        wasActive = UserActivityStore.isActive;
        const delay = chooseJWTRefreshDelay(Date.now());
        scheduleJWTRefreshIn(delay);
      } catch (er) {
        console.error(`Unable to refresh JWT. Retrying in ${DELAY_RETRY}ms`, er);
        scheduleJWTRefreshIn(DELAY_RETRY);
      }
    }, milliseconds);
  }

  function chooseJWTRefreshDelay(timestamp) {
    let delay = millisecondsUntilTokenExpiration(timestamp) - REFRESH_WINDOW;
    if (delay < 0) delay = DELAY_RETRY;
    return delay;
  }

  function millisecondsUntilTokenExpiration(timestamp) {
    let expiration = LoginStore.getTokenExpiration();
    return expiration - timestamp;
  }

  const jwtDisposer = mobx.reaction(
    () => LoginStore.isLoggedIn,
    (shouldKeepAlive) => {
      let authResponse = LoginStore.getAuthResponse();
      if (shouldKeepAlive && authResponse && LoginStore.isJWTActive(authResponse)) {
        scheduleJWTRefreshIn(chooseJWTRefreshDelay(Date.now()));
      } else {
        clearTimeout(timer);
      }
    },
    { fireImmediately: true }
  );

  const isActiveDisposer = mobx.reaction(
    () => LoginStore.isLoggedIn && UserActivityStore.isActive,
    (isActive) => (wasActive = wasActive || isActive),
    { fireImmediately: false }
  );

  return () => {
    jwtDisposer();
    isActiveDisposer();
    clearTimeout(timer);
  };
}

/** renews legacy session token if its in use */
function startSessionTokenHeartbeat() {
  let timer;

  /**
   * determines a good delay before extending the session again.
   * If the expiration is near (minutes away), sends frequent requests to ensure
   * the session doesn't expire from time drift or latency,
   * otherwise it sends much less frequent updates.
   * @param {string} timestamp - timestamp that the token will expire
   */
  function chooseDelay(timestamp) {
    const msUntilExpiry = moment(timestamp).valueOf() - Date.now();
    // if expiration is very near (<2 minutes) every 10s until further out)
    // otherwise schedule halfway before expiration, --but at least 60s out, and at most an hour out
    const delay = msUntilExpiry < 120000 ? 10000 : Math.max(60000, Math.min(msUntilExpiry / 2, 1000 * 60 * 60));
    return delay;
  }

  function scheduleRenewalIn(milliseconds) {
    timer = setTimeout(async () => {
      try {
        const { inactivityTimeout, isValid } = await LoginStore.extendSession();
        if (inactivityTimeout) {
          const delay = chooseDelay(inactivityTimeout);
          scheduleRenewalIn(delay);
        } else if (!isValid) {
          console.warn("session no longer active");
        }
      } catch (er) {
        console.error("unable to extend session. Retrying in a minute", er);
        scheduleRenewalIn(60000);
      }
    }, milliseconds);
  }

  const disposer = mobx.reaction(
    () => LoginStore.isLoggedIn && UserActivityStore.isActive,
    (shouldKeepAlive) => {
      let authResponse = LoginStore.getAuthResponse();
      if (shouldKeepAlive && authResponse && !LoginStore.isJWTActive(authResponse)) {
        if (!LoginStore.tokenInactivityTimeout) {
          scheduleRenewalIn(0);
        } else {
          scheduleRenewalIn(10000);
        }
      } else {
        clearTimeout(timer);
      }
    },
    { fireImmediately: true }
  );
  return () => {
    disposer();
    clearTimeout(timer);
  };
}
