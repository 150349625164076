import React from "react";
import { getPathAndQuery } from "../../util/UrlFragmentSearch";

/**
 * @typedef {object} SiteMap
 * @property {string} id
 * @property {SiteMapNode[]} routes
 * @property {boolean} [hidenav]
 * @property {boolean} [drillIn]
 */

/**
 * @typedef {object} SiteMapNode
 * @property {string} path
 * @property {string} title
 * @property {string} [icon]
 * @property {boolean} [isConfigPortal]
 * @property {SiteMapNode[]} [children]
 */

/** @type {SiteMap[]} */
export const billingAdminSiteMap = {
  siteMapTitle: "System Tools",
  drillIn: true,
  routes: [
    {
      sectionTag: "Customers",
      icon: "far fa-dollar-sign",
      title: "Billing Accounts",
      path: "/chatmeterAdmin/billingAccounts",
    },
    {
      sectionTag: "Customers",
      path: "/admin/v2/changeLogs",
      title: "Change Logs",
      icon: "fas fa-search-plus",
    },
    {
      sectionTag: "Categories",
      icon: "fas fa-th-list",
      title: "Keywords",
      path: "/chatmeterAdmin/keywords",
    },
    {
      sectionTag: "Categories",
      icon: "fas fa-industry-alt",
      title: "Verticals",
      path: "/chatmeterAdmin/verticals",
    },
    {
      sectionTag: "Listings",
      icon: "far fa-clock",
      title: "LLM Recent Locations",
      path: "/listingManagement/listingManagementStats/recentLocations",
    },
    {
      sectionTag: "Listings",
      icon: "fas fa-chart-area",
      title: "Listing Summary",
      path: "/listingSummary",
    },
    {
      sectionTag: "Listings",
      icon: "far fa-sync-alt",
      title: "LLM Publishing",
      path: "/admin/listingManagement",
    },
    {
      sectionTag: "Listings",
      icon: "fas fa-arrow-left",
      title: "Advice Local Back-Sync",
      path: "/adviceLocalBackSync",
    },
    {
      sectionTag: "Jobs",
      icon: "fas fa-coffee",
      title: "Manage Tool",
      path: "/manageTool",
    },
    {
      sectionTag: "Jobs",
      icon: "fas fa-cloud-upload",
      title: "Manage Tool Uploader",
      path: "/chatmeterAdmin/manageToolUploader",
    },
    {
      sectionTag: "Jobs",
      icon: "far fa-stethoscope",
      title: "Healthmeter",
      path: "/healthmeter",
    },
    {
      sectionTag: "Jobs",
      icon: "far fa-chart-bar",
      title: "Listing Health Stats",
      path: "/healthmeter/listingStats/summary",
    },
    {
      sectionTag: "Integrations",
      icon: "fab fa-yelp",
      title: "Yelp Subscriptions",
      path: "/yelpSubscription",
    },
  ],
};
