import config from "../../config";
import req from "../../modules/social/req";

export function trace(message, extraData = {}) {
  sendLogMessage("trace", message, extraData);
}
export function debug(message, extraData = {}) {
  sendLogMessage("debug", message, extraData);
}
export function info(message, extraData = {}) {
  sendLogMessage("info", message, extraData);
}
export function warn(message, extraData = {}) {
  sendLogMessage("warn", message, extraData);
}
export function error(message, extraData = {}) {
  sendLogMessage("error", message, extraData);
}

function sendLogMessage(level, message, extraData) {
  console.log("sending remote log", level, message, extraData);
  fetch(req.json(config.apiUrl + `/log/${level}/${encodeURIComponent(message)}`, { body: extraData }));
}
