import * as metrics from "./metricPlugin";
import * as iterateUserFeedback from "./iterateUserFeedbackPlugin";
import * as localize from "./localizePlugin";
import * as zendesk from "./zendeskPlugin";
import * as userguiding from "./userguidingPlugin";

export function install() {
  metrics.install();
  localize.initialize();
  zendesk.install();
  userguiding.initialize();
  iterateUserFeedback.initialize();
}

export function uninstall() {
  metrics.uninstall();
  localize.uninitialize();
  zendesk.uninstall();
  userguiding.uninitialize();
}
