import React from "react";

const LoadingIcon = (props) => {
  return (
    <span {...props} className="loading-icon-centered">
      <i className="fas fa-spinner fa-spin fa-3x" />
      <span className="sr-only loading">Loading...</span>
    </span>
  );
};

export default LoadingIcon;
