import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { UncontrolledTooltip } from "reactstrap";
import uuid from "uuid/v4";
import { action, observable } from "mobx";
import { NameChip } from "../";
import ProvidersStore from "../../services/providers/ProvidersStore";
import * as mobx from "mobx";
import config from "../../config";
import hasSVG from "./hasSVG";
import { generateProviderUrl } from "./helpers";

const svgFilePath = "/images/providers/";
const pngFilePath = "/images/reviews/";

const formatImgURL = (str) => str.replace(/ /g, "").replace(".com", "");

const disabledProviders = mobx.observable.map();

const failUrl = mobx.action((url) => {
  disabledProviders.set(url, true);
});

export const ProviderImage = observer(
  class ProviderImage extends Component {
    static propTypes = {
      provider: PropTypes.string.isRequired,
      providers: PropTypes.any.isRequired,
      nocolor: PropTypes.bool,
      tooltip: PropTypes.string,
      size: PropTypes.string,
    };

    static defaultProps = {
      providers: ProvidersStore.byId,
    };

    constructor(props) {
      super(props);
      this.id = `_${uuid()}`;
    }

    getUrl() {
      const { provider, providers, nocolor } = this.props;
      const options = [];
      const providerHasSVG = provider.toLowerCase() in hasSVG;
      options.push(providers && providers[provider] && providers[provider].imageUrl);

      if (nocolor && providerHasSVG) {
        options.push(`${config.assetUrl}${svgFilePath}${formatImgURL(provider.toLowerCase())}-line.svg`);
      }
      options.push(generateProviderUrl(provider, config.assetUrl, providerHasSVG));
      options.push(generateProviderUrl(provider, config.assetUrl));

      return options.filter((x) => !!x && !disabledProviders.has(x));
    }

    render() {
      const { provider, providers, tooltip, size, multi, className, ...otherProps } = this.props;
      const url = this.getUrl()[0];
      let display = [
        <span
          className={`cm-provider-image ${provider.toLowerCase()} ${multi ? "multi" : ""} ${className || ""} ${
            size || ""
          }`}
          {...otherProps}
          id={this.id}
          key={this.id}
        >
          {!url ? (
            <NameChip size={size} name={provider} />
          ) : (
            <img src={url} alt={provider || ""} onError={(e) => failUrl(url)} />
          )}
        </span>,
      ];
      if (multi) {
        [1, 2].map((idx) => {
          display.push(
            <span
              className={`cm-provider-image inception inception-${idx} ${className || ""} ${size || ""}`}
              {...otherProps}
              id={this.id}
              key={`inception-${idx}`}
            >
              {!url ? (
                <NameChip size={size} name={provider} />
              ) : (
                <img src={url} alt={provider || ""} onError={(e) => failUrl(url)} />
              )}
            </span>
          );
        });
      }
      if (!!tooltip) {
        display.push(
          <UncontrolledTooltip key={`${this.id}-tooltip`} placement="top" target={this.id}>
            {tooltip}
          </UncontrolledTooltip>
        );
      }

      return <span className={`cm-provider-image-wrapper ${multi ? "multi" : ""}`}>{display}</span>;
    }
  }
);
