import { marked } from "marked";
import sanitize from "sanitize-html";

import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * returns just the text content of markdown without meta chars
 * @param markdown
 */
export function stripMarkdown(markdown) {
  const el = document.createElement("span");
  el.innerHTML = marked(markdown);
  return el.textContent;
}

export class MarkdownRenderer extends Component {
  static propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getHtmlFromMarkdown(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.getHtmlFromMarkdown(nextProps);
  }

  getHtmlFromMarkdown(props) {
    let isChanged = !this.state.html || props.value !== this.props.value;
    if (isChanged) {
      let input = props.value ? marked(props.value) : "";
      let __html = sanitize(input, {
        allowedTags: sanitize.defaults.allowedTags.concat(["img", "h1", "h2"]),
        transformTags: {
          a: (tagName, attribs) => ({
            // make all links open in new tab
            tagName,
            attribs: { ...attribs, target: "_blank", rel: "noopener" },
          }),
        },
      });
      this.setState({ html: { __html: __html } });
    }
  }

  render() {
    const { value, placeholder, ...props } = this.props;
    const { html } = this.state;
    if ((!html || !html.__html) && placeholder) {
      return (
        <div {...props} className="form-control">
          <span className="text-muted">{placeholder}</span>
        </div>
      );
    } else {
      return <div className="markdown-preview" {...props} dangerouslySetInnerHTML={html} />;
    }
  }
}
