import sessionStore from "../session/SessionStore";
import { ACCOUNT_TYPE, ALL_TYPE, GROUP_TYPE, LEGACY_TYPE, LOCATION_TYPE, USER_TYPE } from "./entityTypes";

export function getLegacyAll() {
  return sessionStore.user
    ? `${ALL_TYPE.toLowerCase()}-${sessionStore.user.username.replace(/ /g, "_")}`
    : `${ALL_TYPE.toLowerCase()}-`;
}

export function getUserEntityId() {
  if (!sessionStore.user) throw new Error("session must be booted to convert a legacy user id");
  else {
    return `${USER_TYPE}-${sessionStore.user.userId}`;
  }
}

/** returns a legacy group id for the specified entity id */
export function toLegacyGroupId(entityId) {
  const { entityType, id } = entityIdStringToStructuredEntityId(entityId);
  switch (entityType) {
    case USER_TYPE:
      return getLegacyAll();
    case GROUP_TYPE:
      return id;
    case ACCOUNT_TYPE:
      return `${ACCOUNT_TYPE.toLowerCase()}-${id}`;
    case LOCATION_TYPE:
      return `${LEGACY_TYPE.toLowerCase()}-${id}`;
    default:
      throw new Error(`unknown entity prefix ${entityType}`);
  }
}

export function entityIdStringToStructuredEntityId(entityIdString) {
  const split = entityIdString.split("-");
  const entityType = split[0];
  const id = split.slice(1).join("-");
  return { entityType, id };
}

/** returns an entity id for the specified legacy group id */
export function toEntityId(groupId) {
  const split = groupId.split("-");
  switch (split[0]) {
    case ALL_TYPE.toLowerCase():
      return getUserEntityId();
    case ACCOUNT_TYPE.toLowerCase():
      return `${ACCOUNT_TYPE}-${split[1]}`;
    case LEGACY_TYPE.toLowerCase():
      return `${LOCATION_TYPE}-${split[1]}`;
    default:
      return `${GROUP_TYPE}-${groupId}`;
  }
}
