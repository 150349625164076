import React from "react";

const RegionCardHeader = ({
  title = "",
  titleClasses = "",
  className = "",
  customStyle = {},
  /** Deprecated */
  classNames = "",
  childrenWrapperClassName = "",

  children,
  hasButtons = false,
}: {
  title?: string;
  titleClasses?: string;
  className?: string;
  customStyle?: React.CSSProperties;
  classNames?: string;
  childrenWrapperClassName?: string;
  children?: React.ReactNode;
  hasButtons?: boolean;
}) => {
  const childrenArray = React.Children.toArray(children);
  return (
    <div
      className={`card-header ${classNames ? classNames : ""} ${className ? className : ""} ${
        hasButtons ? "pt-0" : ""
      }`}
      style={customStyle}
    >
      <h2 className={`card-title-lg ${titleClasses ? titleClasses : ""} ${hasButtons ? "card-padding-top" : ""}`}>
        {title}
      </h2>
      {!!childrenArray.length && (
        <div
          className={`${hasButtons ? "" : "card-padding-left card-padding-right"} ${
            childrenWrapperClassName ? childrenWrapperClassName : ""
          }`}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default RegionCardHeader;
