/** interface over window sessionStorage in case we need to modify or make more compatible */

export function getItem(key) {
  return window.sessionStorage.getItem(key);
}

export function setItem(key, value) {
  return window.sessionStorage.setItem(key, value);
}

export function getObject(key) {
  return JSON.parse(window.sessionStorage.getItem(key));
}

export function setObject(key, value) {
  return window.sessionStorage.setItem(key, JSON.stringify(value));
}

export function removeItem(key) {
  return window.sessionStorage.removeItem(key);
}
