import React from "react";
import { NavigationNode } from "../navigator/RouteNode";

type NavMenuItemTitleProps = Pick<NavigationNode, "title" | "icon" | "altText"> & {
  isRoot?: boolean;
};

const NavMenuItemTitle = ({ title, icon, altText, isRoot }: NavMenuItemTitleProps) => {
  const updatedTitle = getTitle(title);
  const ariaTitle = altText || updatedTitle;
  if (isRoot || (!isRoot && icon)) {
    return (
      <>
        <span className="fa-fw fas">{getIcon(icon)}</span>
        <span className="content-spacer-left mr-auto" aria-label={ariaTitle}>
          {updatedTitle}
        </span>
      </>
    );
  }

  return (
    <div className="ml-3">
      <span aria-label={ariaTitle}>{updatedTitle}</span>
    </div>
  );
};

const getTitle = (title: string | (() => string)) => {
  return typeof title === "function" ? title() : title;
};

const getIcon = (Icon?: string | React.FunctionComponent<React.SVGAttributes<SVGElement>>) => {
  if (!Icon) {
    return null;
  }
  return typeof Icon === "function" ? <Icon /> : <span className={`${Icon}`} />;
};

export default NavMenuItemTitle;
