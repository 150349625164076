import React, { useMemo, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import uuid from "uuid/v4";
import PropTypes from "prop-types";
import "./HelpIcon.css";

/** A little question mark that triggers a tooltip when hovered */
export default function HelpIcon({ placement = "top", style, message = "", id }) {
  const triggerId = useMemo(() => id || `help-icon-${uuid()}`, [id]);
  const [triggerEl, setTriggerEl] = useState(null);
  return (
    <div className="cm-help-icon position-relative d-inline-flex" style={style}>
      {triggerEl && (
        <UncontrolledTooltip className="text-left" innerClassName="text-left" target={triggerId} placement={placement}>
          {typeof message === "function" ? message() : message}
        </UncontrolledTooltip>
      )}
      <i className="fas fa-question-circle" href="#" id={triggerId} ref={setTriggerEl} />
    </div>
  );
}

HelpIcon.propTypes = {
  placement: PropTypes.string,
  style: PropTypes.object,
  message: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  id: PropTypes.string,
};
