import client from "../session/apiClient";
import { chunk, flatten } from "lodash";
import defaultSessionStore from "../session/SessionStore";
import { UserPreferences } from "../session/UserPreferences";
import { User, UserWithLocationTotal } from "../session/Authentication";

export function listUsers({
  query = undefined,
  offset = 0,
  limit = 100,
  ...otherParams
}): Promise<{ hasMore: boolean; users: User[] }> {
  return client
    .get("/v5/users", {
      params: {
        query,
        offset,
        limit,
        skipLocationTotals: true,
        ...otherParams,
      },
    })
    .then(({ data }: { data: { hasMore: boolean; users: User[] } }) => {
      return data;
    });
}

export function listWorkflowUsers({
  query = undefined,
  offset = 0,
  limit = 100,
  locationId = undefined,
}): Promise<{ hasMore: boolean; users: User[] }> {
  return client
    .get("/v5/workflowUsers", {
      params: {
        query,
        limit,
        offset,
        locationId,
        skipLocationTotals: true,
      },
    })
    .then(({ data }: { data: { hasMore: boolean; users: User[] } }) => {
      return data;
    });
}

export function listSocialDraftUsers({
  query = undefined,
  offset = 0,
  limit = 100,
}): Promise<{ hasMore: boolean; users: User[] }> {
  return client
    .get("/v5/socialDraftUsers", {
      params: {
        query,
        limit,
        offset,
        skipLocationTotals: true,
      },
    })
    .then(({ data }: { data: { hasMore: boolean; users: User[] } }) => {
      return data;
    });
}

export function getUsers(userId: string | string[]): Promise<{ users: UserWithLocationTotal[] }> {
  return client
    .get("/v5/users", {
      params: {
        userId,
        skipLocationTotals: true,
        limit: userId && userId.length ? userId.length : 1,
      },
    })
    .then((resp) => resp.data);
}

export function getUser(userId: string): Promise<User> {
  return client.get(`/v5/user/${userId}`).then((resp) => resp.data);
}

export function userPreferences(userId?: string): Promise<UserPreferences> {
  return client.get(`/v5/user/preferences`, { params: { userId } }).then((x) => x.data);
}

export function upsertUserPreferences({
  userId,
  userPreferences,
}: {
  userId: string;
  userPreferences: Partial<UserPreferences>;
}): Promise<void> {
  return client.post(`/v5/user/preferences/${userId}`, userPreferences).then(() => {});
}

/** TODO - yuck, this should be on the store. This is circular dependencies */
export function markAsShown(userguidingId: number) {
  const userPrefs = defaultSessionStore.userPreferences!;
  userPrefs.seenGuides.push(userguidingId);
  upsertUserPreferences({ userId: userPrefs.userId, userPreferences: userPrefs });
}

export function userSummaries(userId: string) {
  const chunked = chunk(userId, 50); // Long query strings break

  const chunkedPromises = chunked.map((chunk) =>
    client
      .get("/userSummaries", {
        params: {
          userId: chunk,
        },
      })
      .then((x) => x.data)
  );

  return Promise.all(chunkedPromises).then(flatten);
}

export function getUserRoles(): Promise<unknown> {
  return client.get("/v5/userRoles").then((response) => response.data);
}

export function getUserRolesByPermission(permissions: string[]): Promise<unknown> {
  return client.get(`/v5/userRolesByPermission?permissions=${permissions}`).then((response) => response.data);
}

export function upsertUser(user: User & { id?: string }): Promise<unknown> {
  if (user.id || user.userId) {
    return client.put(`/v5/users/${user.id || user.userId}`, user).then((resp) => resp.data);
  }
  return client.post("/v5/users", user).then((resp) => resp.data);
}

export function changePassword(user: User & { id?: string; password: string }) {
  return client.put(`/v5/users/${user.id || user.userId}/password`, user).then((resp) => resp.data);
}
