import React from "react";

export default function FeedbackThankYou({ onClose }) {
  return (
    <div className="card">
      <div className="card-body d-flex flex-column justify-content-center">
        <h2 className="card-title-lg text-center">Thanks for participating</h2>
        <h6 className="card-subtitle content-spacer-top text-center">
          Your feedback is an invaluable tool for us to improve your experience.
        </h6>
      </div>
      <div className="card-footer">
        <div className="d-flex flex-row content-spacer-top pull-right">
          <button className="btn btn-secondary" type="button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
