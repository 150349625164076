import * as React from "react";
import { Form, FormGroup, Input, Button, Alert } from "reactstrap";

export class PlainLogin extends React.Component {
  render() {
    const {
      username,
      onUsernameChanged,
      password,
      onPasswordChanged,
      isKnownInvalid,
      errorMessage,
      isSubmitting,
      onSubmit,
    } = this.props;
    return (
      <div className="d-flex flex-column h-100">
        <div className="container m-auto">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <Form onSubmit={onSubmit}>
                <FormGroup>
                  <Input placeholder="Username" type="text" value={username} onChange={onUsernameChanged} />
                </FormGroup>
                <FormGroup>
                  <Input placeholder="Password" type="password" value={password} onChange={onPasswordChanged} />
                </FormGroup>
                <Button
                  color="primary"
                  block
                  size="sm"
                  outline
                  disabled={isKnownInvalid || isSubmitting || !username || !password}
                >
                  Login
                </Button>
                {errorMessage && (
                  <Alert className="mt-2" color="danger">
                    {errorMessage}
                  </Alert>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
