import * as mobx from "mobx";
import { makeObservable } from "mobx";
import { action, observable } from "mobx";
import loginStoreInstance, { LoginStore } from "../session/LoginStore";
import * as ConfigApi from "./ConfigApi";
import { stallUnresolvedPromises } from "../../util/async";
import { BrandConfig } from "./BrandConfig";

export class BrandConfigStore {
  brandConfig: BrandConfig | null = null;

  /**
   * host to fetch the brand config with
   */
  host: string | null = null;

  /**
   * whether the application was initialized with the iFrame=true flag in the url
   */
  isIframe: boolean = false;

  /**
   * true if the brand config was determined via the windows hostname instead of the actual user.
   * Will query the user's brand config once they are logged in
   * */
  isApproximate: boolean = true;

  loginStore: LoginStore;

  constructor(loginStore: LoginStore = loginStoreInstance) {
    this.loginStore = loginStore;
    makeObservable(this, {
      brandConfig: observable,
      host: observable,
      isIframe: observable,
      isApproximate: observable,
      resetBrandConfig: action,
      setBrandConfig: action.bound,
      trySetHost: action,
      setIframeTrue: action,
      _setBrandConfigLogoUrl: action,
    });

    // re-fetch the brand config whenever the conditions that determine the brand config change
    // some scenarios to keep in mind:
    // - logged in as a user, fetch their accounts brand config
    // - logging into a whitelabelled account (e.g. show their brand logo,
    //    or show their brand theme, but no user is available for a specific account. config is fetched via the hostname)
    // - logging into a sub-account of a whitelabelled account. For example a reseller has a login with one theme, but the user is
    //    of a sub-account with their own theme

    mobx.reaction(
      () => [this.loginStore.attemptedAutomatedLogin, !!this.loginStore.token, this.host],
      ([attempted, ...rest]) => attempted && this.resetBrandConfig(),
      {
        fireImmediately: true,
        equals: mobx.comparer.structural,
      }
    );
  }

  resetBrandConfig = (): void => {
    if (this.loginStore.renderOnly) {
      return;
    }
    if (this.loginStore.token || this.host) {
      const wasApproximate = this.isApproximate;
      this.isApproximate = !this.loginStore.token;
      if (wasApproximate && !this.isApproximate && this.brandConfig) {
        this.brandConfig = null;
      }
      this._fetchBrandConfig().then(this.setBrandConfig);
    } else {
      this.brandConfig = null;
      this.isApproximate = true;
    }
  };

  _fetchBrandConfig: () => Promise<BrandConfig> = stallUnresolvedPromises(() => {
    if (this.loginStore.token) {
      return ConfigApi.getBrandConfig();
    } else if (this.host) {
      return ConfigApi.getBrandConfigFromHost(this.host);
    } else {
      return Promise.reject(new Error("no host or token set. Cannot determine brand config"));
    }
  });

  setBrandConfig(brandConfig: BrandConfig): void {
    this.brandConfig = brandConfig;
  }

  /**
   * sets the host if currently unset
   */
  trySetHost(hostname: string): void {
    if (!this.host) {
      this.host = hostname;
    }
  }

  setIframeTrue(): void {
    this.isIframe = true;
  }

  updateLogo = async (file: any): Promise<void> => {
    const updatedLogo = await ConfigApi.saveLogo({ logo: file });
    this._setBrandConfigLogoUrl(updatedLogo);
  };

  _setBrandConfigLogoUrl = (url: string): void => {
    if (this.brandConfig) {
      this.brandConfig.logoUrl = url;
      this.brandConfig.darkThemeLogoUrl = "";
    }
  };
}

export default new BrandConfigStore();
