import * as React from "react";
import * as PropTypes from "prop-types";
import { throttle } from "lodash";

/**
 * monitors the page's size, and passes the bootstrap size string through to a child function.
 *
 * TODO - if this starts getting frequently used, should share event listeners
 * (and still removeEventListener once all instances unmount)
 */
export class WithBreakpoint extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
  };

  state = {
    breakpoint: "xs",
  };

  onResize = () => {
    const width = window.document.documentElement.clientWidth;
    const breakpoint = breakpointForWidth(width);
    const breakpointIndex = orderedBreakpoints.indexOf(breakpoint);
    if (this.state.breakpoint !== breakpoint) {
      this.setState({ breakpoint: breakpoint, breakpointIndex: breakpointIndex });
    }
  };

  onResizeThrottled = throttle(this.onResize, 100);

  componentWillMount() {
    this.onResize();
    window.addEventListener("resize", this.onResizeThrottled);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResizeThrottled);
  }

  render() {
    return this.props.children(this.state.breakpoint, this.state.breakpointIndex);
  }
}

const orderedBreakpoints = ["xs", "sm", "md", "lg", "xl"];
export const breakpoints = () =>
  orderedBreakpoints.map((breakpoint) => {
    const px = getComputedStyle(window.document.documentElement).getPropertyValue("--breakpoint-" + breakpoint);

    return {
      name: breakpoint,
      minPixels: Number.parseInt(px),
    };
  });

export function breakpointForWidth(screenWidth) {
  const filtered = breakpoints()
    .filter((x) => x.minPixels <= screenWidth)
    .map((x) => x.name)
    .reverse();
  return filtered[0] || "xs";
}

/** comparator that will sort breakpoints. returns -1 if less, 0 if equal, 1 if greater */
export function compare(a, b) {
  const diff = distanceCompare(a, b);
  if (diff < 0) {
    return -1;
  } else if (diff > 0) {
    return 1;
  } else {
    return 0;
  }
}

/** returns index distance between a and b (returns negative number if b is less than a) */
export function distanceCompare(a, b) {
  const aIndex = orderedBreakpoints.indexOf(a);
  const bIndex = orderedBreakpoints.indexOf(b);
  if (aIndex === -1) {
    throw new Error(`${a} is not a valid breakpoint`);
  }
  if (bIndex === -1) {
    throw new Error(`${b} is not a valid breakpoint`);
  }
  return bIndex - aIndex;
}
