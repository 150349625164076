import * as React from "react";
import * as PropTypes from "prop-types";
import SavedFilterSetFilters from "./SavedFilterSetFilters";
import "./savedFilterSetContainer.scss";

import { observer } from "mobx-react";
import { ClearFiltersButton } from "../app/layout/PageFilters";

export const SavedFilterSetContainer = observer(
  class SavedFilterSetContainer extends React.Component {
    static propTypes = {
      savedFilterSetFilters: PropTypes.shape(SavedFilterSetFilters).isRequired,
      filters: PropTypes.arrayOf(PropTypes.func).isRequired,
    };

    static defaultProps = {};

    render() {
      const { filters, savedFilterSetFilters, ...rest } = this.props;

      return (
        <>
          {savedFilterSetFilters.savedFilterSetPageFilter()}
          {filters.map((pageFilter, i) => React.cloneElement(pageFilter({}), { key: i }))}
        </>
      );
    }
  }
);
