import config from "../config";

export function themeStyleUrl({ themeId }) {
  return `${config.themeApiURL}/theme/${themeId}.css?v=1`; // increment to bust. Hack. Should force revalidate
}

export default function ({ themeId, style }) {
  if (!themeId && !style) return new Promise(() => {});

  const themeElementId = "cmTheme";
  const oldThemeId = "oldCmTheme";
  let themeElement;
  if (style) {
    themeElement = document.createElement("style");
    themeElement.appendChild(document.createTextNode(style));
  } else {
    themeElement = document.createElement("link");
    themeElement.rel = "stylesheet";
    themeElement.type = "text/css";
    themeElement.href = themeStyleUrl({ themeId });
  }
  themeElement.id = themeElementId;
  const oldTheme = document.getElementById(themeElementId);
  if (oldTheme) {
    oldTheme.id = oldThemeId;
  }
  const head = document.head;
  const onceLoaded = new Promise((resolve, reject) => {
    if (style) {
      resolve();
    } else {
      themeElement.addEventListener("load", resolve);
      themeElement.addEventListener("error", reject);
    }
  });
  head.insertBefore(themeElement, head.children[0]);
  if (oldTheme) {
    return (
      onceLoaded
        // slight delay... better to have both styles for a scecond rather than have UI flicker to no style
        // There may be some fancier way to wait until the new style is rendered.
        // I don't see any obvious javascript events
        .then(() => new Promise((res) => setTimeout(res, 50)))
        .then(() => {
          head.removeChild(oldTheme);
        })
    );
  } else {
    return onceLoaded;
  }
  // return onceLoaded
}
