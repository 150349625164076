"use strict";

import React from "react";
import { abbreviatedName } from "../common/utils/stringUtils";

const AbbreviatedName = (props) => {
  return <span>{abbreviatedName(props.name)}</span>;
};

export default AbbreviatedName;
