import PropTypes from "prop-types";
import React, { Component } from "react";
import { Selection, Selector } from "..";
import { SearchToSelectRenderer } from "./SearchToSelectRenderer";
import { SearchCache } from "./SearchCache";

export class SearchToSelect extends Component {
  static propTypes = {
    ...Selector.propTypes,
    /**
     *
     * function with signature:
     * ({ query: string, offset: int, additional?: any }) => Promise({ items: T[], hasMore: bool, additional?: any })
     *
     * will also accept results that aren't promises, or return an array rather than the pagination items object.
     * (In that case it will assume there are no more results)
     *
     * `additional` is arbitrary non required pagination state related information kept between pages
     */
    getSearchResults: PropTypes.func.isRequired,

    /**
     * resolveSelection - function of type (ids: string[]) => Promise<T[]>
     * used if selectByKey is true, and the id has not been encountered in the results
     */
    resolveSelection: PropTypes.func,

    /**
     * Optional function. if defined, called when a value has no results, and instead the user intends to create a value
     * from the current search (e.g. for a tag set)
     * string => void
     *
     * The internal logic around this is pretty barebones. Checking for duplicates and whatnot would need to be dealt with manually
     */
    onForceSelect: PropTypes.func,

    eager: PropTypes.bool,
    loading: PropTypes.bool,
    delay: PropTypes.number, // debounce delay in ms before executing search while typing
    loadingMessage: PropTypes.any,
    Render: PropTypes.any,
    disableCaching: PropTypes.bool,

    /** optional ad hoc pagination state that may be mutated during pagination attempts.
     * If not defined as a prop, this will be null when first paginating */
    additional: PropTypes.any,
  };

  static defaultProps = {
    ...Selector.defaultProps,
    placeholder: <span className="text-muted">Search...</span>,
    loadingMessage: <span className="text-muted">Loading...</span>,
    delay: 250,
    Render: SearchToSelectRenderer,
  };

  selector;

  constructor(props) {
    super(props);
    this.state = {
      results: [],
      hasRequestYet: false,
      resultsHasMore: false,
      resultsLoading: false,
      resultsLoadingMore: false,
      query: "",
      activeIndex: -1,
      idCache: {},
    };

    this.searcher = SearchCache({
      getSearchResults: this.getSearchResults,
      onSearchResults: this.onSearchResults,
      debounce: this.props.delay,
      additional: this.props.additional,
      disableCaching: this.props.disableCaching,
    });
  }

  onSearchResults = ({ items, hasMore }, loadedMore) => {
    this.cacheResultIds(items); // Should this run if this.props.disableCaching?
    this.setState({
      results: items,
      resultsHasMore: hasMore,
      resultsLoading: false,
      resultsLoadingMore: false,
      ...(loadedMore ? {} : { activeIndex: -1 }),
    });
  };

  getSearchResults = ({ query, offset, additional }) => {
    this.setState({ resultsLoading: offset === 0, resultsLoadingMore: offset > 0, hasRequestYet: true });
    return this.props.getSearchResults({ query, offset, additional });
  };

  selectorComponent = undefined;

  componentDidMount() {
    if (this.props.eager) {
      this.searcher.getImmediate();
    }

    this.resolveSelections(this.selectedArray);
  }

  componentWillReceiveProps(nextProps, ctx) {
    if (this.props.disableCaching) {
      // To ensure that if a values change, the updated selections are resolved properly
      this.searcher.getImmediate(this.state.query).then(this.resolveSelections(this.selectedArray));
    }
    this.resolveSelections(this.selectedArray);
  }

  resolveSelections(selections) {
    if (this.props.selectByKey) {
      const unresolved = selections.filter((x) => !this.state.idCache[x]);
      if (unresolved.length && this.props.resolveSelection) {
        Promise.resolve(this.props.resolveSelection(unresolved)).then((xs) => {
          this.cacheResultIds(xs);
        });
      }
    }
  }

  cacheResultIds(items) {
    if (!!items) {
      const idCache = !this.props.selectByKey
        ? {}
        : items.reduce((sum, x) => {
            const key = this.props.getKey(x);
            if (key) sum[key] = x;
            return sum;
          }, {});
      this.setState({ idCache: { ...this.state.idCache, ...idCache } });
    }
  }

  onSearchChange = (event) => {
    const query = (!!event && !!event.target && event.target.value) || "";
    this.setState({ query });
    this.searcher.getDebounced(query);
  };

  get selection() {
    return Selection(this.props);
  }

  onInputKeyPress = (e) => {
    const { resultsLoading, activeIndex } = this.state;
    if (e.key === "Escape" && this.selectorComponent) {
      this.selectorComponent.toggle();
    } else if (e.key === "Enter" && !resultsLoading) {
      const unselected = this.props.multi
        ? Selector.rightOuterByKey(this.props.getKey, this.selectedArray, this.state.results, false)
        : this.state.results;

      const idx = activeIndex === -1 ? 0 : activeIndex;
      const selection = unselected[idx];

      if (this.props.onForceSelect && (activeIndex === -1 || !selection)) {
        this.props.onForceSelect(this.state.query);
      } else if (idx < unselected.length) {
        this.selection.addSelection(this.selectedArray, selection);
      }
      e.preventDefault();
      this.moveActiveIndex(0);
    } else if (e.key === "ArrowDown") {
      this.moveActiveIndex(1);
    } else if (e.key === "ArrowUp") {
      this.moveActiveIndex(-1);
    }
  };

  moveActiveIndex(offset) {
    const maxIndex = this.state.results.length - 1 - (this.props.multi ? this.selectedArray.length : 0);
    this.setState({ activeIndex: Math.min(Math.max(this.state.activeIndex + offset, -1), maxIndex) });
  }

  get selectedArray() {
    return Selector.toArray(this.props.selected);
  }

  onOpen = () => {
    if (!this.state.hasRequestYet) {
      this.searcher.getImmediate(this.state.query);
    } else if (this.props.disableCaching) {
      this.searcher.getImmediate(this.state.query);
    }
    if (this.props.onOpen) this.props.onOpen();
  };
  setSelectorRef = (selectorComponent) => {
    this.selectorComponent = selectorComponent;
  };

  get options() {
    const results = this.state.results;
    if (!this.props.selectByKey) {
      return results;
    } else {
      const included = results.reduce((sum, x) => {
        sum[this.props.getKey(x)] = x;
        return sum;
      }, {});
      const maybeNormalizedArray = this.props.normalizingFunction
        ? this.props.normalizingFunction(this.selectedArray)
        : this.selectedArray;
      const notIncluded = maybeNormalizedArray.filter((x) => !included[x]);
      const plusCached = notIncluded
        .map((x) => {
          return this.state.idCache[x];
        })
        .filter((x) => !!x);
      return results.concat(plusCached);
    }
  }

  render() {
    const { getSearchResults, eager, loading, delay, loadingMessage, ...rest } = this.props;

    this.resolveSelections(this.selectedArray);

    return (
      <Selector
        {...rest}
        maxItems={this.props.maxItems}
        onClose={() => {
          // clear text on close
          const clearedQuery = undefined;
          const func = !!rest.onClose
            ? () => {
                this.onSearchChange(clearedQuery);
                rest.onClose();
              }
            : () => this.onSearchChange(clearedQuery);

          func();
        }}
        onSelected={(items) => {
          // clear text on selection if its not a multi-select
          const clearedQuery = undefined;
          const func = !!rest.onSelected
            ? () => {
                if (!rest.multi) this.onSearchChange(clearedQuery);
                rest.onSelected(items);
              }
            : () => {
                if (!rest.multi) this.onSearchChange(clearedQuery);
              };

          if ((items || []).length >= this.props.maxItems) {
            this.selectorComponent.toggle();
          }

          func();
        }}
        ref={this.setSelectorRef}
        options={this.options}
        onOpen={this.onOpen}
        Render={this.Renderer}
      />
    );
  }

  Renderer = (renderProps) => {
    const { loading, loadingMessage, Render, onForceSelect, positionFixed, right } = this.props;
    const { resultsLoading, resultsLoadingMore, activeIndex, hasRequestYet, query } = this.state;
    return (
      <Render
        {...{
          ...renderProps,
          positionFixed,
          right,
          loading: loading || resultsLoading,
          loadingMore: resultsLoadingMore,
          onForceSelect: onForceSelect,
          noOptions: !resultsLoadingMore && !resultsLoading ? renderProps.noOptions : undefined,
          disabled: loading || renderProps.disabled,
          loadingMessage,
          onQueryChange: this.onSearchChange,
          query,
          onKeyDown: this.onInputKeyPress,
          activeIndex,
          getMoreSearchResults: hasRequestYet ? this.searcher.getMore : () => {},
        }}
      />
    );
  };
}
