import React from "react";

const Checkbox = (props) => {
  const { label, name, checked, onChange, align } = props;

  return (
    <div className={"checkbox" + (align ? " " + align : "")}>
      <label>
        <input type="checkbox" name={name} checked={checked} onChange={onChange} />
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
