import React, { ReactNode } from "react";
import cx from "classnames";

import "./BulletPoint.scss";

interface BulletPointProps {
  className?: string;
  children: ReactNode;
}
const BulletPoint = ({ className, children }: BulletPointProps) => {
  return (
    <li className={cx("d-flex flex-row pt-2", className)}>
      <div className="bulletpoint-circle" />
      {children}
    </li>
  );
};

export default BulletPoint;
