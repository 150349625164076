export type AuthDiscriminator = "TokenAuthenticationResponse" | "JWTAuthenticationResponse" | "AuthenticationResponse";

export type AuthenticationResponse =
  | BasicAuthenticationResponse
  | TokenAuthenticationResponse
  | JWTTokenAuthenticationResponse;

export interface BasicAuthenticationResponse {
  type: AuthDiscriminator;
  username: string;
  authenticated: boolean;
  errorMessage?: string;
  credentialsInvalid?: boolean;
}

export interface TokenAuthenticationResponse extends BasicAuthenticationResponse {
  type: "TokenAuthenticationResponse";
  token?: string;
  timeout?: string; // iso utc
  inactivityTimeout?: string; // iso utc
}

export interface JWTTokenAuthenticationResponse extends BasicAuthenticationResponse {
  type: "JWTAuthenticationResponse";
  userId: string;
  accountId: string;
  jwt?: string;
  refreshToken?: string;
  expiration?: number;
  sessionTimeout?: number;
}

export function isTokenAuthenticationResponse(auth: AuthenticationResponse): auth is TokenAuthenticationResponse {
  return auth.type === "TokenAuthenticationResponse";
}

export function isJwtAuthenticationResponse(auth: AuthenticationResponse): auth is JWTTokenAuthenticationResponse {
  return auth.type === "JWTAuthenticationResponse";
}

export interface TokenTimeoutResponse {
  isValid: boolean;
  timeout?: string; // iso utc string
  inactivityTimeout?: string;
}

export interface User {
  userId: string;
  username: string;
  usernameLowercase: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  userType: string;
  accountId: string;
  accountIdPath?: string;
  billingAccountId: string;
  permissions: string[];
  enabled?: boolean;
  timezone: string;
  defaultNoteVisibilityLevel?: "Standard" | "Admin";
}

export type ExtendedUser = User & {
  billingAccountName: string;
  accountName: string;
  impersonatedByUser?: {
    username: string;
    accountId: string;
    billingAccountId: string;
    billingAccountName: string;
    accountName: string;
    internalAdmin?: boolean;
  };
  reseller: boolean;
};

export type UserWithLocationTotal = User & {
  id: string;
  locationTotal: string;
};

export interface Account {
  name: string;
  services: AccountServices;
  parentAccountId?: string;
  billingAccountId: string;
  clientAccountId?: string;
  accountId: string;
}

export interface AccountServices {
  chatExecListingManagement: boolean;
  localPages: boolean;
  listingManagement: boolean;
  bingListingManagement: boolean;
  appleListingManagement: boolean;
  yelpListingManagement: boolean;
  googleListingManagement: boolean;
  facebookListingManagement: boolean;
  yelpApi: boolean;
  socialPublishing: boolean;
  socialEngagement: boolean;
  socialCommenting: boolean;
  sendiblePublishing: boolean;
  reportsService: {
    level: "basic" | "plus" | "premium";
    reportsAllowed: number;
    componentsAllowed: number;
  };
  yardi: boolean;
}
