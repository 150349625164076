import React from "react";
import * as d3 from "d3";
import { each } from "lodash";

const percToDeg = (perc) => perc * 360;
const percToRad = (perc) => degToRad(percToDeg(perc));
const degToRad = (deg) => (deg * Math.PI) / 180;
const getSentimentClass = (classification) => {
  switch (classification) {
    case "Positive":
      return "sentiment-green";
    case "Neutral":
      return "sentiment-orange";
    case "Negative":
      return "sentiment-red";
  }
};

const getTopSentiment = () => {
  let topSentiment = {
    percentage: 0,
    classification: "",
  };

  _.each(sentimentData, (item) => {
    if (item.percentage > topSentiment.percentage) {
      topSentiment = item;
    }
  });

  return topSentiment;
};

const getDescription = () => {
  let topSentiment = getTopSentiment();
  return "mostly " + topSentiment.classification.toLowerCase();
  //TODO check for ties and handle those edge cases
};

const sentimentData = [
  {
    classification: "Positive",
    percentage: 60,
  },
  {
    classification: "Neutral",
    percentage: 20,
  },
  {
    classification: "Negative",
    percentage: 20,
  },
];

class Needle {
  constructor(len, radius) {
    this.len = len;
    this.radius = radius;
  }

  drawOn(el, perc) {
    el.append("circle").attr("class", "needle-center").attr("cx", 0).attr("cy", 0).attr("r", this.radius);

    el.append("path").attr("class", "needle").attr("d", this.mkCmd(perc));
  }

  animateOn(el, perc) {
    el.transition()
      .delay(500)
      .ease(d3.easeElastic)
      .duration(3000)
      .selectAll(".needle")
      .tween("progress", () => {
        return (percentOfPercent) => {
          let progress = percentOfPercent * perc;
          d3.selectAll(".needle").attr("d", this.mkCmd(progress));
        };
      });
  }

  mkCmd(perc) {
    const thetaRad = percToRad(perc / 2); // half circle

    const centerX = 0;
    const centerY = 0;

    const topX = centerX - this.len * Math.cos(thetaRad);
    const topY = centerY - this.len * Math.sin(thetaRad);

    const leftX = centerX - this.radius * Math.cos(thetaRad - Math.PI / 2);
    const leftY = centerY - this.radius * Math.sin(thetaRad - Math.PI / 2);

    const rightX = centerX - this.radius * Math.cos(thetaRad + Math.PI / 2);
    const rightY = centerY - this.radius * Math.sin(thetaRad + Math.PI / 2);

    return `M ${leftX} ${leftY} L ${topX} ${topY} L ${rightX} ${rightY}`;
  }
}

class Key {
  constructor(el, chartDiameter, inset) {
    (this.width = chartDiameter - inset),
      (this.inset = inset),
      (this.description = getDescription()),
      (this.height = 20),
      (this.padding = 0),
      (this.y = 0);
  }

  getWidth(percentage) {
    return (this.width * percentage) / 100;
  }

  getOffset(percentage, offset, index) {
    return (this.width * percentage) / 100 + offset;
  }

  drawTitle(el) {
    el.append("text")
      .attr("class", "key-title")
      .attr("text-anchor", "middle")
      .attr("x", this.width / 2 + this.inset)
      .attr("height", 100)
      .attr("width", this.width)
      .text(this.description);
  }

  drawBar(el) {
    let offset = this.inset;
    _.each(sentimentData, (section, index) => {
      el.append("rect")
        .attr("class", getSentimentClass(section.classification))
        .attr("x", offset)
        .attr("y", this.y)
        .attr("height", this.height)
        .attr("width", this.getWidth(section.percentage));
      offset = this.getOffset(section.percentage, offset);
    });
  }

  drawText(el) {
    let offset = this.inset;
    _.each(sentimentData, (section, index) => {
      el.append("text")
        .attr("class", "key-percentage")
        .attr("x", offset)
        .attr("y", this.y)
        .attr("height", this.height)
        .attr("width", this.getWidth(section.percentage))
        .text(section.percentage + "%");
      el.append("text")
        .attr("class", "key-classification")
        .attr("x", offset)
        .attr("y", this.y + 20)
        .attr("height", this.height)
        .attr("width", this.getWidth(section.percentage))
        .text(section.classification);
      offset = this.getOffset(section.percentage, offset);
    });
  }
}

const MeterChart = ({ wrappers }) => {
  var fauxDiv = wrappers.fauxDiv;
  let svg = wrappers.svg;

  let percent = 0.65;

  let numSections = sentimentData.length;

  /*# / 2 for HALF circle*/
  /*let sectionPerc = 1 / numSections / 2*/
  let padRad = 0.05;

  let chartInset = 10;

  /*# start at 270deg*/
  let totalPercent = 0.75;

  let margin = { top: 20, right: 20, bottom: 30, left: 20 };
  let width = 400;
  let height = width;
  let radius = Math.min(width, height) / 2;
  let barWidth = 0.325 * width;

  /*svg
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)*/

  let chart = svg
    .append("g")
    .attr("transform", `translate(${(width + margin.left) / 2}, ${(height + margin.top) / 2})`);

  /*# build gauge bg*/
  for (var x = 0; x < numSections; x++) {
    let classification = sentimentData[x].classification;
    let sectionPerc = sentimentData[x].percentage / 100 / 2;
    let sectionNumber = x + 1;
    let arcStartRad = percToRad(totalPercent);
    let arcEndRad = arcStartRad + percToRad(sectionPerc);
    totalPercent += sectionPerc;

    let arc = d3
      .arc()
      .outerRadius(radius - chartInset)
      .innerRadius(radius - chartInset - barWidth)
      .startAngle(arcStartRad)
      .endAngle(arcEndRad);

    chart
      .append("path")
      .attr("class", `arc chart-color${sectionNumber} ${getSentimentClass(classification)}`)
      .attr("d", arc);
  }

  let needle = new Needle(130, 15);
  needle.drawOn(chart, 0);
  needle.animateOn(chart, getTopSentiment().percentage / 100 / 2);

  let key = new Key(svg, radius * 2, chartInset * 2);

  let titleWrapper = svg.append("g").attr("transform", `translate(0, ${(height + margin.top) / 2 + 50})`);
  key.drawTitle(titleWrapper);

  let barWrapper = svg.append("g").attr("transform", `translate(0, ${(height + margin.top) / 2 + 70})`);
  key.drawBar(barWrapper);

  let textWrapper = svg.append("g").attr("transform", `translate(0, ${(height + margin.top) / 2 + 120})`);
  key.drawText(textWrapper);

  return <div className="cm-meter-chart">{fauxDiv.toReact()}</div>;
};

export default MeterChart;
