import React, { useRef, useEffect, useContext, useMemo } from "react";
import * as mobx from "mobx";
import PropTypes from "prop-types";
import { useObserver } from "mobx-react";

/**
 * Registers a portal that can be rendered to when referenced by it's `id`
 */
export default function RegisterPortal({ id, Tag = "div", tagProps = {} }) {
  const ref = useRef(null);
  const context = useContext(PortalScopeContext);
  const { portals } = context;
  useEffect(() => {
    portals.set(id, ref.current);
    return () => {
      delete portals.delete(id);
    };
  }, [ref, portals]);
  return <Tag {...tagProps} ref={ref} id={id} />;
}

RegisterPortal.propTypes = {
  /** string id to be referenced later */
  id: PropTypes.string.isRequired,
  /** tag or component to render as the portal element */
  Tag: PropTypes.elementType,
  /** props to pass through to the tag, such as style or className */
  tagProps: PropTypes.object,
};

/**
 * Resets the portal scope so that the same ID portal can be re-used within the same dom.
 * RegisterPortal will only mutate/register with the the nearest context.
 * RenderToPortal however will fall back to rendering to an outer context if available.
 **/
export function PortalScopeProvider({ children }) {
  const outerContext = useContext(PortalScopeContext);
  const outer = useObserver(() => mobx.toJS(outerContext.portals));
  const portals = useMemo(() => mobx.observable.map());
  return <PortalScopeContext.Provider value={{ portals, outer }}>{children}</PortalScopeContext.Provider>;
}

PortalScopeProvider.propTypes = {
  children: PropTypes.any,
};

export const PortalScopeContext = React.createContext({
  portals: mobx.observable.map(),
  outer: {},
});
