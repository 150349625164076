"use strict";

import React from "react";
import PropTypes from "prop-types";

const styles = {
  root: {
    position: "relative",
    boxSizing: "border-box",
  },
  allText: {
    MozUserSelect: "text",
    WebkitUserSelect: "text",
    msUserSelect: "text",
    userSelect: "text",
  },
};

const EllipsisText = (props) => {
  const { text, length, tail, tailClassName, ...others } = props;

  if (text.length <= length || length < 0) {
    return (
      <div {...others}>
        <span>{text}</span>
      </div>
    );
  }

  let tailStyle = {
    cursor: "auto",
  };

  let displayText;
  if (length - tail.length <= 0) {
    displayText = "";
  } else {
    displayText = text.slice(0, length - tail.length);
  }

  return (
    <div style={styles.root} {...others}>
      <span>
        {displayText}
        <span style={tailStyle} className={tailClassName}>
          {tail}
        </span>
      </span>
    </div>
  );
};

EllipsisText.propTypes = {
  text: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
  tail: PropTypes.string,
  tailClassName: PropTypes.string,
};

EllipsisText.defaultProps = {
  tail: "...",
  tailClassName: "more",
};

export default EllipsisText;
