import * as rudderanalytics from "rudder-sdk-js";
import MetricsProvider from "./MetricsProvider";

export default class Rudderstack extends MetricsProvider {
  constructor(appId, url) {
    super();
    this.appId = appId;
    this.url = url;
  }

  identify(userIdentity, userProps) {
    // wtf, this is awful. Rudderstack stores a cookie for the user's "anonymousId" so that
    // the user continues being tracked after they logout. (which is weird default in the first place.)
    // This gets worse though--it emits merge events to mixpanel. So if you login as one user, logout,
    // and then login with another user, those user's will get merged, globbing all of the data together.
    // from rudderstack slack:
    // > Scenario that might be causing all profiles to be merged: -
    // > When an identify event is called, a $merge call is made to Mixpanel passing the anonymousId and
    // > userId as distinct_ids.  On logout of a user, calling reset() does not reset the anonymousId.
    // > The next identify call made on the same browser would pass the same anonymousId and the two users
    // > would be merged. Please call reset(true) on logout to also reset the anonymousId. That would solve
    // > the issue of all profiles being merged.
    //
    // in addition, on the server side, they seem to treat null as an anonymous ID, rather than auto generating one,
    // and end up merging everything into a mega null user
    // (Question)
    // > So for example, if I raised an Order Completed event for 2 different users without specifying an anonymous_id as such:
    // > ```
    // > rudder_analytics.track('123abc', 'Order Completed', {
    // > ...
    // > })
    // > rudder_analytics.track('456def', 'Order Completed', {
    // > ...
    // > })
    // > ```
    // > it would be identified as and merged into 1 user?
    // (Reply)
    // > yeah, since both will have same anonymous_id. You can also just set the anonymous_id to user_id instead of random one

    rudderanalytics.reset(true); // true resets the anonymous ID
    rudderanalytics.identify(userIdentity, userProps, {
      anonymousId: userIdentity, // doubly make sure we're not doing anything with an autogenerated or old anonymousId
    });
  }

  recordEvent(name, properties) {
    rudderanalytics.track(name, properties);
  }

  install = () => {
    rudderanalytics.ready(() => {
      console.log("rudder stack loaded and ready");
    });
    console.log("rudderstack load", { appId: this.appId, url: this.url });
    rudderanalytics.load(this.appId, this.url, {
      loadIntegration: false,
      // this sourceConfig stuff is mostly undocumented. By default, tries to phone home to
      // rudderstack control plane for misc configuration. We don't have an app configured there
      //
      // configuring here sets this up so that this is just a dumb pipe to the rudderstack data plane
      // See source code:
      // https://github.com/rudderlabs/rudder-sdk-js/blob/05cdabac31a0f246e5343b0622142cdefee94ec2/src/core/analytics.js
      getSourceConfig() {
        return {
          source: {
            id: "static", // if undefined, complains in setting up error collection
            config: {
              // disable
              statsCollection: { errors: { enabled: false } },
            },
            destinations: [],
          },
        };
      },
    });
  };
}
