import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

/**
 * Checks to see if there are multiple events, if there are then plural
 * language should be used
 * @param {Number} count
 * @returns {Boolean}
 */
function isPlural(count) {
  return count !== 1;
}

/**
 * Generate notification item text based on event type and number of events
 * @returns {String}
 */
function renderNotificationText(notification, objectType, verb) {
  const {
    count,
    trigger: { eventType },
  } = notification;

  // Modify name and verb if there are multiple events
  if (isPlural(count)) {
    verb = verb?.replace("has", "have");
    objectType = `${objectType}s`;
  }

  const time = moment(notification.firstEvent ? notification.firstEvent : undefined)
    .fromNow()
    .replace(" ago", "") // remove the words "a", "an" and "ago" returned by fromNow, otherwises grammer would be off
    .replace("a ", "")
    .replace("an ", "");

  // Now build notification text
  const text = `${count} ${objectType} matching ${notification.name} ${verb} in the last ${time}`;

  return text;
}

const NotificationListItem = ({
  notification,
  objectType,
  verb,
  followAndDismissNotification,
  dismissNotification,
}) => {
  return (
    <div
      key={notification._id}
      className="notification-item d-flex flex-row border-bottom"
      onClick={() => followAndDismissNotification(notification)}
    >
      <div className="notification-description">
        <span>{renderNotificationText(notification, objectType, verb)}</span>
      </div>
      <div
        onClick={(event) => {
          event.stopPropagation(); // stop the click event from triggering the notification redirection
          dismissNotification(notification, true);
        }}
      >
        <button type="button" className="close p-2">
          <i className="fal fa-times" />
        </button>
      </div>
    </div>
  );
};

NotificationListItem.propTypes = {
  // object name of notification type  (e.g. ReviewReceived -> review)
  objectType: PropTypes.string.isRequired,
  // action verb for event (has arrived, needs approval, etc)
  verb: PropTypes.string.isRequired,
};

export default NotificationListItem;
