import React from "react";
import { cssVar } from "./cssInJs";

export default ({ src, size = 75, style = {}, ...props }) => {
  const imgSize = `${size}px`;
  return (
    <div
      {...props}
      style={{
        display: "inline-block",
        width: imgSize,
        height: imgSize,
        overflow: "hidden",
        border: `${cssVar("--border-width")} solid ${cssVar("--border-color")}`,
        borderRadius: "50%",
        ...style,
      }}
    >
      {src ? (
        <img
          src={src}
          style={{
            width: "100%",
            height: "100%",
            verticalAlign: "top",
            objectFit: "cover",
          }}
        />
      ) : (
        <span
          style={{
            textAlign: "center",
            width: "100%",
            height: "100%",
            verticalAlign: "middle",
            lineHeight: imgSize,
            display: "block",
          }}
        />
      )}
    </div>
  );
};
