import axios, { AxiosInstance } from "axios";
import qs from "qs";
import config from "../../config";
import { DASHBOARD_HEADER } from "../../requestSource";

let api: AxiosInstance;
let token: string;

export const setAuthToken = (_token: string) => {
  token = _token;
};

export const getAuthToken = (): string => token;

//init
(() => {
  //TODO - timeout ?
  api = axios.create({
    paramsSerializer: {
      serialize(params) {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    },
    baseURL: config.apiUrl,
    headers: DASHBOARD_HEADER,
  });
  api.interceptors.request.use((cfg) => {
    cfg.headers.set("Authorization", getAuthToken());
    return cfg;
  });
})();

export default api;
