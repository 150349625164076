import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

export class CheckboxGroup extends Component {
  static propTypes = {
    getKey: PropTypes.func.isRequired, // function to get a unique key for an option/selection. Defaults to the option's field at .value. Also used to generate id and for attributes on input and label
    selected: PropTypes.any, // an array of selected options
    options: PropTypes.any, // an array available options. Selected does not need to be included in this list
    onSelected: PropTypes.func, // returns all current selections
    disabled: PropTypes.bool, // if true, disables the checkboxes,
    getLabel: PropTypes.func,
    spacer: PropTypes.any, // used with form-group inline to space if not done in CSS
  };

  onChange(e, x) {
    const { onSelected, selected } = this.props;
    if (selected.indexOf(x) > -1) {
      onSelected(selected.filter((o) => o !== x));
    } else {
      onSelected([...selected, x]);
    }
  }

  renderOption = (x) => {
    const { disabled, getLabel, getKey, selected, spacer } = this.props;
    let label;
    if (getLabel) {
      label = getLabel(x);
    } else if (x.label) {
      label = x.label;
    }
    let key = getKey ? getKey(x) : x.value;
    return (
      <Fragment key={key}>
        <div className="form-check">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="checkbox"
              onChange={(e) => this.onChange(e, x)}
              checked={selected.indexOf(x) !== -1}
              disabled={disabled || x.disabled}
            />{" "}
            {label}
          </label>
        </div>
        {spacer}
      </Fragment>
    );
  };

  render() {
    const { options } = this.props;
    return <Fragment>{options.map(this.renderOption)}</Fragment>;
  }
}
