import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { PropTypes as ObservablePropTypes } from "mobx-react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { IconToTheSide } from "..";
import ReactDOM from "react-dom";
import _ from "lodash";

export class SelectorRenderer extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    multi: PropTypes.bool,
    clientHeight: PropTypes.number,
    clientWidth: PropTypes.number,
    getKey: PropTypes.func,
    selections: PropTypes.oneOfType([PropTypes.array, ObservablePropTypes.observableArray]),
    options: PropTypes.oneOfType([PropTypes.array, ObservablePropTypes.observableArray]),
    renderOption: PropTypes.func,
    renderSelectedOption: PropTypes.func,
    noOptions: PropTypes.any, // component to render if no options, or no more options. missing when options is non-empty
    addSelection: PropTypes.func,
    removeSelection: PropTypes.func,
    activeKey: PropTypes.any,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    placeholder: PropTypes.any,
    onToggle: PropTypes.func,
    renderMultiSelected: PropTypes.func,
    className: PropTypes.string,
    showChevron: PropTypes.bool,
  };

  /**
   * splits properties into 2 objects, one whose field names exists in my propTypes, and the other without
   * @param props
   * @returns {*[]}
   */
  static splitProps(props) {
    return splitComponentProps(SelectorRenderer.propTypes, props);
  }
}
/**
 * splits properties into 2 objects, the first whose field names exists in propTypes, and the other without
 * @param propTypes
 * @param props
 * @returns {*[]}
 */

function splitComponentProps(propTypes, props) {
  const myPropNames = new Set(Object.keys(propTypes));
  const myProps = {};
  const theirProps = {};
  for (let [k, v] of Object.entries(props)) {
    if (myPropNames.has(k)) {
      myProps[k] = v;
    } else {
      theirProps[k] = v;
    }
  }
  return [myProps, theirProps];
}

export class DropdownSelectorContainerRenderer extends React.Component {
  static propTypes = {
    ...SelectorRenderer.propTypes,
    ...Dropdown.propTypes,
  };
  render() {
    const [{ isOpen, onToggle }, rest] = SelectorRenderer.splitProps(this.props);
    const [dropdownProps, ignored] = splitComponentProps(Dropdown.propTypes, rest);
    return <Dropdown data-testid={this.props.dataTestId} isOpen={isOpen} toggle={onToggle} {...dropdownProps} />;
  }
}

export class DropdownButtonSelectorToggle extends React.Component {
  static propTypes = {
    ...SelectorRenderer.propTypes,
    overrideButtonContent: PropTypes.any,
  };

  render() {
    const {
      disabled,
      required,
      placeholder,
      multi,
      selections,
      renderSelectedOption,
      renderMultiSelected,
      removeSelection,
      overrideButtonContent,
      showChevron,
    } = this.props;
    let toggleButton;
    if (overrideButtonContent) {
      toggleButton = overrideButtonContent;
    } else if (selections.length === 1 && (multi || !required)) {
      toggleButton = (
        <IconToTheSide
          side="right"
          icon={
            <span
              className={`far ${showChevron ? "fa-chevron-down" : "fa-times"}`}
              onClick={(e) => {
                if (!disabled && !showChevron) {
                  e.stopPropagation();
                  removeSelection(selections[0]);
                }
              }}
            />
          }
        >
          {renderSelectedOption(selections[0])}
        </IconToTheSide>
      );

      // show dropdown arrow
    } else {
      toggleButton = (
        <IconToTheSide side="right" icon={<i className="far fa-chevron-down"></i>}>
          {selections.length === 0
            ? placeholder
            : multi
            ? renderMultiSelected(selections)
            : renderSelectedOption(selections[0])}
        </IconToTheSide>
      );
    }

    return (
      <DropdownToggle
        disabled={disabled}
        tag="span"
        className={`dropdown-toggle dropdown-toggle-no-icon form-control ${disabled ? "form-control-disabled" : ""}`}
      >
        {toggleButton}
      </DropdownToggle>
    );
  }
}

export class DropdownMenuContainer extends React.Component {
  static propTypes = {
    maxHeight: PropTypes.number,
    minHeight: PropTypes.number,
    toggleRef: PropTypes.any,
    ...SelectorRenderer.propTypes,
  };

  static defaultProps = {
    maxHeight: 450,
    minHeight: 150,
  };

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = { height: this.props.minHeight };
  }

  componentWillMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.updateMenuHeightFromEvent);
    window.removeEventListener("scroll", this.updateMenuHeightFromEvent);
  }

  componentWillReceiveProps(nextProps, _) {
    if ((nextProps.isOpen && !this.props.isOpen) || nextProps.toggleRef !== this.props.toggleRef) {
      this.updateMenuHeight(nextProps, this.state.menuRef);
    }

    if (this.props.isOpen && !nextProps.isOpen) {
      // may be a better and more event based way to detect the actual element resize?
      window.removeEventListener("resize", this.updateMenuHeightFromEvent);
      window.removeEventListener("scroll", this.updateMenuHeightFromEvent);
      this.setState({ height: this.props.minHeight });
    }
    if (!this.props.isOpen && nextProps.isOpen) {
      // janky delay "sometime after render",
      // otherwise the element starts at the min height until a scroll occurs when initially opened.
      window.addEventListener("resize", this.updateMenuHeightFromEvent, true);
      window.addEventListener("scroll", this.updateMenuHeightFromEvent, true);
      setTimeout(() => this.updateMenuHeight(), 50);
    }
  }

  updateMenuHeightFromEvent = _.throttle(() => {
    this.updateMenuHeight(this.props, this.state.menuRef);
  }, 17); // 60fps

  setMenuRef = (elem) => {
    if (this.state.menuRef !== elem) {
      this.setState({ menuRef: elem });
      this.updateMenuHeight(this.props, elem);
    }
  };

  updateMenuHeight = (props = this.props, menuRef = this.state.menuRef) => {
    const dir = this.props.direction || "down";
    if (props.toggleRef && menuRef && (dir === "up" || dir === "down") && props.isOpen && this._isMounted) {
      const toggleRect = ReactDOM.findDOMNode(props.toggleRef).getBoundingClientRect();
      const menuRect = ReactDOM.findDOMNode(menuRef).getBoundingClientRect();
      const min = props.minHeight;
      const max = props.maxHeight;
      const aboveToggle = toggleRect.top;
      const windowHeight = document.documentElement.clientHeight;
      const belowToggle = windowHeight - (Math.max(toggleRect.top, 0) + toggleRect.height);
      const buffer = 40;
      let available;
      if (toggleRect.top > menuRect.top) {
        available = aboveToggle;
      } else {
        available = belowToggle;
      }
      const height = Math.max(min, Math.min(max, available - buffer));
      this.setState({ height: height });
    }
  };

  render() {
    const [{ clientHeight }, { style, maxHeight, minHeight, toggleRef, direction, right, positionFixed, ...rest }] =
      SelectorRenderer.splitProps(this.props);

    return (
      <div ref={this.setMenuRef}>
        <DropdownMenu
          tabIndex="0"
          className="w-100"
          style={{
            maxHeight: `${this.state.height}px`,
            overflowY: "auto",
            ...style,
          }}
          direction={direction || "down"}
          data-testid="dropdown-selections"
          right={right || false}
          positionFixed={positionFixed || false}
          modifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: true,
            },
          }}
          {...rest}
        />
      </div>
    );
  }
}

export class DropdownPaneOptions extends React.Component {
  static propTypes = SelectorRenderer.propTypes;

  refsByKey = {};
  setRef = (key, ref) => {
    this.refsByKey[key] = ref;
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeKey && nextProps.activeKey !== this.props.activeKey) {
      const ref = this.refsByKey[nextProps.activeKey];
      if (ref) {
        const node = ReactDOM.findDOMNode(ref);

        // support for scrollIntoView is pretty good. The option arguments to it... not so much (no IE, later chromes+firefoxes)
        node.scrollIntoView &&
          node.scrollIntoView({
            block: "nearest",
            inline: "nearest",
          });
      }
    }
  }

  render() {
    const { multi, selections, getKey, options, renderOption, addSelection, activeKey } = this.props;

    const selectedKey = !multi && selections[0] && getKey(selections[0]);

    return (
      <Fragment>
        {options.map((x, i) => {
          const key = getKey(x);

          return (
            <DropdownItem
              key={key}
              ref={(r) => this.setRef(key, r)}
              toggle={!multi}
              active={!!activeKey && key === activeKey}
              disabled={!!selectedKey && key === selectedKey}
              onClick={() => addSelection(x)}
            >
              {renderOption(x)}
            </DropdownItem>
          );
        })}
      </Fragment>
    );
  }
}

export class DropdownPaneSelections extends React.Component {
  static propTypes = SelectorRenderer.propTypes;
  render() {
    const { selections, getKey, renderOption, removeSelection, activeKey } = this.props;
    return (
      <Fragment>
        {selections.map((x, i) => {
          const key = getKey(x);
          if (key === undefined || (key === null && process.env.NODE_ENV === "development")) {
            console.warn("get undefined for key for option", key, "option", x);
          }
          return (
            <DropdownItem toggle={false} key={key} active={activeKey === key} onClick={() => removeSelection(x)}>
              <span className="selector-selection">
                <IconToTheSide side="right" key={key} icon={<span className="far fa-times" />}>
                  {renderOption(x)}
                </IconToTheSide>
              </span>
            </DropdownItem>
          );
        })}
      </Fragment>
    );
  }
}

function FullWidthDropdownMenuContainer({ style, ...props }) {
  return <DropdownMenuContainer style={{ ...props }} {...props} />;
}

export class DropdownSelectorRenderer extends React.Component {
  static propTypes = {
    ...SelectorRenderer.propTypes,
    renderContainer: PropTypes.any,
    renderToggle: PropTypes.any,
    renderSelections: PropTypes.any,
    renderOptions: PropTypes.any,
    renderHeader: PropTypes.any,
    renderFooter: PropTypes.any,
    renderAroundSelectOptions: PropTypes.any,
  };

  static defaultProps = {
    renderContainer: DropdownSelectorContainerRenderer,
    renderToggle: DropdownButtonSelectorToggle,
    renderMenu: FullWidthDropdownMenuContainer,
    renderSelections: DropdownPaneSelections,
    renderOptions: DropdownPaneOptions,
    renderAroundSelectOptions: Fragment,
  };

  state = {};

  setToggle = (elem) => this.setState({ toggleRef: elem });

  render() {
    const [
      selectorProps,
      {
        renderContainer: Container,
        renderToggle: Toggle,
        renderMenu: Menu,
        renderSelections: Selections,
        renderOptions: Options,
        renderHeader: Header,
        renderFooter: Footer,
        renderAroundSelectOptions: AroundSelectOptions,
        ...rest
      },
    ] = SelectorRenderer.splitProps(this.props);

    const childProps = { ...selectorProps };
    const { multi, selections, noOptions } = selectorProps;

    return (
      <Container {...selectorProps} {...rest}>
        <Toggle ref={this.setToggle} {...childProps} />
        <Menu
          right={this.props.right || false}
          positionFixed={this.props.positionFixed || false}
          toggleRef={this.state.toggleRef}
          {...childProps}
        >
          {Header && <Header {...childProps} />}
          <AroundSelectOptions>
            {!multi ? undefined : <Selections ref={this.setSelections} {...childProps} />}
            {!multi || !selections.length ? undefined : <DropdownItem divider />}
            <Options ref={this.setOptions} {...childProps} />
            {noOptions}
          </AroundSelectOptions>
          {Footer && <Footer {...childProps} />}
        </Menu>
      </Container>
    );
  }
}
