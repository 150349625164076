import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

function HeaderHelpCenterButton() {
  const [toggle, setToggle] = useState<boolean>(false);

  return (
    <>
      <Dropdown toggle={() => setToggle((val) => !val)} isOpen={toggle}>
        <DropdownToggle
          className="header-button border-0"
          tag="button"
          aria-label="Help Center In Application Bar Button"
        >
          <div className="d-flex align-items-center">
            <i className="fas fa-question-circle d-flex justify-content-center m-auto" />
          </div>
        </DropdownToggle>
        <DropdownMenu right={true} onClick={() => setToggle(false)}>
          <a className="dropdown-item" href="http://learn.chatmeter.com/" target="_blank">
            Help Center
          </a>
          <a className="dropdown-item" href="https://chatmeter.my.site.com/s/contactsupport" target="_blank">
            Contact Us
          </a>
        </DropdownMenu>
      </Dropdown>
    </>
  );
}

export default HeaderHelpCenterButton;
