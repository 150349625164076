import React from "react";
import classnames from "classnames";

export const Recommended = ({ doesRecommend }) => {
  const label = doesRecommend ? "Recommended" : "Doesn't Recommend";
  const classes = { fa: true, "fa-thumbs-o-up": doesRecommend, "fa-thumbs-o-down": !doesRecommend };

  return (
    <span className="review-recommend">
      <span className={classnames(classes)} /> {label} &nbsp;
    </span>
  );
};
