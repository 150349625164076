export const GLOBAL_FILTER = "GLOBAL_FILTER";
export const PAGE_FILTERS = "PAGE_FILTERS";
export const FILTER_TOGGLE = "FILTER_TOGGLE";
export const SECONDARY_PAGE_FILTERS = "SECONDARY_PAGE_FILTERS";
export const PAGE_HEADER = "PAGE_HEADER";
export const PAGE_TITLE = "PAGE_TITLE";
export const TITLE_IMAGE = "TITLE_IMAGE";
export const PAGE_COMMANDS = "PAGE_COMMANDS";
export const BACK_BUTTON = "BACK_BUTTON";
export const ANGULAR_FILTERS = "ANGULAR_FILTERS";
