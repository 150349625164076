import { action, computed, observable, extendObservable, toJS, makeObservable } from "mobx";
import defaultSessionStore from "../../../services/session/SessionStore";
import { deepClone } from "lodash";
import { createViewModel } from "mobx-utils";
import moment from "moment";
import workflowApi from "../api";

export class CommentsStore {
  constructor(taskStore, sessionStore) {
    makeObservable(this, {
      comments: observable,
      commentCount: computed,
      draft: observable,
      setDraft: action,
      edit: action,
      _updateCommentData: action,
      _appendCommentAndResetDraft: action,
      revert: action,
      updateCommentBody: action,
      deleteComment: action,
      saveEdit: action,
    });

    this.taskStore = taskStore;
    this.userResolver = taskStore.userResolver;

    this.sessionStore = sessionStore || defaultSessionStore;
    this.setDraft();
  }

  initializeComments(comments) {
    this.comments = comments.map((x) => this.serverCommentToViewModel(x));
  }

  serverCommentToViewModel = (server) => {
    let lastUpdated = server.lastUpdated && moment(server.lastUpdated).startOf("minute");
    let created = server.created && moment(server.created).startOf("minute");
    return createViewModel(
      observable({
        commentId: server.commentId,
        created: created,
        lastUpdated: lastUpdated,
        isCreatedSameAsUpdated: !!(server.created && server.lastUpdated && lastUpdated.isSame(created)),
        authorUserId: server.authorUserId,
        body: server.body,
        author: this.userResolver.fetchOne(server.authorUserId),
        canEdit: this.sessionStore.user.userId === server.authorUserId,
      })
    );
  };

  viewModelToServerUpdate = (viewModel) => {
    return {
      body: viewModel.body,
    };
  };

  comments = [];

  get commentCount() {
    return this.comments.filter((x) => !!x.commentId).length;
  }
  draft = undefined;

  setDraft() {
    let userId = this.sessionStore.user.userId;
    this.draft = createViewModel(
      observable({
        commentId: "",
        created: undefined,
        lastUpdated: undefined,
        authorUserId: userId,
        body: "",
        author: this.userResolver.fetchOne(userId),
      })
    );
  }

  edit(task) {
    task.isEditing = true;
    task.original = toJS(task);
  }

  _updateCommentData(viewModel, server) {
    Object.assign(viewModel.model, this.serverCommentToViewModel(server));
  }

  _appendCommentAndResetDraft(viewModel, server) {
    viewModel.reset();
    console.log({ comments: this.comments });
    this.comments.push(this.serverCommentToViewModel(server));
  }

  revert(comment) {
    comment.reset();
  }

  updateCommentBody(comment, body) {
    comment.body = body;
  }

  deleteComment(comment) {
    const taskId = this.taskStore.original._id;
    return workflowApi.deleteComment(taskId, comment.commentId).then((resp) => {
      const index = this.comments.findIndex((x) => x.commentId === comment.commentId);
      action("deleteCommentSuccess", () => this.comments.splice(index, 1))();
    });
  }

  saveEdit(comment) {
    const taskId = this.taskStore.original._id;
    const commentId = comment.commentId;
    const payload = this.viewModelToServerUpdate(comment);

    if (!commentId) {
      return workflowApi.addComment(taskId, payload).then((resp) => {
        this._appendCommentAndResetDraft(comment, resp.data.comment);
      });
    } else {
      return workflowApi.updateComment(taskId, commentId, payload).then((resp) => {
        this._updateCommentData(comment, resp.data.comment);
      });
    }
  }
}
