import React from "react";
import PropTypes from "prop-types";
import { ColorSelector, colorValueFromVariableName } from "./ColorSelector";
import { ColorOrGradient } from "./ColorOrGradient";
import ThemePrimaryColorButton from "./ThemePrimaryColorButton";
import store from "../ThemeEditorStore";
import { neutrals } from "../AllVariables";
import { Selector } from "../../../components";
import CustomGoogleFontPicker from "./CustomGoogleFontPicker";
import uuid from "uuid/v4";
export class VariableGroupEditor extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    variables: PropTypes.object,
    invalid: PropTypes.object,
    sidebar: PropTypes.any,
    group: PropTypes.shape({
      sectionName: PropTypes.string.isRequired,
      variables: PropTypes.object.isRequired,
      hideSection: PropTypes.boolean,
      collapsed: PropTypes.boolean,
    }).isRequired,
  };

  state = { isCollapsed: this.props.group.collapsed };

  toggleState = () => this.setState({ isCollapsed: !this.state.isCollapsed });

  render() {
    const allVars = this.props.variables;
    const inputVars = this.props.group.variables;
    const keys = Object.keys(inputVars);

    const flexSections = ["Brand Colors", "Neutrals", "Chart Colors"];
    const { sectionName } = this.props.group;
    const rowCSS = !flexSections.includes(sectionName) ? "" : this.props.sidebar ? "editor-selector-grid mx-n2" : "row";

    return !!this.props.group.hideSection && !store.state.debugMode ? null : (
      <React.Fragment>
        <div
          className="form-group mb-2 card-spacer-top d-flex justify-content-between clickable"
          onClick={this.toggleState}
        >
          <h5>{this.props.group.sectionName}</h5>
          <i
            className={`far fa-chevron-down animate-rotate d-flex align-items-center ${
              this.state.isCollapsed ? "" : "rotate-180"
            }`}
          ></i>
        </div>
        <div className={`collapse ${this.state.isCollapsed ? "" : "show"} ${rowCSS}`}>
          {!!keys &&
            keys.length &&
            keys.map((name, i) => {
              const obj = inputVars[name];
              const key = `group_variable_input_${name}_${i}`;
              const dependencies = !!(obj.dependencies && Array.isArray(obj.dependencies))
                ? obj.dependencies.map((dep) => ({ key: dep.key, value: allVars[dep.key], when: dep.when }))
                : undefined;

              return (
                <VariableFormGroup name={name} variable={obj} key={key} sidebar={this.props.sidebar}>
                  <GenericVariable
                    name={name}
                    value={this.props.variables[name]}
                    invalid={this.props.invalid[name]}
                    variable={obj}
                    sidebar={this.props.sidebar}
                    onChange={(name, value, errorMsg) => {
                      // at the moment, this is basically just calling the ThemeEditorStore.handleVariableChange,
                      // but is passed through a few components to be "re-usable"
                      // If this gets too annoying, might be better to just read and write state straight to the ThemeEditorStore
                      this.props.onChange({ name, value, invalidMessage: errorMsg });
                    }}
                    dependencies={dependencies}
                  />
                </VariableFormGroup>
              );
            })}
        </div>
      </React.Fragment>
    );
  }
}

function variableToNiceName(name) {
  return name
    .replace(/^\$/, "")
    .split("-")
    .map((x) => x[0].toUpperCase() + x.slice(1))
    .join(" ");
}

const GenericVariable = ({ name, variable, onChange, invalid, value, sidebar, dependencies, options }) => {
  switch (variable.type) {
    case "palette-color":
      const isNeutralColor = !!neutrals.variables[name];
      return (
        <ThemePrimaryColorButton
          name={name}
          label={variableToNiceName(name)}
          size={sidebar ? 40 : 50}
          color={value.includes("$") ? colorValueFromVariableName(value) : value}
          onColorSelected={({ name, value }) => onChange(name, value)}
          pickerDisabled={isNeutralColor}
        />
      );
    case "color":
      return <ColorSelector name={name} value={value} onChange={onChange} />;

    case "color-or-gradient":
      return (
        <ColorOrGradient name={name} value={value} onChange={onChange} options={options} dependencies={dependencies} />
      );

    case "boolean": {
      return <BooleanVariableFormGroup name={name} value={value} onChange={onChange} />;
    }
    case "numeric": {
      return <NumericVariableFormGroup name={name} value={value} onChange={onChange} invalid={invalid} />;
    }
    case "font": {
      const val = value === "null" ? "Open Sans" : value;
      const variants = [];
      return (
        <div key={uuid()} className="brand-config-font-picker" style={{ fontFamily: `'${val}'` }}>
          <CustomGoogleFontPicker
            pickerId={variableToNiceName(name).replace(/ /gi, "")}
            apiKey="AIzaSyDVVm9ynLLD_Xd5smNnvGPYodiHJFTaBn0"
            name={name}
            variants={variants}
            families={[
              //This list is limited to 50 fonts. If more than 50 fonts are inputted, the 50 most popular (according to google) will populate.
              "Archivo",
              "Assistant",
              "Barlow Condensed",
              "Barlow",
              "Bitter",
              "Cairo",
              "Catamaran",
              "Comfortaa",
              "Cormorant Garamond",
              "Dosis",
              "Exo 2",
              "Manrope",
              "Fira Sans Condensed",
              "Fira Sans",
              "Heebo",
              "Hind Madurai",
              "Hind Siliguri",
              "Hind",
              "IBM Plex Sans",
              "IBM Plex Serif",
              "Inconsolata",
              "Inter",
              "Josefin Sans",
              "Kanit",
              "Karla",
              "Lato",
              "Libre Franklin",
              "Merriweather Sans",
              "Montserrat",
              "Mukta",
              "Mulish",
              "Noto Serif JP",
              "Nunito",
              "Open Sans",
              "Oswald",
              "Overpass",
              "Poppins",
              "Prompt",
              "Quicksand",
              "Rajdhani",
              "Raleway",
              "Roboto Mono",
              "Roboto Slab",
              "Roboto",
              "Rubik",
              "Signika Negative",
              "Source Code Pro",
              "Teko",
              "Work Sans",
              "Yanone Kaffeesatz",
            ]}
            scripts={["latin"]}
            activeFontFamily={val}
            sort="alphabet"
            onChange={(nextFont) => onChange(name, nextFont.family)}
          />
        </div>
      );
    }
    case "options":
      const { originalTheme } = store.state;
      const originalValue = originalTheme.variables[name];
      let supportedOpts = variable.supportedOpts;
      if (!supportedOpts.find((x) => x.value === originalValue)) {
        // Add the original values from the theme.
        supportedOpts = [...supportedOpts, { value: originalValue, label: originalValue }];
      }
      return (
        <NumericSupportedVariableFormGroup
          name={name}
          value={value}
          onChange={onChange}
          invalid={invalid}
          supportedOpts={supportedOpts}
        />
      );
    default: {
      return <UncontrolledVariableFormGroup name={name} value={value} onChange={onChange} />;
    }
  }
};

/**
 * A generic form group wrapper to ensure that all inputs will look correct in and out of preview mode along with
 * at various screen sizes. This will ensure that everything is formatted in a consistent and safe manner.
 */
const VariableFormGroup = ({ name, variable, children, sidebar }) => {
  if (!!variable && !!variable.advanced && !store.state.debugMode) {
    return null;
  }
  const title = !!variable && !!variable.title ? variable.title : variableToNiceName(name);

  const renderLabel = () => {
    if (variable.type === "palette-color") return undefined;
    return <label className={`form-label`}>{title}</label>;
  };

  return (
    <div className={`${variable.type !== "palette-color" ? "form-group" : sidebar ? "" : "col-2 col-md-3"}`}>
      {renderLabel()}
      {children}
    </div>
  );
};

/**
 * For Boolean css inputs (not actually booleans, just strings of booleans for scss)
 *
 * @param name the name of the variable that the input will be assigned to
 * @param variable the variable options, such as title, type, etc.
 */
export const BooleanVariableFormGroup = ({ name, value, onChange, options }) => {
  return (
    <Selector
      required={true}
      className="form-control"
      selected={value || "false"}
      name={name}
      options={
        options
          ? options
          : [
              { key: "true", value: "On" },
              { key: "false", value: "Off" },
            ]
      }
      selectByKey={true}
      onSelected={(value, error) => {
        onChange(name, value, error);
      }}
    />
  );
};

/**
 * For Numeric css inputs
 *
 * @param name the name of the variable that the input will be assigned to
 * @param variable the variable options, such as title, type, etc.
 */
const NumericVariableFormGroup = ({ name, value, onChange, invalid }) => {
  /*
   * Invalid Numeric value for css Regex
   *    ^([+-]?([0-9]*[.])?[0-9]+)(em|rem|px|%|$)$
   *
   * Valid: [12, 12px, 12em, 12rem, 12%, 12.3, 12.3px, 12.3em, 12.3rem, 12.3%]
   * Invalid: [e123, 123e, 12.2.px1231, 12px123, 12pxb, 12.3pxb]
   */
  const validation = (value) => {
    const validationReg = new RegExp("^([+-]?([0-9]*[.])?[0-9]+)(em|rem|px|%|$)$");
    if (!!value && !value.match(validationReg)) {
      return "Invalid Number";
    }
    return undefined;
  };

  return (
    <React.Fragment>
      <input
        type={"text"}
        className={`form-control ${!!invalid ? "is-invalid" : ""}`}
        defaultValue={value}
        name={name}
        onBlur={(event) => {
          const value = event.target.value;
          const invalid = validation(value);
          onChange(name, value, invalid);
        }}
      />
      {!!invalid && <div className="invalid-feedback">{invalid}</div>}
    </React.Fragment>
  );
};

const NumericSupportedVariableFormGroup = ({ name, value, onChange, supportedOpts }) => {
  if (!supportedOpts || !supportedOpts.length) {
    console.error("NumericSupportedVariableFormGroup does not have any supportedOpts.");
  }

  // Map options to `Selector format`
  const options = supportedOpts.map((opt) => ({ ...opt, key: opt.value }));

  return (
    <Selector
      required={true}
      className="form-control"
      selected={value}
      selectByKey={true}
      name={name}
      onSelected={(value) => onChange(name, value)}
      options={options}
    />
  );
};

/**
 * For any free text that could be considered css.
 *
 * @param name the name of the variable that the input will be assigned to
 * @param variable the variable options, such as title, type, etc.
 */
const UncontrolledVariableFormGroup = ({ value, onChange }) => {
  return (
    <input
      type={"text"}
      className="form-control"
      defaultValue={value}
      onBlur={(event) => onChange(undefined, event.target.value)}
    />
  );
};
