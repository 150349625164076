import * as React from "react";
import * as PropTypes from "prop-types";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { FormControlButton, IconToTheSide } from "../../../components";
import { StyledCheckbox } from "../../../components/checkbox/StyledCheckbox";
import * as lodash from "lodash";

/**
 * transforms the normal filters into a more Boolean/Set friendly format.
 * Keeps values cached as the transformation of filters to advanced filter booleans is lossy:
 * e.g. checkmarking both isReply and isPost is equivalent to having both unchecked,
 * so it has to rely on the internal state to have a pleasant UX
 *
 * NOTE: will not recompute internal state if filters are changed externally for the above reason
 * if the filters mutation needs to handle multiple sources, this may need to be rethought (it does its best)
 *
 * propagates changes upwards to the traditional filters
 */

export class AdvancedPostFiltersStateTransform extends React.Component {
  static propTypes = {
    filters: PropTypes.shape({
      hasCustomerReply: PropTypes.bool,
      hasBusinessCustomerReply: PropTypes.bool,
    }).isRequired,
    onFiltersChanged: PropTypes.func.isRequired,
  };

  state = {
    advanced: {
      hasCustomerReply: false,
      hasBusinessReply: false,
      hasNoBusinessReply: false,
    },
  };

  componentWillMount() {
    this.setState({ advanced: this.advancedFromFilters() });
  }

  componentDidUpdate(prevProps) {
    if (!lodash.isEqual(prevProps.filters, this.props.filters)) {
      this.setState({ advanced: this.advancedFromFilters() });
    }
  }

  /**
   * converts social filter props to advanced filter booleans
   */
  advancedFromFilters() {
    const { hasCustomerReply, hasBusinessReply } = this.props.filters;
    const current = this.state.advanced;
    const both = typeof hasBusinessReply !== "boolean" && current.hasBusinessReply && current.hasNoBusinessReply;
    return {
      hasCustomerReply: hasCustomerReply === true,
      hasBusinessReply: both || hasBusinessReply === true,
      hasNoBusinessReply: both || hasBusinessReply === false,
    };
  }

  /**
   * updates advanced filter state, and sets social filters from them
   * @param updates
   */
  setAdvancedFilters = (updates) => {
    const advanced = { ...this.state.advanced, ...updates };
    this.setState({ advanced: advanced });

    const hasCustomerReply = advanced.hasCustomerReply || null;
    let hasBusinessReply = null;
    if (advanced.hasBusinessReply && !advanced.hasNoBusinessReply) hasBusinessReply = true;
    if (!advanced.hasBusinessReply && advanced.hasNoBusinessReply) hasBusinessReply = false;
    this.props.onFiltersChanged({ hasCustomerReply, hasBusinessReply });
  };

  render() {
    if (!this.state.advanced) {
      // waiting for filters to be set from the url..
      return <span />;
    } else {
      return <AdvancedPostFilters filters={this.state.advanced} onFiltersChanged={this.setAdvancedFilters} />;
    }
  }
}

/**
 * stateless component (other than whether the menu is open).
 * Has a menu of a bunch of checkboxes of options
 */
export class AdvancedPostFilters extends React.Component {
  static propTypes = {
    filters: PropTypes.shape({
      hasCustomerReply: PropTypes.bool,
      hasBusinessReply: PropTypes.bool,
      hasNoBusinessReply: PropTypes.bool,
    }).isRequired,
    onFiltersChanged: PropTypes.func.isRequired,
  };

  state = {
    isOpen: false,
  };

  toggle = () => this.setState((s) => ({ isOpen: !s.isOpen }));

  CheckboxMenuItem = ({ attribute, label }) => {
    const current = this.props.filters[attribute];
    return (
      <DropdownItem tag="span" toggle={false}>
        <StyledCheckbox
          checked={current}
          onChange={() => {
            this.props.onFiltersChanged({ [attribute]: !current });
          }}
          id={`advanced-include-${label.replace(/\s+/, "-").toLowerCase()}`}
          label={label}
          labelStyle={{ width: "100%" }}
        />
      </DropdownItem>
    );
  };

  render() {
    const { isOpen } = this.state;
    const { toggle } = this;
    const CheckboxMenuItem = this.CheckboxMenuItem;
    const anySelected = Object.values(this.props.filters).filter((x) => x).length;
    return (
      <Dropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle tag="span" className="d-block form-control dropdown-toggle dropdown-toggle-no-icon">
          <IconToTheSide side="right" icon={<span className="far fa-chevron-down" />}>
            <span>
              {anySelected ? `${anySelected} Advanced Filter${anySelected !== 1 ? "s" : ""}` : "Advanced Filters"}
            </span>
          </IconToTheSide>
        </DropdownToggle>
        <DropdownMenu positionFixed={true}>
          <DropdownItem header>Posts</DropdownItem>
          <CheckboxMenuItem attribute="hasCustomerReply" label="With Customer Reply" />
          <CheckboxMenuItem attribute="hasBusinessReply" label="With Business Reply" />
          <CheckboxMenuItem attribute="hasNoBusinessReply" label="Without Business Reply" />
        </DropdownMenu>
      </Dropdown>
    );
  }
}
