import React, { useState, useEffect } from "react";
import * as ThemeApi from "./ThemeApi";
import { SearchToSelect } from "../../components";
import { recordEvent } from "../app/plugins/metricPlugin";
import PropTypes from "prop-types";

/**
 * Selector that reads available themes from the bootstrap theme server
 */
export function ThemeSelector({ themeId, onThemeSelected, excludeThemes = [], additionalThemes = [] }) {
  const [{ themes, loadingThemes }, setThemes] = useState({
    themes: [],
    loadingThemes: true,
  });

  useEffect(() => {
    ThemeApi.listDefaultThemes().then((themes) => {
      setThemes({ themes, loadingThemes: false });
    });
  }, []);
  const namedThemes = themes.map((x) => ({ value: x, name: x })).filter((x) => !excludeThemes.includes(x.name));
  const namedIds = new Set(namedThemes.map((x) => x.value));
  namedThemes.unshift(
    ...additionalThemes.filter((x) => {
      const has = !namedIds.has(x.value);
      if (!has) namedIds.add(x.value);
      return has;
    })
  );
  const selected = namedThemes.find((x) => x.value === themeId);
  return (
    <SearchToSelect
      loading={loadingThemes}
      selected={selected}
      required
      getSearchResults={(opts) =>
        namedThemes.filter(({ name }) => name.toLowerCase().includes((opts.query || "").toLowerCase()))
      }
      renderOption={(x) => x.name}
      onSelected={(x) => {
        recordEvent("ThemeChanged", { themeName: x.name });
        return onThemeSelected(x.value);
      }}
    />
  );
}

ThemeSelector.propTypes = {
  /** the currently active theme id */
  themeId: PropTypes.string,
  /**  callback when new theme is selected */
  onThemeSelected: PropTypes.func,
  /** any theme ids that should be excluded from the list*/
  excludeThemes: PropTypes.arrayOf(PropTypes.string),
  /** additional themes to be included in options. "value" is the theme ID,
   * and "name" is how the theme will be displayed in the dropdown.
   * These themes will be excluded if they share a value with a default theme
   */
  additionalThemes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};
