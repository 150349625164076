import _ from "lodash";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

export class TreeView extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired, //array of children: [], name: '', icon: '', opened, class
    onItemClicked: PropTypes.func,
    renderChild: PropTypes.func,
    style: PropTypes.object,
  };

  constructor(props) {
    super(props);

    const sortedData = _.cloneDeep(props.data);
    if (sortedData && sortedData.length) {
      sortedData[0].children = _.sortBy(sortedData[0].children, ["name"], ["desc"]);
    }

    this.state = {
      data: sortedData,
    };
  }

  componentWillMount() {
    // Initialize the items as open.
    const newData = this.state.data.map((item) => {
      return { ...item, opened: true };
    });
    this.setState({ data: newData });
  }

  onItemClicked(item, e) {
    e.stopPropagation();
    this.props.onItemClicked && this.props.onItemClicked(item);
    item.opened = !item.opened;
    this.setState({});
  }

  renderRecursive(data, level) {
    return data.map((item, index) => {
      const classes = {
        "ml-4": level > 0,
        "mb-2": true,
        "tree-item": true,
        [item.className || ""]: true,
        opened: item.opened,
        closed: !item.opened,
      };
      const openClosed = { "ss-icon": true, "ss-plussquare": !item.opened, "ss-hyphensquare": item.opened };
      let child;
      if (this.props.renderChild) {
        child = this.props.renderChild(item, index);
      } else {
        child = (
          <Fragment>
            {item.icon && <span className={item.icon} />} {item.name}
          </Fragment>
        );
      }

      return (
        <div
          key={`${item.name}-${level}`}
          className={classnames(classes)}
          onClick={this.onItemClicked.bind(this, item)}
          styles={this.props.styles}
        >
          {item.children && item.children.length && (
            <Fragment>
              <span className={classnames(openClosed)} /> &nbsp;&nbsp;
            </Fragment>
          )}
          {child}
          {item.children && item.children.length && item.opened && this.renderRecursive(item.children, level + 1)}
        </div>
      );
    });
  }

  render() {
    return <div className="tree-view ">{this.renderRecursive(this.state.data, 0)}</div>;
  }
}
