export interface WordData {
  text: string;
  value: number;
  avgPolarity: number;
}

export interface PulseTopicMentions {
  topicName: string;
  mentionCount: number;
  avgPolarity: number;
}

export interface PulseContentAnalysis {
  name: string;
  count: number;
  path: string[];
  avgPolarity: number;
  sentimentHistogram: SentimentHistogram[];
}

export interface SentimentHistogram {
  polarity: number;
  count: number;
}

export interface PulseTopTopic extends PulseTopicMentions {
  avgReviewRating: number;
}

export interface PulseTopTopicsResponse {
  positive: PulseTopTopic[];
  negative: PulseTopTopic[];
}

export const ByTotalMentions = { name: "byTotalMentions", label: "By Total Mentions" };
export const ByChangeInMentions = { name: "byChangeInMentions", label: "By Change in Mentions" };

export interface PulseTopTopicChange {
  topicName: string;
  mentionChange: number; // Positive number indicates an increase in mention change. Negative number is a decrease.
  percentChange: number; // Percent change rounded to the nearest Integer. Positive is increase. Negative is decrease. Ex: -52 = 52% decrease.
  avgPolarity: number; // Avg polarity from 0 to 4. For the UI, you have to clamp it down to values between 1 and 3.
}

export interface PulseTopChangeTopicsResponse {
  positive: PulseTopTopicChange[];
  negative: PulseTopTopicChange[];
}

export interface PulseCategoryTrend {
  categoryName: string;
  categoryId: string;
  mentions: MentionsByDate[];
}

export interface MentionsByDate {
  date: string;
  count: number;
}

export interface PulseLocationByRating {}

export interface PulseFilters {
  startDate?: string;
  endDate?: string;
  providers?: string[];
  sentiments?: string[];
  categories?: string[];
  topics?: string[];
  entities?: string[] | string;
  daysPerInterval?: number;
  dataset?: string;
  reportRange?: string;
}

export interface PulseSearchParams {
  entities?: string[] | string;
  providers?: string[];
  sentiments?: string[];
  categories?: string[];
  topics?: string[];
  startDate?: string;
  endDate?: string;
  reportRange?: string;
  daysPerInterval?: number;
  dataset?: string;
  maxPointOfInterests: number;
  dateGranularity?: string;
}

export interface PulseCategoryTrendsParams extends PulseSearchParams {
  /**
   * Selected categories in the SentimentCategoryTrendSettingsModal.
   * This is different from `categories`! These will be the only categories returned!
   * These are category names, not category IDs.
   */
  selectedCategories?: string[];
}

export enum Polarity {
  Positive = "Positive",
  Neutral = "Neutral",
  Negative = "Negative",
}

export interface MentionsWithPolarityByDate {
  date: string;
  negativeCount: number;
  neutralCount: number;
  positiveCount: number;
}

export interface PulseCategoryTrendPolarity {
  categoryId: string;
  distribution: MentionsWithPolarityByDate[];
}

export interface PulseRelationalPair {
  name: string;
  count: number;
  percentageOfWhole: number;
  avgPolarity?: number;
  parent?: string;
}

export interface CategoryTrendsPreferences {
  categoryTrendSelectedCategories: string[];
}

export interface ObjectId {
  $oid: string;
}

export interface Segment {
  _id: ObjectId;
  locationId: string;
  reviewId: string;
  reviewDate: string;
  polarity: number;
  segment: string;
  contentAnalysis: PulseContentAnalysis;

  // Deprecated?
  allTopics?: string[];

  // There are a lot more fields but they aren't used on FE yet, see SentimentSegment.scala
  // Add more fields as necessary
}

export interface PulseSentimentStoreComparison {
  entityId: string;
  stats: AggregateStats;
  previousChange: Partial<AggregateStats>;
  topPositiveTopic?: TermStats;
  topNegativeTopic?: TermStats;
  displayName?: string;
  resellerLocId?: string;
}

export interface AggregateStats {
  count: number;
  sumTotalPolarity: number;
  averagePolarity?: number;
  total1Polarity: number;
  total2Polarity: number;
  total3Polarity: number;
}

export interface TermStats {
  name: string;
  count: number;
  parent?: string;
  avgPolarity?: number;
  children?: TermStats[];
  sentimentHistogram: SentimentHistogram[];
}
