import axios from "axios";
import qs from "qs";
import config from "../../config";

/**
 * {AxiosInstance}
 */
let client;

/**
 * Initialize the client
 */
(() => {
  const isDev = process.env.NODE_ENV === "development";

  client = axios.create({
    debug: isDev,
    paramsSerializer: {
      serialize(params) {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    },
    baseURL: config.themeApiURL,
  });
})();

/**
 *
 * @typedef {Object} Theme
 * @property {string} customStyles
 * @property {string} variables
 *
 */

/**
 * Render a theme in real time.
 *
 * @param Theme the theme
 * @returns {Promise<string>} css file
 */
export async function compileStyle({ customStyles, variables, overrides }) {
  const resp = client.post("/compileTheme", { customStyles, variables, overrides });
  return (await resp).data;
}

/**
 * Function to get the variables, i.e. to continue editing
 *
 *
 * @param variablesId
 * @returns {Promise<Theme>}
 */
export async function fetchVariables(variablesId) {
  const resp = client.get("/variables/" + variablesId + "");
  return (await resp).data;
}

/**
 * Function to get the compiled css.
 *
 * Used to retrieve a specific theme's CSS. For instance, to get the default theme styles.
 *
 * @param variablesId
 * @returns {Promise<string>}
 */
export async function fetchStyle(variablesId) {
  const resp = client.get("/theme/" + variablesId + ".css");
  return (await resp).data;
}

/**
 * Upsert the style to s3, and return the computed style, along with the variablesId
 *
 *
 * @typedef {Object} ComputedStyle
 * @property {string} css
 * @property {string} themeId
 *
 * @param Theme
 * @returns {Promise<ComputedStyle>}
 */
export async function saveStyle({ customStyles, variables, overrides }) {
  const resp = client.post("/theme", { customStyles, variables, overrides });
  return (await resp).data;
}

/**
 *
 * @param {string} q
 * @returns {Promise<string[]>}
 */
export async function listDefaultThemes({ q = "" } = {}) {
  const resp = await client.get("/themes", { params: { q } });
  return (await resp).data.themes;
}
