import { groupBy, sumBy } from "lodash";
import { Polarity, PulseContentAnalysis } from "./types";

export interface SentimentPolarity {
  readonly id: Polarity;
  readonly title: string;
  readonly class: string;
  readonly polarity: number;
}

/**
 * These constants are used for frontend polarity labeling. Any segment with a polarity under the negative threshold is
 * considered negative. Any segment with a polarity above the positive threshold is considered positive. Anything in
 * between is neutral.
 *
 * These thresholds currently assume a scale of 1 - 3.
 */

export const NEGATIVE_POLARITY_THRESHOLD = 1.7;
export const POSITIVE_POLARITY_THRESHOLD = 2.3;

export const sentimentPolarities: readonly SentimentPolarity[] = [
  { id: Polarity.Positive, title: "Positive", class: "positive", polarity: 3 },
  { id: Polarity.Neutral, title: "Neutral", class: "neutral", polarity: 2 },
  { id: Polarity.Negative, title: "Negative", class: "negative", polarity: 1 },
];

export const sentimentToClassification = (sentiment: number): Polarity => {
  let rounded = roundSentiment(sentiment);
  if (rounded === 3) return Polarity.Positive;
  else if (rounded === 1) return Polarity.Negative;
  else return Polarity.Neutral;
};

/**
 * Rounds sentiment to a whole number. These numbers correspond to sentiment values.
 * 1 - negative, 2 - neutral, 3 - positive
 * @param sentiment input polarity in the range of 1 - 3
 */
export const roundSentiment = (sentiment: number): number => {
  if (sentiment >= POSITIVE_POLARITY_THRESHOLD) return 3;
  else if (sentiment < NEGATIVE_POLARITY_THRESHOLD) return 1;
  else return 2;
};

export function getPolarityModelByScore(polarity: number): SentimentPolarity {
  return sentimentPolarities.find((x) => x.id === sentimentToClassification(polarity))!;
}

export function getPolarityClassFromId(polarity: Polarity): SentimentPolarity {
  return sentimentPolarities.find((x) => x.id === polarity)!;
}

export function getPolarityModelByName(polarity: Polarity): SentimentPolarity {
  return sentimentPolarities.find((x) => x.id === polarity)!;
}

interface GroupedAttribute {
  name: string;
  avgPolarity: number;
  count: number;
  isAttributeGroup: boolean;
  parent?: string;
}

export interface TreeNodeCommon {
  name: string;
  type: string;
  path: string[];
  children: readonly ChildNode[];
  fetchedPercents?: boolean;
}

export interface RootNode extends TreeNodeCommon {
  name: string;
  type: "root";
  path: string[];
  children: readonly CategoryNode[];
}

export interface ChildNodeCommon extends TreeNodeCommon {
  value?: number;
  parent?: string;
  parentNode?: TreeNode;
  sentimentChange?: number;
  mentionChange?: number;
}

export interface CategoryNode extends ChildNodeCommon, PulseContentAnalysis {
  type: "Category";
  children: readonly TopicNode[];
}

export interface TopicNode extends ChildNodeCommon, PulseContentAnalysis {
  type: "Topic";
  children: readonly AttributeNode[];
  attributeChildren: readonly AttributeNode[];
}

export interface AttributeNode extends ChildNodeCommon, GroupedAttribute {
  type: "Attribute";
  value: number;
  children: readonly never[];
}

export type ChildNode = CategoryNode | TopicNode | AttributeNode;

export type TreeNode = RootNode | ChildNode;

export function hasParentNode(node: TreeNode): node is ChildNode & { parentNode: TreeNode } {
  return !!(node as ChildNode).parentNode;
}

function groupByParent(items: PulseContentAnalysis[]): { [key: string]: PulseContentAnalysis[] } {
  const hash: { [key: string]: PulseContentAnalysis[] } = {};
  // group
  for (let item of items) {
    const parentPath = item.path.slice(1).join("/");
    (hash[parentPath] = hash[parentPath] || []).push(item);
  }
  return hash;
}

//For attribute summary view
function groupByPolarity(attrs: { avgPolarity: number; count: number }[], parent?: string): GroupedAttribute[] {
  const grouped = groupBy(attrs, (a) => sentimentToClassification(a.avgPolarity));

  return Object.entries(grouped).map(([avgPolarity, rest]) => {
    const polarity = getPolarityModelByName(avgPolarity as Polarity);
    return {
      name: polarity.title,
      avgPolarity: polarity.polarity,
      count: sumBy(rest, (attr) => attr.count),
      isAttributeGroup: true,
      parent,
    };
  });
}

function sort(arr: PulseContentAnalysis[]): PulseContentAnalysis[] {
  return arr.slice().sort((a, b) => {
    let roundedA = roundSentiment(a.avgPolarity);
    let roundedB = roundSentiment(b.avgPolarity);
    if (roundedA > roundedB) {
      return -1;
    } else if (roundedB > roundedA) {
      return 1;
    } else {
      return b.count - a.count;
    }
  });
}

/**
 * turns categories, topics, attributes into a tree object for d3
 *
 * To ensure that d3 can use this data, it has to have a specific layout:
 *    https://web.archive.org/web/20201125043907/https://www.d3indepth.com/layouts/
 */
export function mapPayloadToNestedData({
  categories,
  topics,
  attributes,
  selectedSeriesName,
  selectedNode,
  upper,
  lower,
}: {
  categories: PulseContentAnalysis[];
  topics: PulseContentAnalysis[];
  attributes: PulseContentAnalysis[];
  selectedSeriesName?: string;
  selectedNode?: { name: string; parent: string };
  upper?: number;
  lower?: number;
}): RootNode {
  let groupedTopics = groupByParent(topics);
  let groupedAttributes = groupByParent(attributes);

  const minRatio = 0; // Used to be 6 / 360 degrees
  // build the tree
  const tree: RootNode = {
    name: "",
    type: "root",
    path: [],
    children: sort(categories).map((cat) => {
      const minTopicCount = 1; // Used to be Math.floor(cat.count * minRatio);

      const topics = sort(groupedTopics[cat.path.join("/")] || [])
        .filter((x) => x.count >= minTopicCount)
        .filter((x) => {
          let upperCheck = !!upper ? x.count <= upper : true;
          let lowerCheck = !!lower ? x.count >= lower : true;
          let inSelectedPath =
            (selectedSeriesName === "Topic" && selectedNode?.name === x.name) ||
            (selectedSeriesName === "Attribute" && selectedNode?.parent === x.name);
          return (lowerCheck && upperCheck) || inSelectedPath;
        });

      return {
        ...cat,
        type: "Category",
        path: [cat.name],
        ...(!topics.length
          ? {
              // treat as a leaf
              value: cat.count,
              children: [],
            }
          : {
              children: topics
                .filter((t) => t.count >= minTopicCount)
                .map((topic) => {
                  const minAttributeCount = Math.floor(topic.count * minRatio),
                    attributes: GroupedAttribute[] = sort(groupedAttributes[topic.path.join("/")] || [])
                      .map((x) => ({ ...x, isAttributeGroup: false }))
                      .filter((x) => x.count >= minAttributeCount),
                    attributesSum = groupByPolarity(attributes, topic.name) || [],
                    allAttributeChildren = attributesSum.concat(attributes),
                    sumOfAttributes = sumBy(attributes, (x) => x.count),
                    ratio = sumOfAttributes ? topic.count / sumOfAttributes : 1;

                  return {
                    ...topic,
                    type: "Topic",
                    path: [cat.name, topic.name],
                    ...(!attributes.length
                      ? {
                          // treat as a leaf
                          value: topic.count,
                          children: [],
                          attributeChildren: [],
                        }
                      : {
                          attributeChildren: attributes.map((attr) => ({
                            ...attr,
                            type: "Attribute",
                            path: [cat.name, topic.name, attr.name],
                            // This is specifically for d3 to structure the tree data correctly
                            value: attr.count * ratio,
                            children: [],
                          })),
                          children: allAttributeChildren.map((attr) => ({
                            ...attr,
                            type: "Attribute",
                            path: [cat.name, topic.name, attr.name],
                            // This is specifically for d3 to structure the tree data correctly
                            value: attr.count * ratio,
                            children: [],
                          })),
                        }),
                  };
                }),
            }),
      };
    }),
  };

  function backlink(node: ChildNode, parent?: TreeNode) {
    if (parent) {
      node.parentNode = parent;
    }
    if (node.children) {
      for (let child of node.children) {
        backlink(child, node);
      }
    }
  }

  for (let child of tree.children) {
    backlink(child, tree);
  }

  return tree;
}
