import moment from "moment";
import * as React from "react";
import { SearchToSelect, Selector } from "../../../components";
import SentimentIcon from "../components/SentimentIcon";
import { today } from "../../reviews/ReviewFilterStore";

const eod = today.endOf("day").format("YYYY-MM-DD");

const tomorrow = moment().add(1, "day").format("YYYY-MM-DD");

export interface DatePickerOptions {
  id: string;
  name: string;
  from: string;
  to?: string;
}

export const dateRangePickerOptions: DatePickerOptions[] = [
  // TODO - would be nice to remove startDate, endDate, from, to duplication
  {
    id: "AllTime",
    name: "All Time",
    from: moment("1990-01-01").format("YYYY-MM-DD"), // TODO: Find a better way
    to: undefined,
  },
  {
    id: "Last7Days",
    name: "Last 7 Days",
    from: moment().subtract(7, "days").format("YYYY-MM-DD"),
    to: eod,
  },
  {
    id: "Last30Days",
    name: "Last 30 Days",
    from: moment().subtract(30, "days").format("YYYY-MM-DD"),
    to: eod,
  },
  {
    id: "Last3Months",
    name: "Last 3 Months",
    from: moment().subtract(3, "months").format("YYYY-MM-DD"),
    to: eod,
  },
  {
    id: "Last6Months",
    name: "Last 6 Months",
    from: moment().subtract(6, "months").format("YYYY-MM-DD"),
    to: eod,
  },
  {
    id: "Last12Months",
    name: "Last 12 Months",
    from: moment().subtract(12, "months").format("YYYY-MM-DD"),
    to: eod,
  },
  {
    id: "Last24Months",
    name: "Last 24 Months",
    from: moment().subtract(24, "months").format("YYYY-MM-DD"),
    to: eod,
  },
];

export interface NormalizedDatePickerOptions {
  id: string;
  name: string;
  from: string;
  to?: moment.Moment;
}

// -1 day when displaying in the UI. Stored value is a timestamp, but ui is a day selector.
// Selecting a single day, e.g. 2019-10-30 in the selector sets a day range of 2019-10-30 - 2019-10-30
// api queries expect a range of 2019-10-30T00:00:00 - 2019-10-31T00:00:00 for the equivalent selection
export const normalizedDatePickerOptions: NormalizedDatePickerOptions[] = dateRangePickerOptions.map((x) => ({
  ...x,
  to: x.to ? moment(x.to).subtract(1, "day") : undefined,
}));

export const granularities = [
  {
    name: "Day",
    approximateDays: 1,
  },
  {
    name: "Month",
    approximateDays: 30,
  },
  {
    name: "Quarter",
    approximateDays: 91,
  },
  {
    name: "Year",
    approximateDays: 365,
  },
];

// TODO: Remove when beta is done and the old summary vanishes
export function granularitiesForRange(startDate: moment.MomentInput, endDate: moment.MomentInput) {
  const startMoment = startDate ? moment(startDate) : moment().subtract(5, "years");
  const endMoment = endDate ? moment(endDate) : moment();
  const days = endMoment.diff(startMoment, "days");
  if (Number.isNaN(days) || days < 1) {
    throw new Error(`invalid range: ${startDate} - ${endDate}`);
  } else {
    const okay = granularities.filter((x) => {
      // only allow granularities that will show between 1 and 100 data points
      return x.approximateDays < days && days < x.approximateDays * 100;
    });

    // choose the granularity that shows the closest to 12 slices
    const closest = okay
      .map((x) => ({ name: x.name, score: Math.abs(days / x.approximateDays - 12) }))
      .sort((a, b) => a.score - b.score);

    return {
      availableGranularities: okay.map((x) => x.name),
      defaultGranularity: closest.map((x) => x.name)[0] || "Day",
    };
  }
}

/**
 * This function is to help ensure that the dates selected, match the id.
 * The main case for this is handling of null/undefined start and end dates.
 * These often come up when dealing with all time and initial loads.
 */
export function datesForRange(
  reportRange: string | undefined,
  startDate: moment.MomentInput,
  endDate: moment.MomentInput
) {
  const range =
    !!reportRange &&
    dateRangePickerOptions.filter((x) => {
      return x.id === reportRange;
    });

  if (!!range && range.length > 0) {
    return { startDate: range[0].from, endDate: range[0].to };
  } else
    return {
      reportRange: "SpecificDates",
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    };
}

export const polarityOptions = [
  { id: "Negative", name: "Negative" },
  { id: "Neutral", name: "Neutral" },
  { id: "Positive", name: "Positive" },
];

export const SentimentSelector = ({
  selected,
  onSelected,
  multi = true,
}: {
  selected: string[];
  onSelected: (s?: string[]) => void;
  multi?: boolean;
}) => {
  return (
    <Selector
      right={true}
      placeholder={<span>Sentiment</span>}
      multi={multi}
      selectByKey={true}
      options={polarityOptions}
      getKey={(x: { id: string; name: string }) => x.id}
      renderOption={(x: { id: string; name: string }) => (
        <span>
          <SentimentIcon classification={x.id} /> {x.name}
        </span>
      )}
      renderMultiSelected={(xs: { id: string; name: string }[]) => (
        <div>
          {xs.map((x) => (
            <SentimentIcon key={x.id} className="ml-1 mr-1" classification={x.id} />
          ))}
        </div>
      )}
      selected={selected}
      onSelected={onSelected}
    />
  );
};

function getSearchResults(options: () => Promise<Iterable<string>>) {
  return function ({ query }: { query: string }) {
    return options().then((sets) => {
      const matching = Array.from(sets).filter((x) => {
        return !query || (!!x && x.toLowerCase().startsWith(query.trim().toLowerCase()));
      });
      return ([] as string[]).concat(matching);
    });
  };
}

export const dataSetOptions = [
  { id: "onlyOrganic", name: "Pulse Topics" },
  { id: "onlyCustom", name: "Your Topics" },
  { id: "allTopics", name: "Both" },
];

export const DataSetSelector = ({
  selected,
  onSelected,
  multi = false,
}: {
  selected?: string;
  onSelected: (s?: string) => void;
  multi?: boolean;
}) => {
  return (
    <Selector
      right={true}
      placeholder={<span>Data Set</span>}
      multi={multi}
      selectByKey={true}
      options={dataSetOptions}
      getKey={(x: any) => x.id}
      renderOption={(x: any) => <span>{x.name}</span>}
      renderMultiSelected={(xs: any[]) => <div>{xs.map((x) => x.name)}</div>}
      selected={selected}
      onSelected={onSelected}
    />
  );
};

export const CategoriesSelector = ({
  selected,
  onSelected,
  options,
  multi = true,
}: {
  selected?: string[];
  onSelected: (s?: string[]) => void;
  options: () => Promise<Iterable<string>>;
  multi?: boolean;
}) => {
  return (
    <SearchToSelect
      right={true}
      positionFixed={true}
      placeholder={<span>Categories</span>}
      getSearchResults={getSearchResults(options).bind(this)}
      multi={multi}
      options={options}
      renderMultiSelected={(xs: string[]) => <span>{xs.length} Categories</span>}
      selected={selected}
      onSelected={onSelected}
      disableCaching={true}
      eager={true}
    />
  );
};

export const TopicsSelector = ({
  selected,
  onSelected,
  options,
  multi = true,
}: {
  selected?: string[];
  onSelected: (s?: string[]) => void;
  options: () => Promise<Iterable<string>>;
  multi?: boolean;
}) => {
  return (
    <SearchToSelect
      right={true}
      positionFixed={true}
      placeholder={<span>Topics</span>}
      getSearchResults={getSearchResults(options).bind(this)}
      multi={multi}
      options={options}
      renderMultiSelected={(xs: string[]) => <span>{xs.length} Topics</span>}
      selected={selected}
      onSelected={onSelected}
      disableCaching={true}
      eager={true}
      selectByKey={true}
      getKey={(x: any) => x}
    />
  );
};
