import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Tooltip } from "reactstrap";
import ReactDOM from "react-dom";

export class CopyToClipboard extends Component {
  static propTypes = {
    value: PropTypes.string,
  };

  copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = this.props.value;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    if (this.props.onCopy) this.props.onCopy();
  };

  render() {
    const { value, ...props } = this.props;
    return (
      <React.Fragment>
        {value === undefined ? <span /> : <span {...props} onClick={this.copyToClipboard} />}
      </React.Fragment>
    );
  }
}

export class CopyToClipboardButton extends Component {
  state = { copied: false, tooltipOpen: false };

  getButton = () => {
    return this.button;
  };

  setButton = (component) => {
    this.button = ReactDOM.findDOMNode(component);
  };

  setCopied = () => {
    this.setState({ copied: true });
  };

  unsetCopied = () => {
    this.setState({ copied: false, tooltipOpen: false });
  };

  toggleTooltip = () => {
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  };

  renderTooltip = (msg) => {
    return (
      <Tooltip target={this.getButton} toggle={this.toggleTooltip} isOpen={this.state.tooltipOpen}>
        {msg}
      </Tooltip>
    );
  };

  render() {
    const { value, ...props } = this.props;
    const tooltipMsg = this.state.copied ? "Copied!" : "Copy to Clipboard";
    const tooltipControl = this.renderTooltip(tooltipMsg);
    return (
      <CopyToClipboard value={value} onCopy={this.setCopied} onMouseLeave={this.unsetCopied}>
        <Button ref={this.setButton} {...props}>
          {this.props.icon ? <i className={this.props.icon} /> : <span className="ss ss-clipboard" />}
        </Button>
        {tooltipControl}
      </CopyToClipboard>
    );
  }
}
