import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { DropdownItem } from "reactstrap";
import { PropTypes as ObservablePropTypes, useObserver } from "mobx-react";
import * as mobx from "mobx";
import { SearchToSelect, Spinner } from "../../components/index";
import { SearchToSelectRenderer } from "../../components/selector/SearchToSelectRenderer";
import { initialPagination, loadOptions } from "./locationGroupPaginatedSearch";
import { LOCATION_TYPE } from "./entityTypes";
import { formatPostalAddress } from "./locationAddress/util";

export function LocationGroupSelector({ selected, placeholder, includeAccounts, ...props }) {
  const selectedItems = useObserver(() => mobx.toJS(selected));
  return (
    <SearchToSelect
      {...props}
      getKey={(x) => x.entityIdString}
      placeholder={placeholder || "All Location Groups"}
      additional={initialPagination({ includeAccounts })}
      getSearchResults={getSearchResults}
      renderMultiSelected={(x) => `${selectedItems.length} Location Groups`}
      renderOption={(x) => nameWithCountIfNotLocation(x)}
      renderSelectedOption={(x) => (
        <span>
          <strong>{x.entityId.entityType}:</strong> {nameWithCountIfNotLocation(x)}
        </span>
      )}
      delay={100}
      selected={selectedItems}
      Render={LocationGroupDropdown}
    />
  );
}

function getSearchResults({ query, additional }) {
  return loadOptions(query, additional).then((result) => ({
    ...result,
    items: result.options.filter((x) => x.entityId.entityType !== "User"),
  }));
}

LocationGroupSelector.propTypes = {
  selected: ObservablePropTypes.observableArray.isRequired,
  includeAccounts: PropTypes.bool,
  ...SearchToSelect.propTypes,
};

function LocationGroupDropdown(props) {
  return <SearchToSelectRenderer renderOptions={LocationGroupOptions} {...props} />;
}

/**
 * Adds a heading between location groups
 */
export function LocationGroupOptions({
  loading,
  activeIndex,
  options,
  multi,
  selections,
  renderOption,
  addSelection,
  getKey,
}) {
  const selectedKey = !multi && selections[0] && getKey(selections[0]);
  let currentType = undefined;

  return loading ? (
    <DropdownItem disabled toggle={false}>
      <Spinner timeout={200} center />
    </DropdownItem>
  ) : (
    <Fragment>
      {options.map((x, i) => {
        let before;
        if (x.entityId.entityType !== currentType) {
          currentType = x.entityId.entityType;
          before = (
            <Fragment>
              {i === 0 ? null : <DropdownItem divider />}
              <DropdownItem toggle={false} disabled={true}>
                <strong>{x.entityId.entityType}s</strong>
              </DropdownItem>
              <DropdownItem divider />
            </Fragment>
          );
        }
        const key = getKey(x);
        return (
          <Fragment>
            {before}
            <DropdownItem
              key={key}
              toggle={!multi}
              active={i === activeIndex}
              disabled={!!selectedKey && key === selectedKey}
              onClick={() => addSelection(x)}
            >
              {renderOption(x)}
            </DropdownItem>
          </Fragment>
        );
      })}
    </Fragment>
  );
}

/**
 *
 * @param {LocationGroup} entity
 * @returns {string}
 */
export function nameWithCountIfNotLocation(entity) {
  return (
    entity.name +
    (entity.entityId.entityType !== LOCATION_TYPE
      ? ` (${entity.totalLocations})`
      : `, ${formatPostalAddress(entity.address)}`)
  );
}

export default LocationGroupSelector;
