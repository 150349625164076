import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { observer } from "mobx-react";
import { action, observable, makeObservable } from "mobx";
import { ProviderImage } from "../providerimage/ProviderImage";

const generateStyles = (containerSize, providerImageSize) => {
  return {
    container: {
      position: "relative",
      height: `${containerSize}px`,
      width: `${containerSize}px`,
    },
    userImageContainer: {
      width: `${containerSize}px`,
      height: `${containerSize}px`,
      borderRadius: "50%",
      overflow: "hidden",
      border: "1px solid #eee",
    },
    userImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    defaultUserImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      color: "#b3b5ba",
      background: "#EEE",
      fontSize: `${Math.round(containerSize * (2 / 3))}px`,
      textAlign: "center",
      lineHeight: `${containerSize}px`,
    },
    providerImage: {
      width: `${providerImageSize}px`,
      height: `${providerImageSize}px`,
      borderRadius: "50%",
      position: "absolute",
      top: `${containerSize - providerImageSize}px`,
      left: `${containerSize - providerImageSize}px`,
      border: "solid 2px white",
    },
  };
};

export const SocialImage = observer(
  class SocialImage extends Component {
    static propTypes = {
      userImage: PropTypes.string,
      userImageOverride: PropTypes.object,
      provider: PropTypes.string,
      providers: PropTypes.any,
      size: PropTypes.number,
      providerImageSize: PropTypes.number,
      className: PropTypes.string,
      styles: PropTypes.object,
    };

    userImageHadError = false;

    constructor(props) {
      super(props);

      makeObservable(this, {
        userImageHadError: observable,
        onImageError: action.bound,
      });
    }

    onImageError() {
      this.userImageHadError = true;
    }

    render() {
      const {
        userImage,
        userImageOverride,
        provider,
        providers, // public
        size = 60, // pixel dimensions
        providerImageSize,
        className,
        style,
      } = this.props;

      const styles = generateStyles(size, providerImageSize || Math.round(size * 0.4));
      const image =
        userImage && !this.userImageHadError ? (
          <img src={userImage} style={styles.userImage} onError={this.onImageError} />
        ) : (
          <div className="fas fa-user" style={styles.defaultUserImage} />
        );
      const providerImage = provider ? (
        <ProviderImage provider={provider} providers={providers} style={styles.providerImage} />
      ) : undefined;

      return (
        <div
          className={`cm-social-image-container ${className ? className : ""}`}
          style={{
            ...style,
            ...styles.container,
          }}
        >
          {userImageOverride || <div style={styles.userImageContainer}>{image}</div>}
          {providerImage}
        </div>
      );
    }
  }
);

export default SocialImage;
