import * as React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import * as PropTypes from "prop-types";
import * as mobx from "mobx";
import { createFilterSet, updateFilterSet, deleteFilterSet } from "./savedFilterSetAPI";
import { mergeWith, isEqual } from "lodash";
import SessionStore from "../../services/session/SessionStore";

export class ManageSavedFilterSet extends React.Component {
  static propTypes = {
    filterSet: PropTypes.any,
    filterSetConfig: PropTypes.object.isRequired,

    onToggle: PropTypes.func.isRequired,
    setFilterSet: PropTypes.func.isRequired,
    updateFilterSets: PropTypes.func.isRequired,
    featureGroup: PropTypes.string.isRequired,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      filterSet: props.filterSet,
    };
  }

  toggleShared = (checked, forShared) => {
    this.setState((state) => {
      return {
        ...state,
        filterSet: { ...state.filterSet, shareWithAccount: forShared ? checked : !checked },
      };
    });
  };

  setSetName = (name) => {
    this.setState((state) => {
      return {
        ...state,
        filterSet: { ...state.filterSet, name: name },
      };
    });
  };

  render() {
    const { onToggle, featureGroup, originalName, updateFilterSets, setFilterSet } = this.props;

    const { invalidName, error, errorMessage } = this.state;

    const filterSet = this.state.filterSet || this.props.filterSet;

    const query = mobx.toJS(this.props.filterSetConfig);

    const filterSetName = !!filterSet && !!filterSet.name ? filterSet.name : "";
    const filterSetId = !!filterSet && !!filterSet._id && filterSet._id;

    const user = SessionStore.user;
    const admin = user.permissions.includes("SavedFilterSetAdmin");
    const ownerOfFilterSet = !!filterSet && !!filterSet.userId && !!user ? user.userId === filterSet.userId : false;
    const shareWithAccount = !!filterSet && !!filterSet.shareWithAccount ? admin && filterSet.shareWithAccount : false;

    return (
      <div
        style={{
          padding: "5px 12px 12px 12px",
          margin: "0 0 7px 0",
          borderBottom: "0.077rem solid rgba(0, 0, 0, 0.15)",
          borderRadius: "0.153rem",
        }}
      >
        <div>
          {(!!error || !!errorMessage) && (
            <FormGroup>
              <Label style={{ color: "red" }}>{!!errorMessage ? errorMessage : "An Error occurred."}</Label>
            </FormGroup>
          )}
          <FormGroup>
            <Label>Set Name</Label>
            <Input
              type="text"
              name="filterSetName"
              value={filterSetName}
              style={invalidName && { borderColor: "red" }}
              onChange={(e) => {
                this.setSetName(e.target.value);
              }}
            />
          </FormGroup>
          {admin && (
            <span>
              <Label>Share Saved Filters with Team?</Label>
              <FormGroup>
                <span style={{ marginLeft: 17 }}>
                  <Label>
                    <Input
                      type="radio"
                      name="shared"
                      checked={shareWithAccount}
                      onChange={(e) => {
                        this.toggleShared(e.target.checked, true);
                      }}
                    />
                    Shared
                  </Label>
                </span>
                <span style={{ paddingLeft: 17, marginLeft: 17 }}>
                  <Label>
                    <Input
                      type="radio"
                      name="private"
                      checked={!shareWithAccount}
                      onChange={(e) => {
                        this.toggleShared(e.target.checked, false);
                      }}
                    />
                    Private
                  </Label>
                </span>
              </FormGroup>
            </span>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: originalName !== filterSetName && !filterSetId ? "flex-end" : "space-between",
          }}
        >
          {!!filterSetId && (admin || ownerOfFilterSet) && (
            <button
              style={{ padding: "7px" }}
              className={"btn"}
              onClick={() => {
                deleteFilterSet({ filterSetId })
                  .then(() => {
                    setFilterSet(undefined);
                    updateFilterSets();
                    onToggle();
                  })
                  .catch((e) => {
                    this.setState({ error: true, errorMessage: e.response.data });
                  });
              }}
            >
              <i className={"ss-trash"} />
            </button>
          )}{" "}
          <button
            style={{ padding: "7px", visibility: originalName !== filterSetName ? "unset" : "hidden" }}
            className={!!filterSetId ? "btn btn-outline-secondary" : "btn btn-secondary"}
            onClick={() => {
              !!filterSetName && filterSetName.length > 0 && filterSetName !== originalName
                ? createFilterSet({
                    featureGroup: featureGroup,
                    filterSetName,
                    shareWithAccount,
                    query: { ...query, filterSetId: undefined, filterSet: undefined },
                  })
                    .then((newFilterSet) => {
                      setFilterSet({ ...newFilterSet, _id: newFilterSet._id.$oid });
                      updateFilterSets();
                      onToggle();
                    })
                    .catch((e) => {
                      this.setState({
                        error: true,
                        errorMessage: e.response.data,
                      });
                    })
                : this.setState({
                    invalidName: true,
                    errorMessage: "Invalid name.",
                  });
            }}
          >
            Create
          </button>{" "}
          {!!filterSetId && ownerOfFilterSet && (
            <button
              style={{ padding: "7px" }}
              className={"btn btn-secondary"}
              onClick={() => {
                !!filterSetName && filterSetName.length > 0
                  ? updateFilterSet({ featureGroup: featureGroup, filterSetId, filterSetName, shareWithAccount, query })
                      .then((updatedFilterSet) => {
                        setFilterSet({ ...updatedFilterSet, _id: updatedFilterSet._id.$oid });
                        updateFilterSets();
                        onToggle();
                      })
                      .catch((e) => {
                        this.setState({ error: true, errorMessage: e.response.data });
                      })
                  : this.setState({ invalidName: true, errorMessage: "You must specify a name." });
              }}
            >
              Update
            </button>
          )}{" "}
        </div>
      </div>
    );
  }
}
