import { EventType } from "../../modules/notifications/settings/notificationTriggersStore";
import _ from "lodash";
import moment from "moment";
import { applyFiltersToToQueryObject } from "../../util/urlSync";
import {
  urlQueryDefinition as reviewUrlQueryDefinition,
  dateRanges as reviewDateRanges,
} from "../../modules/reviews/ReviewFilterStore";
import { urlQueryDefinition as socialUrlQueryDefinition } from "../../modules/social/filters/SocialPostFiltersStore";
import { urlQueryDefinition as credentialUrlQueryDefinition } from "../../modules/admin/credentialManagement/CredentialFiltersStore.ts";
import { dateRangeOptions as socialDateRanges } from "../../modules/social/filters/SocialPostFiltersContainer";
import { stringify } from "../../util/UrlFragmentSearch";
import WorkflowDomainStore from "../../modules/workflow/workflowDomainStore";
import { priorities } from "../../modules/workflow/models";
import defaultSessionStore from "../session/SessionStore";

const WorkflowPriorityToInt = priorities.reduce((acc, curr) => {
  acc[curr.name] = curr.value;
  return acc;
}, {});

const LinkBuilder = {
  fromTrigger(trigger) {
    switch (trigger.eventType) {
      case EventType.REVIEW_RECEIVED:
        return LinkBuilder.reviewReceived(trigger);
      case EventType.REVIEW_UPDATED:
        return LinkBuilder.reviewUpdated(trigger);
      case EventType.GOOGLE_QUESTIONS_AND_ANSWERS:
        return LinkBuilder.socialGoogleQA(trigger);
      case EventType.SOCIAL_POST_FAILED:
        return LinkBuilder.socialPostFailed(trigger);
      case EventType.SOCIAL_POST_PUBLISHED:
        return LinkBuilder.socialPostPublished(trigger);
      case EventType.CREDENTIAL_EXPIRED:
        return LinkBuilder.credentialsExpired(trigger);
      case EventType.WORKFLOW_TASK_ASSIGNED:
        return LinkBuilder.workflowTaskAssigned(trigger);
      case EventType.WORKFLOW_TASK_APPROVAL_NEEDED:
        return LinkBuilder.workflowTaskPendingApprovalOrUnapproved(trigger);
      case EventType.WORKFLOW_TASK_UNAPPROVED:
        return LinkBuilder.workflowTaskPendingApprovalOrUnapproved(trigger);
      case EventType.WORKFLOW_TASK_OVERDUE:
        return LinkBuilder.workflowTaskOverdue(trigger);
      case EventType.SOCIAL_COMMENT_RECEIVED:
        return LinkBuilder.socialCommentReceived(trigger);
      default:
        console.warn("url not supported");
    }
  },

  reviewReceived(trigger) {
    const { range, endDate, startDate } = LinkBuilder.getReviewDate();
    const params = {
      range,
      endDate,
      startDate,
      ratings: (trigger.criteria.reviewStars || [])
        .map((x) => `${x}star`)
        .concat(
          // Convert to camel case to match  expected query parameters
          (trigger.criteria.reviewRecommendations || []).map((x) => _.camelCase(x))
        ),
      providers: trigger.criteria.reviewProvider || [],
      riskCategories: trigger.criteria.risk || [],
      searchText: LinkBuilder.getReviewKeywords(trigger),
    };
    const baseUrl = "/#/reviews?";
    const query = {};
    applyFiltersToToQueryObject(reviewUrlQueryDefinition, params, query);
    const queryString = stringify(query);
    return baseUrl + queryString;
  },

  reviewUpdated(trigger) {
    const { range, endDate, startDate } = LinkBuilder.getReviewDate();
    const params = {
      range,
      endDate,
      startDate,
      sortField: "lastRevisionDate",
      sortOrder: "DESC",
      providers: trigger?.criteria?.reviewProvider || [],
      searchText: LinkBuilder.getReviewKeywords(trigger),
      revisionsFilter: "OnlyWith", // filter always by revisions
    };
    const baseUrl = "/#/reviews?";
    const query = {};
    applyFiltersToToQueryObject(reviewUrlQueryDefinition, params, query);
    const queryString = stringify(query);
    return `${baseUrl}${queryString}`;
  },

  socialCommentReceived(trigger) {
    const params = {
      hasCustomerReply: true,
    };
    const baseUrl = "/#/social/activity?";
    const query = {};
    applyFiltersToToQueryObject(socialUrlQueryDefinition, params, query);
    const queryString = stringify(query);
    return `${baseUrl}${queryString}`;
  },

  getReviewKeywords(trigger) {
    return trigger?.criteria?.keywords.map((x) => `"${x}"`).join(" | ") || null;
  },

  getReviewDate() {
    const dateRange = reviewDateRanges.find((x) => x.id === "Last30Days");
    if (!dateRange) throw new Error("date range not found. Did date ranges change?");
    return { range: dateRange.id, endDate: dateRange.to, startDate: dateRange.from };
  },

  getSocialDate() {
    const dateRange = socialDateRanges.find((x) => x.dateRangeType === "month");
    if (!dateRange) throw new Error("date range not found. Did date ranges change?");
    return { dateRangeType: dateRange.dateRangeType, end: dateRange.to, start: dateRange.from };
  },

  socialGoogleQA(trigger) {
    const baseUrl = "#/social/activity?";
    const params = {
      chatterProvider: ["Google"],
      text: trigger.criteria.keywords.join("|"),
      ...LinkBuilder.getSocialDate(),
    };
    const query = {};
    applyFiltersToToQueryObject(socialUrlQueryDefinition, params, query);
    const queryString = stringify(query);
    return `${baseUrl}${queryString}`;
  },

  socialPostFailed(trigger) {
    const baseUrl = "#/social/publishing/list/errors";
    return `${baseUrl}`;
  },

  socialPostPublished(trigger) {
    const baseUrl = "#/social/publishing/list/published";
    return `${baseUrl}`;
  },

  credentialsExpired(trigger) {
    const baseUrl = "#/admin/v2/credentials?";
    const expirationThreshold = (thresholdDays) => {
      if (thresholdDays === 7) {
        return "7Days";
      } else if (thresholdDays === 0) {
        return "expired";
      }
    };
    const params = {
      expiration: expirationThreshold(trigger.criteria.thresholdDays),
    };
    const query = {};
    applyFiltersToToQueryObject(credentialUrlQueryDefinition, params, query);
    const queryString = stringify(query);
    return `${baseUrl}${queryString}`;
  },

  workflowTaskAssigned(trigger) {
    //Build dictionary of name to value
    const baseUrl = "#/workflow2?";
    const priority = trigger.criteria.priority.map((p) => WorkflowPriorityToInt[p]);
    const params = { priority, assigneeId: [defaultSessionStore.user.userId] };
    const query = {};
    applyFiltersToToQueryObject(WorkflowDomainStore.urlFilterTypes, params, query);
    const queryString = stringify(query);
    return `${baseUrl}${queryString}`;
  },

  workflowTaskPendingApprovalOrUnapproved(trigger) {
    const baseUrl = "#/workflow2?";
    const priority = trigger.criteria.priority.map((p) => WorkflowPriorityToInt[p]);
    let status = trigger.eventType === "WorkflowTaskApprovalNeeded" ? ["PendingApproval"] : ["Unapproved"];
    const params = { status: status, priority, taskType: trigger.criteria.approvalType };
    const query = {};
    applyFiltersToToQueryObject(WorkflowDomainStore.urlFilterTypes, params, query);
    const queryString = stringify(query);
    return `${baseUrl}${queryString}`;
  },

  workflowTaskOverdue(trigger) {
    const baseUrl = "#/workflow2?";
    const priority = trigger.criteria.priority.map((p) => WorkflowPriorityToInt[p]);
    // usually workflow events are forward looking unless it's a custom date range
    const { start, end } = LinkBuilder.getWorkflowDateRange(-30);
    const params = {
      priority,
      status: [],
      sortField: "dueDate",
      dueBefore: start,
      dueAfter: end,
      assigneeId: [defaultSessionStore.user.userId],
    };
    const query = {};
    applyFiltersToToQueryObject(WorkflowDomainStore.urlFilterTypes, params, query);
    const queryString = stringify(query);
    return `${baseUrl}${queryString}`;
  },

  getWorkflowDateRange(range) {
    const start = moment().format("YYYY-MM-DD");
    const end = moment().add(range, "days").format("YYYY-MM-DD");
    return { start, end };
  },
};

export default LinkBuilder;
