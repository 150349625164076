import { extendObservable, makeAutoObservable, runInAction, toJS, action, observable } from "mobx";
import { cloneDeep } from "lodash";
import { EventType, UserPermission, LocationCriteriaFields } from "./notificationTriggersStore";
import {
  checkValidName,
  createNotificationTrigger,
  testNotificationTrigger,
  updateNotificationTrigger,
} from "./notificationTriggersApi";
import defaultSessionStore from "../../../services/session/SessionStore";
import { getTaskType, TaskCategory } from "../../workflow/models";
import { shouldDisableRisk } from "./criteria/ReviewReceived";

class CreateOrEditStore {
  mode;
  trigger;
  originalTrigger;
  errors;
  hasValidName = true;
  checkingValidName;
  eventTypes = [];

  constructor(eventTypes, trigger) {
    this.eventTypes = eventTypes;
    window.NotificationTriggerEditor = this;
    if (trigger && trigger.emailDeliveryDelay === undefined)
      trigger.emailDeliveryDelay = EmailDefaultDeliveryDelay.value;
    makeAutoObservable(this, {}, { autoBind: true });
    this.mode = trigger ? CreateOrEditMode.EDIT : CreateOrEditMode.CREATE;
    if (trigger) {
      this.trigger = trigger;
      // optional field that is not always available
      if (!trigger.criteria) {
        // needs to be an object first or extendObservable won't work
        this.trigger.criteria = {};
        extendObservable(this.trigger.criteria, this.criteriaByEventType(trigger.type));
      }
    } else {
      const clone = cloneDeep(emptyTrigger);
      this.trigger = { ...clone };
    }
    this.originalTrigger = toJS(this.trigger);
  }

  setName(value) {
    this.trigger.name = value;
  }

  onNameBlur() {
    if (this.originalTrigger && this.trigger.name === this.originalTrigger.name) {
      this.hasValidName = true;
    } else {
      const trimmed = this.trigger.name.trim();
      if (trimmed) {
        this.checkingValidName = true;
        checkValidName(trimmed).then((resp) =>
          runInAction(() => {
            this.hasValidName = resp.isUnique;
            this.checkingValidName = false;
          })
        );
      }
    }
  }

  setDeliveryMethod(field, value) {
    switch (field) {
      case "notificationInbox":
        this.trigger.channels.notificationInbox = value;
        break;
      case "email":
        this.trigger.channels.email.enabled = value;
        break;
      case "popups":
        this.trigger.channels.popups = value;
        break;
    }
  }

  setEventType(type) {
    this.trigger.eventType = type;
    const oldCriteria = toJS(this.trigger.criteria);

    this.trigger.criteria = this.criteriaByEventType(type, oldCriteria);

    // annoying little side effect, but if the event type changes to one that
    // doesn't allow custom then recipient options need to be updated
    if (!this.isCustomRecipientsEnabled) [this.setRecipientTypes(this.recipientOptions[0].key)];
  }

  setEmailDeliveryDelay(delay) {
    this.trigger.emailDeliveryDelay = delay;
  }

  criteriaByEventType(type, oldCriteria) {
    let newCriteria;
    switch (type) {
      case EventType.WORKFLOW_TASK_APPROVAL_NEEDED:
        newCriteria = {
          type: EventType.WORKFLOW_TASK_APPROVAL_NEEDED,
          taskType: [],
          priority: [],
        };
        break;
      case EventType.WORKFLOW_TASK_UNAPPROVED:
        newCriteria = {
          type: EventType.WORKFLOW_TASK_UNAPPROVED,
          taskType: [],
          priority: [],
        };
        break;
      case EventType.WORKFLOW_TASK_ASSIGNED:
        newCriteria = {
          type: EventType.WORKFLOW_TASK_ASSIGNED,
          priority: [],
        };
        break;
      case EventType.WORKFLOW_TASK_OVERDUE:
        newCriteria = {
          type: EventType.WORKFLOW_TASK_OVERDUE,
          priority: [],
        };
        break;
      case EventType.REVIEW_UPDATED:
        newCriteria = {
          type: EventType.REVIEW_UPDATED,
          reviewProvider: [],
          keywords: [],
          ...LocationCriteriaFields,
        };
        break;
      case EventType.GOOGLE_QUESTIONS_AND_ANSWERS:
        newCriteria = {
          type: EventType.GOOGLE_QUESTIONS_AND_ANSWERS,
          postType: [],
          keywords: [],
          ...LocationCriteriaFields,
        };
        break;
      case EventType.CREDENTIAL_EXPIRED:
        newCriteria = {
          type: EventType.CREDENTIAL_EXPIRED,
          thresholdDays: 0,
          secondThreshold: undefined,
        };
        break;
      case EventType.SOCIAL_POST_FAILED:
        newCriteria = {
          type: EventType.SOCIAL_POST_FAILED, // Need this for saving trigger
        };
        break;
      case EventType.SOCIAL_POST_PUBLISHED:
        newCriteria = {
          type: EventType.SOCIAL_POST_PUBLISHED, // Need this for saving trigger
        };
        break;
      case EventType.SOCIAL_COMMENT_RECEIVED:
        newCriteria = {
          type: EventType.SOCIAL_COMMENT_RECEIVED, // Need this for saving trigger
          keywords: [],
        };
        break;
      case EventType.REVIEW_RECEIVED:
      default:
        newCriteria = {
          type: EventType.REVIEW_RECEIVED,
          reviewStars: [],
          reviewProvider: [],
          keywords: [],
          risk: [],
          ...LocationCriteriaFields,
        };
        break;
    }
    if (oldCriteria) {
      Object.keys(oldCriteria)
        .filter((key) => key !== "type")
        .forEach((key) => {
          if (newCriteria[key]) {
            newCriteria[key] = oldCriteria[key];
          }
        });
    }
    return newCriteria;
  }

  setReviewReceivedCriteria(key, value) {
    // something was getting mixed up when just setting the array values not causing a redraw, so copying them seemed to work
    const criteria = [...value];

    switch (key) {
      case "reviewStars":
        this.trigger.criteria.reviewStars = criteria;
        break;
      case "reviewProvider":
        this.trigger.criteria.reviewProvider = criteria;
        break;
      case "keywords":
        this.trigger.criteria.keywords = criteria;
        break;
      case "locations":
        this.trigger.criteria.locations = criteria;
        break;
      case "risk":
        this.trigger.criteria.risk = criteria;
        break;
    }
  }

  setWorkflowTaskApprovalCriteria(key, value) {
    switch (key) {
      case "taskType":
        this.trigger.criteria.taskType = [...value];
        break;
      case "priority":
        this.trigger.criteria.priority = [...value];
        break;
    }
  }

  setWorkflowTaskAssignedCriteria(key, value) {
    switch (key) {
      case "priority":
        this.trigger.criteria.priority = [...value];
        break;
    }
  }

  setGoogleQACriteria(key, value) {
    switch (key) {
      case "publisher":
        this.trigger.criteria.publisher = [...value];
        break;
      case "postType":
        this.trigger.criteria.postType = [...value];
        break;
      case "keywords":
        this.trigger.criteria.keywords = value;
        break;
      case "locations":
        this.trigger.criteria.locations = value;
        break;
    }
  }

  setReviewUpdatedCriteria(key, value) {
    switch (key) {
      case "reviewProvider":
        this.trigger.criteria.reviewProvider = [...value];
        break;
      case "keywords":
        this.trigger.criteria.keywords = value;
        break;
      case "locations":
        this.trigger.criteria.locations = value;
        break;
    }
  }

  setSocialCommentReceivedCriteria(key, value) {
    switch (key) {
      case "keywords":
        this.trigger.criteria.keywords = value;
        break;
    }
  }

  setCredentialExpiration(value) {
    this.trigger.criteria.thresholdDays = value.thresholdDays;
    this.trigger.criteria.secondThreshold = value.secondThreshold;
  }

  setRecipientTypes(all) {
    this.trigger.recipients.all = all;
    if (all) {
      this.trigger.recipients.userIds = [];
      this.trigger.recipients.userRoles = [];
    }
  }

  setUsers(users) {
    this.trigger.recipients.userIds = users;
  }

  setUserRoles(roles) {
    this.trigger.recipients.userRoles = roles;
  }

  get hasValidAssignees() {
    const { recipients } = this.trigger;
    return !!(recipients.all || recipients.userIds.length || recipients.userRoles.length);
  }
  // TODO - verify against data models what are options iterables and clear those not just all criteria
  get triggerForApi() {
    const triggerAsJs = toJS(this.trigger);
    triggerAsJs.name = triggerAsJs.name.trim();
    switch (triggerAsJs.eventType) {
      case EventType.REVIEW_RECEIVED:
        if (triggerAsJs.criteria.reviewStars && triggerAsJs.criteria.reviewStars.length) {
          const recommendations = triggerAsJs.criteria.reviewStars.filter((star) => isNaN(star));
          triggerAsJs.criteria.reviewStars = triggerAsJs.criteria.reviewStars.filter((star) => !isNaN(star));
          triggerAsJs.criteria.reviewRecommendations = recommendations;

          if (shouldDisableRisk(triggerAsJs.criteria.reviewStars)) {
            triggerAsJs.criteria.risk = [];
          }
        }

        break;
    }
    return triggerAsJs;
  }

  get isSavable() {
    const trimmed = this.trigger.name.trim();
    return this.hasValidName && this.hasValidAssignees && !!trimmed.length;
  }

  /**
   *
   * https://docs.google.com/spreadsheets/d/1S-Usw_fUCoFrrbnBUbln9w77GnU92vqYzv-UFzM1ZNM/
   */
  get isCustomRecipientsEnabled() {
    const customEnabledTypes = [
      EventType.REVIEW_RECEIVED,
      EventType.REVIEW_UPDATED,
      EventType.GOOGLE_QUESTIONS_AND_ANSWERS,
      EventType.SOCIAL_POST_RESPONDED_TO,
      EventType.SOCIAL_COMMENT_RECEIVED,
      EventType.WORKFLOW_TASK_APPROVAL_NEEDED,
    ];

    return customEnabledTypes.includes(this.trigger.eventType);
  }

  get getAutomaticRecipient() {
    const eventType = this.eventTypes.find((e) => e.eventType === this.trigger.eventType);
    return eventType ? toJS(eventType).automaticName.name : "Automatic";
  }

  /**
   * Computed method to fetch array of recipient assignee options based on if
   * the event type allows custom
   * @returns {Array}
   */
  get recipientOptions() {
    return [
      { key: true, value: this.getAutomaticRecipient }, // always present for every event type
      ...(this.isCustomRecipientsEnabled ? [{ key: false, value: "Custom" }] : []),
    ];
  }

  saveNotificationTrigger() {
    this.savingNotificationTrigger = true;
    const trigger = this.triggerForApi;
    trigger.accountId = defaultSessionStore.user.accountId;
    const promise = trigger._id ? updateNotificationTrigger : createNotificationTrigger;

    return promise(trigger).then(() => {
      runInAction(() => {
        this.savingNotificationTrigger = false;
        this.notificationTrigger = undefined;
      });
    });
  }

  testTrigger(delay = false) {
    if (!this.trigger._id) throw new Error("must save trigger before testing it");
    return testNotificationTrigger(this.trigger._id, { delay });
  }

  get requiredUserPermissions() {
    switch (this.trigger.eventType) {
      case EventType.REVIEW_RECEIVED:
      case EventType.REVIEW_UPDATED:
      case EventType.NEW_REVIEW:
        return [UserPermission.SHOW_REVIEWS];
      case EventType.GOOGLE_QUESTIONS_AND_ANSWERS:
      case EventType.SOCIAL_COMMENT_RECEIVED:
        return [UserPermission.SHOW_CHATTER];
      case EventType.WORKFLOW_TASK_APPROVAL_NEEDED:
        const socialTaskRequiredPermissions = [UserPermission.SOCIAL_COMMENTER, UserPermission.SOCIAL_POSTER];
        const reviewTaskRequiredPermissions = [UserPermission.APPROVE_REVIEW_RESPONSE];
        let taskTypes = this.trigger.criteria.taskType || [];
        let taskCategories = taskTypes.map((taskType) => getTaskType(taskType).category);
        let socialRequirements =
          !taskCategories.length || taskCategories.includes(TaskCategory.SOCIAL) ? socialTaskRequiredPermissions : [];
        let reviewRequirements =
          !taskCategories.length || taskCategories.includes(TaskCategory.REVIEWS) ? reviewTaskRequiredPermissions : [];
        return [UserPermission.TASK_ADMIN, ...socialRequirements, ...reviewRequirements];
      case EventType.WORKFLOW_TASK_ASSIGNED:
      case EventType.WORKFLOW_TASK_OVERDUE:
      case EventType.WORKFLOW_TASK_UNAPPROVED:
      case EventType.CREDENTIAL_EXPIRED:
      default:
        return [];
    }
  }
}

export const EmailDeliveryDelay = [
  { key: 5, value: 300, name: "5 min delay" },
  { key: 15, value: 900, name: "15 min delay" },
  { key: 30, value: 1800, name: "30 min delay" },
];

export const EmailDefaultDeliveryDelay = EmailDeliveryDelay[1];

export const emptyTrigger = {
  channels: {
    email: {
      enabled: false,
      sendImmediately: false,
    },
    popups: false,
    notificationInbox: false,
  },
  recipients: {
    all: true,
    userIds: [],
    userRoles: [],
  },
  criteria: {
    //TODO - type is a bit over redundant but required
    type: "ReviewReceived",
    reviewStars: [],
    reviewProvider: [],
    keywords: [],
    locations: [],
    risk: [],
  },
  name: "",
  eventType: "ReviewReceived",
  emailDeliveryDelay: EmailDefaultDeliveryDelay.value,
};

export const CreateOrEditMode = {
  EDIT: "CreateOrEditStore.EDIT",
  CREATE: "CreateOrEditStore.CREATE",
};

export default CreateOrEditStore;
