import React, { useState } from "react";
import hasSVG from "./hasSVG";
import { generateProviderUrl } from "./helpers";

const svgFilePath = "/images/providers/";
const pngFilePath = "/images/reviews/";

const ProviderImageInternal = ({ provider, customProviders, alt, ...props }) => {
  const [providerImage, setProviderImage] = useState(
    (() => {
      if (
        customProviders !== undefined &&
        customProviders[provider] !== undefined &&
        customProviders[provider]["imageUrl"] !== undefined
      ) {
        return customProviders[provider]["imageUrl"];
      }

      // To make assets work in storybook
      const assetUrl = !window.assetUrl.includes("localhost:") ? window.assetUrl : "https://assets.chatmeter.com/dev";
      return generateProviderUrl(provider, assetUrl, provider.toLowerCase() in hasSVG);
    })()
  );
  const [fallbackFailed, setFallbackFailed] = useState(false);
  return (
    <span className={`cm-provider-image`} {...props}>
      {fallbackFailed ? undefined : (
        <img
          src={providerImage}
          alt={alt || ""}
          onError={(e) => {
            if (providerImage.indexOf(".png") === -1) {
              setProviderImage((i) => pngFilePath + provider.toLowerCase() + ".png");
            } else {
              setFallbackFailed(true);
            }
          }}
        />
      )}
    </span>
  );
};

export default ({ provider, ...props }) => <ProviderImageInternal provider={provider} key={provider} {...props} />; // reset the component if the provider changes
