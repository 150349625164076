import React from "react";
import PropTypes from "prop-types";
import { getPathAndQuery } from "./UrlFragmentSearch";

/**
 * Use to quickly  control components from the url.
 * For example
 * <WithUrlFragment>{
 *  ({ pathname, search}) => <div>pathname: {pathname}, search: {search}</div>
 * }</WithUrlFragment>
 *
 *
 */
export class WithUrlFragment extends React.Component {
  /**
   * @typedef {Object} PathAndQuery
   * @property pathname: string
   * @property search: string
   */

  static propTypes = {
    // callback function called whenever path and query changes (passes in an {PathAndQuery} ). Should return jsx
    children: PropTypes.func.isRequired,
  };

  state = { pathAndQuery: getPathAndQuery() };

  componentWillMount() {
    window.addEventListener("hashchange", this.setPathAndQuery);
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", this.setPathAndQuery);
  }

  setPathAndQuery = () => this.setState({ pathAndQuery: getPathAndQuery() });

  render() {
    return this.props.children(this.state.pathAndQuery);
  }
}
