import accessStore from "./AccessStore";

const PAGE_SIZE = 30;

/**
 * @typedef {object} LocationGroupPagination
 * @property {number} accountsCursor
 * @property {boolean} accountsHasMore
 * @property {boolean} includeAccounts
 * @property {number} groupsCursor
 * @property {boolean} groupsHasMore
 * @property {number} locationsCursor
 * @property {boolean} locationsHasMore
 * @property {Promise<{groups: LocationGroup[]}> | undefined} groupsPromise
 * @property {Promise<{locations: LocationGroup[]}> | undefined} locationPromise
 */

/**
 * @param {string} search - a search term
 * @param {LocationGroupPagination} [additional] - paging information
 * @return {Promise<{options: LocationGroup[], hasMore: boolean, additional: LocationGroupPagination}>}
 */
export async function loadOptions(search, additional = initialPagination()) {
  /**
   * A decorator around searchOptions that immediately pages to the next section if there were no results for the last request.
   * Prevents jank from UI switching into and out of a loading state and waiting for a load more event
   */
  try {
    let result = await searchOptions(search, additional);
    const previousResults = result.options.slice();
    while (result.hasMore && result.options.length === 0) {
      result = await searchOptions(search, result.additional);
      previousResults.push(...result.options);
    }
    return { ...result, options: previousResults };
  } catch (e) {
    console.error("uh oh, location selector broke", e);
    throw e;
  }
}

/**
 *
 * @param includeAccounts - whether accounts should be queried
 * @return {LocationGroupPagination}
 */
export function initialPagination({ includeAccounts = true } = {}) {
  return {
    accountsCursor: 0,
    accountsHasMore: true,
    includeAccounts,
    groupsCursor: 0,
    groupsHasMore: true,
    locationsCursor: 0,
    locationsHasMore: true,
    groupsPromise: undefined,
    locationsPromise: undefined,
  };
}

/**
 * @param {string} search - a search term
 * @param {LocationGroupPagination} additional - paging information
 * @return {Promise<{options: LocationGroup[], hasMore: boolean, additional: LocationGroupPagination}>}
 */
async function searchOptions(search, additional) {
  if (additional.accountsCursor === 0 && additional.accountsHasMore) {
    // this is the first search
    const eventuallyLocations = accessStore.searchLocations({ q: search, limit: PAGE_SIZE });
    const eventuallyAccounts = accessStore.searchAccounts({ q: search, limit: PAGE_SIZE });
    const eventuallyGroups = accessStore.searchGroups({ q: search, limit: PAGE_SIZE });
    const { all, accounts } = await eventuallyAccounts;
    return {
      options: all.concat(additional.includeAccounts ? accounts : []),
      hasMore: true,
      additional: {
        ...additional,
        accountsCursor: accounts.length,
        accountsHasMore: additional.includeAccounts && accounts.length === PAGE_SIZE,
        groupsPromise: eventuallyGroups,
        locationsPromise: eventuallyLocations,
      },
    };
  } else if (additional.accountsHasMore) {
    // first page accounts
    const { accountsCursor } = additional;
    const { accounts } = await accessStore.searchAccounts({ q: search, limit: PAGE_SIZE, offset: accountsCursor });
    return {
      options: accounts,
      hasMore: true,
      additional: {
        ...additional,
        accountsHasMore: accounts.length === PAGE_SIZE,
        accountsCursor: accounts.length + accountsCursor,
      },
    };
  } else if (additional.groupsHasMore) {
    // then page groups
    const { groupsCursor, groupsPromise } = additional;
    const { groups } = await (groupsPromise ||
      (await accessStore.searchGroups({ q: search, limit: PAGE_SIZE, offset: groupsCursor })));
    return {
      options: groups,
      hasMore: true,
      additional: {
        ...additional,
        groupsPromise: undefined,
        groupsHasMore: groups.length === PAGE_SIZE,
        groupsCursor: groups.length + groupsCursor,
      },
    };
  } else if (additional.locationsHasMore) {
    // finally page locations
    const { locationsCursor, locationsPromise } = additional;
    const { locations } = await (locationsPromise ||
      (await accessStore.searchLocations({ q: search, limit: PAGE_SIZE, offset: locationsCursor })));
    const locationsHasMore = locations.length === PAGE_SIZE;
    return {
      options: locations,
      hasMore: locationsHasMore,
      additional: {
        ...additional,
        locationsPromise: undefined,
        locationsHasMore: locationsHasMore,
        locationsCursor: locations.length + locationsCursor,
      },
    };
  } else {
    return {
      options: [],
      hasMore: false,
    };
  }
}
