import React, { Component } from "react";
import PropTypes from "prop-types";
import AnimateHeight from "react-animate-height";
import classnames from "classnames";

export class ExpandCard extends Component {
  static propTypes = {
    className: PropTypes.string,
    header: PropTypes.element,
    children: PropTypes.element.isRequired,
    height: PropTypes.number,
    duration: PropTypes.number,
    opened: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      inTransition: false,
    };
  }

  toggle = () => {
    this.props.onToggle();
  };

  componentWillReceiveProps(nextProps, _) {
    if (nextProps.opened !== this.props.opened) {
      this.setState({ inTransition: true });
    }
  }

  setNotAnimating = () => this.setState({ inTransition: false });

  render() {
    let height = 0;
    const { children, className, duration, header, style } = this.props;
    const opened = this.props.opened;
    if (opened) {
      height = this.props.height || "auto";
    }

    const containerClasses = { card: true, "expand-card": true, opened: opened, closed: !opened };
    return (
      <div
        className={`${className || ""} ${classnames(containerClasses)}`}
        style={{
          boxShadow: "none",
        }}
      >
        <div className="card-body rounded border">
          <div className="card-title d-flex align-items-center justify-content-between" onClick={this.toggle}>
            {header ? header : "&nbsp;"}
          </div>
          <AnimateHeight duration={duration || 500} onAnimationEnd={this.setNotAnimating} height={height}>
            <div className="card-content">{opened || this.state.inTransition ? children : undefined}</div>
          </AnimateHeight>
        </div>
      </div>
    );
  }
}
