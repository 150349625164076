import { observer } from "mobx-react";
import React from "react";
import PropTypes from "prop-types";
import { SearchToSelect } from "../../components";
import { ProviderItem, SelectedProviders } from "./ProviderItems";
import { PropTypes as MobxPropTypes } from "mobx-react";

export class ProviderSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      memoizedPromise: undefined,
      resolved: false,
    };
  }
  static propTypes = {
    /** @type {function():Promise.<ContentProvider[]>} getProviders - returns a promise of an array of ContentProvider.
     * RESULT IS MEMOIZED INDEFINITELY,
     * please key this component accordingly (e.g. by group id if fetching the providers for a group)
     * in order to re-instantiate this component without previous promise's result */
    getProviders: PropTypes.func,
    selected: MobxPropTypes.arrayOrObservableArray, //PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
    onSelected: PropTypes.func.isRequired,
    onResultsLoaded: PropTypes.func,
    multi: PropTypes.bool,
  };

  static defaultProps = {
    multi: true,
  };

  memoizedPromise() {
    if (this.state.memoizedPromise) {
      return this.state.memoizedPromise;
    } else {
      const promise = this.props.getProviders();
      promise.then((providers) => {
        this.setState({ resolved: true });
        this.props.onResultsLoaded && this.props.onResultsLoaded(providers);
      });

      this.setState({ memoizedPromise: promise });
      return promise;
    }
  }

  getSearchResults = ({ query, offset }) => {
    return this.memoizedPromise().then((providers) => {
      return {
        items: !query ? providers : providers.filter((x) => x.name.toLowerCase().includes(query.trim().toLowerCase())),
      };
    });
  };

  render() {
    const { multi, getProviders, size, activeSize, ...props } = this.props;
    const loading = !!this.state.memoizedPromise && !this.state.resolved;
    return (
      <SearchToSelect
        positionFixed={true}
        selectByKey
        getKey={(x) => x.id}
        loading={loading}
        getSearchResults={this.getSearchResults}
        renderOption={(x) => <ProviderItem provider={x.id} size={size} />}
        renderMultiSelected={(selected) => (
          <SelectedProviders providers={selected.map((x) => x.id)} size={activeSize} />
        )}
        renderSelectedOption={(selected) => (
          <SelectedProviders providers={[selected].map((x) => x.id)} size={activeSize} />
        )}
        multi={multi}
        {...props}
      />
    );
  }
}
