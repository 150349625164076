import { Collapse } from "reactstrap";
import * as React from "react";
import { WithUrlFragment } from "../../../util/WithUrlFragment";
import { NavLink } from "react-router-dom";
import {
  AppNavigatorContextValue,
  isNavigationSection,
  NavigationNode,
  NavigationSection,
} from "../navigator/RouteNode";
import NavMenuItemTitle from "./NavMenuItemTitle";

/**
 * Nav Rendering Utilities.
 * Some common layout rendering utilities so they don't have to be duplicated among the various Layouts.
 */

export const renderL1NodeWithSections = (l1Nodes: NavigationSection[]) => renderNavSections(l1Nodes);

export const renderL1NodeWithoutSections = (node: NavigationNode, idx: number, ctx: AppNavigatorContextValue) => {
  const { path, active } = node;

  return (
    <div key={idx} className="nav-link-margin">
      <div className="font-weight-bold d-flex flex-row align-items-center">
        <NavItem
          id={!!path ? `path-${path.replace(/\//g, "-")}` : ""}
          path={path}
          key={path}
          containerClassName="w-100"
          className={`d-flex align-items-center justify-content-between ${!!ctx.navL2 ? "has-children" : ""}`}
        >
          <NavMenuItemTitle title={node.title} icon={node.icon} altText={node.altText} isRoot />
        </NavItem>
      </div>
      {!!ctx.navL2 && active && (
        <Collapse isOpen={active} className="nav-accordion">
          {renderNavSections(ctx.navL2)}
        </Collapse>
      )}
    </div>
  );
};

export const renderNav = (ctx: AppNavigatorContextValue) => {
  const navL1Nodes = ctx.navL1;
  const sections = navL1Nodes.flatMap((sect) => (isNavigationSection(sect) ? [sect] : []));
  const sectionTitles = sections.map((x) => x.title).filter((x) => x !== "Default");
  const nodes = navL1Nodes.flatMap((node) => (!isNavigationSection(node) ? [node] : node.children));
  if (sectionTitles.length) return renderL1NodeWithSections(sections);
  return nodes.map((node, idx) => {
    return renderL1NodeWithoutSections(node, idx, ctx);
  });
};

export const renderNavSections = (nodes: NavigationSection[]) => {
  if (!nodes) return undefined;
  const isSingleSection = nodes.length === 1;
  const sections = nodes.map((section, idx) => {
    return (
      <div key={`${section.title}-${idx}`}>
        {!isSingleSection && section.title !== "Default" && (
          <div className="dropdown-header">
            <span>{section.title}</span>
          </div>
        )}
        {section.children.map((childNode) => {
          return (
            <div key={`${section.title}-${childNode.path}`} className="nav-link-margin">
              <NavItem path={childNode.path} active={childNode.active} exact={childNode.exact}>
                <NavMenuItemTitle title={childNode.title} icon={childNode.icon} altText={childNode.altText} />
              </NavItem>
            </div>
          );
        })}
      </div>
    );
  });
  return <div>{sections}</div>;
};

/** retains url params */
export function NavItem({
  path,
  children,
  active,
  exact,
  className,
  containerClassName,
  id,
}: {
  path: string;
  children: React.ReactElement | React.ReactElement[];
  active?: boolean;
  exact?: boolean;
  className?: string;
  containerClassName?: string;
  id?: string;
}) {
  return (
    <WithUrlFragment>
      {({ pathname, search }: { pathname: string; search: string }) => {
        return (
          <li className={containerClassName || ""}>
            <NavLink
              id={id}
              isActive={() => !!active}
              className={`nav-item nav-link ${className || ""}`}
              exact={exact}
              to={{
                pathname: path,
                search: search,
              }}
            >
              {children}
            </NavLink>
          </li>
        );
      }}
    </WithUrlFragment>
  );
}
