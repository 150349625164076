import React from "react";
import normalizeUrl from "normalize-url";
import EllipseText from "./EllipsisText";
import { isString } from "lodash";

/**
 * Creates a normalized hyperlink and displays a truncated version of the link.
 *
 * @param href The href to add to normalize and link to.
 * @param length The max length of the display text. will be truncated to 40 by default.
 * @param text The display text to use if none is provided the href will be used.
 */
const NormalizedShortLink = ({ href, length = 40, text = null, target = null }) =>
  isString(href) ? (
    <a href={normalizeUrl(href, { stripWWW: false })} target={target}>
      <EllipseText length={length} text={text ? text : href} />
    </a>
  ) : null;

export default NormalizedShortLink;
