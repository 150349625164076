import React from "react";

const ListingRowCategoryCount = ({ listing, providers }) => {
  const displayCategories =
    providers &&
    providers[listing.contentProvider] &&
    providers[listing.contentProvider].displaySettings.displaysCategories;
  const categoryCount = !displayCategories ? "N/A" : listing.stats.categoryCount;

  return <span>{categoryCount}</span>;
};

export default ListingRowCategoryCount;
