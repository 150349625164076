import React from "react";
import { getPolarityClassFromId, getPolarityModelByScore } from "../../modules/pulse/sentimentData";
import { Polarity } from "../../modules/pulse/types";

type LegalNames = { [key in Polarity]?: boolean };

const legalNames: LegalNames = ["Positive", "Neutral", "Negative"].reduce((soFar, x) => ({ ...soFar, [x]: true }), {});

interface SentimentIconProps {
  classification?: string;
  polarity?: number;
  className?: string;
  style?: React.CSSProperties;
}

const SentimentIcon = ({ classification, polarity, className, style, ...rest }: SentimentIconProps) => {
  let classificationObj;
  if (polarity !== undefined) {
    classificationObj = getPolarityModelByScore(polarity);
  } else if (legalNames[classification as Polarity]) {
    classificationObj = getPolarityClassFromId(classification as Polarity);
  }

  if (!classificationObj) {
    return null;
  } else {
    return (
      <i
        style={style}
        className={`sentiment pulse-icon sentiment-icon ${classificationObj.class || ""} ${className || ""}`}
        {...rest}
      />
    );
  }
};

export default SentimentIcon;
