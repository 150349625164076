import config from "./config";
// Configure webpack public path to use script's host, rather than the window's host
if (typeof document !== "undefined") {
  __webpack_public_path__ = config.assetUrl + (config.assetUrl.endsWith("/") ? "" : "/");
}

import "regenerator-runtime/runtime";
import "core-js/stable";
// please keep this file light. Any kind of global imports/setup should
// be done within App.jsx, otherwise hot module reloading doesn't work well
// since it causes the root react app to entirely re-render
// for IE 11
import "./util/disableMobxProxies";
import ReactDOM from "react-dom";
import * as React from "react";
import App from "./App";

const root = window.document.createElement("div");
root.style.height = "100%";
window.document.body.appendChild(root);

ReactDOM.render(<App />, root);
