import React from "react";
/**
 *
 * @param {PostalAddress} postalAddress
 * @return {string}
 */
export function formatPostalAddress(postalAddress) {
  const commaSeparated = [postalAddress.streetAddress, postalAddress.addressLocality, postalAddress.addressRegion]
    .map((x) => x && x.trim())
    .filter((x) => !!x)
    .join(", ");

  return [commaSeparated, postalAddress.postalCode, postalAddress.addressCountry]
    .map((x) => x && x.trim())
    .filter((x) => !!x)
    .join(" ");
}

export function lineBreakPostalAddress(postalAddress) {
  const { streetAddress, addressLocality, addressRegion, postalCode, addressCountry } = postalAddress;
  return (
    <>
      {streetAddress ? <div className="text-truncate">{streetAddress}</div> : ""}
      {(addressLocality || addressRegion || postalCode || addressCountry) && (
        <div className="text-truncate">
          {addressLocality || ""}
          {addressLocality && addressRegion ? ", " : ""}
          {addressRegion || ""} {postalCode || ""} {addressCountry || ""}
        </div>
      )}
    </>
  );
}

export function countryMatches(text, country) {
  const lower = text.toLowerCase();
  return country.name.toLowerCase() === lower || country.id.toLowerCase() === lower;
}

export function countryLooselyMatches(text, country) {
  const lower = text.toLowerCase();
  return country.name.toLowerCase().includes(lower) || country.id.toLowerCase().includes(lower);
}

/**
 *
 * @param {countrySettings} country
 * @param {postalAddress} postalAddress
 * @returns {string[]} - invalid field names (required but not defined)
 */
export function validateAddress(country, postalAddress) {
  if (!country) {
    return ["addressCountry"];
  } else {
    const requirements = requiredFields(country);
    return requirements.filter((rq) => !((postalAddress[rq] && postalAddress[rq].trim()) || ""));
  }
}

export function requiredFields(country) {
  return ["addressCountry", "streetAddress"]
    .concat(country.settings.includeCity ? ["addressLocality"] : [])
    .concat(country.settings.includeState ? ["addressRegion"] : [])
    .concat(country.settings.includePostalCode ? ["postalCode"] : []);
}
