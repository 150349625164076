export const priorities = [
  {
    value: 0,
    nameValue: "Low",
    name: "Low",
    colorName: "success",
  },
  {
    value: 1,
    nameValue: "Medium",
    name: "Medium",
    colorName: "warning",
  },
  {
    value: 2,
    nameValue: "High",
    name: "High",
    colorName: "danger",
  },
];
const priorityByValue = priorities.reduce((builder, x) => ({ ...builder, [x.nameValue]: x }), {});

export const getPriority = (value) => {
  if (Number.isInteger(value)) {
    return priorities[value];
  } else {
    return priorityByValue[value];
  }
};

export const statuses = [
  {
    value: "NotStarted",
    name: "Not Started",
    colorName: "gray-400",
  },
  {
    value: "InProgress",
    name: "In Progress",
    colorName: "info",
  },
  {
    value: "PendingApproval",
    name: "Pending Approval",
    colorName: "warning",
  },
  {
    value: "Complete",
    name: "Complete",
    colorName: "success",
  },
  {
    value: "Unapproved",
    name: "Unapproved",
    colorName: "danger",
  },
];
const statusesByValue = statuses.reduce((builder, x) => ({ ...builder, [x.value]: x }), {});

export const getStatus = (value) => {
  return statusesByValue[value];
};

export const categories = [
  {
    value: "Custom",
    iconClass: "ss ss-pushpin",
    colorName: "wf-custom",
    prefix: "CUS",
  },
  {
    value: "Reviews",
    iconClass: "ss ss-star",
    colorName: "wf-reviews",
    prefix: "REV",
  },
  {
    value: "Listings",
    iconClass: "ss ss-location",
    colorName: "wf-listings",
    prefix: "LIS",
  },
  {
    value: "Media",
    iconClass: "ss ss-picture",
    colorName: "wf-media",
    prefix: "MED",
  },
  {
    value: "Social",
    iconClass: "ss ss-chat",
    colorName: "wf-social",
    prefix: "SOC",
  },
];

export const generatorCategories = categories.filter(
  (c) => c.value !== "Custom" && c.value !== "Media" && c.value !== "Social"
);

export const getCategory = (value) => {
  return categories.find((x) => x.value === value);
};

export const workflowEntityType = {
  CUSTOM: "Custom",
  RESPOND_TO_REVIEW: "RespondToReview",
  OPTIMIZE_LISTING: "OptimizeListing",
  UPDATE_LISTING_DETAILS: "UpdateListingDetails",
  DELETE_DUPLICATE_LISTING: "DeleteDuplicateListing",
  RESPOND_TO_POST: "RespondToPost",
};

export const monitoredEntityTypes = [
  {
    value: "Review",
  },
  {
    value: "Post",
  },
  {
    value: "LocationAndProvider",
  },
  {
    value: "Media",
  },
];

export const TaskCategory = {
  CUSTOM: "Custom",
  REVIEWS: "Reviews",
  SOCIAL: "Social",
  LISTINGS: "Listings",
  MEDIA: "Media",
};

export const taskTypes = [
  {
    value: "Custom",
    name: "Custom",
    category: TaskCategory.CUSTOM,
  },
  {
    value: "RespondToReview",
    name: "Respond To Review",
    category: TaskCategory.REVIEWS,
  },
  {
    value: "PublishPost",
    name: "Publish Post",
    category: TaskCategory.SOCIAL,
  },
  {
    value: "RespondToPost",
    name: "Respond To Post",
    category: TaskCategory.SOCIAL,
  },
  {
    value: "AddListing",
    name: "Add Listing",
    category: TaskCategory.LISTINGS,
  },
  {
    value: "OptimizeListing",
    name: "Optimize Listing",
    category: TaskCategory.LISTINGS,
  },
  {
    value: "UpdateListingDetails",
    name: "Update Listing Details",
    category: TaskCategory.LISTINGS,
  },
  {
    value: "DeleteDuplicateListing",
    name: "Delete Duplicate Listing",
    category: TaskCategory.LISTINGS,
  },
  {
    value: "FlagPhoto",
    name: "Flag Photo",
    category: TaskCategory.MEDIA,
  },
  {
    value: "RespondToPhoto",
    name: "Respond To Photo",
    category: TaskCategory.MEDIA,
  },
  {
    value: "SharePhoto",
    name: "Share Photo",
    category: TaskCategory.MEDIA,
  },
];

export function getTaskType(value) {
  return taskTypes.find((x) => x.value === value);
}

export const taskTypesByCategory = taskTypes.reduce((sum, x) => {
  return { ...sum, [x.category]: (sum[x.category] || []).concat(x) };
}, {});
