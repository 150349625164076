import * as mobx from "mobx";
import { makeObservable } from "mobx";
import { action, observable, computed } from "mobx";
import LoginStore from "../session/LoginStore";
import * as ConfigApi from "./ConfigApi";
import { stallUnresolvedPromises } from "../../util/async";
import themeSwapper from "../../util/themeSwapper";
import BrandConfigStore from "./BrandConfigStore";
import { cssVar } from "../../components/cssInJs";
import { fetchStyle } from "../../modules/theme/ThemeApi";

class ThemeStore {
  /**
   * For now, this will be a subset of the brand config.
   *
   * @typedef {Object} ThemeConfig
   * @property {string} themeId
   * @property {string} foldyThemeId
   */

  /** @type {ThemeConfig} */
  themeConfig = {
    themeId: "standard",
    foldyThemeId: "bright",
  };

  get themeId() {
    return this.themeConfig.foldyThemeId || this.themeConfig.themeId;
  }

  isLoaded = false;

  // incremented whenever the stylesheet is swapped in order to detect changes
  themeVersion = 0;

  /**
   * A theme as returned from the bootstrap theme server, plus some extra attributes
   *
   * @typedef {Object} ThemePreview
   * @property {string} themeId - the last theme this preview was based on
   * @property {string} customStyles - scss variable declarations
   * @property {{[string]: string}} - record of scss variables to values
   * @property {string} overrides - custom scss to be loaded after bootstrap, adjusting its default styling
   * @property {string} logoUrl - logo to display in nav
   */

  /** @type {ThemePreview | null} */
  preview = null;

  isPreviewMode = false;

  topNavIsLight = true;

  /**
   * Used to store the css for the default theme so that PDFs can be generated with it.
   * See PDFOptions.ts for more information
   *  @type {string | undefined}
   */
  defaultStyle = undefined;

  constructor() {
    makeObservable(this, {
      themeConfig: observable,
      themeId: computed,
      isLoaded: observable,
      preview: observable,
      isPreviewMode: observable,
      themeVersion: observable,
      topNavIsLight: observable,
      logoUrl: computed,
      previewStyle: action,
      setThemeConfig: action,
      clearPreview: action,
      setPreviewMode: action,
    });

    /** fetch the theme config once application is ready (after check for stored authentication token) */
    mobx.reaction(
      () => [LoginStore.attemptedAutomatedLogin, !!LoginStore.token, BrandConfigStore.host],
      ([attempted, token]) => attempted && this.resetThemeConfig(),
      {
        fireImmediately: true,
        equals: mobx.comparer.structural,
      }
    );

    /** apply the theme or preview when there's a change  */
    mobx.reaction(
      () => {
        // calculate arguments to pass to themeSwapper
        if (this.preview) {
          return { style: this.preview.style, logo: this.preview.logo };
        } else {
          return { themeId: this.themeId };
        }
      },
      (args) => {
        if (!LoginStore.renderOnly) {
          themeSwapper(args)
            .catch((ex) => {
              console.error("failed to load theme", ex);
              return themeSwapper({ themeId: "default" });
            })
            .then(
              action("setLoaded", () => {
                this.isLoaded = true;
                this.themeVersion += 1;
              })
            );
        }
      },
      {
        fireImmediately: true,
        equals: mobx.comparer.structural,
      }
    );
    mobx.reaction(
      () => this.themeVersion,
      () => {
        const cssVarValue = cssVar("--top-nav-is-light");
        // excuse the weird syntax. Written to fallback to `true` if the css variable is not defined for backwards compatibility
        mobx.runInAction(() => (this.topNavIsLight = cssVarValue.trim() !== "false"));
      },
      {
        fireImmediately: true,
      }
    );
    window.ThemeStore = this;
  }

  get logoUrl() {
    const isLight = this.topNavIsLight;
    const lightUrl = BrandConfigStore.brandConfig?.logoUrl;
    const darkUrl = BrandConfigStore.brandConfig?.darkThemeLogoUrl || lightUrl;
    const logoUrl = isLight ? lightUrl : darkUrl;
    return this.preview?.logo || logoUrl;
  }

  getDefaultStyle = async () => {
    if (this.defaultStyle) return this.defaultStyle;
    try {
      const style = await fetchStyle("bright");
      mobx.runInAction(() => {
        this.defaultStyle = style;
      });
      return this.defaultStyle;
    } catch (error) {
      // Handle error (e.g., set an error state, log the error, etc.)
      console.error("Failed to fetch style:", error);
    }
  };

  /**
   * set the current theme to an unsaved temporary preview stylesheet
   * @param {string} style - compiled stylesheet to be inlined in the <head>
   * @param {string} scss - scss the style is derived from (should just be variable declarations)
   * @param {string} overrides - custom bootstrap styles to apply after bootstrap styles are declared (e.g. to enhance cards with dropshadows)
   * @param {*} logo - JavaScript file object to be posted to server
   * @param {*} variables - json variables to generate the scss
   */
  previewStyle = ({ style, scss, overrides, variables, logo }) => {
    this.preview = {
      style,
      scss,
      overrides,
      variables,
      logo,
    };
  };

  /**
   * set the style to a persisted theme
   * @param {ThemeConfig | null} newThemeConfig
   */
  setThemeConfig = (newThemeConfig) => {
    this.preview = null;
    if (!!newThemeConfig) {
      Object.assign(this.themeConfig, newThemeConfig);
    }
  };

  clearPreview = () => {
    this.preview = null;
  };

  setPreviewMode = (previewMode) => {
    this.isPreviewMode = previewMode;
  };

  resetThemeConfig = () => {
    if (!LoginStore.renderOnly) {
      this._fetchThemeConfig().then(this.setThemeConfig);
    }
  };

  _fetchThemeConfig = stallUnresolvedPromises((token) => {
    if (LoginStore.token) {
      return ConfigApi.getThemeConfig();
    } else if (BrandConfigStore.host) {
      return ConfigApi.getThemeConfigFromHost(BrandConfigStore.host);
    } else {
      return ConfigApi.getDefaultThemeConfig();
    }
  });

  /** run the specified callback when the theme changes
   * @returns - disposer function
   */
  whenModified(callback, options = {}) {
    return mobx.reaction(
      () => this.isLoaded && ((this.preview && this.preview.style) || this.themeId),
      () => callback(),
      options
    );
  }
}

const store = new ThemeStore();
window.ThemeStore = store;

export default store;
