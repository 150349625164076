import React from "react";

/**
 * - A card consists of the container element, a card-header, card-body and an optional card-footer.
 * - There are two main types of cards:
 *  1. "Region Cards" containing graphs and tables and sets of data.
 *  2. "Grid Cards", which often contain metric deltas or discreet information and are either in a card deck row or grid.
 *
 * ## Style Guide
 * - Region cards should have an h2 title with the custom card-title-lg class.
 * - If grid cards are in a limited group like a single row, the title should have a class of card-title-sm, otherwise if in a large grid like Social Activity grid, the title class should be the smaller card-title-grid.
 * - Cards should space themselves most of the time with the card-spacer-top class.
 * - There are many other custom helper classes for cards to maintain consistency.
 */

const RegionCard = ({ topSpacer = false, hasTable = false, children, style = {}, className = "", ...rest }) => {
  return (
    <div
      className={`card ${topSpacer ? "card-spacer-top" : ""} ${hasTable ? "table-card" : ""} ${className || ""}`}
      style={{
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default RegionCard;
