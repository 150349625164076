import React from "react";

export const SortToggle = ({ direction }) => {
  const classes = ["fa"];
  let lower = direction?.toLowerCase();
  if (lower in directions) {
    classes.push(`fa-sort-${directions[lower]}`);
  } else {
    classes.push("fa-sort");
  }
  return <i className={classes.join(" ")} />;
};

const directions = {
  asc: "up",
  desc: "down",
  up: "up",
  down: "down",
};
