import React, { useState, useMemo, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { SketchPicker, SwatchesPicker, GithubPicker } from "react-color";
import { cssVar } from "../../../components/cssInJs";
import { Popover, PopoverBody, Tooltip, UncontrolledTooltip } from "reactstrap";
import { ALL_VARIABLES, brandColors, neutrals, chartColors } from "../AllVariables";
import "./colorselector.scss";
import variableDisplayNames from "../variableDisplayNames";

export function ColorSelector(props) {
  // keep an edit buffer and flush it when the popover closes
  const [editBuffer, setEditBuffer] = useState(null);
  useEffect(() => {
    // clear out the buffer if the outside world makes an update
    setEditBuffer(null);
  }, [props.value]);

  const value = editBuffer || props.value || "";

  const sanitizedValue = sanitizeValue(value);
  const isVar = !!sanitizedValue && !!sanitizedValue.match(new RegExp("^\\$.*$"));
  const renderSafeColor = isVar ? `var(--${sanitizedValue.replace("$", "")})` : sanitizedValue;
  const title = valueToNiceName(isVar, sanitizedValue);
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const buttonRef = useRef(null);

  const circleStyles = useMemo(() => {
    const diameter = "20px";
    return {
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      minHeight: diameter,
      maxHeight: diameter,
      height: diameter,
      minWidth: diameter,
      width: diameter,
      maxWidth: diameter,
      border: `${cssVar("--border-width")} solid var(--border-color)`,
      background: renderSafeColor,
      textAlign: "center",
      display: "flex",
      verticalAlign: "center",
      alignSelf: "center",
      position: "absolute",
      right: "1rem",
    };
  }, [renderSafeColor]);

  const toggle = () => {
    const nextIsOpen = !isPopoverOpen;
    if (!nextIsOpen && editBuffer) {
      props.onChange(props.name, editBuffer);
    }
    setPopoverOpen(nextIsOpen);
  };

  const close = () => {
    // reactstrap legacy trigger is crummy if you close the popover yourself
    if (isPopoverOpen) {
      toggle();
    }
  };

  const onSelectVariableColor = (scssVariableName) => {
    // immediately close and apply
    setPopoverOpen(false);
    setEditBuffer(null);
    props.onChange(props.name, scssVariableName);
  };

  return (
    <React.Fragment>
      <button
        className="form-control w-100 text-left justify-content-between d-flex align-items-center position-relative"
        type="button"
        onClick={toggle}
        ref={buttonRef}
      >
        <div>{title}</div>
        <div className="clickable" style={circleStyles} />
      </button>
      {buttonRef.current && (
        <Popover
          isOpen={isPopoverOpen}
          toggle={close}
          target={buttonRef.current}
          placement="bottom"
          trigger="legacy"
          style={{ backgroundColor: "white" }}
        >
          <SketchPicker
            color={isVar ? colorValueFromVariableName(value) : value}
            onChangeComplete={(value) => {
              const colorValue =
                value.rgb.a < 1 ? `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, ${value.rgb.a})` : value.hex;
              setEditBuffer(colorValue);
            }}
            presetColors={[]}
            className="shadow-none"
          />
          <div className="m-2 pb-2" style={{ maxWidth: "200px" }}>
            <VariableGroupColorSwatch colorGroup={brandColors} onSelect={onSelectVariableColor} />
            <VariableGroupColorSwatch colorGroup={neutrals} onSelect={onSelectVariableColor} />
            <VariableGroupColorSwatch colorGroup={chartColors} onSelect={onSelectVariableColor} />
          </div>
        </Popover>
      )}
    </React.Fragment>
  );
}

ColorSelector.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

ColorSelector.defaultProps = {
  onChange: () => {},
};

function VariableGroupColorSwatch({ colorGroup, onSelect }) {
  const variableNames = Object.keys(colorGroup.variables);
  return (
    <div style={{ lineHeight: "0px" }}>
      {variableNames.map((variableName) => (
        <ColorVariableReferenceTile
          key={variableName}
          scssVariableName={variableName}
          onSelect={() => onSelect(variableName)}
        />
      ))}
    </div>
  );
}

function ColorVariableReferenceTile({ scssVariableName, onSelect }) {
  const staticColor = colorValueFromVariableName(scssVariableName);
  const cssVarName = scssVariableName.replace(/^\$/, "--");
  const niceName = variableToNiceName(scssVariableName);
  const tileRef = useRef(null);
  const size = "16px";
  if (!staticColor) {
    return null;
  }
  return (
    <React.Fragment>
      <a
        ref={tileRef}
        tabIndex={0}
        className="clickable d-inline-block p-0 m-0 color-variable-reference-tile"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onSelect();
        }}
        style={{ width: size, height: size, backgroundColor: `var(${cssVarName})` }}
      />
      {tileRef.current && (
        <UncontrolledTooltip target={tileRef.current} delay={0} trigger="hover">
          {niceName} ({staticColor?.toUpperCase() || ""})
        </UncontrolledTooltip>
      )}
    </React.Fragment>
  );
}

// Given a variable like $gray-600, returns the computed color value, e.g. #72757E. Returns null if cannot parse to a good value.
// parses value from css variables
export const colorValueFromVariableName = (myVar) => {
  if (!myVar || !myVar.includes("$")) return null;
  const value = cssVar(myVar.replace("$", "--")).trim();
  return !!value ? value : null;
};

//accepts kebab case string, and title cases it, splitting into words with spaces
const variableToNiceName = (variable) => {
  return variable
    .replace(/^\$/, "")
    .split("-")
    .map((x) => x[0].toUpperCase() + x.slice(1))
    .join(" ");
};

const isCSSPreprocessorValue = (value) => value.includes("lighten") || value.includes("darken");
const isRGBAWithVariable = (value) => value.startsWith("rgba") && value.includes("$");

// lighten(color, amount) or darken(color, amount) or rgba($black, .075) => color
const colorValueFromFunction = (value) => {
  const startColorValIdx = value.indexOf("(");
  const endColorValIdx = value.indexOf(",");
  if (startColorValIdx === -1 || endColorValIdx === -1) {
    console.log("Invalid color value: ", value);
    return null;
  }
  return value.substring(startColorValIdx + 1, endColorValIdx).trim();
};

const valueToNiceName = (isVar, value) => {
  if (!value || value === "null") return ""; // No color selected
  const colorValue = !!isVar ? colorValueFromVariableName(value) : null;
  const colorValueString = !!colorValue ? ` (${colorValue.toUpperCase()})` : "";
  // If it's a variable, e.g. $body-color, but it has not value, let's show an empty string for the nice name so the user knows to enter a value.
  // In practice, all variables should have values, so this shouldn't happen often.
  const niceName =
    !!isVar && variableDisplayNames.hasOwnProperty(value)
      ? variableDisplayNames[value]
      : !!isVar && !!colorValue
      ? variableToNiceName(value)
      : !!isVar
      ? ""
      : value.toUpperCase();
  return `${niceName}${colorValueString}`;
};

const sanitizeValue = (value) => {
  let sanitizedValue = value;
  sanitizedValue = sanitizedValue.replace("!default", "").trim();
  sanitizedValue = isCSSPreprocessorValue(sanitizedValue) ? colorValueFromFunction(sanitizedValue) : sanitizedValue;
  sanitizedValue = isRGBAWithVariable(sanitizedValue) ? colorValueFromFunction(sanitizedValue) : sanitizedValue;
  return sanitizedValue;
};
