import { find } from "lodash";
import PostToBePublishedStatus from "./publishing/PostToBePublishedStatus";

// TODO: #chatter-provider after chatter provider is killed, switch these to SocialProvider
export const FACEBOOK = "Facebook";
export const INSTAGRAM = "Instagram";
export const GOOGLE = "Google";
export const TWITTER = "Twitter";
export const LINKEDIN = "LinkedIn";
export const GOOGLE_POSTS_ONLY = "googlePostsOnly";
export const GOOGLE_QA_ONLY = "googleQAndAOnly";

export const FACEBOOK_DBNAME = "FACEBOOK";
export const INSTAGRAM_DBNAME = "INSTAGRAM";
export const GOOGLE_DBNAME = "GOOGLE";
export const TWITTER_DBNAME = "TWITTER";
export const LINKEDIN_DBNAME = "LINKEDIN";

export const MANAGE_EXTERNAL_ACCOUNTS = "ManageExternalAccounts";
export const SOCIAL_ADD_CREDS_PERMISSION = "AddExternalAccounts";
export const SOCIAL_POSTER_PERMISSION = "SocialPoster";
export const SOCIAL_COMMENTER_PERMISSION = "SocialCommenter";
export const SOCIAL_SUBMIT_POST_PERMISSION = "SubmitChatterPost"; // social post, but requires approval
export const SOCIAL_SUBMIT_COMMENTER_PERMISSION = "SubmitSocialCommenter"; // social comment, but requires approval

export const convertProviderDBNameToNiceName = (providerDBName) => {
  switch (providerDBName) {
    case FACEBOOK_DBNAME:
      return FACEBOOK;
    case INSTAGRAM_DBNAME:
      return INSTAGRAM;
    case GOOGLE_DBNAME:
      return GOOGLE;
    case TWITTER_DBNAME:
      return TWITTER;
    case LINKEDIN_DBNAME:
      return LINKEDIN;
    default:
      return undefined;
  }
};

export const convertProviderNiceNameToDBName = (providerNiceName) => {
  const niceName = convertProviderToNiceName(providerNiceName);
  switch (niceName) {
    case FACEBOOK:
      return FACEBOOK_DBNAME;
    case INSTAGRAM:
      return INSTAGRAM_DBNAME;
    case GOOGLE:
      return GOOGLE_DBNAME;
    case TWITTER:
      return TWITTER_DBNAME;
    case LINKEDIN:
      return LINKEDIN_DBNAME;
    default:
      return undefined;
  }
};

export const convertProviderToNiceName = (provider) => {
  const niceNames = [FACEBOOK, INSTAGRAM, GOOGLE, TWITTER, LINKEDIN];
  return find(niceNames, (x) => x.toLowerCase() === provider.toLowerCase());
};

export const GROUP_ADD_MODE = "GROUP_ADD_MODE";
export const GROUP_EDIT_MODE = "GROUP_EDIT_MODE";

export const LISTINGS_PER_PAGE = 10;
export const GROUPS_PER_PAGE = 10;

export const DEFAULT_FILTER = {
  pageSize: 10,
  listingPageIndex: 0,
  listingSortOrder: 1,
  listingGroupIndex: 0,
  listingGroupSortOrder: 1,
  listingGroupPageIndex: 0,
  listingGroupPageSortOrder: 1,
  textSearch: null,
  onlyIgGraphId: false,
  onlySourceType: null,
  onlySourceAccount: null,
  subSectionIndex: 0,
};

export const INSTAGRAM_FILTER = {
  pageSize: 10,
  listingPageIndex: 0,
  listingSortOrder: 1,
  listingGroupIndex: 0,
  listingGroupSortOrder: 1,
  listingGroupPageIndex: 0,
  listingGroupPageSortOrder: 1,
  textSearch: null,
  onlyIgGraphId: true,
  onlySourceType: null,
  onlySourceAccount: null,
  subSectionIndex: 0,
};

export const SOCIAL_IMAGE_TYPE = { id: "image", label: "Image", value: "image" };
export const SOCIAL_VIDEO_TYPE = { id: "video", label: "Video", value: "video" };
export const SOCIAL_LINK_TYPE = { id: "link", label: "Link", value: "link" };
export const SOCIAL_MEDIA_TYPES = [SOCIAL_IMAGE_TYPE, SOCIAL_VIDEO_TYPE, SOCIAL_LINK_TYPE];

export const SCHEDULED = "Scheduled";
export const POSTED = "Posted";
export const FAILED = "Failed";

export const SOCIAL_STATUS_SCHEDULED = {
  id: "scheduled",
  label: SCHEDULED,
  value: "scheduled",
  param: PostToBePublishedStatus.Pending,
};
export const SOCIAL_STATUS_POSTED = {
  id: "posted",
  label: POSTED,
  value: "posted",
  param: PostToBePublishedStatus.Published,
};
export const SOCIAL_STATUS_FAILED = {
  id: "failed",
  label: FAILED,
  value: "failed",
  param: PostToBePublishedStatus.Error,
};
export const SOCIAL_STATUS_TYPES = [SOCIAL_STATUS_SCHEDULED, SOCIAL_STATUS_POSTED, SOCIAL_STATUS_FAILED];

export const SOCIAL_POSTS_AND_COMMENTS = "PostsAndComments";
export const SOCIAL_KEYWORD_LISTENING = "HashtagMetrics"; // "Keyword Listening" is the marketing term. They asked us to change it, then it confused people.

export const POST_TYPE = {
  QA: "QA",
  ALERT: "Alert",
  OFFER: "Offer",
  WHATS_NEW: "WhatsNew",
};

export const GOOGLE_CTA_OPTIONS = [
  { value: "Book", label: "Book" },
  { value: "Order", label: "Order online" },
  { value: "Shop", label: "Buy" },
  { value: "LearnMore", label: "Learn more" },
  { value: "SignUp", label: "Sign up" },
  { value: "Call", label: "Call now" },
];

export const FORM_NAME = {
  FacebookForm: "FacebookForm",
  InstagramForm: "InstagramForm",
  TwitterForm: "TwitterForm",
  GoogleQAForm: "GoogleQAForm",
  GoogleAlertForm: "GoogleAlertForm",
  GoogleOfferForm: "GoogleOfferForm",
  GoogleWhatsNewForm: "GoogleWhatsNewForm",
  LinkedInForm: "LinkedInForm",
};

export const FORM_MODE = {
  EDIT: "edit",
  VIEW: "view",
};

export const MANUAL_POST_DELAY_COPY =
  "Non-scheduled Instagram posts may have a delay of up to an hour before they are published.";
