import React, { Component } from "react";
import PropTypes from "prop-types";

export class FallbackImage extends Component {
  static propTypes = {
    imageURL: PropTypes.string.isRequired,
    fallbackURL: PropTypes.string.isRequired,
  };
  state = {
    imageLoadError: false,
  };

  onImageError = (ex) => {
    // console.error(ex)
    this.setState({
      imageLoadError: ex,
    });
  };

  getImageURL() {
    const { imageURL, fallbackURL } = this.props;
    return !this.state.imageLoadError ? imageURL : fallbackURL;
  }

  render() {
    const { fallbackURL, imageURL, ...rest } = this.props;
    return <img onError={this.onImageError} src={this.getImageURL()} {...rest} />;
  }
}
