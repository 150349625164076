import SessionStore from "../../../services/session/SessionStore";
import * as mobx from "mobx";
import ThemeStore from "../../../services/config/ThemeStore";
import { ExtendedUser } from "../../../services/session/Authentication";
import { ThemeConfig } from "../../../services/config/BrandConfig";

declare global {
  interface UserGuidingApi {
    identify(userId: string, attributes: Record<string, string>): void;
    previewGuide(userguidingId: number): void;
  }

  interface Window {
    userGuidingLayer: ({ event: string } | Record<string, any>)[];
    userGuiding: UserGuidingApi;
  }
}

function loadGuide(user: ExtendedUser = SessionStore.user!, theme: ThemeConfig = ThemeStore.themeConfig) {
  // TODO: this should be configurable based on development/production.
  const containerId = "92273868ID";

  function userGuidingLoad() {
    window.userGuiding.identify(user.userId, {
      foldyThemeId: theme.foldyThemeId,
      accountId: user.accountId,
      billingAccountId: user.billingAccountId,
      username: user.username,
      reseller: user.reseller ? "true" : "false",
    });
  }

  window.userGuidingLayer ||= [];
  window.userGuidingLayer.push({ "ug.start": new Date().getTime(), event: "embed.js" });
  window.userGuidingLayer.push({
    event: "onload",
    fn: userGuidingLoad,
  });

  const userGuideScript = document.createElement("script");
  userGuideScript.async = true;
  userGuideScript.src = `https://static.userguiding.com/media/user-guiding-${containerId}-embedded.js`;
  document.head.appendChild(userGuideScript);
}

function allInfoAvailable() {
  return !!(SessionStore.user && ThemeStore.themeConfig);
}

let disposer: mobx.IReactionDisposer | undefined = undefined;
export function initialize() {
  uninitialize();

  disposer = mobx.when(
    // when the account and user is initialized
    () => allInfoAvailable(),
    () => loadGuide()
  );
}

export function uninitialize() {
  disposer && disposer();
}
