window.installDebugger = () => {
  const mobx = require("mobx");
  const moment = require("moment");
  const sessionStore = require("./SessionStore").default;
  const accessStore = require("../locations/AccessStore").default;
  const apiClient = require("./apiClient").default;

  const fields = {
    mobx,
    sessionStore,
    accessStore,
    moment,
    apiClient,
  };
  Object.assign(window, {
    // install to debug fields as well... autocompletes in the console
    debug: fields,
    ...fields,
  });
};
