import React, { useState } from "react";
import { GradientSelector } from "./GradientSelector";
import { BooleanVariableFormGroup } from "./VariableGroupEditor";
import { ColorSelector } from "./ColorSelector";

export const ColorOrGradient = ({ name, value, dependencies, onChange }) => {
  const editingField = dependencies.find((dep) => dep.when === value);
  return (
    <>
      <BooleanVariableFormGroup
        name={name}
        value={value}
        options={[
          { key: "true", value: "Gradient" },
          { key: "false", value: "Solid" },
        ]}
        onChange={onChange}
      />
      <div className={"mb-2"} />
      {value === "true" && <GradientSelector name={editingField.key} value={editingField.value} onChange={onChange} />}
      {value === "false" && <ColorSelector name={editingField.key} value={editingField.value} onChange={onChange} />}
    </>
  );
};
