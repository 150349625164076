import React, { Fragment } from "react";
import { PropTypes as MobxPropTypes, observer } from "mobx-react";
import PropTypes from "prop-types";
import * as mobx from "mobx";
import ProvidersStore from "./ProvidersStore";
import { ProviderImage, SearchToSelect } from "../../components/index";
import { ProviderSelector } from "./ProviderSelector";

/**
 *
 *
 * wraps SearchToSelect component
 * provides reasonable defaults for rendering the providers and searching for providers.
 * Passes through any additional props to SearchToSelect
 *
 * @param providers - list of provider models
 * @param multi - defaults to true for ProvidersDropDown. Pass multi: false to disable
 * @param selected - list of selected provider ids
 */
const ProvidersDropDown = observer(
  class ProvidersDropDown extends React.Component {
    static propTypes = {
      providers: MobxPropTypes.arrayOrObservableArray, // optional list of provider models. Defaults from providerStore providers
      selected: MobxPropTypes.arrayOrObservableArray, // array of provider ids or provider models
      onSelected: PropTypes.func.isRequired,
      multi: PropTypes.bool,
    };
    static defaultProps = {
      providers: ProvidersStore.providers,
      multi: true,
    };

    getSearchResults = () => {
      return mobx
        .when(() => ProvidersStore.loaded)
        .then(() => {
          return this.props.providers.slice();
        });
    };

    render() {
      const { providers, ...props } = this.props;

      return (
        <ProviderSelector
          eager
          getProviders={this.getSearchResults}
          renderMultiSelected={(xs) => (
            <div>
              {xs.map((x) => (
                <ProviderImage className="mr-1 ml-1" key={x.id} provider={x.id} size="sm" />
              ))}
            </div>
          )}
          {...props}
        />
      );
    }
  }
);

export default ProvidersDropDown;
