import React, { Component, Fragment } from "react";
import PropType from "prop-types";
import { PropTypes as MobxPropTypes } from "mobx-react";
import { UncontrolledTooltip } from "reactstrap";
import uuid from "uuid/v4";
import { ProviderImage } from "./ProviderImage";
import ProvidersStore from "../../services/providers/ProvidersStore";

export class ProvidersImageGroup extends Component {
  static propTypes = {
    availableProviders: PropType.oneOfType([PropType.array.isRequired, MobxPropTypes.observableArray.isRequired]),
    selectedProviders: PropType.oneOfType([PropType.array.isRequired, MobxPropTypes.observableArray.isRequired]),
    tooltip: PropType.bool,
    maxDisplay: PropType.number,
  };

  constructor(props) {
    super(props);
    this.id = `_${uuid()}`;
  }

  renderProvider = (availableProviders, provider, tooltip) => {
    return (
      <ProviderImage
        key={`${this.id}-${provider}`}
        provider={provider}
        providers={availableProviders}
        tooltip={!!tooltip ? ProvidersStore.displayNameFor(provider) : ""}
      />
    );
  };

  renderProviderGroup(availableProviders, selectedProviders, tooltip, maxDisplay) {
    let displayProviders;

    if (maxDisplay && maxDisplay < selectedProviders.length - 1) {
      let targetId = `${this.id}-remainingProviders`;
      displayProviders = (
        <div className="providers-group">
          {selectedProviders
            .filter((p, i) => i < maxDisplay)
            .map((provider) => this.renderProvider(availableProviders, provider, tooltip, maxDisplay))}
          <span key={`${this.id}-remainingProviders`} id={targetId} className="remaining-providers round-circle">
            {`+${selectedProviders.length - maxDisplay}`}
          </span>
          <UncontrolledTooltip key={`${this.id}-tooltip`} target={targetId}>
            {selectedProviders.filter((p, i) => i >= maxDisplay).map((p) => [p, <br />])}
          </UncontrolledTooltip>
        </div>
      );
    } else {
      displayProviders = selectedProviders.map((provider) =>
        this.renderProvider(availableProviders, provider, tooltip, maxDisplay)
      );
    }
    return displayProviders;
  }

  render() {
    const { availableProviders, selectedProviders, tooltip, maxDisplay } = this.props;
    return <Fragment>{this.renderProviderGroup(availableProviders, selectedProviders, tooltip, maxDisplay)}</Fragment>;
  }
}
