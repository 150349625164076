export enum ProviderType {
  REVIEWS = "REVIEWS",
  PULSE = "PULSE",
  REVIEW_RESPONSES = "REVIEW_RESPONSES",
  LISTINGS = "LISTINGS",
  LISTING_MANAGEMENT = "LISTING_MANAGEMENT",
  CHATTER = "CHATTER",
  LOCAL_RANKING = "LOCAL_RANKING",
  WEB_RANKING = "WEB_RANKING",
  MEDIA = "MEDIA",
}
