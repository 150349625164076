import LoginStore from "../../../../services/session/LoginStore";
import { fromPairs } from "lodash";

export default class MetricsProvider {
  install() {
    console.warn(`INSTALL NOT IMPLEMENTED ${this.constructor.name}`);
  }
  identify(user, userProps) {
    console.warn(`IDENTIFY NOT IMPLEMENTED ${this.constructor.name}`);
  }
  recordEvent(name, properties) {
    console.warn(`RECORD EVENT NOT IMPLEMENTED ${this.constructor.name}`);
  }
}

export function addScript(url) {
  const d = document;
  const f = d.getElementsByTagName("script")[0];
  const s = d.createElement("script");
  s.type = "text/javascript";
  s.async = true;
  s.src = url;
  f.parentNode.insertBefore(s, f);
}

/**
 * maps the user to a flat json object to send to the metrics provider in some way
 * (either when identifying the user or if the event needs to be enhanced with properties)
 */
export function mapUserInfo(user) {
  function toAttributes(user) {
    return {
      accountName: user.accountName,
      billingAccountName: user.billingAccountName,
      accountId: user.accountId,
      billingAccountId: user.billingAccountId,
      username: user.username,
      userId: user.userId,
    };
  }
  function addCamelCasedPrefixToAllFields(prefix, obj) {
    return fromPairs(
      Object.entries(obj).map(([k, v]) => {
        return [prefix + k.slice(0, 1).toUpperCase() + k.slice(1), v];
      })
    );
  }

  return {
    ...toAttributes(user.impersonatedByUser || user),
    ...(user.impersonatedByUser ? addCamelCasedPrefixToAllFields("impersonated", toAttributes(user)) : {}),
    internalAdmin: (user.impersonatedByUser || user).internalAdmin || false,
    hostname: window.location.hostname,
    isSSO: LoginStore.isSSO || false,
    isImpersonation: !!user.impersonatedByUser,
  };
}
