import React from "react";
import { createUltimatePagination } from "react-ultimate-pagination";
import { Pagination as BootstrapPagination, PaginationItem, PaginationLink } from "reactstrap";

function Page(props) {
  const { value, isActive, onClick, className, disabled } = props;
  return (
    <PaginationItem {...{ className, disabled, onClick }} active={isActive}>
      <PaginationLink>{value}</PaginationLink>
    </PaginationItem>
  );
}

function ControlItem(props) {
  const { className, disabled, children, onClick } = props;
  return (
    <PaginationItem {...{ className, disabled, onClick }}>
      <PaginationLink>{children}</PaginationLink>
    </PaginationItem>
  );
}
function Ellipsis(props) {
  return (
    <ControlItem disabled {...props}>
      ...
    </ControlItem>
  );
}

function FirstPageLink(props) {
  return <ControlItem {...props}>&laquo;</ControlItem>;
}

function PreviousPageLink(props) {
  return <ControlItem {...props}>&lsaquo;</ControlItem>;
}

function NextPageLink(props) {
  return <ControlItem {...props}>&rsaquo;</ControlItem>;
}

function LastPageLink(props) {
  return <ControlItem {...props}>&raquo;</ControlItem>;
}

/**
 *
 * https://github.com/ultimate-pagination/react-ultimate-pagination
 *
 * Created Pagination component has the following interface:
 *
 * currentPage: number - current page number
 * totalPages: number - total number of pages
 * boundaryPagesRange: number, optional, default: 1 - number of always visible pages at the beginning and end
 * siblingPagesRange: number, optional, default: 1 - number of always visible pages before and after the current one
 * hideEllipsis: bool, optional, default: false - boolean flag to hide ellipsis
 * hidePreviousAndNextPageLinks: bool, optional, default: false - boolean flag to hide previous and next page links
 * hideFirstAndLastPageLinks: bool, optional, default: false - boolean flag to hide first and last page links
 * onChange: function - callback that will be called with new page when it should be changed by user interaction (optional)
 * disabled: bool, optional, default: false - boolean flag to disable all buttons in pagination
 *
 */
export const Pagination = createUltimatePagination({
  itemTypeToComponent: {
    PAGE: Page,
    ELLIPSIS: Ellipsis,
    FIRST_PAGE_LINK: FirstPageLink,
    PREVIOUS_PAGE_LINK: PreviousPageLink,
    NEXT_PAGE_LINK: NextPageLink,
    LAST_PAGE_LINK: LastPageLink,
  },
  WrapperComponent: BootstrapPagination,
});
