import qs from "qs";

/**
 * abstractions to manipulate the query parameters of a hash fragment
 * e.g. for "http://example.com/main/path?main=options#/fragment/path?fragment=option&other=value"
 *                                                                    ^^^  this section       ^^^
 */

/** returns parsed url query string of fragment
 */
export function getQueryString() {
  return getPathAndQuery().search;
}

/**
 *
 * @returns {{search: string, pathname: string}}
 */
export function getPathAndQuery() {
  const parser = document.createElement("a");
  parser.href = "http://example.com" + window.location.hash.replace(/^#/, "");
  return {
    pathname: "/" + parser.pathname.replace(/^\//, ""), // Like an idiot IE doesn't include a starting slash.
    search: parser.search.replace(/^\?/, ""),
  };
}

/**
 * returns an object representation of the query string
 */
export function getQueryObject(queryString = getQueryString()) {
  return qs.parse(queryString, {
    parseArrays: true,
    arrayLimit: 1000,
    strictNullHandling: true,
    ignoreQueryPrefix: true,
  });
}

/**
 * updates the window.location url's hash with the new query opt value if it's different
 * @param {Object<string, *>} updates - fields to update
 * @param history - whether to record transition to browser history
 * returns nothing
 **/
export function setQueryParams(updates, { history = false } = {}) {
  const obj = getQueryObject();
  for (let [k, v] of Object.entries(updates)) {
    if (v === undefined) {
      delete obj[k];
    } else {
      obj[k] = v;
    }
  }

  setQueryString(stringify(obj), { history });
}

/**
 * serialize an object to a query string. Just dispatches to qs. This method should be preferred to interacting directly with qs, so
 * that there's a central qs configuration
 * @param {*} obj
 */
export function stringify(obj) {
  return qs.stringify(obj);
}

/**
 *
 * @param {*} pathAndQuery
 * @param {{ history: boolean }} history - whether to record transition to browser history
 */
export function navigateRaw(pathAndQuery, { history }) {
  // TODO - angular UI router jumps in after this change and replaces %2C with commas, causing the back button to be useless
  // would be nice if qs supported un-encoded commas
  // https://github.com/ljharb/qs/issues/237

  const hashIndex = window.location.href.indexOf("#");

  const destination = window.location.href.slice(0, hashIndex >= 0 ? hashIndex : 0) + "#" + pathAndQuery;
  if (history) {
    window.location.assign(destination);
  } else {
    window.location.replace(destination);
  }
}

/** navigates the path, retaining any query strings */
export function navigatePath(path) {
  navigatePathAndQuery(path, getQueryObject());
}

/**
 * navigates the page. Can pass in the existing getQueryObject() to the second parameter to not change the query
 * @param path
 * @param queryObject
 * @param history - whether to record transition to browser history
 */
export function navigatePathAndQuery(path, queryObject = {}, { history = true } = {}) {
  navigatePathAndQueryRaw(path, qs.stringify(queryObject), { history });
}

/**
 *
 * @param path
 * @param queryString
 * @param history - whether to record transition to browser history
 */
export function navigatePathAndQueryRaw(path, queryString, { history = true } = {}) {
  navigateRaw(path + (queryString ? `?${queryString}` : ""), { history });
}

/**
 *
 * @param queryString
 * @param history - whether to record transition to browser history
 */
export function setQueryString(queryString, { history = false } = {}) {
  navigatePathAndQueryRaw(getPathAndQuery().pathname, queryString, { history });
}
