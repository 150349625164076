import moment from "moment";
import { observable, action, reaction, toJS, makeObservable } from "mobx";
import { FieldDescriptors, FieldType } from "../../../util/urlSync";
import SavedFilterSetFilters, {
  savedFilterSetBindings,
  savedFilterSetBindingDefaults,
} from "../../savedFilterSets/SavedFilterSetFilters";
import { SOCIAL_POSTS_AND_COMMENTS } from "../socialConstants";

export const filterBindings = {
  mapType: FieldType.string,
  text: FieldType.string,
  chatterProvider: FieldType.arrayOf(FieldType.string),
  sentimentClassification: FieldType.arrayOf(FieldType.string),
  dateRangeType: FieldType.string,
  start: FieldType.moment("YYYY-MM-DD"),
  end: FieldType.moment("YYYY-MM-DD"),
  hasNotes: FieldType.bool,
  hashtagMetricType: FieldType.string,
  hashtagsToManuallyInclude: FieldType.arrayOf(FieldType.string),
  geoIntersects: FieldType.json,
  sourceTag: FieldType.arrayOf(FieldType.string),
  isReply: FieldType.bool,

  hasCustomerReply: FieldType.bool,
  hasBusinessReply: FieldType.bool,

  timelineRangeStart: FieldType.moment("YYYY-MM-DD"),
  timelineRangeEnd: FieldType.moment("YYYY-MM-DD"),
  reportsDateRangeType: FieldType.string,
  reportsTimelineStart: FieldType.moment("YYYY-MM-DD"),
  reportsTimelineEnd: FieldType.moment("YYYY-MM-DD"),

  status: FieldType.arrayOf(FieldType.string),
  userId: FieldType.arrayOf(FieldType.string),

  // Social tabs
  summaryActiveTab: FieldType.string,

  ...savedFilterSetBindings,
};

export const urlQueryDefinition = FieldDescriptors.build(filterBindings);

export const filterBindingDefaults = {
  mapType: "frequency",
  text: "",
  chatterProvider: [],
  sentimentClassification: [],
  dateRangeType: "week",
  start: moment().subtract(1, "week").startOf("day"),
  end: moment().endOf("day"),
  hasNotes: null,
  hashtagMetricType: "Trending",
  hashtagsToManuallyInclude: [],
  geoIntersects: null,
  sourceTag: [],
  excludeSourceTag: [],
  isReply: null,

  hasCustomerReply: null,
  hasBusinessReply: null,

  timelineRangeStart: moment().subtract(3, "months"),
  timelineRangeEnd: null,
  reportsDateRangeType: "week",
  reportsTimelineStart: moment().subtract(1, "year"),
  reportsTimelineEnd: null,

  status: [],
  userId: [],

  // Social tabs
  summaryActiveTab: "metrics",

  ...savedFilterSetBindingDefaults,
};

// TODO: Add more things to this.
export const defaultFilterSetFilters = Object.freeze({
  chatterProvider: [],
  sentimentClassification: [],
  hasCustomerReply: null,
  hasBusinessReply: null,
});

export const defaultPostApprovalFilters = Object.freeze({
  chatterProvider: [],
  status: [],
  userId: [],
});

export class SocialPostFiltersStore {
  // TODO - lots of these filters ought to be pulled down to their respective components.
  // Shared state is excessive
  filters = filterBindingDefaults;

  onFiltersChanged = (fn) => {
    return reaction(() => toJS(this.filters), fn, { fireImmediately: true });
  };

  setFilters = (updates) => {
    Object.assign(this.filters, updates);
  };

  savedFilterSetFilters = new SavedFilterSetFilters({
    featureGroup: "Social",
    setFilter: this.setFilters,
    getFilters: () => {
      return this.filters;
    },
    defaultFilterSetFilters: defaultFilterSetFilters,
  });

  constructor() {
    makeObservable(this, {
      filters: observable,
      setFilters: action,
    });
  }
}

export default new SocialPostFiltersStore();
