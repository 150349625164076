import * as React from "react";
import BrandConfigStore from "../../services/config/BrandConfigStore";
import { Spinner } from "../../components";
import { observer } from "mobx-react";

/**
 * static route to navigate to when SSO fails
 */
export const SSOFailedView = observer(
  class SSOFailedView extends React.Component {
    render() {
      const brandConfig = BrandConfigStore.brandConfig;

      let innerComponent;
      if (!brandConfig) {
        innerComponent = <Spinner timeout={1000} className="m-auto" size="lg" center />;
      } else {
        innerComponent = (
          <div className="text-center">
            <h2>Authentication error</h2>
            {!!brandConfig.authErrorMessage && <p>{brandConfig.authErrorMessage}</p>}
          </div>
        );
      }

      return <div className="d-flex h-100 flex-column">{innerComponent}</div>;
    }
  }
);
