import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import "./VerticalPageFiltersLayout.scss";
import SavedFilterSetIcon from "../../../components/buttons/SavedFilterSetIcon";
import SavedFilterSetFilters from "../../savedFilterSets/SavedFilterSetFilters";
import SaveFilterSetIcon from "../../../components/buttons/SaveFilterSetIcon";
import RenderToPortal from "../../../components/portals/RenderToPortal";
import { FILTER_TOGGLE } from "../navigator/navigatorPortalNames";
import Button from "../../../components/buttons/Button";
import { FoldyContext } from "./FoldyLayout";
import { FormControlButton } from "../../../components";

/** A layout with vertical page filters */
export default function VerticalPageFiltersLayout({ children, global, onClearFilters, savedFilterSet, onSaveFilters }) {
  const globalFilterArray = React.Children.toArray(global);
  const pageFilterArray = React.Children.toArray(children);
  const hasMultipleSections = globalFilterArray.length && pageFilterArray.length;

  const { setFiltersOpen, isFiltersOpen, setHasFilters } = useContext(FoldyContext);

  const anyFilters = !!pageFilterArray.length || !!globalFilterArray.length;
  useEffect(() => {
    setHasFilters(anyFilters);
  }, [anyFilters]);

  if (!anyFilters) {
    return null;
  }

  return (
    <div className="collapse-prevention-container filter-bg">
      <div className="d-flex flex-column flex-wrap align-items-center w-100 rounded-0 card-padding pb-0">
        <div className="d-flex align-items-center flex-row w-100">
          <h5 className="card-title-sm mr-auto">Filters</h5>
          <Button
            color=""
            className="mobile-nav-close-button ml-auto text-decoration-none text-body p-0"
            aria-label="Mobile Close Filters"
            data-analyticsclick="Mobile Close Filters"
            onClick={() => {
              setFiltersOpen(false);
            }}
          >
            Close
          </Button>
          <RenderToPortal id={FILTER_TOGGLE}>
            <div className="control-spacer-left floating-commands">
              <Button
                className={`filter-toggle ${isFiltersOpen ? "active" : ""}`}
                color="link"
                icon="fas fa-sliders-h"
                aria-label="Open/Close Filters"
                onClick={() => {
                  setFiltersOpen(!isFiltersOpen);
                }}
              />
            </div>
          </RenderToPortal>
        </div>
        {onSaveFilters ||
          (savedFilterSet && (
            <div className="d-flex flex-row align-items-center w-100">
              <div className="filter-actions d-flex flex-1 flex-row flex-wrap align-items-center card-padding justify-content-start w-100 px-0 pb-0">
                {onSaveFilters ? <SaveFilterSetIcon onClick={() => onSaveFilters()} /> : null}
                {savedFilterSet ? <SavedFilterSetIcon onClick={() => savedFilterSet.getFilters()} /> : null}
              </div>
            </div>
          ))}
      </div>
      <div className="mt-0 card-padding filters-inner">
        {hasMultipleSections ? <div>{`Global Filter${globalFilterArray.length > 1 ? "s" : ""}`}</div> : null}
        {global}
        {hasMultipleSections ? <div>{`Page Filter${pageFilterArray.length > 1 ? "s" : ""}`}</div> : null}
        {children}
        {onClearFilters && (
          <FormControlButton
            className="clear-filters-button btn btn-link w-auto ml-auto control"
            onClick={onClearFilters}
          >
            Clear Filters
          </FormControlButton>
        )}
      </div>
    </div>
  );
}

VerticalPageFiltersLayout.propTypes = {
  onSaveFilters: PropTypes.func,
  savedFilterSet: PropTypes.shape(SavedFilterSetFilters),
  onClearFilters: PropTypes.func,
  global: PropTypes.oneOfType([PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};
