import { observable, action, computed, makeObservable } from "mobx";
import * as mobx from "mobx";
import { fetchStyle, fetchVariables, compileStyle, saveStyle } from "./ThemeApi";
import ThemeStore from "../../services/config/ThemeStore";
import { navigatePath } from "../../util/UrlFragmentSearch";
import { setThemeConfig } from "../../services/config/ConfigApi";
import BrandConfigStore from "../../services/config/BrandConfigStore";
import { debounceAsync } from "../../util/async";
import _ from "lodash";
import sessionCache from "../../util/sessionCache";
import LoginStore from "../../services/session/LoginStore";
import { ToastService } from "../../components/toasts/Toasts";
import SessionStore from "../../services/session/SessionStore";

/**
 * This store keeps theme editor state in a global store outside of component
 * so you can jump around the dashboard (preview mode, etc) without losing state.
 * Also encapsulates non visual functionality
 **/
class ThemeEditorStore {
  state = {
    themeId: computeThemeId(),
    activeTab: "logo",
    /** theme object currently being edited. Mostly just care about variables sub field.
     * Can be used to compile scss and apply css to ThemeStore for previews */
    theme: null,
    /** the original state of the theme, for change detection and cancellation */
    invalidVariables: {},
    originalTheme: null,
    /** the last fetched rendered style of the theme */
    renderedStyle: ThemeStore.preview && ThemeStore.preview.style,
    /** true when filling in the theme for the first time from the themeId */
    initialLoading: true,
    /** true while loading a theme (such as on first load, or switching themes in the selector) */
    loading: false,
    /** true when updating stylesheet */
    loadingStyle: false,
    /** true if fetching a theme failed */
    loadError: false,
    /** a temporary preview of the logo url before saving if modified */
    logoUrl: undefined,
    /** the logo file uploaded, waiting to be saved */
    logoFile: null,
    /** secret mode to access normally hidden fields */
    debugMode: false, // TODO - false on release
  };

  constructor() {
    makeObservable(this, {
      state: observable,
      setState: action,
      toggleDebugMode: action,
      useFoldyThemeId: computed,
      hasThemeChanges: computed,
      hasLogoChanges: computed,
      hasDifferentTheme: computed,
      hasChanges: computed,
      isValid: computed,
    });
  }

  setState(updates, callback) {
    Object.assign(this.state, updates);
    callback && callback();
  }

  observableState = ThemeEditorStore;

  _disposeSessionCache;
  _disposeRemoveCache;
  _hasMounted = false;
  _cache = sessionCache("ThemeEditorStore");

  /** called by component while the ThemeEditorStore is needed */
  mount() {
    // don't share cache across users
    if (!this._disposeRemoveCache) {
      this._disposeRemoveCache = mobx.reaction(
        () => LoginStore.token,
        () => this._cache.clear()
      );
    }
    if (!this._disposeSessionCache) {
      // cache some select state fields. Make it reload safe without losing your changes
      this._disposeSessionCache = mobx.reaction(
        () => {
          const { renderedStyle, theme, originalTheme, themeId, activeTab } = this.state;
          return { renderedStyle, theme, originalTheme, themeId, activeTab };
        },
        (persistentState) => {
          this._cache.set(persistentState);
        },
        {
          equals: mobx.comparer.structural,
        }
      );
    }

    if (!this._hasMounted) {
      this._hasMounted = true;
      if (this._cache.hasValue()) {
        // restore the cache (page reloaded)
        this.setState(
          {
            initialLoading: false,
            ...this._cache.get(),
          },
          this.applyTheme
        );
      } else {
        // fetch the current theme
        this.getTheme(ThemeStore.themeId);
      }
    }

    // reset theme ID when layout changes
    mobx.reaction(
      () => this.useFoldyThemeId,
      () => {
        this.onThemeSelected(computeThemeId());
      }
    );
  }

  unmount() {
    this._disposeSessionCache && this._disposeSessionCache();
    this._disposeSessionCache = undefined;
  }

  toggleDebugMode = () => {
    const isDebugMode = !this.state.debugMode;
    const activeTab = isDebugMode ? this.state.activeTab : "logo";
    this.setState({ debugMode: isDebugMode, activeTab });
  };

  handleDropRejected = () => {
    this.setState({ logoError: "Unsupported File Type" });
  };

  handleDropAccepted = (file) => {
    // Because we specify acceptable MIME types, this should only be an image.
    const firstFile = file[0];
    if (firstFile.size > 1024 * 1024) {
      this.setState({ logoError: "File too big" });
    } else {
      this.setState({
        logoError: null,
        logoFile: firstFile,
        logoUrl: firstFile.preview,
      });
    }
    this.queueThemeUpdate();
  };

  onDeleteLogo = () => {
    // TODO - delete functionality is a little uncertain.
    // Seems like maybe we just want a cancel button on the editor
    this.setState({
      logoUrl: undefined,
      logoFile: null,
    });
  };

  /** initializes internal theme state from the theme server */
  getTheme = async (themeId) => {
    this.setState({ loading: true, loadError: false });
    const setThemeState = async (id) => {
      const { customStyles, variables, overrides } = await fetchVariables(id);
      const renderedStyle = await fetchStyle(id);
      const theme = {
        customStyles: customStyles || "",
        variables: variables || {},
        overrides: overrides || "",
      };
      await new Promise((res) =>
        this.setState(
          {
            themeId: id,
            originalTheme: theme,
            theme,
            renderedStyle,
          },
          res
        )
      );
    };
    try {
      await setThemeState(themeId);
    } catch (ex) {
      console.error("failed to load theme", ex);
      setThemeState("standard");
    } finally {
      this.setState({ loading: false, initialLoading: false });
    }
  };

  handleVariableChange = async ({ name, value, invalidMessage }) => {
    const theme = this.state.theme;
    const previous = theme.variables[name];
    if (value !== previous) {
      let invalidChange;
      if (invalidMessage) {
        invalidChange = { ...this.state.invalidVariables, [name]: invalidMessage };
      } else {
        let { [name]: previousInvaid, ...restInvalid } = this.state.invalidVariables;
        invalidChange = restInvalid;
      }
      function addDerivedVariables(variables) {
        return {
          ...variables,
          ["$web-font-path"]: `'https://fonts.googleapis.com/css?family=${variables["$headings-font-family"]}:${variables["$headings-font-weight"]}|${variables["$font-family-base"]}:400,600&display=swap'`,
        };
      }
      const variablesUpdate = addDerivedVariables({
        ...theme.variables,
        [name]: value,
      });
      this.setState({
        invalidVariables: invalidChange,
        theme: {
          ...theme,
          variables: variablesUpdate,
        },
      });
      await this.queueThemeUpdate();
    }
  };

  queueThemeUpdate = debounceAsync(async () => {
    await this.applyTheme();
  }, 1000);

  applyTheme = async () => {
    if (this.state.theme && this.isValid) {
      this.setState({ loadingStyle: true });
      const style = await compileStyle(this.state.theme);
      this.setState({ renderedStyle: style, loadingStyle: false });

      ThemeStore.previewStyle({
        ...this.state.theme,
        themeId: this.state.themeId,
        style,
        logo: this.state.logoUrl,
      });
    }
  };

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  get useFoldyThemeId() {
    return SessionStore.userPreferences && !BrandConfigStore.brandConfig.restrictions.hideNewUiOptIn;
  }

  saveTheme = async () => {
    try {
      this.setState({ loading: true });
      let themeId = this.state.themeId;
      const originalThemeId = ThemeStore.themeId;

      const modifiedThemeContent = !_.isEqual(this.state.theme, this.state.originalTheme);

      if (modifiedThemeContent) {
        const saved = await saveStyle(this.state.theme);
        this.setState({ originalTheme: { ...this.state.theme } });
        themeId = saved.themeId;
      }

      const modifiedLogo = !!this.state.logoFile;
      if (modifiedLogo) {
        await BrandConfigStore.updateLogo(this.state.logoFile);
      }

      const modifiedThemeId = themeId !== originalThemeId;
      if (modifiedThemeId) {
        const themeLabel = this.useFoldyThemeId ? "foldyThemeId" : "themeId";
        await setThemeConfig({ [themeLabel]: themeId }); // save it to locationHQ
        ThemeStore.setThemeConfig({ [themeLabel]: themeId });
      }

      this.setState({
        themeId,
        loading: false,
        logoUrl: undefined,
        logoFile: null,
      });

      ToastService.success("Success", "Updated brand theme");
    } catch (err) {
      ToastService.error("Oops!", "Something went wrong");
      console.error("Oops! Something went wrong with the theme update", err);
      this.setState({ loading: false });
    }
  };

  onThemeSelected = async (themeId) => {
    this.setState({ themeId });
    await this.getTheme(themeId);
    const { customStyles, variables, renderedStyle } = this.state;
    ThemeStore.previewStyle({
      style: renderedStyle,
      scss: customStyles,
      variables,
      logo: this.state.logoUrl,
    });
  };

  cancel = () => {
    if (ThemeStore.isPreviewMode) {
      this.endPreviewMode();
    } else {
      this.onDeleteLogo();
      if (this.hasDifferentTheme) {
        this.onThemeSelected(ThemeStore.themeId);
      } else {
        this.setState(
          {
            theme: { ...this.state.originalTheme },
          },
          this.queueThemeUpdate
        );
      }
    }
  };

  /** have the theme (variables) change */
  get hasThemeChanges() {
    return !_.isEqual(this.state.theme, this.state.originalTheme);
  }
  /** has the logo been modified */
  get hasLogoChanges() {
    return !!this.state.logoFile;
  }

  /** has the theme been switched */
  get hasDifferentTheme() {
    return this.state.themeId != ThemeStore.themeId;
  }

  /** true if there's any unsaved changes */
  get hasChanges() {
    return this.hasThemeChanges || this.hasLogoChanges || this.hasDifferentTheme;
  }

  /** are there any invalid variables */
  get isValid() {
    return Object.values(this.state.invalidVariables).every((x) => !x);
  }

  endPreviewMode = () => {
    // Redirect to the brandSettings.
    navigatePath("/admin/v2/brand");
    ThemeStore.setPreviewMode(false);
  };

  startPreviewMode = async () => {
    ThemeStore.setPreviewMode(true);
    // Redirect to the home.
    navigatePath("/");
  };
}

function computeThemeId() {
  return (ThemeStore.preview && ThemeStore.preview.themeId) || ThemeStore.themeId || "standard";
}

const store = new ThemeEditorStore();

window.toggleAdvancedThemeEditor = () => {
  store.toggleDebugMode();
};

export default store;
