import { toJS, observable, action, computed, makeObservable } from "mobx";
import moment from "moment";
import { FieldDescriptors, FieldType } from "../../util/urlSync";
import selectedLocationsStore from "../../../src/services/locations/SelectedLocationsStore";

export const today = moment().startOf("day");

export const dateRanges = [
  { count: 1, name: "Last Day", id: "LastDay", dateRangeType: "day", unit: "days" },
  { count: 7, name: "Last Week", id: "Last7Days", dateRangeType: "week", unit: "days" },
  { count: 1, name: "Last Month", id: "Last30Days", dateRangeType: "month", unit: "months" },
  { count: 3, name: "Last 3 Months", id: "Last3Months", dateRangeType: "3months", unit: "months" },
  { count: 6, name: "Last 6 Months", id: "Last6Months", dateRangeType: "6months", unit: "months" },
  { count: 1, name: "Last Year", id: "Last12Months", dateRangeType: "year", unit: "years" },
  { count: null, name: "All Time", id: "AllTime", dateRangeType: "alltime", unit: undefined },
].map(({ count, name, dateRangeType, id, unit }) => {
  return {
    from: moment(today).subtract(count, unit).startOf("day"),
    to: today.endOf("day"),
    name,
    id,
    dateRangeType,
  };
});

export const reports = [
  { id: 1, name: "Summary", reportType: "reviewSummary", icon: "fa-chart-line" },
  // Leaving the review detail tab as summary to ensure links to reviews still function correctly.
  {
    id: 2,
    name: "Reviews",
    reportType: "summary",
    icon: "fa-comment",
    hide: (brandConfig) => {
      return !!brandConfig && brandConfig.restrictions.hideReviewDetails;
    },
  },
  { id: 3, name: "Store Comparison", reportType: "storeComparison", icon: "fa-balance-scale" },
];

export const ratings = [
  { key: "1star", value: "1 star" },
  { key: "2star", value: "2 stars" },
  { key: "3star", value: "3 stars" },
  { key: "4star", value: "4 stars" },
  { key: "5star", value: "5 stars" },
  { key: "notRecommended", value: "Not Recommended" },
  { key: "recommended", value: "Recommended" },
];

const sharedParams = {
  riskCategories: FieldType.delimitedArrayOf(FieldType.string),
  providers: FieldType.delimitedArrayOf(FieldType.string),
  tags: FieldType.delimitedArrayOf(FieldType.string),
  ratings: FieldType.delimitedArrayOf(FieldType.string),
  reportSortOrder: FieldType.string,
  reportSortField: FieldType.string,
  sortOrder: FieldType.string,
  sortField: FieldType.string,
  showSentiment: FieldType.bool,
  page: FieldType.string,
  searchText: FieldType.string,
  responsesFilter: FieldType.string,
  publicResponsesFilter: FieldType.string,
  notesFilter: FieldType.string,
  revisionsFilter: FieldType.string,
  textFilter: FieldType.string,
  tagFilter: FieldType.string,
  imageFilter: FieldType.string,
  removedReviewsFilter: FieldType.string,
  respondableReviewsFilter: FieldType.bool,
  approvableResponseFilter: FieldType.bool,
  customerResponseFilter: FieldType.bool,
};

export const urlQueryDefinition = FieldDescriptors.build({
  ...sharedParams,
  range: FieldType.string,
  filteredReviewsFilter: FieldType.string,
  startDate: FieldType.dateString("YYYY-MM-DD"),
  endDate: FieldType.dateString("YYYY-MM-DD"),
  compareToPreviousPeriod: FieldType.bool,
  showGroups: FieldType.bool,
});

export const singleReviewUrlQueryDefinition = FieldDescriptors.build({
  ...sharedParams,
  reviewId: FieldType.string,
});

const defaultState = Object.freeze({
  report: reports[0],
  riskCategories: [],
  providers: [],
  ratings: [],
  tags: [],
  range: "Last3Months",
  reportSortOrder: undefined,
  reportSortField: undefined,
  sortOrder: undefined,
  sortField: undefined,
  searchText: undefined,
  responsesFilter: undefined,
  publicResponsesFilter: undefined,
  notesFilter: undefined,
  revisionsFilter: undefined,
  textFilter: undefined,
  tagFilter: undefined,
  imageFilter: undefined,
  removedReviewsFilter: undefined,
  filteredReviewsFilter: undefined,
  respondableReviewsFilter: undefined,
  approvableResponseFilter: undefined,
  customerResponseFilter: undefined,
});

const defaultReviewState = Object.freeze({
  compareToPreviousPeriod: false,
  startDate: dateRanges.find((x) => x.id === "Last3Months").from,
  endDate: dateRanges.find((x) => x.id === "Last3Months").to,
  showGroups: false,
});
class ReviewFiltersStore {
  state = {
    ...defaultState,
    ...defaultReviewState,
  };

  get getQueryParams() {
    const selected = dateRanges.find(
      (range) =>
        range.to.diff(reviewFiltersStore.state.endDate, "days") === 0 &&
        range.from.diff(reviewFiltersStore.state.startDate, "days") === 0
    );
    const state = toJS(this.state);
    const params = {
      sortField: state.sortField,
      sortOrder: state.sortOrder,
      startDate: moment(state.startDate).format("YYYY-MM-DD"),
      endDate: moment(state.endDate).format("YYYY-MM-DD"),
      customerResponseFilter: state.customerResponseFilter,
      riskCategories: state.riskCategories.join(","),
      providers: state.providers.join(","),
      tags: state.tags.join(","),
      tagFilter: state.tagFilter,
      range: state.range,
      ratings: state.ratings.join(","),
      responsesFilter: state.responsesFilter,
      notesFilter: state.notesFilter,
      publicResponsesFilter: state.publicResponsesFilter,
      revisionsFilter: state.revisionsFilter,
      removedReviewsFilter: state.removedReviewsFilter,
      respondableReviewsFilter: state.respondableReviewsFilter,
      approvableResponseFilter: state.approvableResponseFilter,
      filteredReviewsFilter: state.filteredReviewsFilter ? state.filteredReviewsFilter : "onlyWithout",
      searchText: state.searchText,
      textFilter: state.textFilter,
      imagefilter: state.imageFilter,
      groupId: selectedLocationsStore.legacyGroupIdOrAll,
      reportRange: !!selected && selected.id !== "LastDay" ? selected.id : "SpecificDates",
    };
    return params;
  }

  onTagEditingAngularCallback = undefined;
  setTagEditingAngularCallback = (cb) => (this.onTagEditingAngularCallback = cb);

  constructor() {
    makeObservable(this, {
      state: observable,
      onTagEditingAngularCallback: observable,
      setTagEditingAngularCallback: action,
      setState: action,
      getQueryParams: computed,
    });
  }

  setState(updates, callback) {
    Object.assign(this.state, updates);
    callback && callback();
  }
}

const reviewFiltersStore = new ReviewFiltersStore();

export default reviewFiltersStore;

const defaultSingleReviewState = Object.freeze({
  reviewId: undefined,
  range: "AllTime",
  filteredReviewsFilter: "all",
});

class SingleReviewFiltersStore {
  state = {
    ...defaultState,
    ...defaultSingleReviewState,
  };

  onTagEditingAngularCallback = undefined;
  setTagEditingAngularCallback = (cb) => (this.onTagEditingAngularCallback = cb);

  constructor() {
    makeObservable(this, {
      state: observable,
      onTagEditingAngularCallback: observable,
      setTagEditingAngularCallback: action,
      setState: action,
    });
  }

  setState(updates, callback) {
    Object.assign(this.state, updates);
    callback && callback();
  }
}

export const singleReviewFiltersStore = new SingleReviewFiltersStore();
