import "./namechip.scss";
import React, { createRef } from "react";
import classnames from "classnames";
import { UncontrolledTooltip } from "reactstrap";
import colors from "../../modules/theme/colors";

const colorClasses = ["bg-primary", "bg-secondary", "bg-warning", "bg-info"];

export interface NameChipProps {
  name?: string;
  colorFrom?: string;
  className?: string;
  size: "xl" | "lg" | "md" | "sm" | "xs";
  defaultName?: any;
  overrideFullName?: any;
  displayName?: boolean;
  tooltip?: boolean;
  overrideBgColor?: string;
  overrideTextColor?: string;
}
/**
 * Renders a generated colored circle based on the user's name. Can also optionally display
 * the full name to the side or in a tooltip
 */
export class NameChip extends React.Component<
  NameChipProps,
  {
    spanRef: null | HTMLElement;
  }
> {
  state = {
    spanRef: null,
  };

  setSpanRef = (spanRef: null | HTMLElement) => {
    this.setState({ spanRef });
  };

  render() {
    const {
      name,
      className,
      size,
      defaultName,
      displayName,
      colorFrom,
      tooltip,
      overrideFullName,
      overrideBgColor,
      overrideTextColor,
      ...props
    } = this.props;
    const classes = ["round-circle", "name-chip", "control-spacer-right"];
    const themeColors = colors({ nColors: colorClasses.length });
    let abbreviation;
    let themeColor = themeColors[0];
    let isUnassigned = false;
    let themeColorNumber = 1;
    if ((colorFrom || name) && overrideBgColor === undefined) {
      let upper = (colorFrom || name || "").toUpperCase();
      let charSumHash = Array.from(upper.toLowerCase())
        .map((x) => x.charCodeAt(0))
        .reduce((a, b) => a + b, 0);
      const colorIndex = charSumHash % colorClasses.length;
      const nameSplit = (name || "").toUpperCase().split(" ");
      abbreviation =
        nameSplit.length >= 2 ? `${nameSplit[0][0]}${nameSplit[nameSplit.length - 1][0]}` : nameSplit[0][0];
      themeColor = themeColors[colorIndex];
      themeColorNumber = colorIndex + 1;
    } else if (overrideBgColor !== undefined) {
      const nameSplit = (name || "").toUpperCase().split(" ");
      abbreviation =
        nameSplit.length >= 2 ? `${nameSplit[0][0]}${nameSplit[nameSplit.length - 1][0]}` : nameSplit[0][0];
      themeColor = overrideBgColor;
    } else {
      abbreviation = "";
      isUnassigned = true;
    }

    const circle = (
      <span
        ref={this.setSpanRef}
        className={classnames(classes, className, size)}
        style={{
          backgroundColor: isUnassigned ? "var(--gray-500)" : themeColor,
          color:
            overrideTextColor !== undefined
              ? overrideTextColor
              : isUnassigned
              ? "var(--text-on-null)"
              : `var(--text-on-color-${themeColorNumber})`,
        }}
        {...props}
      >
        <div>{abbreviation}</div>
      </span>
    );
    // TODO - adjust font size based on size
    const middleAlignedName = displayName && <span>{name || defaultName}</span>;
    const el = this.state.spanRef;
    return tooltip ? (
      <React.Fragment>
        <span className="nowrap d-flex align-items-center" ref={this.setSpanRef}>
          {circle}
          {overrideFullName || middleAlignedName}
        </span>
        {el && (
          <UncontrolledTooltip container="inline" target={el}>
            {name || defaultName}
          </UncontrolledTooltip>
        )}
      </React.Fragment>
    ) : (
      <span className="nowrap d-flex align-items-center">
        {circle}
        <span className="">{overrideFullName || middleAlignedName}</span>
      </span>
    );
  }
}
