import BrandConfigStore from "../../../services/config/BrandConfigStore";
import { installScript, onceScriptLoaded } from "./scriptUtil";
import * as mobx from "mobx";
import variables from "../../../../src/components/bootstrap4/includes/_core-variables.scss";

const shouldShowHelpCenter = () => {
  return (
    !!BrandConfigStore.brandConfig &&
    !BrandConfigStore.brandConfig.restrictions.hideHelpCenter &&
    !BrandConfigStore.isApproximate
  );
};

function hide() {
  /* Remove Zendesk from non-login pages*/
  for (let selector of ["#ze-snippet", ".zopim", ".zEWidget-launcher", ".zEWidget-webWidget"]) {
    for (let elem of document.querySelectorAll(selector)) {
      elem.parentElement && elem.parentElement.removeChild(elem);
    }
  }
}

let disposeMobxReaction;
export function install() {
  disposeMobxReaction = mobx.reaction(
    () => shouldShowHelpCenter(),
    (display) => {
      if (display) {
        var s = installScript("https://static.zdassets.com/ekr/snippet.js?key=37d33c51-706f-4b13-8d13-f5a6f8509b6d");
        s.id = "ze-snippet";
        onceScriptLoaded(s, () => {
          // Make sure ZenDesk has loaded all of its dependencies
          return !!window.zE.hide;
        }).then(() => {
          window.helpCenterOpen = false;
          window.showHelpCenter = () => {
            window.helpCenterOpen = true;
            // https://developer.zendesk.com/api-reference/widget/api/#options
            // url is used to update the suggestions based on the current url without refreshing the page
            zE.setHelpCenterSuggestions({ url: true });
            zE.showIPMWidget();
          };
          window.hideHelpCenter = () => {
            window.helpCenterOpen = false;
            zE.hideIPMWidget();
          };

          // Hide the default ZenDesk help center button
          const launcher = document.getElementById("launcher");
          if (launcher) {
            launcher.setAttribute("hidden", true);
          }

          zE(() => {
            zE("webWidget:on", "close", function () {
              window.helpCenterOpen = false;
            });
          });
        });

        const headerHeight = parseInt(variables.headerHeight.replace("px", ""));
        // We need to lower the offset a bit to make the widget look better
        const additionalHeaderOffset = -6;

        const widgetPlacement = BrandConfigStore?.brandConfig?.restrictions?.hideApplicationBar
          ? {
              offset: {
                horizontal: `calc(${parseInt(variables.sidebarWidth.replace("px", ""))}px + ${variables.cardPaddingX})`,
                vertical: variables.cardPaddingY,
              },
              position: { horizontal: "left", vertical: "bottom" },
            }
          : {
              offset: {
                horizontal: "-7px",
                vertical: "" + (headerHeight + additionalHeaderOffset) + "px",
              },
              position: { horizontal: "right", vertical: "top" },
            };

        window.zESettings = {
          webWidget: {
            ...widgetPlacement,
          },
        };
      }
    }
  );
}

export function uninstall() {
  hide();
  disposeMobxReaction();
}
