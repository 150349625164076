import client from "../shared/apiClient";

/**
 * Function that list triggers
 * @param offset {number} number of results to offset
 * @param limit {number} number of results per page
 * @returns Promise {
 *   triggers: [],
 *   total: boolean
 * }
 */
export const listNotificationTriggers = (params) => {
  return client.get("/notificationTriggers", { params }).then((resp) => resp.data);
};

/**
 * Function that gets a specific notification trigger
 * @param triggerId {string}
 * @returns Promise {trigger}
 */
export const getNotificationTrigger = (triggerId) => {
  return client.get(`/notificationTriggers/${triggerId}`).then((resp) => resp.data);
};

/**
 * Function that deletes a specific trigger trigger
 * @param triggerId {string}
 * @returns Promise
 */
export const deleteNotificationTrigger = (triggerId) => {
  return client.delete(`/notificationTriggers/${triggerId}`).then((resp) => resp.data);
};

/**
 * Function that creates a notification trigger
 * @param trigger
 * @returns Promise{trigger}
 */
export const createNotificationTrigger = (trigger) => {
  return client.post("/notificationTriggers", trigger).then((resp) => resp.data);
};

/**
 * Function that updates a notification trigger
 * @param trigger
 * @returns Promise{trigger}
 */
export const updateNotificationTrigger = (trigger) => {
  return client.put("/notificationTriggers", trigger).then((resp) => resp.data);
};

export const checkValidName = (name) => {
  return client.get("/notificationTriggers/validate", { params: { name } }).then((resp) => resp.data);
};

export const testNotificationTrigger = (triggerId, { delay = false } = {}) => {
  return client
    .post(`/notificationTriggers/${encodeURIComponent(triggerId)}/test`, null, {
      params: {
        delay,
      },
    })
    .then(() => {});
};

export const listEventTypes = () => {
  return client.get(`/notificationTriggers/event/types`).then((resp) => resp.data);
};
