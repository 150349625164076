"use strict";

import React, { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";

export default function SearchBox({ updateSearchTerm, placeholder, tooltip, value, className, ...props }) {
  const [internalValue, setInternalValue] = useState(null);
  const [lastSubmittedValue, setLastSubmittedValue] = useState(null);
  if (value && !lastSubmittedValue) {
    setLastSubmittedValue(value);
    setInternalValue(value);
  }

  const search = () => {
    if (internalValue !== value) {
      updateSearchTerm(internalValue);
      setLastSubmittedValue(internalValue);
    }
  };
  // if internal value has already been submitted, allow incoming changes,
  // otherwise make the internal value sticky
  const displayValue = lastSubmittedValue === internalValue ? value : internalValue;

  const input = (
    <span className="input-group">
      <input
        className="form-control"
        type="text"
        value={displayValue || ""}
        placeholder={placeholder}
        charSet="utf-8"
        onBlur={(event) => search(event.target.value)}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            search(event.target.value);
          }
        }}
        onChange={(event) => setInternalValue(event.target.value)}
      />
      <div className="input-group-append">
        <i className="input-group-text far fa-search d-flex"></i>
      </div>
    </span>
  );
  const nextId = (() => {
    let id = 0;
    return () => "search-box-trigger" + id++;
  })();
  const id = nextId();

  return (
    // TODO
    // tooltip should not be the responsibility of this component, but unfortunately,
    // it didn't seem to work when wrapping the component
    <div className={`form-group ${className || ""}`} {...props}>
      {tooltip ? (
        <span>
          <span href="#" id={id}>
            {input}
          </span>
          <UncontrolledTooltip placement={"bottom"} target={id} id={`${id}-search-box`}>
            {tooltip}
          </UncontrolledTooltip>
        </span>
      ) : (
        <span>{input}</span>
      )}
    </div>
  );
}
