import * as mobx from "mobx";
import * as api from "./api";
import { getAccounts, getEntity, getGroups, searchLocations } from "./api";
import sessionStore from "../session/SessionStore";
import { ACCOUNT, ALL, GROUP, LOCATION, LOCATION_TYPE } from "./entityTypes";
import LoginStore from "../session/LoginStore";
import { memoize } from "../../util/cache/memoize";
import { LocationGroup, LocationGroupEntityType } from "./LocationTypes";

const { action, observable, computed } = mobx;

/**
 * store that keeps a cache of user's full location access (and what other location access providing entities they have access to,
 * e.g. accounts and groups)
 *
 * TODO - this cache needs attention. It was implemented such that it loads a full copy of a user's access
 *        (locations, groups, accounts). However this is pretty heavy weight:
 *        slow to load, lots of network overhead, heavy memory consumption.
 *        It would be more useful as a just-in-time search and loading utility that can be invalidated when content changes.
 *
 *
 */
export class AccessStore {
  constructor() {
    mobx.makeObservable(this, {
      _locations: observable,
      _groups: observable,
      _accounts: observable,
      _all: observable,
      _isLoaded: observable,
      _accountsLoaded: observable,
      loadingMoreLocations: observable,
      _lastLoaded: observable,
      isLoaded: computed,
      invalidate: action.bound,
      cache: action,
      locations: computed({ keepAlive: true }),
      groups: computed,
      accounts: computed,
      all: computed,
      allGroup: computed,
      allEntities: computed,
      byEntityId: computed,
      byType: computed,
    });
    this.invalidate();
  }
  _locations: LocationGroup[] = [];
  _groups: LocationGroup[] = [];
  _accounts: LocationGroup[] = [];
  _all: LocationGroup[] = [];

  _isLoaded = false;
  _accountsLoaded = false;
  loadingMoreLocations = false;
  _lastLoaded = 0;

  get isLoaded() {
    return this._isLoaded;
  }

  _invalidationUnloader: null | (Promise<void> & { cancel(): void }) = null;

  invalidate(): void {
    this._isLoaded = false;
    this._invalidationUnloader && this._invalidationUnloader.cancel();
    this._invalidationUnloader = null;
    clearRequestCache();
    sessionStore.onceLoaded().then(() => {
      this._load();
      this._invalidationUnloader = sessionStore.onceUnloaded();
      this._invalidationUnloader
        .then(() => this.invalidate())
        .catch((ex) => {
          /*cancellations propogate exceptions here*/
        });
    });
  }

  /**
   * @deprecated just a temporary measure for SelectedLocationsStore
   * stores the location in the access stores cache
   * @param {LocationGroup} locationGroup
   */

  cache(locationGroup: LocationGroup): void {
    const lists: Record<LocationGroupEntityType, LocationGroup[]> = {
      User: this._all,
      Group: this._groups,
      Account: this._accounts,
      Location: this._locations,
    };
    const toMutate = lists[locationGroup.entityId.entityType];
    const existingIdx = toMutate.findIndex((x) => x.entityIdString === locationGroup.entityIdString);
    if (existingIdx) {
      toMutate[existingIdx] = locationGroup; // freshen it up
    } else {
      toMutate.push(locationGroup);
    }
  }

  /**
   * @deprecated should use a scrolling/paginated/searchable view of locations rather than loading all locations into memory
   * groups for single locations
   * @return {Array.<LocationGroup>}
   * */

  get locations(): LocationGroup[] {
    return mobx.toJS(this._locations);
  }

  /**
   * @deprecated should use a scrolling/paginated/searchable view of groups rather than loading all into memory
   * real groups
   * @return {Array.<LocationGroup>} */
  get groups(): LocationGroup[] {
    return mobx.toJS(this._groups);
  }

  /**
   * @deprecated should use a scrolling/paginated/searchable view of accounts rather than loading all accounts into memory
   * account groups
   * @return {Array.<LocationGroup>} */
  get accounts(): LocationGroup[] {
    return mobx.toJS(this._accounts);
  }

  /**
   * @deprecated should use a scrolling/paginated/searchable view of accounts rather than loading all accounts into memory.
   * If you just need a reference to the single "all" group, use `allGroup` instead
   * the single "All" group
   * @return {Array.<LocationGroup>} */
  get all(): LocationGroup[] {
    return mobx.toJS(this._all);
  }

  /**
   * @returns {LocationGroup|null}
   */
  get allGroup(): LocationGroup | null {
    return this.all[0] || null;
  }
  /**
   * @deprecated should use a scrolling/paginated/searchable view of location groups rather than loading all location groups into memory
   * all of the above types. But together
   * @return {Array.<LocationGroup>} */
  get allEntities() {
    return this.locations.concat(this.groups).concat(this.accounts).concat(this.all);
  }

  /**
   * @deprecated - use a resolver pattern, entity may not yet be loaded. Private access is ok
   */
  get byEntityId(): Record<string, LocationGroup> {
    const hash: Record<string, LocationGroup> = {};
    for (let x of this.allEntities) {
      hash[x.entityIdString] = x;
    }
    return hash;
  }

  /**
   *
   * @param {string} entityIdString - like "Location-1234" or "User-56789"
   */
  resolveEntity(entityIdString: string): Promise<LocationGroup | null> {
    return Cache.getEntity(entityIdString);
  }

  /**
   *
   * @param {string[]} entityIds - like ["Location-1234", "User-56789"]
   * @returns {Promise<LocationGroup[]>}
   */
  async resolveEntities(entityIds: string[]): Promise<LocationGroup[]> {
    // TODO id this ends up getting used for large batches of locations, there's another api.
    // May want to rethink caching from being dumb. Something that would batch up requests for those things that are
    // not cached, a la UserResolver
    const resolved = await Promise.all(entityIds.map(Cache.getEntity));
    return resolved.flatMap((x) => (x ? [x] : []));
  }

  getLocation(locationId: string): LocationGroup | undefined {
    return this.byEntityId[LOCATION_TYPE + "-" + locationId];
  }

  _load(): void {
    this.loadingMoreLocations = true;
    Promise.all([this._resolveAccounts(), this._resolveGroups(), this._resolveLocations()]).then(() => {
      this._isLoaded = true;
    });
  }

  _resolveLocations(): void {
    const gettingLocations = this._loadLocations();

    gettingLocations.then(
      mobx.action("resolveLocationsHandler", (x) => {
        this._locations = x;
      })
    );
  }

  _resolveAccounts(): void {
    api.getAccounts().then(
      mobx.action("resolveAccountsHandler", (x) => {
        this._accounts = x.accounts;
        this._all = x.all;
        this._accountsLoaded = true;
      })
    );
  }

  _resolveGroups(): void {
    const gettingGroups = this._loadGroups();

    gettingGroups.then(
      mobx.action("resolveGroupsHandler", (x) => {
        this._groups = x;
      })
    );
  }

  /**
   * Fetch all groups, in batches of 1000
   */
  async _loadGroups(): Promise<LocationGroup[]> {
    const limit = 1000;
    const locationGroups = await api.getGroups({ limit, offset: this._groups.length });
    // Concatenate the results right away so those searching for groups will be able to find them as fast as possible
    this._groups = this._groups.concat(locationGroups.groups);
    if (locationGroups.groups.length >= limit) {
      await this._loadGroups();
    }
    return this._groups;
  }

  /**
   * Fetch all locations, in batches of 1000
   */
  async _loadLocations(): Promise<LocationGroup[]> {
    const limit = 1000;
    const locs = await api.searchLocations({ limit, offset: this._locations.length });
    // Concatenate the results right away so those searching for locations will be able to find them as fast as possible
    this._locations = this._locations.concat(locs.locations);
    if (locs.locations.length >= limit) {
      await this._loadLocations();
    } else {
      this.loadingMoreLocations = false;
    }
    return this._locations;
  }

  onceLoaded(): Promise<void> & { cancel(): void } {
    return mobx.when(() => this._isLoaded);
  }

  onceAccountsLoaded(): Promise<void> & { cancel(): void } {
    return mobx.when(() => this._accountsLoaded);
  }

  get byType(): Record<"LOCATION" | "GROUP" | "ACCOUNT" | "ALL", LocationGroup[]> {
    return {
      [LOCATION]: this.locations,
      [GROUP]: this.groups,
      [ACCOUNT]: this.accounts,
      [ALL]: this.all,
    };
  }

  searchLocations = Cache.getLocations;
  searchAccounts = Cache.getAccounts;
  searchGroups = Cache.getGroups;

  /**
   * @callback SearchFunction
   * @param {PaginationConfig} options
   * @returns {Promise<LocationGroup[]>}
   */
  /** @type {{[string]: SearchFunction}} */
  _searchByType: Record<LocationGroupEntityType, (cfg: PaginationConfig) => Promise<LocationGroup[]>> = {
    Location: (cfg: PaginationConfig) => Cache.getLocations(cfg).then((x) => x.locations),
    Group: (cfg: PaginationConfig) => Cache.getGroups(cfg).then((x) => x.groups),
    Account: (cfg: PaginationConfig) => Cache.getAccounts(cfg).then((x) => x.accounts),
    User: (cfg: PaginationConfig) => Cache.getAccounts(cfg).then((x) => x.all),
  };

  searchType(entityType: LocationGroupEntityType, options: PaginationConfig): Promise<LocationGroup[]> {
    const getter = this._searchByType[entityType];
    if (!getter) throw new Error(`entityType '${entityType}' is not supported`);
    return getter(options);
  }
}

export interface PaginationConfig {
  q?: string;
  offset?: number;
  limit?: number;
}

/** Cache Utilities */
let MEMOIZE_CONFIG = { timeout: 10 * 60 * 1000, maxSize: 20 };
const Cache = {
  getAccounts: memoize(getAccounts, MEMOIZE_CONFIG),
  getGroups: memoize(getGroups, MEMOIZE_CONFIG),
  getLocations: memoize(searchLocations, MEMOIZE_CONFIG),
  getEntity: memoize(getEntity, { ...MEMOIZE_CONFIG, maxSize: 100 }),
};

function clearRequestCache() {
  Object.values(Cache).forEach((x) => x.invalidate && x.invalidate());
}

/** The singleton */
const accessStore = new AccessStore();
export default accessStore;
