export function installScript(src) {
  var s = document.createElement("script");
  s.src = src;
  s.async = true;
  s.type = "text/javascript";
  document.body.appendChild(s);
  return s;
}

export function onceScriptLoaded(script, checker) {
  return new Promise((res, rej) => {
    script.onload = () => {
      // Note onload will start the callback the minute the script "LOADS" not when its actually
      // finished execution, which is disappointing. Use the checker to validate that the script is loaded
      const timer = setInterval(() => {
        try {
          if (checker()) {
            clearInterval(timer);
            res();
          }
        } catch (e) {}
      });
    };
  });
}
