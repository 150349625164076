import * as React from "react";
import BrandConfigStore from "../../config/BrandConfigStore";
import { observer } from "mobx-react";
import ThemeStore from "../../config/ThemeStore";
import * as mobx from "mobx";
import * as UrlFragmentSearch from "../../../util/UrlFragmentSearch";
import { LoginWithPassword } from "./LoginWithPassword";
import { LoginWithOkta } from "./LoginWithOkta";
import FullPageSpinner from "./FullPageSpinner";

/**
 * Selects sub login component depending on accounts login style,
 * and manages the actual url forwarding of logging in
 */
const LoginContainerView = observer(({ history }) => {
  const options = UrlFragmentSearch.getQueryObject();
  const redirect = options["redirect"] || "/";
  const onLoginSuccess = () => {
    history.push(redirect);
  };
  const brandConfig = { ...mobx.toJS(BrandConfigStore.brandConfig), logoUrl: ThemeStore.logoUrl };
  if (!brandConfig) {
    // external component is checking for null brand config before rendering this component,
    // but somehow in the login callback this ends up trying to render. Maybe some observer higher up has issues (MaybeAutoLogin)
    return <FullPageSpinner />;
  } else if (brandConfig.oktaUrl && brandConfig.samlUrl) {
    return <LoginWithOkta onLoginSuccess={onLoginSuccess} brandConfig={brandConfig} />;
  } else {
    return <LoginWithPassword onLoginSuccess={onLoginSuccess} brandConfig={brandConfig} />;
  }
});

export default LoginContainerView;
