import * as mobx from "mobx";
import { makeObservable } from "mobx";
import { action, observable } from "mobx";
import { throttle } from "lodash";
import * as usersApi from "./usersApi";

/**
 * Fetches and caches user details.
 * Not implemented as a global singleton so that users can be garbage collected once the relevant store is done
 * but that could be done
 * 08/09 - added singleton so that multiple user selectors can reference a single store of users, cache queries for 5 min
 */
const CACHE_QUERY_DURATION = 5 * 60 * 1000;

export class UserResolver {
  _queryCacheCleanupTimer;
  _queryCache = {};

  users = observable.map();

  fetchQueue = [];

  loading = false;

  throttledFetch = throttle(
    () => {
      const userIds = this.fetchQueue;
      this.fetchQueue = [];
      if (userIds.length) {
        usersApi.userSummaries(userIds, true).then(this._setUserLoaded);
      }
    },
    300,
    { trailing: true }
  );

  constructor() {
    makeObservable(this, {
      _setUserLoaded: action.bound,
      _initUserToMap: action.bound,
      fetchById: action,
    });

    this._queryCacheCleanupTimer = setInterval(
      () =>
        Object.keys(this._queryCache).forEach((cacheKey) => {
          const now = +new Date();
          if (now - this._queryCache[cacheKey].requested > CACHE_QUERY_DURATION) {
            delete this._queryCache[cacheKey];
          }
        }),
      60 * 1000
    );
  }

  _setUserLoaded(users) {
    for (let user of users) {
      let observableUser = this.users.get(user.userId);
      if (!observableUser) {
        observableUser = observable.object({
          userId: user.userId,
        });
        this.users.set(user.id, observableUser);
      }
      Object.assign(observableUser.user, user);
      observableUser.isLoaded = true;
    }
  }

  _initUserToMap(id) {
    if (!this.users.has(id)) {
      this.users.set(
        id,
        observable.object({
          isLoaded: false,
          loaded: false,
          userId: id,
          user: {},
        })
      );
    }
  }

  /**
   * returns observables of the user { isLoaded, userId, user }
   **/
  fetchById(userIds) {
    return userIds.map((id) => {
      if (!this.users.has(id)) {
        this._initUserToMap(id);
        this.fetchQueue.push(id);
        this.throttledFetch();
      }
      return this.users.get(id);
    });
  }

  fetchPromise(userIds) {
    return Promise.all(
      this.fetchById(userIds).map((observable) => {
        return mobx
          .when(() => observable.isLoaded)
          .then(() => {
            return observable.user;
          });
      })
    );
  }

  fetchOne(userId) {
    return this.fetchById([userId])[0];
  }
}

export default new UserResolver();
