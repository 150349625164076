import axios from "../../services/session/apiClient";

export function getFilterSets({ featureGroup }) {
  return axios.get(`/v5/savedFilterSets/${featureGroup}`).then((resp) => {
    return resp.data;
  });
}

export const createFilterSet = ({ featureGroup, filterSetName, shareWithAccount, query }) => {
  const body = {
    featureGroup,
    query: {
      ...query,
      featureGroup,
    },
    name: filterSetName,
    shareWithAccount,
  };

  return axios.post(`/v5/savedFilterSets/createSet`, body).then((resp) => resp.data);
};

export const updateFilterSet = ({ featureGroup, filterSetId, filterSetName, shareWithAccount, query }) => {
  const body = {
    featureGroup,
    query: {
      ...query,
      featureGroup,
    },
    id: filterSetId,
    name: filterSetName,
    shareWithAccount,
  };

  return axios.post(`/v5/savedFilterSets/updateSet`, body).then((resp) => resp.data);
};

export const deleteFilterSet = ({ filterSetId }) => {
  return axios.delete(`/v5/savedFilterSets/${filterSetId}`);
};
