import * as mobx from "mobx";
import { debounce } from "lodash";

/**
 * sets an observable flag isActive that is false when the user has been inactive for timeout (default 1 minute)
 */
export class UserActivityStore {
  constructor(timeout = 60 * 1000) {
    mobx.makeObservable(this, {
      _isActive: mobx.observable,
      setActive: mobx.action.bound,
    });

    let t;
    const resetTimer = debounce(() => {
      if (!this.isActive) {
        this.setActive(true);
      }
      clearTimeout(t);
      t = setTimeout(() => this.setActive(false), timeout);
    }, 500);
    const events = ["load", "mousemove", "mousedown", "touchstart", "click", "scroll", "keypress"];
    events.forEach((x) => window.addEventListener(x, resetTimer, true));
    this.dispose = () => {
      events.forEach((x) => window.removeEventListener(x, resetTimer, true));
    };
  }

  _isActive = true;
  setActive(isActive) {
    if (isActive !== this._isActive) {
      if (isActive) {
        console.info("Entering active state");
      } else {
        console.info("Entering idle state");
      }
      this._isActive = isActive;
    }
  }
  get isActive() {
    return this._isActive;
  }
}

export default new UserActivityStore();
