import type { SiteMap } from "../../navigator/RouteNode";

export default {
  siteMapTitle: "Settings",
  drillIn: true,
  routes: [
    {
      path: "/admin/v2/user",
      title: "My Profile",
      icon: "fas fa-user",
      sectionTag: "Account",
    },
    {
      path: "/admin/v2/accounts",
      title: "Accounts",
      hasPageTitleOverride: true,
      icon: "fas fa-globe-americas",
      sectionTag: "Account",
    },
    {
      path: "/admin/v2/groups",
      title: "Location Groups",
      icon: "fas fa-map-marker-plus",
      sectionTag: "Account",
      hasPageTitleOverride: true,
    },
    {
      path: "/admin/v2/users",
      title: "Users",
      icon: "fas fa-users",
      sectionTag: "Account",
    },
    {
      path: "/admin/v2/userRoles",
      title: "User Roles",
      icon: "fas fa-wrench",
      sectionTag: "Account",
      hasPageTitleOverride: true,
    },
    {
      path: "/admin/v2/competitors",
      title: "Competitors",
      icon: "fa-solid fa-scale-unbalanced",
      sectionTag: "Account",
    },
    {
      path: "/admin/v2/credentials",
      title: "Third-Party Credentials",
      icon: "fas fa-key",
      sectionTag: "Features",
      children: [
        {
          path: "/admin/v2/credentials/list",
          title: "Credentials",
        },
        {
          path: "/admin/v2/credentialPages",
          title: "Pages",
        },
      ],
    },
    {
      path: "/admin/v2/notificationSettings",
      title: "Notifications",
      icon: "fas fa-bell",
      sectionTag: "Features",
    },
    {
      path: "/admin/v2/notifications/settings",
      title: "Notifications",
      icon: "fas fa-bell",
      sectionTag: "Features",
    },
    {
      path: "/admin/v2/brand",
      title: "Theme",
      icon: "fas fa-paint-brush-alt",
      sectionTag: "Features",
    },
    {
      path: "/workflow2/settings",
      title: "Tasks",
      icon: "fas fa-cog",
      sectionTag: "Features",
    },
    {
      path: "/admin/v2/manageScoring",
      title: "LBV Score",
      icon: "fas fa-trophy",
      sectionTag: "Features",
    },
    {
      path: "/admin/v2/manageTemplates",
      title: "Local Page Templates",
      icon: "fas fa-cloud-upload",
      sectionTag: "Features",
    },
    {
      path: "/admin/v2/customProviders",
      title: "Custom Providers",
      icon: "fas fa-th-list",
      sectionTag: "Features",
    },
    {
      path: "/analytics/bi-integration",
      title: "BI Integration",
      hasPageTitleOverride: true,
      icon: "fas fa-lightbulb",
      sectionTag: "Features",
    },
    {
      path: "/admin/v2/instantAuditsUsage",
      title: "Instant Audit Usage",
      icon: "far fa-chart-bar",
      sectionTag: "Features",
    },
    {
      path: "/admin/v2/upload",
      title: "Upload Tool",
      icon: "fas fa-cloud-upload",
      sectionTag: "Internal Tools",
    },
    {
      path: "/admin/v2/localPagesUploader",
      title: "Upload Local Pages",
      icon: "fas fa-cloud-upload",
      sectionTag: "Internal Tools",
    },
    {
      path: "/admin/v2/aiReviewResponseAudit",
      title: "Download AI Review Response Audit",
      icon: "fas fa-cloud-arrow-down",
      sectionTag: "Internal Tools",
    },
    {
      path: "/admin/v2/locations/searchToAdd",
      title: "Locations Search",
      icon: "fas fa-map-marker-alt",
      sectionTag: "Internal Tools",
    },
    {
      path: "/chatmeterAdminRedirect",
      title: "System Tools",
      icon: "fas fa-dollar-sign",
      sectionTag: "Internal Tools",
    },
  ],
} satisfies SiteMap;
