import React from "react";
import classnames from "classnames";

export const Stars = ({ id, rating }) => {
  let stars = [];
  for (let i = 1; i <= 5; i++) {
    const classes = {
      fa: true,
      rating: true,
      "fa-star": i <= rating,
      "fa-star-o": i > rating,
      "rating-poor": rating < 3,
      "rating-average": rating === 3,
      "rating-good": rating > 3,
    };
    stars.push(<i key={`${id}-star-${i}`} className={classnames(classes)} />);
  }

  return <span className="star-rating">{stars}</span>;
};
