import React, { useContext } from "react";
import HorizontalPageFiltersLayout from "./HorizontalPageFiltersLayout";
import { PAGE_FILTERS } from "../navigator/navigatorPortalNames";
import RenderToPortal from "../../../components/portals/RenderToPortal";
import PropTypes from "prop-types";
import SavedFilterSetFilters from "../../savedFilterSets/SavedFilterSetFilters";
/**
 * A generic component that renders filters according to the configured filter layout.
 * Also optionally renders filters to a distant DOM location rather than inline if configured to do so
 * by the AppLayout.
 *  *
 * ```javascript
 * <PageFilters>
 *    <MyAwesomeSelect selected={value} onSelected={onValueChanged} options={myAwesomeOptions} />
 * </PageFilters>
 * ```
 */
export function PageFilters({ onClearFilters, savedFilterSet, onSaveFilters, global, children }) {
  const { PageFiltersLayout } = useContext(PageFiltersContext);
  return (
    <RenderToPortal id={PAGE_FILTERS} recoverInline>
      <PageFiltersLayout
        onClearFilters={onClearFilters}
        onSaveFilters={onSaveFilters}
        savedFilterSet={savedFilterSet}
        global={global}
      >
        {children}
      </PageFiltersLayout>
    </RenderToPortal>
  );
}

PageFilters.propTypes = {
  onClearFilters: PropTypes.func,
  onSaveFilters: PropTypes.func,
  savedFilterSet: PropTypes.shape(SavedFilterSetFilters),
  global: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

/**
 * Context that AppLayouts can override in order to specify the PageFilter layout rendering component.
 * @param PageFiltersLayout
 * Component responsible for the filters layout.
 * @param isHorizontal
 * Flag to conditionally render based off of the orientation
 */
export const PageFiltersContext = React.createContext({
  PageFiltersLayout: HorizontalPageFiltersLayout,
  isHorizontal: true,
});

export function ClearFiltersButton({ onClick = () => {} }) {
  return (
    <button className="btn btn-link w-auto ml-auto" onClick={onClick}>
      Clear Filters
    </button>
  );
}

ClearFiltersButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
