import React, { Suspense } from "react";
import { observer } from "mobx-react";

import { LoginRouteGuard } from "./LoginRouteGuard";
import { HashRouter, Route } from "react-router-dom";
import { install as installPlugins } from "./plugins/install";
import ThemeStore from "../../services/config/ThemeStore";
import { DefaultToastProvider } from "../../components/toasts/Toasts";
import ModalComponent from "../../components/modal/ModalComponent";
import { ThemeEditor } from "../theme/ThemeEditor";
import { BindWithUrl } from "../../util/BindWithUrl";
import { FieldDescriptors, FieldType } from "../../util/urlSync";
import Split from "react-split";
import AppRoutes from "./AppRoutes";
import { Spinner } from "../../components";
import { AngularContext } from "./Angular";
import { WindowLocationListener } from "./WindowLocationListener";
import { PersonaFormModal } from "../../components/Forms/PersonaFormModal";
import startSessionActivityHeartbeat from "../../services/session/sessionActivityHeartbeat";
import useHighchartsTheme from "../../modules/theme/useHighchartsTheme";
import config from "../../config";

/**
 * Topmost component. Not much more to say, other than,
 * This component is very bound to the application (not very testable).
 * Its responsible primarily for "wiring things up."
 *
 * add content here with caution. This could easily grow into a
 * file that does too much.
 */
const AppContainer = observer(
  class AppContainer extends React.Component {
    cancelSessionHeartbeat = () => {};

    componentDidMount() {
      installPlugins();
      this.cancelSessionHeartbeat = startSessionActivityHeartbeat();
    }
    componentWillUnmount() {
      this.cancelSessionHeartbeat();
    }

    render() {
      const content = (
        <div className="m-0 p-0 position-relative" style={{ height: "100%" }} data-chatmeter-version={config.version}>
          <DefaultToastProvider>
            <ModalComponent />
            <AngularContext.Provider value={ClientDashboardApp}>
              <HashRouter>
                {
                  // put things in routes to workaround mobx shouldUpdateComponent overrides
                  // https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
                }
                <Route
                  render={(props) => (
                    <LoginRouteGuard
                      {...props}
                      next={
                        <React.Fragment>
                          <AppRoutes
                            {
                              ...props /* pass location props to force component updates, otherwise observer prevents update */
                            }
                          />
                          <WindowLocationListener />
                          <PersonaFormModal {...props} />
                        </React.Fragment>
                      }
                    />
                  )}
                />
              </HashRouter>
            </AngularContext.Provider>
          </DefaultToastProvider>
        </div>
      );
      return (
        <React.Fragment>
          <div className="m-0 p-0" style={{ height: "100%" }}>
            <BindWithUrl
              observable={ThemeStore}
              safe
              fieldDescriptors={FieldDescriptors.build({
                isPreviewMode: FieldType.bool.withDefaultValue(false),
              })}
            />
            {ThemeStore.isPreviewMode && ThemeStore.isLoaded ? (
              <Split sizes={[75, 25]} className="split-column-container d-flex">
                <div className="split-column">{content}</div>
                <div className="split-column h-100 p-0">
                  <ThemeEditor sidebar />
                </div>
              </Split>
            ) : (
              content
            )}
          </div>
        </React.Fragment>
      );
    }
  }
);

//Embedded components load a nested top nav if AppRoutes/loadable is used instead of angularLoadable for Angular controllers embedded in a React container
function angularLoadable(Component) {
  return function AngularLoadable(props) {
    return (
      <Suspense fallback={<Spinner center />}>
        <Component {...props} />
      </Suspense>
    );
  };
}

const ClientDashboardApp = angularLoadable(React.lazy(() => import("../../uiMigration/ClientDashboardApp")));

export default AppContainer;
