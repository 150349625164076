import React from "react";
import { useToasts, ToastProvider } from "react-toast-notifications";
import BootstrapToast from "./BootstrapToast";

let ref;

/**
 * component must be placed on the page within a ToastProvider in order to link up the ToastService
 */
export default function ToastWrapper() {
  let toasts = useToasts();
  const showToast = (level) => (title, message, opts) => {
    if (typeof message === "string") {
      toasts.addToast(message, { title, level, ...opts });
    } else {
      // move arguments over one if title is skipped, title is actually message, and message is opts
      toasts.addToast(title, { message, level, ...opts });
    }
  };
  ref = {
    error: showToast("error"),
    info: showToast("info"),
    success: showToast("success"),
    warning: showToast("warning"),
    notification: showToast(),
    clear: () => toasts.removeAllToasts(),
  };
  return null;
}

/**
 * default configuration from react-toast-notifications toast provider.
 * ToastProvider provides access to the useToasts interface
 * */
export function DefaultToastProvider(props) {
  return (
    <ToastProvider autoDismiss autoDismissTimeout={5000} components={{ Toast: BootstrapToast }}>
      <ToastWrapper />
      {props.children}
    </ToastProvider>
  );
}

const ToastService = {
  error(title, message, opts = {}) {
    ref && ref.error(title, message, { ...opts });
  },

  info(title, message, opts = {}) {
    ref && ref.info(title, message, { ...opts });
  },

  success(title, message, opts = {}) {
    ref && ref.success(title, message, { ...opts });
  },

  warning(title, message, opts = {}) {
    ref && ref.warning(title, message, { ...opts });
  },

  notification(message, opts = {}) {
    ref && ref.notification(undefined, message, { containerClassNames: { "notification-growable": true }, ...opts });
  },

  clear() {
    ref && ref.clear();
  },

  test() {
    for (const level of ["error", "info", "success", "warning", "notification"]) {
      ToastService[level](
        "Toast Title",
        `This is a test of a${level === ("error" || "info") ? "n" : ""} ${level} toast description`,
        { autoDismiss: false }
      );
    }
  },
};

window.toastService = ToastService;

export { ToastService };
