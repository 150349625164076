// various global styles
import "./components/index.scss";
import "./styles/main.scss";
import "./styles/library-styles.scss";
import "./styles/css3.css";

import "./config"; // has some side affects to window
// // some legacy libraries need or at least really like to be installed to the global window
import "./globals";

import * as React from "react";
import AppContainer from "./modules/app/AppContainer";
import UnrecoveredErrorHandler from "./modules/app/UnrecoveredErrorHandler";

export default function App() {
  return (
    <UnrecoveredErrorHandler>
      <AppContainer />
    </UnrecoveredErrorHandler>
  );
}
