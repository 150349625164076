import React from "react";

import * as mobx from "mobx";
import { FieldDescriptors, FieldType } from "../../util/urlSync";
import { getFilterSets } from "./savedFilterSetAPI";
import { FilterSetSelector } from "./FilterSetSelector";
import { isEqual, sortBy } from "lodash";

export const defaultFilterSet = Object.freeze({
  filterSetId: undefined,
  filterSet: undefined,
});

export const savedFilterSetBindingDefaults = {
  filterSetId: undefined,
};

export const savedFilterSetBindings = {
  filterSetId: FieldType.string,
};

/**
 * Holds the logic for handling the filter set hell
 */
export default class SavedFilterSetFilters {
  /**
   * To use the saved filter set filters, you will need pass the following parameters in:
   *
   * @param featureGroup - the root of which the filters apply to. i.e. Pulse, Social, Reviews, etc...
   * @param setFilter - a function to call in order to set filters.
   * @param getFilters - a function to get the filters. If you have an observable for the filters, then simply return that.
   *                      it is a function as it allows you to store your filters however you please.
   * @param defaultFilterSetFilters - the default filters that are contained within the filter set, these are to be able to clear the filters correctly.
   */
  constructor({ featureGroup, setFilter, getFilters, defaultFilterSetFilters, isEditable = true }) {
    mobx.makeObservable(this, {
      loaded: mobx.observable,
      resetFilters: mobx.action,
      setFilterSet: mobx.action,
      setFilterSetFromSet: mobx.action,
    });

    // TODO: Make these params have types
    this.featureGroup = featureGroup;
    this.setFilter = setFilter;
    this.getFilters = getFilters;
    this.defaultFilterSetFilters = defaultFilterSetFilters;
    this.loaded = !this.getFilters().filterSetId;
  }

  loaded = false;

  /**
   * Instead of resetting filters controlled by filter sets, use the reset filter function to reset them
   * This will ensure that the filter set being used, and the default filters are applied to the filters that
   * the filter set controls.
   *
   * This is really to catch the case in which you forget to apply the default filter set to your filter set--or
   * rather, keeps you from having to make your code super depended on this.
   */
  resetFilters = () => {
    const updatedFilters = {
      ...mobx.toJS(defaultFilterSet),
      ...mobx.toJS(this.defaultFilterSetFilters),
    };
    this.loaded = true;
    this.setFilter(updatedFilters);
  };

  /**
   * Set the filter set and subsequent filters.
   * Since the search to select by key passes in a key, be sure to
   * always get the most up to date value from the main cache and apply that.
   */
  setFilterSet = (id) => {
    this.loaded = false;
    this.getFilterSetFromId(id).then((input) => {
      this.loaded = true;
      return this.setFilterSetFromSet(input);
    });
  };

  /**
   * This is being used by the manage filter sets, to ensure everything stays in sync between the drop down options and the management popover.
   */
  setFilterSetFromSet = (input) => {
    this.filterSetCache = {};
    const cleanedInput = !input || input.default ? undefined : input;
    let id = !!cleanedInput && !!cleanedInput.query ? cleanedInput._id : undefined;
    let query = !!cleanedInput && !!cleanedInput.query ? cleanedInput.query : this.defaultFilterSetFilters;

    this.setFilter({ ...mobx.toJS(this.defaultFilterSetFilters), ...query, filterSetId: id, filterSet: cleanedInput });
  };

  filterSetCache = {};

  /**
   * The main cache of the filter sets to make sure we aren't needlessly making requests to the server.
   * The filterSetCache will be cleared on updates to make sure values are always up to date.
   */
  fetchAll = () => {
    if (isEqual(this.filterSetCache, {})) {
      this.filterSetCache = getFilterSets({ featureGroup: this.featureGroup })
        .then((data) => {
          let map = [];
          data.map((x) => map.push({ ...x, _id: x._id.$oid }));
          return map;
        })
        .catch((ex) => {
          this.filterSetCache = {};
          throw ex;
        });
    }
    return this.filterSetCache;
  };

  /**
   * This is used when the selected filter set is changed to make sure the params that are being applied are not stale.
   */
  getFilterSetFromId = (id) => {
    let maybe = undefined;
    return this.fetchAll().then((filters) => {
      if (!!filters && filters.length > 0 && !!id) {
        maybe = filters.filter((x) => x._id === id);
      }
      return !!maybe && maybe.length > 0 && maybe[0];
    });
  };

  savedFilterSetPageFilter = () => {
    return (
      <FilterSetSelector
        options={this.fetchAll}
        featureGroup={this.featureGroup}
        onSelected={(value) => this.setFilterSet(value)}
        selected={this.getFilters().filterSetId}
        columnWeight={2}
        filterSetConfig={this.getFilters()}
        updateFilterSets={() => {
          this.filterSetCache = {};
        }}
        setFilterSet={(value) => this.setFilterSetFromSet(value)}
      />
    );
  };
}
