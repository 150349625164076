import React, { CSSProperties } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

export interface RegionCardBodyProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  className?: string;
  classNames?: string;
  children?: React.ReactNode;
  style?: CSSProperties;
  hasHeader?: boolean;
  hasFooter?: boolean;
}
const RegionCardBody = ({
  className = "",
  classNames = "",
  children,
  style = {},
  /**
   * Cards usually have headers, but not footers.
   */
  hasHeader = true,
  hasFooter = false,
  ...rest
}: RegionCardBodyProps) => {
  return (
    <div
      className={cx(className, classNames, "card-body", {
        "card-headerless": !hasHeader,
        "card-footerless": !hasFooter,
        "card-only-child": !hasHeader && !hasFooter,
      })}
      style={{ ...style }}
      {...rest}
    >
      {children}
    </div>
  );
};

RegionCardBody.propTypes = {
  /**
   * Adds styles handle headerless cards
   */
  hasHeader: PropTypes.bool,
  /**
   * Adds styles handle footerless cards
   */
  hasFooter: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  /**
   * @deprecated Use className instead
   */
  classNames: PropTypes.string,
  children: PropTypes.node,
};

export default RegionCardBody;
