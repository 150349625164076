import apiClient from "../session/apiClient";
import { BrandConfig, ThemeConfig, ThemeConfigPatch } from "./BrandConfig";

export function getBrandConfigFromHost(hostname: string): Promise<BrandConfig> {
  return apiClient.get("/brand/config/" + hostname).then((x) => x.data as BrandConfig);
}

export function getThemeConfigFromHost(hostname: string): Promise<ThemeConfig> {
  return apiClient.get("/brand/theme/" + hostname).then((x) => x.data);
}

export function getBrandConfig(): Promise<BrandConfig> {
  return apiClient.get("/brand/config").then((x) => x.data);
}

export function getThemeConfig(): Promise<ThemeConfig> {
  return apiClient.get("/brand/theme").then((x) => x.data);
}

export function getDefaultThemeConfig(): Promise<ThemeConfig> {
  return apiClient.get("/brand/defaultTheme").then((x) => x.data);
}

export function setThemeConfig(themeConfig: ThemeConfigPatch): Promise<ThemeConfig> {
  return apiClient.post("/brand/theme", themeConfig).then((x) => x.data);
}

export function validateAppleDelegationCompanyId(companyId: string): Promise<boolean> {
  return apiClient.get("/config/isAppleCompanyIdDelegated?companyId=" + companyId).then((response) => response.data);
}

export async function saveLogo({ logo }: { logo: any }): Promise<string> {
  const formData = new FormData();
  formData.append("file", logo);
  formData.append("imageType", "Logo");

  const saveImageResponse = await apiClient.post("/image/upload", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  const imageUrl = saveImageResponse.data as string;
  await apiClient.post("/image/save", { imageUrl, imageType: "Logo" });
  return imageUrl;
}
