import { installScript, onceScriptLoaded } from "./scriptUtil";
import BrandConfigStore from "../../../services/config/BrandConfigStore";
import SessionStore from "../../../services/session/SessionStore";
import * as localStorage from "../../../services/storage/localStorage";
import * as mobx from "mobx";
import "./localizePlugin.css";
/**
 *
 *
 *
 * if imported, this module will watch the brand config store, and, if locale is enabled,
 * will load the locale plugin and set the configured language
 *
 */

let lazyLoad = () => {
  let p = onceScriptLoaded(installScript("https://cdn.localizejs.com/localize.js"), () => !!window["Localize"]);
  lazyLoad = () => p;
  return lazyLoad();
};

export default function getLocalizeInstance() {
  return window.Localize;
}

function getBrowserLocale() {
  let s = window.navigator.language || window.navigator.userLanguage;
  return s && s.substring(0, 2);
}

function getBrandLocale() {
  let found = BrandConfigStore.brandConfig.localeInfo.find((x) => x.isDefault);
  return found && found.languageCode;
}

const getStorageKey = () => "userLocale:" + ((SessionStore.user && SessionStore.user.userId) || "default");
function getPersistedLocale() {
  return localStorage.getItem(getStorageKey());
}

function persistLocale(locale) {
  localStorage.setItem(getStorageKey(), locale);
}

function getDefaultLocale() {
  return "en";
}

function initializeLanguage() {
  if (useLocalize()) {
    lazyLoad().then(() => {
      const Localize = window.Localize;

      Localize.initialize({
        key: "mrLCMKA1bqBti",
        rememberLanguage: true,
        saveNewPhrases: true,
        translateBody: true,
      });

      for (let getter of [getPersistedLocale, getBrandLocale, getBrowserLocale, getDefaultLocale]) {
        try {
          let locale = getter();
          if (locale) {
            Localize.setLanguage(locale);
            break;
          }
        } catch (e) {}
      }

      Localize.on("setLanguage", function () {
        persistLocale(Localize.getLanguage());
      });
    });
  } else {
    // theoretically should unload
  }
}

function useLocalize() {
  return (
    !!BrandConfigStore.brandConfig &&
    !!BrandConfigStore.brandConfig.restrictions &&
    !BrandConfigStore.brandConfig.restrictions.hideLocalize &&
    !!(SessionStore.user && SessionStore.user.userId)
  );
}

let disposer;
export function initialize() {
  uninitialize();
  disposer = mobx.reaction(
    // when the brand config localize pref changes or the user id changes
    () => useLocalize(),
    () => initializeLanguage()
  );
}

export function uninitialize() {
  disposer && disposer();
}
