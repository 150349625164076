import React from "react";
import { observer } from "mobx-react";
import { Popover, PopoverBody, PopoverHeader } from "reactstrap";
import moment from "moment";
import { Spinner } from "../../components";
import notificationsStore from "./NotificationsStore";
import NotificationListItem from "./NotificationListItem";
import LinkBuilder from "./LinkBuilder";
import defaultSessionStore from "../session/SessionStore";
import NotificationsSocket from "./NotificationsSocket";
import { ToastService } from "../../components/toasts/Toasts";
import { recordEvent } from "../../modules/app/plugins/metricPlugin";
import PropTypes from "prop-types";
import { getNotificationVerb, getObjectType } from "./Notifications";

export const NotificationList = observer(
  class NotificationList extends React.PureComponent {
    static propTypes = {
      unreadNotificationCountMax: PropTypes.number,
    };

    static defaultProps = {
      unreadNotificationCountMax: 99,
    };

    socket;
    state = {
      open: false,
      hadChromeDome: false,
    };

    constructor(props) {
      super(props);
      this.componentRef = React.createRef();
      this.listRef = React.createRef();
    }

    componentWillMount() {
      if (!notificationsStore.isInitializing) {
        this.socket = new NotificationsSocket(defaultSessionStore);
        this.socket.messageCallback = this.onMessage;
        notificationsStore.init();
      }
    }

    getVerb(eventType) {
      return getNotificationVerb(eventType);
    }

    getObjectType(eventType) {
      return getObjectType(eventType);
    }

    onMessage = (notification) => {
      const words = `A ${this.getObjectType(notification.trigger.eventType)} matching ${
        notification.name
      } ${this.getVerb(notification.trigger.eventType)}`;
      const message = (
        <div>
          <div>{words}</div>
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-sm btn-secondary ml-auto mt-2"
              onClick={() => {
                this.followAndDismissNotification(notification);
              }}
            >
              View
            </button>
          </div>
        </div>
      );
      if (notificationsStore.notificationPreferences?.dashboard && notification.trigger.channels.popups) {
        ToastService.notification(message);
      }
      notificationsStore.processSocketNotification(notification);
    };

    followAndDismissNotification = (notification) => {
      recordEvent("NotificationClicked", { viewType: "toast", eventType: notification.trigger.eventType });
      const url = LinkBuilder.fromTrigger(notification.trigger);
      this.dismissNotification(notification);
      ToastService.clear();
      if (url) {
        window.location.href = url;
      }
    };

    dismissNotification = (notification, record = false) => {
      record &&
        recordEvent("NotificationDismissed", {
          viewType: "list",
          eventType: notification.trigger.eventType,
          count: notification.count,
        });
      notificationsStore.dismissNotification(notification);
      this.triggerResize();
    };

    triggerResize() {
      window.dispatchEvent(new Event("resize"));
    }

    onClearAll() {
      recordEvent("NotificationClearAll", { count: notificationsStore.countUnread });
      notificationsStore.dismissAll();
      window.dispatchEvent(new Event("resize"));
    }

    displayedNotificationCount() {
      return notificationsStore.countUnread > this.props.unreadNotificationCountMax
        ? this.props.unreadNotificationCountMax + "+"
        : notificationsStore.countUnread;
    }

    render() {
      return (
        <div
          className="d-flex align-items-center"
          style={{ position: "relative" }}
          data-analyticsclick="Appbar Notification List"
          data-analyticsdata={JSON.stringify({ count: notificationsStore.countUnread })}
        >
          <a id="notification-trigger" className="header-button" onClick={this.toggle}>
            <div className="d-flex align-items-center">
              <i className="fas fa-bell d-flex justify-content-center m-auto" />
            </div>
          </a>
          {!notificationsStore.isLoading && !!notificationsStore.notifications.length && (
            <div className="badge badge-secondary notification-badge">{this.displayedNotificationCount()}</div>
          )}
          <Popover
            trigger="legacy"
            positionFixed={true}
            popperClassName="notifications-popper"
            className="notification-popover"
            placement="top-end"
            fade={false}
            isOpen={this.state.open}
            target="notification-trigger"
            toggle={this.toggle}
          >
            {this.state.open && !notificationsStore.isLoading && (
              <div ref={this.componentRef}>
                <div className="card">
                  <div className="card-header d-flex align-items-center">
                    <h4>Notifications</h4>
                    <button
                      type="button"
                      className="btn btn-link ml-auto p-0"
                      disabled={!notificationsStore.notifications.length}
                      onClick={() => this.onClearAll()}
                    >
                      Clear All
                    </button>
                  </div>
                  <div className="card-body notification-list border-top">
                    {notificationsStore.isLoading ? <Spinner center={true} size={"md"} /> : ""}
                    {
                      // Empty notificaiton list message
                      !notificationsStore.notifications.length && (
                        <div className="notification-item notification-item--empty border-bottom">
                          <span>There are no active notifications</span>
                        </div>
                      )
                    }
                    {notificationsStore.notifications.map((notification, index) => (
                      <NotificationListItem
                        notification={notification}
                        objectType={this.getObjectType(notification.trigger.eventType)}
                        verb={this.getVerb(notification.trigger.eventType)}
                        dismissNotification={this.dismissNotification}
                        followAndDismissNotification={this.followAndDismissNotification}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </Popover>
        </div>
      );
    }

    loadMore() {
      notificationsStore.loadMore();
    }

    toggle = () => {
      !this.state.open && recordEvent("NotificationViewList", { count: notificationsStore.countUnread });
      this.setState((s) => ({ open: !s.open }));
    };
  }
);

export default new NotificationList();
