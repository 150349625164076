import { useEffect } from "react";
import { RiskCategory, getRiskCategories } from "../api";
import RiskCategoryStore from "../state/RiskCategoryStore";

const useRiskCategory = (): RiskCategory[] => {
  const { riskCategories } = RiskCategoryStore.state;

  useEffect(() => {
    if (riskCategories.length === 0) {
      getRiskCategories().then((data) => RiskCategoryStore.setState({ riskCategories: data }));
    }
  }, []);

  return riskCategories;
};

export const RiskyKey = "risky";
export const RiskyLabel = "Risky";
export const NotRiskyKey = "not_risky";
export const NotRiskyLabel = "Not Risky";
export const additionalRiskCategoryOptions = [
  { key: RiskyKey, value: RiskyLabel },
  { key: NotRiskyKey, value: NotRiskyLabel },
];

export default useRiskCategory;
