import { Placement } from "@popperjs/core";
import React, { ReactNode, useRef } from "react";
import { UncontrolledTooltip } from "reactstrap";

interface TooltipProps {
  children: ReactNode;
  title?: string;
  placement: Placement;
  className?: string;
}

const Tooltip = ({ title, children, placement, className }: TooltipProps) => {
  if (!title) {
    return <span className={className}>{children}</span>;
  }

  const ref = useRef<HTMLElement>(null);

  return (
    <span className={className}>
      <span ref={ref}>{children}</span>
      <UncontrolledTooltip placement={placement} target={ref}>
        {title}
      </UncontrolledTooltip>
    </span>
  );
};

export default Tooltip;
