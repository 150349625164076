import * as FAC from "../../modules/app/site/featureAccessControl";
import { WorkflowTask } from "./types";

/**
 * DFL-740. Bit of a hack but tasks have the provider name persisted in the
 * task name title & description. Need to modify review builder tasks to
 * display surveys when survey feature flag is enabled.
 */
export function modifyTaskDescriptions(tasks: WorkflowTask[]) {
  return tasks.map((t) => {
    if (FAC.surveysAllowed() && t.provider === "REVIEWBUILDER") {
      return {
        ...t,
        content: {
          ...t.content,
          taskName: t.content.taskName.replace("Review Builder", "Survey"),
          description: t.content.description.replace("Review Builder", "Survey"),
        },
      };
    } else return t;
  });
}
