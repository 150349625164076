import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { Spinner } from "../../components";
import ThemeStore from "../../services/config/ThemeStore";
import { AllVariablesEditor } from "./components/AllVariablesEditor";
import { BrandColors } from "./components/BrandColors";
import { LogoEditor } from "./components/LogoEditor";
import ThemeEditorStore from "./ThemeEditorStore";
import { ThemeSelector } from "./ThemeSelector";
import { recordEvent } from "../app/plugins/metricPlugin";
import "./themeEditor.scss";
import FoldyThemePreview from "./FoldyThemePreview";

export const ThemeEditor = observer(
  class ThemeEditor extends React.Component {
    static propTypes = {
      sidebar: PropTypes.bool,
    };

    componentDidMount() {
      ThemeEditorStore.mount();
      // restore preview if there was one
      if (!ThemeStore.preview && !this.props.sidebar) {
        ThemeEditorStore.applyTheme();
      }
    }
    componentWillUnmount() {
      ThemeEditorStore.unmount();
      if (!ThemeStore.isPreviewMode && !this.props.sidebar) {
        // clear the preview when leaving the non-sidebar version of the component
        ThemeStore.clearPreview();
      }
    }

    renderButtons() {
      const sidebar = this.props.sidebar;
      const loading = ThemeEditorStore.loading;
      return (
        <div className="d-flex flex-row content-spacer-top pb-5 theme-preview-toolbar">
          <button
            className="btn btn-outline-secondary flex-1"
            onClick={ThemeEditorStore.cancel}
            disabled={!this.props.sidebar && !ThemeEditorStore.hasChanges}
          >
            {!sidebar ? "Cancel" : "Exit Preview Mode"}
          </button>
          {!sidebar && (
            <button
              className="btn btn-outline-secondary control-spacer-left flex-1"
              onClick={ThemeEditorStore.startPreviewMode}
            >
              Preview Mode
            </button>
          )}
          <button
            className="btn btn-secondary control-spacer-left flex-1"
            disabled={loading || !ThemeEditorStore.hasChanges || !ThemeEditorStore.isValid}
            onClick={() => {
              recordEvent("SaveTheme");
              ThemeEditorStore.saveTheme();
            }}
          >
            Save &amp; Apply
          </button>
        </div>
      );
    }

    render() {
      const { loading, initialLoading, debugMode, activeTab, themeId, logoError, logoUrl, logoFile } =
        ThemeEditorStore.state;
      const { variables } = ThemeEditorStore.state.theme || {};
      const sidebar = this.props.sidebar;
      const saveBottom = activeTab === "logo" || sidebar;

      const headerStyles = sidebar ? { background: "var(--card-bg)" } : {};
      return (
        <React.Fragment>
          <div className={`card theme-editor ${sidebar ? "theme-editor-sidebar-mode rounded-0" : ""}`}>
            <div
              className={`${sidebar ? "card-padding" : "card-header"}`}
              style={{
                ...headerStyles,
              }}
            >
              <div className="d-flex align-items-center">
                {sidebar && (
                  <React.Fragment>
                    <h2 className="card-title-lg">Theme</h2>
                    <button
                      type="button"
                      className="m-0 p-0 close ml-auto"
                      style={{ color: `var(--body-color)`, textShadow: "none", opacity: "1" }}
                      onClick={ThemeEditorStore.endPreviewMode}
                      aria-label="Close"
                    >
                      <span aria-hidden="true" style={{ color: "var(--card-cap-color)", textShadow: "none" }}>
                        ×
                      </span>
                    </button>
                  </React.Fragment>
                )}
              </div>
              <div className={`d-flex flex-column ${sidebar ? "card-spacer-top" : ""}`}>
                <ul className="nav nav-pills mr-auto">
                  {
                    /** nav pills */
                    [
                      { id: "logo", label: "Logo" },
                      { id: "theme", label: "Theme" },
                      { id: "all", label: "Customize" },
                    ].map(({ id, label }) => {
                      return (
                        <li className="nav-item" key={id}>
                          <a
                            onClick={() => ThemeEditorStore.setState({ activeTab: id })}
                            className={`nav-link border-0 ${activeTab === id ? "active" : ""}`}
                          >
                            {label}
                          </a>
                        </li>
                      );
                    })
                  }
                </ul>
              </div>
            </div>
            <div className={`card-body card-footerless `}>
              <div className={`${sidebar ? "" : "row"}`}>
                <div className={`${sidebar ? "" : "col-12 col-md-7 col-lg-5 col-xl-4"}`}>
                  {debugMode && (
                    <React.Fragment>
                      <label>Theme ID</label>
                      <pre>
                        <code>{themeId}</code>
                      </pre>
                    </React.Fragment>
                  )}
                  <React.Fragment>
                    {(loading && initialLoading) || !variables ? (
                      <Spinner />
                    ) : (
                      <React.Fragment>
                        {activeTab === "logo" && (
                          <div className="form-group">
                            <LogoEditor
                              onDropRejected={ThemeEditorStore.handleDropRejected}
                              onDropAccepted={ThemeEditorStore.handleDropAccepted}
                              onDeleteLogo={ThemeEditorStore.onDeleteLogo}
                              error={logoError}
                              logo={logoUrl || ThemeStore.logoUrl}
                              // TODO - figure out relationship with default chatmeter logo.
                              // Needs to be able to determine whether image is the default
                              // right now this only lets you delete a logo if you just added it
                              allowDelete={!!logoFile}
                            />
                          </div>
                        )}
                        {activeTab === "theme" && (
                          <React.Fragment>
                            <div className="form-group">
                              <h5>Select Theme</h5>
                            </div>
                            <div>
                              <ThemeSelector
                                themeId={themeId}
                                onThemeSelected={ThemeEditorStore.onThemeSelected}
                                excludeThemes={
                                  ThemeEditorStore.useFoldyThemeId
                                    ? ["standard", "dark", "dark-bordered"]
                                    : ["bright", "limo"]
                                }
                                additionalThemes={
                                  ThemeEditorStore.useFoldyThemeId
                                    ? [
                                        { name: "Custom", value: ThemeStore.themeConfig.foldyThemeId },
                                        { name: "Custom (Old)", value: ThemeStore.themeConfig.themeId },
                                      ]
                                    : [{ name: "Custom", value: ThemeStore.themeConfig.themeId }]
                                }
                              />
                            </div>
                            <div className="content-spacer-top">
                              <BrandColors
                                onChange={({ name, value, errorMessage }) => {
                                  recordEvent("CustomizeBrandThemeColor", { brandThemeColorName: name });
                                  ThemeEditorStore.handleVariableChange({ name, value, errorMessage });
                                }}
                                invalid={ThemeEditorStore.state.invalidVariables}
                                variables={variables}
                                sidebar={sidebar}
                              />
                            </div>
                          </React.Fragment>
                        )}
                        {activeTab === "all" && (
                          <AllVariablesEditor
                            onChange={({ name, value, errorMessage }) => {
                              recordEvent("CustomizeAdvancedThemeVariable", { advancedThemeVariableName: name });
                              ThemeEditorStore.handleVariableChange({ name, value, errorMessage });
                            }}
                            invalid={ThemeEditorStore.state.invalidVariables}
                            variables={variables}
                            sidebar={sidebar}
                          />
                        )}
                        {saveBottom && this.renderButtons()}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                </div>
                {!saveBottom && (
                  <div className="col ml-3">
                    <h5>Preview</h5>
                    <FoldyThemePreview logoUrl={ThemeStore.logoUrl} variables={variables} />
                    {this.renderButtons()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
);
