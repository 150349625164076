import React from "react";
import PropTypes from "prop-types";
import { VariableGroupEditor } from "./VariableGroupEditor";
import { brandColors, neutrals, chartColors } from "../AllVariables";

export class BrandColors extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    invalid: PropTypes.object,
    variables: PropTypes.object,
    sidebar: PropTypes.any,
  };

  render() {
    const { variables, invalid, onChange, sidebar } = this.props;
    return (
      <div className="card-spacer-top">
        <VariableGroupEditor
          onChange={onChange}
          invalid={invalid}
          variables={variables}
          group={brandColors}
          sidebar={sidebar}
        />
        <VariableGroupEditor
          onChange={onChange}
          invalid={invalid}
          variables={variables}
          group={neutrals}
          sidebar={sidebar}
        />
        <VariableGroupEditor
          onChange={onChange}
          invalid={invalid}
          variables={variables}
          group={chartColors}
          sidebar={sidebar}
        />
      </div>
    );
  }
}
