import emitter from "../eventEmitter";
import { REQUEST_MODAL, CLOSE_MODAL } from "./eventNames";
import React from "react";
import ModalWrapper from "./ModalWrapper";
export default {
  requestModalComponent(component, size, closeModal = () => emitter.emit(CLOSE_MODAL), backdrop = true) {
    emitter.emit(
      REQUEST_MODAL,
      <ModalWrapper closeModal={closeModal} size={size} backdrop={backdrop}>
        {component}
      </ModalWrapper>
    );
  },

  closeModal() {
    emitter.emit(CLOSE_MODAL);
  },

  // TODO - remove V2 suffix
  requestModalV2({ modal }) {
    emitter.emit(REQUEST_MODAL, modal);
  },
};
