import { observable, action, makeObservable } from "mobx";
import { FieldDescriptors, FieldType } from "../../../util/urlSync";

export const urlQueryDefinition = FieldDescriptors.build({
  nameSearch: FieldType.string,
  provider: FieldType.delimitedArrayOf(FieldType.string),
  expiration: FieldType.string,
  sortField: FieldType.string,
  sortOrder: FieldType.string,
  pageNumber: FieldType.int,
});

export interface CredentialFilter {
  nameSearch?: string;
  provider?: string[];
  expiration?: string;
  sortField: string;
  sortOrder: string;
  pageNumber: number;
}

const defaultFilters: CredentialFilter = {
  nameSearch: undefined,
  provider: [],
  expiration: undefined,
  sortField: "remoteAccountNameLowercase",
  sortOrder: "ASC",
  pageNumber: 1,
};

class CredentialFiltersStore {
  state = {
    ...defaultFilters,
  };

  constructor() {
    makeObservable(this, {
      state: observable,
      setFilter: action,
      resetFilters: action,
    });
  }

  setFilter(updates: Partial<CredentialFilter>, callback?: () => void) {
    Object.assign(this.state, updates);
    callback && callback();
  }

  resetFilters() {
    Object.assign(this.state, defaultFilters);
  }
}

const credentialFiltersStore = new CredentialFiltersStore();

export default credentialFiltersStore;
