import * as React from "react";
import { ForgotPasswordView } from "./ForgotPasswordView";
import * as SessionApi from "../SessionApi";
import BrandConfigStore from "../../config/BrandConfigStore";
import { observer } from "mobx-react";

/**
 * manages form state for sending password reset. Actual rendering happens in ForgotPasswordView
 */
export const ForgotPasswordData = observer(
  class ForgotPasswordData extends React.Component {
    state = {
      username: "",
      errorMessage: null,
      emailSent: false,
      isSubmitting: false,
    };

    setUsername = (e) => this.setState({ username: e.target.value, isKnownInvalid: false });

    onSubmit = (e) => {
      e && e.preventDefault();
      this.setState({ isSubmitting: true, errorMessage: null, emailSent: false });
      SessionApi.forgotPasswordEmail(this.state.username)
        .then(() => {
          this.setState({ isSubmitting: false, emailSent: true });
        })
        .catch(() => {
          this.setState({ errorMessage: "Invalid Username" });
        });
    };

    render() {
      const { username, errorMessage, emailSent, isSubmitting } = this.state;
      const { setUsername, onSubmit } = this;
      const brandConfig = BrandConfigStore.brandConfig;

      return (
        <ForgotPasswordView
          username={username}
          errorMessage={errorMessage}
          emailSent={emailSent}
          isSubmitting={isSubmitting}
          logoUrl={!brandConfig || brandConfig.restrictions.hideLogo ? null : brandConfig.logoUrl}
          onUsernameChanged={setUsername}
          onSubmit={onSubmit}
        />
      );
    }
  }
);
