import React from "react";
import { PropTypes } from "prop-types";

export default function SavedFilterSetIcon({ onClick }) {
  return (
    <button
      className="btn fa-lg fa-stack btn-link d-flex align-items-center text-decoration-none p-0 d-flex"
      style={{
        fontSize: ".8rem",
      }}
      onClick={onClick}
    >
      <i
        className="fas fa-folder fa-stack-2x"
        style={{
          fontSize: "1.84rem",
        }}
      ></i>
      <i
        className="fas fa-filter fa-inverse fa-stack-1x"
        style={{
          fontSize: ".08rem",
          top: "1px",
        }}
      ></i>
    </button>
  );
}

SavedFilterSetIcon.propTypes = {
  onClick: PropTypes.func,
};
