import * as React from "react";

class NoAngularAvailable extends React.Component {
  render() {
    return (
      <div className="d-flex h-100" style={{ backgroundColor: "#EEE" }}>
        <div className="card m-auto">
          <div className="card-body">
            <div className="card-title">
              <h4>Nope</h4>
            </div>
            This page is still written in angular and must be run from the <code>dashboard-play</code> ui
          </div>
        </div>
      </div>
    );
  }
}

export const AngularContext = React.createContext(NoAngularAvailable);

/**
 * Abstraction for being able to embed the angular application from within flat cats,
 * e.g. if a page is partially migrated, its container can be implemented in flat-cats,
 * and the rest of the angular application can be dropped in as an <Angular /> component.
 * Dashboard-play provides angular via context, whereas flat cats just renders a dummy info card
 */
export class Angular extends React.Component {
  render() {
    return <AngularContext.Consumer>{(Render) => <Render {...this.props} />}</AngularContext.Consumer>;
  }
}
