import React from "react";

export default ({
  className,
  btnClass,
  btnStyle,
  selected,
  items,
  renderItem,
  renderSelected,
  onSelected = (e) => {},
  getKey,
  menuClassName,
  clearable,
  ...props
}) => {
  // if the arg is a string, turns it into a property accessor function
  const toSelectorFunction = (arg) => (typeof arg === "string" ? (x) => x && x[arg] : arg);
  renderItem = toSelectorFunction(renderItem || ((x) => x));
  renderSelected = toSelectorFunction(renderSelected);

  return (
    <div className={`dropdown ${className || ""}`} {...props}>
      {clearable && selected && (
        <span
          className="dropdown-clear far fa-times"
          style={{
            position: "absolute",
            cursor: "pointer",
            right: "35px",
            top: "8px",
            marginLeft: "5px",
            marginTop: "2px",
            marginBottom: "-2px",
            marginRight: "0px",
            fontSize: "10px",
          }}
          onClick={() => onSelected(null)}
        />
      )}
      <button
        className={`btn dropdown-toggle text-left ${btnClass || ""}`}
        style={{ ...btnStyle }}
        data-toggle="dropdown"
        aria-label={props["aria-label"]}
      >
        {renderSelected ? renderSelected(selected) : renderItem(selected)}
      </button>
      <ul className={`dropdown-menu ${menuClassName || ""}`} aria-labelledby="dropdownMenu1">
        {items.map((x, i) => {
          const key = getKey ? getKey(x, i) : i;
          const isSelected = getKey ? getKey(x) === getKey(selected) : x === selected;
          if (x) {
            switch (x.type) {
              case "header":
                return (
                  <li key={key} className="dropdown-header">
                    {renderItem(x)}
                  </li>
                );
                break;
              case "divider":
                return <li className="divider" key={key} />;
                break;
              default:
                return (
                  <li
                    key={key}
                    className={isSelected ? "dropdown-item disabled active" : "dropdown-item"}
                    onClick={() => onSelected(x)}
                  >
                    <a>{renderItem(x)}</a>
                  </li>
                );
                break;
            }
          }
        })}
      </ul>
    </div>
  );
};
