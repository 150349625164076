import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { imageMimeTypes } from "../../../components/uploadgallery/extensions";

export function LogoEditor(props) {
  return (
    <div className="card-spacer-top">
      <div className="text-center">
        <Dropzone
          className="w-100 mb-4 p-5 border rounded cursor-pointer"
          maxSize={1024 * 1024 * 80}
          accept={imageMimeTypes.join(", ")}
          onDropRejected={props.onDropRejected}
          onDropAccepted={props.onDropAccepted}
        >
          <span>
            Drop a file here
            <br />
            or <a className="text-button">browse</a> to upload.
          </span>
        </Dropzone>
        {props.error && <div className="alert alert-danger">{props.error}</div>}
        {!props.logo ? <h4>No Logo Found!</h4> : <img height="45px" src={props.logo} alt="logo" />}
        {props.allowDelete && <span className="fas fa-trash-alt fa-lg clickable" onClick={props.onDeleteLogo} />}
      </div>
    </div>
  );
}
