import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Redirect, Route, Switch, RouteComponentProps } from "react-router-dom";
import { BarebonesAppPage } from "../BarebonesAppPage";
import { recordEvent } from "../plugins/metricPlugin";
import UnrecoveredErrorBoundary from "../UnrecoveredErrorHandler";
import { AppNavigatorContextValue, EMPTY_NAVIGATION_CONTEXT, RouteNode, SiteMap } from "./RouteNode";
import { AppNavigatorUtils, ConcreteRoute, RouteAction, isRedirectRoute, useAppNavigator } from "./useAppNavigator";

export interface AppNavigatorProps {
  /** defines the tree structure of the overall navigation */
  siteMapTemplate: SiteMap;
  /** alternate site maps to support modifying the navigation on certain routes */
  secondarySiteMaps: SiteMap[];
  /** define all possible routes that site maps can navigate to. If a route is excluded here,
   * but listed in one of the site maps, it will be considered "in-accessible" due to user permission restrictions,
   * and removed from the navigation
   */
  routes: RouteNode[];
  /** if no matching route, will to this url */
  defaultUrl?: string;
  /** a layout to wrap all child pages in */
  Layout: React.ComponentType;
  /** If the layout needs to retain state across pages, set the context provider with this prop */
  LayoutContextProvider: React.ComponentType;
}

type SelectedRoute = {
  route: ConcreteRoute;
  props: RouteComponentProps;
  context: AppNavigatorContextValue;
};

/**
 * This component takes routes and a site map in order to build the site navigation state.
 *
 * It routes the subcomponent to render, and provides transient navigation context.
 * It does not render content, and tries to stay away from any opinions about how things should be rendered,
 * just the semantic intention.
 *
 * @constructor
 */
export default function AppNavigator({
  siteMapTemplate = {
    routes: [],
  },
  secondarySiteMaps = [],
  routes = [],
  defaultUrl,
  Layout = BarebonesAppPage as React.ComponentType,
  LayoutContextProvider = React.Fragment,
}: AppNavigatorProps): React.ReactElement {
  const appNavigation = useAppNavigator({
    sitemap: siteMapTemplate,
    secondarySitemaps: secondarySiteMaps,
    routes,
  });

  const defaultOrFirstUrl = defaultUrl || appNavigation.defaultPath;

  const [matchedRoute, setMatchedRoute] = useState<SelectedRoute | undefined>();

  const Component = useMemo<React.ComponentType<RouteComponentProps> | undefined>(() => {
    return matchedRoute && appNavigation.getRoute(matchedRoute?.route.path)?.component;
  }, [matchedRoute?.route.path]);

  return (
    <LayoutContextProvider>
      <Switch>
        {appNavigation.routes.map((routeNode) => {
          return (
            <Route
              key={routeNode.path}
              path={routeNode.path}
              exact={isRedirectRoute(routeNode) || routeNode.exact}
              render={(routeProps) => {
                return (
                  <RouteSwitcher
                    currentPath={matchedRoute?.route.path}
                    routeNode={routeNode}
                    routeProps={routeProps}
                    appNavigator={appNavigation}
                    setMatchedRoute={setMatchedRoute}
                  />
                );
              }}
            />
          );
        })}

        {!defaultOrFirstUrl ? (
          <Route
            path="/"
            render={(props) => {
              if (matchedRoute) setMatchedRoute(undefined);
              return (
                <Layout>
                  <div>No Routes Configured</div>
                </Layout>
              );
            }}
          />
        ) : (
          <Route
            path="/"
            exact
            render={() => {
              return <Redirect to={defaultOrFirstUrl} />;
            }}
          />
        )}
      </Switch>
      {Component && matchedRoute && (
        <AppNavigatorContext.Provider value={matchedRoute.context}>
          <Layout>
            <UnrecoveredErrorBoundary errorKey={matchedRoute.route.path}>
              <TagPageEvent eventName={matchedRoute.route.eventName}>
                <Component {...matchedRoute.props} />
              </TagPageEvent>
            </UnrecoveredErrorBoundary>
          </Layout>
        </AppNavigatorContext.Provider>
      )}
    </LayoutContextProvider>
  );
}

interface RouteSwitcherProps {
  routeNode: RouteAction;
  currentPath?: string;
  appNavigator: AppNavigatorUtils;
  routeProps: RouteComponentProps;
  setMatchedRoute: (updates: {
    route: ConcreteRoute;
    props: RouteComponentProps;
    context: AppNavigatorContextValue;
  }) => void;
}

/**
 * component mainly in order to trigger an effect when the react-router props change
 */
function RouteSwitcher({
  routeNode,
  currentPath,
  appNavigator,
  routeProps,
  setMatchedRoute,
}: RouteSwitcherProps): null | React.ReactElement {
  if (isRedirectRoute(routeNode)) {
    return <Redirect exact key={routeNode.path} path={routeNode.path} to={routeNode.to} />;
  } else {
    useEffect(() => {
      if (routeNode.path !== currentPath) {
        setMatchedRoute({
          route: routeNode,
          props: routeProps,
          context: {
            ...appNavigator.activeContext(routeNode),
            location: routeProps.location,
          },
        });
      }
    }, [routeNode.path, currentPath]);

    return null;
  }
}

/**
 * Shared navigation and navigation menu state that can be consumed by an AppPage implementation
 */
export const AppNavigatorContext = React.createContext<AppNavigatorContextValue>(EMPTY_NAVIGATION_CONTEXT);

/** sends an event when the route event name changes */
function TagPageEvent({ eventName, children }: { eventName?: string; children: ReactElement }): ReactElement {
  useEffect(() => {
    if (eventName) recordEvent(eventName);
  }, [eventName]);
  return children;
}
