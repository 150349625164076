function abbreviatedName(str) {
  if (!str) return "";
  var clean = str.trim();
  if (clean === "") return "";
  var sorted = clean.split(/,/).reverse().join(" ");
  var split = sorted.split(/\s+/);
  if (split.length === 1) return split[0];
  if (split.length >= 4) return str; // probably not a normal name at this point
  var last = split[split.length - 1];
  var rest = split.slice(0, split.length - 1).join(" ");
  return rest + " " + last[0].toUpperCase() + ".";
}

export { abbreviatedName };
