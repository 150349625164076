import React from "react";
import PropTypes from "prop-types";
import RenderToPortal from "../../../components/portals/RenderToPortal";
import { PAGE_TITLE, TITLE_IMAGE } from "../navigator/navigatorPortalNames";
import RegisterPortal from "../../../components/portals/RegisterPortal";
import PageTitle from "../../../components/typography/PageTitle";
import { HelpIcon } from "../../../components";

export default function UsePageTitle({ children }) {
  return <RenderToPortal id={PAGE_TITLE}>{children}</RenderToPortal>;
}

// Use this for Angular Page titles since we can't pass markup to Angular react-components
export function UsePortaledPageTitle({ children, subTitle, helpIconMessage, imageUrl, imageClassName }) {
  return (
    <RenderToPortal id={PAGE_TITLE}>
      <div className={`d-flex flex-row align-items-center`}>
        <PageTitle subTitle={subTitle}>
          {children}
          {imageUrl && <img src={imageUrl} className={imageClassName || ""} />}
        </PageTitle>
        {helpIconMessage && <HelpIcon message={helpIconMessage} />}
      </div>
    </RenderToPortal>
  );
}

// Currently only Search Insights uses
export function PortaledTitleImage({ imageUrl, imageClassName }) {
  return (
    <RenderToPortal id={TITLE_IMAGE}>
      {imageUrl && <img src={imageUrl} className={imageClassName || ""} />}
    </RenderToPortal>
  );
}

UsePageTitle.propTypes = {
  children: PropTypes.any,
};

/** Page title (if any) will be rendered to this element. Can register multiple times, and the last declared portal will win */
export function RegisterPageTitle() {
  return <RegisterPortal id={PAGE_TITLE} />;
}
