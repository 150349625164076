import client from "../../services/session/apiClient";

export default {
  saveTask: (task) => {
    if (typeof task.content.assignee === "object") {
      task.content.assignee = task.content.assignee.userId;
    }
    return client.post("/v5/workflow/tasks", task).then((resp) => resp.data);
  },
  searchTask: (params = {}) => {
    // params filter: Option[String], priority: Option[String], authorId: Option[String], assigneeId: Option[String], status: Option[String], locationId: Option[String], createdBefore: Option[org.joda.time.DateTime], createdAfter: Option[org.joda.time.DateTime], dueBefore: Option[org.joda.time.DateTime], dueAfter: Option[org.joda.time.DateTime], sortField: Option[String], sortOrder: Option[String], offset: Int ?= 0, limit: Int ?= 20
    return client.get("/v5/workflow/tasks/search", { params });
  },
  fetchTaskCount: (params = {}) => {
    return client.get("/v5/workflow/tasks/count", { params }).then((resp) => resp.data.count);
  },
  presign: (url) => {
    // params filter: Option[String], priority: Option[String], authorId: Option[String], assigneeId: Option[String], status: Option[String], locationId: Option[String], createdBefore: Option[org.joda.time.DateTime], createdAfter: Option[org.joda.time.DateTime], dueBefore: Option[org.joda.time.DateTime], dueAfter: Option[org.joda.time.DateTime], sortField: Option[String], sortOrder: Option[String], offset: Int ?= 0, limit: Int ?= 20
    return client.get("/dashboard/presign", { params: { url } });
  },
  getTask: (taskId) => {
    return client.get(`/v5/workflow/tasks/${taskId}?d=${+new Date()}`).then((response) => {
      if (response.status !== 200) {
        throw { _id: taskId, notFound: true };
      }
      return response.data.task;
    });
  },
  deleteTask: (taskId) => {
    return client.delete(`/v5/workflow/tasks/${taskId}`);
  },
  getStats: (params = {}) => {
    return client.get("/v5/workflow/tasks/stats", { params });
  },
  getTaskByEntityId: (entityId) => {
    return client
      .get("/v5/workflow/tasks/search", { params: { entityIds: [entityId] } })
      .then((response) => response.data);
  },
  loadTasksByEntityIds: (entityIds = null, entityType) => {
    return client
      .get("/v5/workflow/tasks/search", { params: { entityIds, entityType } })
      .then((response) => response.data);
  },

  addComment(taskId, comment) {
    return client.post(`/v5/workflow/tasks/${taskId}/comments`, comment);
  },

  updateComment(taskId, commentId, comment) {
    return client.post(`/v5/workflow/tasks/${taskId}/comments/${commentId}`, comment);
  },

  deleteComment(taskId, commentId) {
    return client.delete(`/v5/workflow/tasks/${taskId}/comments/${commentId}`);
  },

  loadTaskSettings() {
    return new Promise((resolve, reject) => setTimeout(resolve, 2000));
  },
  upcomingTasks() {
    return client.get("/v5/workflow/tasks/upcoming");
  },
  fetchEntity(taskId) {
    //bypass cached get request for re
    return client.get(`/v5/workflow/tasks/${taskId}/entity?d=${+new Date()}`).then((response) => response.data.entity);
  },
  sendExternalTaskEmail: (taskId, email) => {
    console.log("sending email for task", taskId);
    return client.post(`/v5/workflow/tasks/${taskId}/comments/share`, email);
  },
};
