import client from "../session/apiClient";
import { ContentProvider } from "./ContentProvider";
import { ProviderType } from "./ProviderType";

/**
 *
 * @param includeNonPublicProviders? - include weird providers, such as one-offs for clients like MoneyTree
 */
export function getProviders(includeNonPublicProviders: boolean): Promise<ContentProvider[]> {
  return client
    .get(`/dashboard/providers/all`, {
      params: {
        includeNonPublicProviders: includeNonPublicProviders,
        // includeUnassigned: true,
      },
    })
    .then((response) => response.data as ContentProvider[]);
}

/**
 *
 * @param groupId - a legacy group id
 * @param type - the type of provider (e.g. "REVIEW")
 */
export function getProvidersByGroupAndType(groupId: string, type: ProviderType): Promise<ContentProvider[]> {
  return client
    .get(`/dashboard/providers/${type}/group/${groupId}`)
    .then((response) => response.data as ContentProvider[]);
}

export function getAdviceLocalProviders(): Promise<ContentProvider[]> {
  return client.get("/dashboard/providers/advicelocal").then((r) => r.data as ContentProvider[]);
}

export function getSearchAnalyticsProviders(): Promise<{ providers: string[] }> {
  return client.get(`/v5/searchAnalytics/providers/`).then((response) => response.data as { providers: string[] });
}

export function getFeatureProviders(reportType: string, accountId: string): Promise<ContentProvider[]> {
  return client
    .get(`/dashboard/listingsReportTypeProviders/${accountId}/${reportType}`)
    .then((response) => response.data);
}

// ported from CustomProvidersService.js
// Used to populate select lists of providers
// Do not use for editing custom providers, will make it confusing to update since providers are all one resource
export const getContentProvidersWithChildAccounts = () =>
  client.get("/v5/contentProvidersWithChildAccounts").then((response) => response.data);
