import React from "react";
import { v4 as UUID } from "uuid";
import * as remoteLog from "../../common/services/remoteLog";
import config from "../../config";
import SessionStore from "../../services/session/SessionStore";

export interface UnrecoveredErrorHandlerProps {
  /** child elements to render if there's no error */
  children: React.ReactNode;
  /** function to be called on error, Passed the error object and a serializable javascript object with relevant info */
  reportError: (error: Error, errorInfo: ErrorInfo) => void;
  /** error is cleared when this errorKey changes value. For example, use a route path */
  errorKey?: string;
}

export interface UnrecoveredErrorHandlerState {
  hasError: boolean;
  error?: any;
  errorId?: string;
}

export type ErrorInfo = React.ErrorInfo & { errorId: string; username?: string };

/**
 * provides a fallback UI in case the application crashes hard
 */
export default class UnrecoveredErrorHandler extends React.Component<
  UnrecoveredErrorHandlerProps,
  UnrecoveredErrorHandlerState
> {
  static defaultProps = {
    reportError: (error: Error, errorInfo: ErrorInfo) => {
      logErrorLocally(error, errorInfo);
      reportErrorToServer(error, errorInfo);
    },
  };

  state: UnrecoveredErrorHandlerState = {
    hasError: false,
    error: undefined,
    errorId: undefined,
  };

  dismiss = () => this.setState({ hasError: false, error: undefined, errorId: undefined });

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const errorId = UUID();
    this.setState({ hasError: true, errorId, error });
    this.props.reportError(error, {
      ...errorInfo,
      username: (SessionStore.user && SessionStore.user.username) ?? undefined,
      errorId,
    });
  }

  componentDidUpdate(prevProps: UnrecoveredErrorHandlerProps, prevState: UnrecoveredErrorHandlerState) {
    if (this.state.hasError && prevProps.errorKey !== this.props.errorKey) {
      this.dismiss();
    }
  }

  tryRecoverFatal() {
    try {
      localStorage.clear();
    } catch (ex) {}
    try {
      sessionStorage.clear();
    } catch (ex) {}
    window.location.reload();
  }

  reload = () => {
    window.location.reload();
  };

  render() {
    return (
      <>
        <ErrorDetails
          visible={this.state.hasError}
          errorId={this.state.errorId}
          error={this.state.error}
          onDismiss={this.dismiss}
          onTryRecoverFatal={this.tryRecoverFatal}
          onReload={this.reload}
        />
        {this.state.hasError ? null : this.props.children}
      </>
    );
  }
}

interface ErrorDetailsProps {
  visible: boolean;
  errorId?: string;
  error?: Error;
  onDismiss: () => void;
  onTryRecoverFatal: () => void;
  onReload: () => void;
}

function ErrorDetails({ visible, errorId, error, onDismiss, onTryRecoverFatal, onReload }: ErrorDetailsProps) {
  if (!visible) {
    return null;
  }
  return (
    <div className={"alert alert-primary"}>
      <button type="button" className="close" aria-label="Close" onClick={onDismiss}>
        <span aria-hidden="true">&times;</span>
      </button>
      <h4>Oops!</h4>
      <p>
        A critical application error occurred, some functionality may not work as expected. It's recommended to reload
        the page. If the problem persists, please send us a message referencing the below error ID.
      </p>
      <pre>
        <code>{errorId}</code>
      </pre>
      {config.isDevelopment && (
        <pre style={{ maxHeight: "400px", overflow: "auto" }}>
          <code>{error && renderError(error)}</code>
        </pre>
      )}
      <div className="d-flex flex-row">
        <div className="flex-1" />
        <button className="btn btn-outline-secondary" onClick={onTryRecoverFatal}>
          Logout
        </button>
        <button className="btn control-spacer-left btn-secondary" onClick={onReload}>
          Reload
        </button>
      </div>
    </div>
  );
}

function renderError(error: Error) {
  return error.toString() + "\n" + error?.stack;
}

export function reportErrorToServer(error: Error, errorInfo: ErrorInfo) {
  remoteLog.error(
    `Error id ${errorInfo.errorId}. Application crashed from error ${error && error.name}: ${
      error && error.toString()
    }`,
    errorInfo
  );
}

export function logErrorLocally(error: Error, errorInfo: ErrorInfo) {
  console.error("Application crashed " + errorInfo.errorId, error);
}
