import apiClient from "../session/apiClient";
import { cloneDeep } from "lodash";
import qs from "qs";
import config from "../../config";

function encodeQuerystring(params, options) {
  if (Object.keys(params).length) {
    return "?" + qs.stringify(params, options);
  } else {
    return "";
  }
}

export default {
  /**
   *
   * @param {string} - path
   * @param {{*}} params - query parameters
   * @param {IStringifyOptions} serializerOptions - options to pass to the qs serializer, for example to configure
   *                              how to serialize arrays (via a comma, or repeated parameters
   * @returns {Promise<Any>}
   */
  downloadExport(
    path,
    params = {},
    serializerOptions = {
      arrayFormat: "comma",
      skipNulls: true,
    }
  ) {
    const paramClone = cloneDeep(params);
    const base = `${config.apiUrl}${path}`;
    const q = encodeQuerystring(paramClone, serializerOptions);
    return apiClient
      .get("/dashboard/presign", {
        params: {
          url: `${base}${q}`,
        },
      })
      .then((resp) => {
        window.location = `${base}${encodeQuerystring({ ...paramClone, bewit: resp.data.token }, serializerOptions)}`;
      });
  },
};
