// priority of configuration
// 1. baked in through webpack build (process.env.*)
// 2. window configured via server
// 3. fallback value, depends on field, other window location, or localhost

const windowUrl = window.location.protocol + "//" + window.location.host;

// from api server
const injected = window.APPLICATION_CONFIG || {};

/** magic value WINDOW_URL gets transformed into the windows current url */
function transformWindowUrl(value?: string): string | undefined {
  return value === "WINDOW_URL" ? windowUrl : value;
}

function toWebsocket<T extends string | undefined>(url: T): T {
  // note, handles https to wss conversion by leaving trailing 's' intact
  return url?.replace(/^http/, "ws") as T;
}

// for the there's 3 scenarios here that we want to support:
// 1. We're visiting the dashboard-ui directly, and it's been pre-configured to a remote apiUrl that it's aware of via an
//    environment variable. (for example, if you're running the dashboard-ui locally, connecting to a non-localhost backend).
//    In this case, the api will actually have a different configured assetUrl in uiConfig.js that we definitely want to ignore
// 2. dashboard-ui and dashboard are behind a nice single API gateway that handles the different routes, and forwards them along.
//    (The index route here being visited must be the UI). This is currently mainly chatbox environments.
//    For this to be supported, either the dashboard server MUST be configured with this url (or configured as WINDOW_URL), or
//    the dashboard-ui must be built with the ASSET_URL environment variable override (otherwise we'll think that we're in scenario 3)
// 3. The most important, yet convoluted scenario: we're visiting the dashboard server directly, which is proxying just the
//    index.html route from the assets. In this case, we want to pick up the assetUrl from the window's APPLICATION_CONFIG,
//    and then start using that url for subsequent asset requests.
const assetUrl = transformWindowUrl(process.env.ASSET_URL || injected.assetUrl) || windowUrl;
const apiUrl = transformWindowUrl(process.env.API_URL || injected.apiUrl) || windowUrl;

const notificationsApiUrl =
  transformWindowUrl(process.env.NOTIFICATIONS_API_URL || injected.notificationsApiUrl) ||
  "https://workflow.dev.k8s.chatmeter.com";

const notificationsSocketUrl = toWebsocket(notificationsApiUrl) || "wss://workflow.dev.k8s.chatmeter.com";

const themeApiURL = process.env.THEME_API_URL || injected.themeApiUrl || `https://themes.chatmeter.com`;
const apiSocketUrl = toWebsocket(apiUrl);
const version = process.env.VERSION || "SNAPSHOT";

// TODO - callers reference the config instead of window.assetUrl
window.assetUrl = assetUrl;

window.printChatmeterVersion = (): string => {
  console.log(`running chatmeter version ${version}`);
  return version;
};

const cssRendererUrl: string =
  process.env.CSS_RENDERER_URL || injected.cssRendererUrl || "https://services.live.k8s.chatmeter.com/css-inliner";

const unleashApiUrl: string =
  process.env.UNLEASH_URL || injected.unleashUrl || "https://unleash.live.k8s.chatmeter.com/api/frontend";

const unleashApiKey: string = process.env.UNLEASH_API_KEY || injected.unleashApiKey || "secret";

const currentEnvironment: string = injected.envName || "dev";

interface Config {
  assetUrl: string;
  apiUrl: string;
  apiSocketUrl: string;
  themeApiURL: string;
  isDevelopment: boolean;
  notificationsApiUrl: string;
  notificationsSocketUrl: string;
  version: string;
  cssRendererUrl: string;
  unleashApiUrl: string;
  unleashApiKey: string;
  currentEnvironment: string;
}

const config: Config = {
  assetUrl,
  apiUrl,
  apiSocketUrl,
  themeApiURL,
  isDevelopment: process.env.NODE_ENV === "development",
  notificationsApiUrl,
  notificationsSocketUrl,
  version,
  cssRendererUrl,
  unleashApiUrl,
  unleashApiKey,
  currentEnvironment,
};

export default config;
