import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { PropTypes as ObservablePropTypes } from "mobx-react";
import * as _ from "lodash";
import { bindWithUrl, bindWithUrlSafe } from "./urlSync";

export const fieldDescriptorShape = PropTypes.shape({
  toQuery: PropTypes.func.isRequired,
  fromQuery: PropTypes.func.isRequired,
  observableFieldName: PropTypes.string.isRequired,
  urlParamName: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
});
/**
 * Synchronizes fields described in fieldDescriptors from the observable to the url (and back again) while mounted
 *
 * Basically, url parameters will be 2 way bound.
 * Updates to the observable will be pushed to the url params,
 * and updates to the url params will be pushed to the observable
 *
 * see function bindWithUrl for more info. React provides a convenient lifecycle for binding/unbinding,
 * so disposal is no longer necessary
 *
 **/
export class BindWithUrl extends Component {
  static propTypes = {
    /**
     * halts the binding
     */
    disabled: PropTypes.bool,

    /**
     * if safe is true, will not pull from the url except to initialize on creation
     */
    safe: PropTypes.bool,

    /**
     * the target
     */
    observable: ObservablePropTypes.observableObject.isRequired,

    /**
     * logs out info about updates in the url binding functions
     */
    debug: PropTypes.bool,

    /**
     * which fields to bind to the url
     */
    fieldDescriptors: PropTypes.arrayOf(fieldDescriptorShape).isRequired,
    pullFirst: PropTypes.bool,
  };

  componentWillReceiveProps(nextProps, context) {
    const changedEnabled = nextProps.disabled !== this.props.disabled;
    const changedFields = !_.isEqual(
      this.props.fieldDescriptors.map((x) => x.urlParamName),
      nextProps.fieldDescriptors.map((x) => x.urlParamName)
    );
    const changedObservable = this.props.observable !== nextProps.observable;
    if (changedEnabled || changedFields || changedObservable) {
      this.rebind(nextProps);
    }
  }

  constructor(props) {
    super(props);
    this.rebind(this.props);
  }

  componentWillUnmount() {
    this.dispose && this.dispose();
  }

  rebind(props) {
    this.dispose && this.dispose();
    if (!props.disabled) {
      if (props.safe) {
        this.dispose = bindWithUrlSafe(props.observable, props.fieldDescriptors, {
          debug: props.debug,
          pullFirst: props.pullFirst,
        });
      } else {
        this.dispose = bindWithUrl(props.observable, props.fieldDescriptors, {
          debug: props.debug,
          pullFirst: props.pullFirst,
        });
      }
    }
  }

  render() {
    return <Fragment />;
  }
}

BindWithUrl.defaultProps = { pullFirst: true };
