import React from "react";
import RegisterPortal from "../../../components/portals/RegisterPortal";
import { PAGE_COMMANDS, TITLE_IMAGE } from "../navigator/navigatorPortalNames";
import { RegisterBackButton } from "./UseBackButton";
import { RegisterPageTitle } from "./UsePageTitle";

/**
 * Registers the Back button, Page Title and Page Commands for both layouts
 */

export default function PageHeaderLayout({}) {
  return (
    <div className="d-flex flex-row align-items-center flex-1">
      <RegisterBackButton />
      <RegisterPageTitle />
      {/* Currently only search insights uses to be able to keep old and new names */}
      <RegisterPortal id={TITLE_IMAGE} />
      <div className="ml-auto">
        <RegisterPortal id={PAGE_COMMANDS} />
      </div>
    </div>
  );
}
