import React from "react";
import { Selector } from "../../../../components";
import InputList from "../../../../components/Forms/InputList";
import { ReviewRatingOptions } from "../notificationTriggersStore";
import ProvidersDropDown from "../../../../services/providers/ProvidersDropDown";
import { observer } from "mobx-react";
import LocationGroupSelector from "../../../../services/locations/LocationGroupSelector";
import accessStore from "../../../../services/locations/AccessStore";
import useRiskCategory, { additionalRiskCategoryOptions } from "../../../riskDetection/hooks/useRiskCategory";
import Tooltip from "../../../../components/Tooltip";
import * as FAC from "../../../app/site/featureAccessControl";
import riskMetrics from "../../../app/plugins/metrics/RiskMetrics";

const ReviewReceived = observer(({ store }) => {
  const riskCategories = useRiskCategory()
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  const riskOptions = additionalRiskCategoryOptions.concat(
    riskCategories.map((risk) => ({
      value: risk.name,
      key: risk.name,
    }))
  );

  const getSelectedRisks = () => {
    return store.trigger.criteria?.risk?.map((x) => riskOptions.find((y) => x === y.key)) || [];
  };

  return (
    <>
      <div className="row form-group">
        <label className="col-3 col-form-label pb-0">Rating</label>
        <div className="col-9">
          <Selector
            className="form-control"
            multi={true}
            selected={
              !store.trigger.criteria
                ? []
                : store.trigger.criteria.reviewStars.map((x) => ReviewRatingOptions.find((y) => x === y.key))
            }
            options={ReviewRatingOptions}
            onSelected={(sel) =>
              store.setReviewReceivedCriteria(
                "reviewStars",
                sel.map((x) => x.key)
              )
            }
          />
        </div>
      </div>
      <div className="row form-group">
        <label className="col-3 col-form-label pb-0">Provider</label>
        <div className="col-9">
          <ProvidersDropDown
            multi={true}
            selected={!store.trigger.criteria ? [] : store.trigger.criteria.reviewProvider}
            onSelected={(selected) => store.setReviewReceivedCriteria("reviewProvider", selected)}
          />
        </div>
      </div>
      <div className="row form-group">
        <label className="col-3 col-form-label pb-0">Keywords</label>
        <div className="col-9">
          <InputList
            containerClassNames="flex-column"
            label={"Keywords"}
            values={!store.trigger.criteria ? [] : store.trigger.criteria.keywords}
            onListUpdate={(values) => store.setReviewReceivedCriteria("keywords", values)}
          />
        </div>
      </div>
      <div className="row form-group">
        <label className="col-3 col-form-label pb-0">Locations</label>
        <div className="col-9">
          <LocationGroupSelector
            multi={true}
            placeholder="All Locations"
            includeAccounts={false}
            containerClassNames="flex-column"
            label={"Locations"}
            selected={!store.trigger.criteria ? [] : store.trigger.criteria.locations}
            onSelected={(locations) => store.setReviewReceivedCriteria("locations", locations)}
            resolveSelection={accessStore.resolveEntities}
            selectByKey={true}
          />
        </div>
      </div>
      {FAC.isRiskMonitoringEnabled() && (
        <div className="row form-group">
          <label className="col-3 col-form-label pb-0">
            Risk
            <Tooltip placement="right" title="Risk Monitoring only processes reviews with one or two stars.">
              <i className="fas fa-circle-question ml-1"></i>
            </Tooltip>
          </label>
          <div className="col-9">
            <Selector
              className="form-control"
              placeholder="All Risk Levels"
              multi
              selected={getSelectedRisks()}
              options={riskOptions}
              onSelected={(sel) =>
                store.setReviewReceivedCriteria(
                  "risk",
                  sel.map((x) => x.key)
                )
              }
              disabled={shouldDisableRisk(store.trigger.criteria.reviewStars)}
              renderOption={(x) => <span {...riskMetrics.riskCategoryClickAnalytics(x?.value)}>{x?.value}</span>}
            />
          </div>
        </div>
      )}
    </>
  );
});

export const shouldDisableRisk = (reviewStars) => {
  const disableRiskReviews = [3, 4, 5];
  return disableRiskReviews.some((el) => reviewStars.includes(el));
};

export default ReviewReceived;
