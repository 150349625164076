import client from "../shared/apiClient";

/**
 * Function that list triggers
 *
 */
export const unreadNotification = () => {
  return client.get("/notifications/unread").then((resp) => resp.data);
};

export const loadNotification = (notificationId) => {
  return client.get(`/notifications/${notificationId}`).then((resp) => resp.data);
};
/**
 * Dismiss Notification with notification id
 * @param {string} notificationId - Notification ID to dismiss
 */
export const dismissNotification = (notificationId) => {
  return client.post(`/notifications/dismiss/${notificationId}`);
};

export const updateNotification = (/** @type {{ _id: any; }} */ notification) => {
  return client.put(`/notifications/${notification._id}`, notification);
};

/**
 * Loads a users notification preferences
 * @param {string} userId
 */
export const userNotificationPreferences = (userId) => {
  return client.get(`/notifications/preferences/user/${userId}`).then((resp) => resp.data);
};

/**
 * Inserts or Updates a users notification preferences
 * @param preferences
 */
export const upsertUserNotificationPreferences = (preferences) => {
  return client.post("notifications/preferences/user", preferences);
};
