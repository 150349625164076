import axios from "../../services/session/apiClient";
import type { CustomerFeedback } from "../askTheAI/types";
import type { RiskSummary } from "./components/RiskSummaryCard";
export { CustomerFeedback, RiskSummary };
import { components } from "../../services/api/schema";

export interface CustomerFeedbackQuery {
  entityId?: string;
  providers?: string[];
  afterDate?: string;
  beforeDate?: string;
}

export type RiskCategory = components["schemas"]["com.chatmeter.datainsights.models.riskDetection.RiskCategory"];

export const getRiskSummary = async (params: CustomerFeedbackQuery): Promise<RiskSummary> => {
  const rsp = await axios.get<RiskSummary>("riskDetection/riskSummary", { params });
  return rsp.data;
};

export const getCustomerFeedback = async (params: CustomerFeedbackQuery): Promise<CustomerFeedback[]> => {
  const rsp = await axios.get<CustomerFeedback[]>("riskDetection/customerFeedback", { params });
  return rsp.data;
};

export const getRiskCategories = async (): Promise<RiskCategory[]> => {
  const rsp = await axios.get<RiskCategory[]>("riskDetection/riskCategories");
  return rsp.data;
};

export const getRiskCategoryStats = async (
  params: CustomerFeedbackQuery
): Promise<components["schemas"]["com.chatmeter.datainsights.models.riskDetection.RiskCategoryStatsResponse"]> => {
  const rsp = await axios.get<
    components["schemas"]["com.chatmeter.datainsights.models.riskDetection.RiskCategoryStatsResponse"]
  >("riskDetection/riskCategoryStats", { params });
  return rsp.data;
};

export type RiskyReviewsCount =
  components["schemas"]["com.chatmeter.datainsights.models.riskDetection.RiskyReviewsCount"];

export const getRiskyReviewsCount = async (params: { entityId?: string }): Promise<RiskyReviewsCount> => {
  const rsp = await axios.get<RiskyReviewsCount>("riskDetection/riskyReviewsCount", { params });
  return rsp.data;
};
