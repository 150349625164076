import React from "react";

export const extensions = [
  {
    extensions: "png",
    mimeType: "image/png",
  },
  {
    extensions: ["jpg", "jpeg"],
    mimeType: "image/jpeg",
  },
  {
    extensions: "gif",
    mimeType: "image/gif",
  },
  {
    extensions: ["mpeg", "mpg"],
    mimeType: "video/mpeg",
  },
  {
    extensions: ["mp4", "mpeg4", "mp4"],
    mimeType: "video/mp4",
  },
  {
    extensions: "mov",
    mimeType: "video/quicktime",
  },
];

export const videoExtensions = [
  {
    extensions: ["mpeg", "mpg"],
    mimeType: "video/mpeg",
  },
  {
    extensions: ["mp4", "mpeg4", "mp4"],
    mimeType: "video/mp4",
  },
  {
    extensions: "mov",
    mimeType: "video/quicktime",
  },
];
export const imageExtensions = [
  {
    extensions: "png",
    mimeType: "image/png",
  },
  {
    extensions: ["jpg", "jpeg"],
    mimeType: "image/jpeg",
  },
  {
    extensions: "gif",
    mimeType: "image/gif",
  },
];

export const acceptableMimeTypes = extensions.map((x) => x.mimeType);
export const primaryExtensionNames = extensions.map((x) => x.extensions[0]);

export const imageMimeTypes = imageExtensions.map((x) => x.mimeType);
export const videoMimeTypes = videoExtensions.map((x) => x.mimeType);

export const primaryReportExtensionNames = imageExtensions.map((x) => x.extensions[0]);

const imageExtensionNames = extensions
  .filter((x) => x.mimeType.indexOf("image/") === 0)
  .reduce((arr, ext) => arr.concat(ext.extensions), []);

const isType = (type) => {
  const extensionNames = extensions
    .filter((x) => x.mimeType.indexOf(type + "/") === 0)
    .reduce((arr, ext) => arr.concat(ext.extensions), []);

  return (filename) => {
    if (!filename) return false;
    const split = filename.split(".");
    const last = split[split.length - 1];
    return !!last && extensionNames.indexOf(last.toLowerCase()) > -1;
  };
};

export const isImage = isType("image");
export const isVideo = isType("video");
export const isImageOrVideo = (url) => isImage(url) || isVideo(url);
