import apiClient from "./apiClient";
import { ToastService } from "../../components/toasts/Toasts";
import * as React from "react";
import * as SessionApi from "./SessionApi";
import LoginStore from "./LoginStore";

let isChecking = false;

export function logoutIfInvalid() {
  if (!isChecking && !!LoginStore.token) {
    isChecking = true;
    SessionApi.validateSession()
      .then((isValid) => {
        if (!isValid) {
          ToastService.info("Session expired", "Your session has expired. Login to continue.");
          LoginStore.logout();
        }
      })
      .finally(() => (isChecking = false));
  }
}

apiClient.interceptors.response.use(
  (resp) => resp,
  (respErr) => {
    if (respErr.response && respErr.response.status === 401) {
      logoutIfInvalid();
    }
    throw respErr;
  }
);
