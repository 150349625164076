import React from "react";
import { PropTypes } from "prop-types";

export default function SaveFilterSetIcon({ onClick }) {
  return (
    <button
      className="btn fa-lg btn-link d-flex align-items-center text-decoration-none p-0 d-flex"
      style={{
        fontSize: ".8rem",
      }}
      onClick={onClick}
    >
      <i className="fas fa-save fa-2x"></i>
    </button>
  );
}

SaveFilterSetIcon.propTypes = {
  onClick: PropTypes.func,
};
