import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import classnames from "classnames";

/**
 * Each level 2 tab has a single h1 with a page-title class. The vast majority of the time it is the first element in the view and uses a card-spacer-top class to vertically space itself below the nav or top filters.
 */

function PageTitle(props) {
  const classes = {
    "page-title": true,
  };
  return (
    <div>
      <h1 className={`${classnames(classes)} ${props.className}`} data-testid="page-title">
        {props.children}
      </h1>
      {!!props.subTitle && props.subTitle}
    </div>
  );
}
// TODO - this is not intended to be a forever observer, just easier to qa with the debug flag
export default observer(PageTitle);

PageTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
