import * as React from "react";
import { useEffect, useState } from "react";
import BrandConfigStore from "../../config/BrandConfigStore";
import { observer } from "mobx-react";
import LoginWithSSOToken from "./LoginWithSSOToken";
import * as UrlFragmentSearch from "../../../util/UrlFragmentSearch";
import LoginStore from "../LoginStore";
import ThemeStore from "../../config/ThemeStore";
import FullPageSpinner from "./FullPageSpinner";

/**
 * logs in with stored token, or passed in query params. Renders children otherwise.
 */
const MaybeAutoLogin = observer(({ children }) => {
  const [queryParams, setQueryParams] = useState({
    ssoToken: undefined,
    redirect: undefined,
    ssoPersistent: undefined,
  });
  useEffect(() => {
    // parse url on first load
    const options = UrlFragmentSearch.getQueryObject();
    if (options["iframe"]) {
      BrandConfigStore.setIframeTrue();
    }
    BrandConfigStore.trySetHost(options["logoOverride"] || window.location.hostname);
    const params = {
      ssoToken: options["ssoToken"],
      ssoPersistent: options["persistentLogin"] || false,
    };
    setQueryParams(params);
    let authResponse = LoginStore.getAuthResponse();
    if (authResponse && LoginStore.isJWTActive(authResponse) && !LoginStore.isLoggedIn && !params.ssoToken) {
      LoginStore.refreshJWT({ updateSessionTimeOut: true });
    } else if (!params.ssoToken) {
      LoginStore.tryLoginLocalStorage();
    }
  }, []);

  const brandConfig = BrandConfigStore.brandConfig;
  if (LoginStore.ssoSessionExpired) {
    const params = {
      ssoToken: undefined,
      ssoPersistent: undefined,
    };
    LoginStore.ssoSessionExpired = false;
    LoginStore.refreshJWT({ updateSessionTimeOut: true });
    setQueryParams(params);
  }
  const { ssoToken, ssoPersistent } = queryParams;
  const presentationReady = ThemeStore.isLoaded;
  let authResponse = LoginStore.getAuthResponse();
  if (ssoToken && !LoginStore.isLoggedIn) {
    return <LoginWithSSOToken ssoToken={ssoToken} persistent={ssoPersistent} />;
  } else if (authResponse && LoginStore.isJWTActive(authResponse) && !LoginStore.isLoggedIn) {
    return <FullPageSpinner />;
  } else if (!brandConfig || !presentationReady) {
    return <FullPageSpinner />;
  } else {
    return children;
  }
});

export default MaybeAutoLogin;
