import { reaction } from "mobx";
import config from "../../config";
import LoginStore from "../session/LoginStore";

const PING_PONG = 1000 * 30;
/**
 * The WebSocket implementation for Dashboard Notifications
 */
class NotificationsSocket {
  _pingPongTimer;
  _cb = () => {};

  constructor() {
    this.socket = null;
    this.socketConnectionTimer = undefined;

    reaction(
      () => !!LoginStore.token,
      (token) => {
        if (token) {
          this.initializeSocket();
        } else {
          this.terminateSocket();
        }
      },
      {
        fireImmediately: true,
      }
    );
  }

  set messageCallback(messageCallback) {
    this._cb = messageCallback;
  }

  initializeSocket() {
    if (this.socket) {
      this.terminateSocket();
    }
    if (LoginStore.token) {
      this.socket = new WebSocket(
        `${config.notificationsSocketUrl}/notifications/subscribe?authenticationJwt=${encodeURIComponent(
          LoginStore.token
        )}`
      );
      this.socket.addEventListener("open", this.onSocketOpened);
      this.socket.addEventListener("message", this.onMessageReceived);
      this.socket.addEventListener("close", this.onSocketClosed);
      this.socket.addEventListener("error", this.onSocketClosed);
    }
  }

  terminateSocket() {
    if (this.socket) {
      this.socket.removeEventListener("open", this.onSocketOpened);
      this.socket.removeEventListener("message", this.onMessageReceived);
      this.socket.removeEventListener("close", this.onSocketClosed);
      this.socket.removeEventListener("error", this.onSocketClosed);
      this.socket.close();
      clearInterval(this._pingPongTimer);
    }
  }

  sendPing = () => {
    if (this.socket) {
      this.socket.send("PING");
    } else {
      clearInterval(this._pingPongTimer);
    }
  };

  onSocketOpened = () => {
    if (this._pingPongTimer) clearInterval(this._pingPongTimer);
    this._pingPongTimer = setInterval(this.sendPing, PING_PONG);
  };

  onMessageReceived = (event) => {
    let data;
    try {
      if (event.data === "PONG") return;
      data = JSON.parse(event.data);
    } catch (ex) {
      //TODO - log it
      return;
    }
    this._cb(data);
  };

  onSocketClosed = () => {
    if (LoginStore.token) {
      setTimeout(() => this.initializeSocket(), 5000);
    }
  };
}

export default NotificationsSocket;
