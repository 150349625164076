import React from "react";
import { setItem } from "../../services/storage/localStorage";
import SessionStore from "../../services/session/SessionStore";
import { useLocation } from "react-router-dom";
import { useObserver } from "mobx-react-lite";

export function WindowLocationListener(props) {
  const { pathname, search } = useLocation();
  // When a user changes, trigger an update
  const userId = useObserver(() => {
    return SessionStore.user && SessionStore.user.userId;
  });

  React.useEffect(() => {
    if (userId && window.location.hash !== "#/") {
      setItem(`RESTORE_URL_${userId}`, JSON.stringify(window.location));
    }
  }, [userId, pathname, search]);
  // Render nothing
  return null;
}
