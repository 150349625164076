import React from "react";
import { Spinner } from "../../../components";

export default function FullPageSpinner() {
  return (
    <div style={{ display: "flex", height: "100%", flexDirection: "column" }} data-testid="fullpage-loading-spinner">
      <Spinner timeout={1000} style={{ margin: "auto" }} size="lg" center />
    </div>
  );
}
