import EventEmitter from "events";

// TODO #flatcatssplitbrain
// Hack to ensure we only have one instance of EventEmitter used in the application
// If this eventEmitter is imported from the dashboard project, a different instance will be used than if it's imported
// from flat-cats.  Once the projects are merged, we should be able to get rid of this.
const eventEmitter = window.eventEmitter || new EventEmitter();
window.eventEmitter = eventEmitter;

export default eventEmitter;
