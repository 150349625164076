import React, { useState } from "react";
import Button from "../../../components/buttons/Button";
import RegionCard from "../../../components/cards/RegionCard";
import RegionCardBody from "../../../components/cards/RegionCardBody";
import RegionCardHeader from "../../../components/cards/RegionCardHeader";
import { auditApi } from "./api";

export const AiReviewResponseAudit = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleOnDownloadClick = async () => {
    setIsLoading(true);
    await auditApi.getAIReviewResponseAuditReport();
    setIsLoading(false);
  };

  return (
    <RegionCard>
      <RegionCardHeader title="Audit Report" />
      <RegionCardBody>
        <Button disabled={isLoading} onClick={handleOnDownloadClick}>
          Download
        </Button>
      </RegionCardBody>
    </RegionCard>
  );
};
