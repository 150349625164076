import React from "react";
import { DropdownItem } from "reactstrap";
import PropTypes from "prop-types";
import { DropdownSelectorRenderer } from "./DropdownSelectorRenderer";

/**
 * Component Responsible for keeping track of selection state.
 * The rendering is passed off to it's render component
 */

export function Selection({ multi = false, selectByKey = false, getKey = Selector.defaultProps.getKey, onSelected }) {
  const self = {
    onSelected(items) {
      /**
       * Some of the selected arrays that get passed in are just id primitives, not objects,
       * so if the values are primitive, don't run getKey.
       */

      const orKeys = selectByKey ? items.map((x) => (x === Object(x) ? getKey(x) : x)) : items;
      multi
        ? onSelected(orKeys, selectByKey ? items : undefined)
        : orKeys.length
        ? onSelected(orKeys[0], selectByKey ? items[0] : undefined)
        : onSelected(undefined);
    },
    addSelection(items, newItem) {
      self.onSelected(multi ? items.concat(newItem) : [newItem]);
    },
    removeSelection(items, item) {
      const key = getKey(item);
      multi ? self.onSelected(items.filter((x) => getKey(x) !== key)) : self.onSelected([]);
    },
    clearSelections() {
      self.onSelected([]);
    },
  };
  return self;
}

export class Selector extends React.Component {
  static propTypes = {
    getKey: PropTypes.func.isRequired, // function to get a unique key for an option/selection. Defaults to the option's field at .value
    selected: PropTypes.any, // undefined, the selected option, or an array of selected options, or an array of selected option ids (see selectionMode)
    selectByKey: PropTypes.bool, // if true, assumes selected is a key or an array of keys.
    // An option must exist with this key to render
    options: PropTypes.any, // array of available options. Selected does not need to be included in this list
    onSelected: PropTypes.func, // if multi, all current selections, else the clicked selection or undefined empty
    // if selectByKey, will pass the key instead of the full option, (as well as the full option as the second argument in case its really needed)
    disabled: PropTypes.bool, // if true, disables the dropdown
    required: PropTypes.bool, // only for non-multi mode. Prevents x-ing out current value
    multi: PropTypes.bool, // multi selection mode
    maxItems: PropTypes.number, // multi selection mode only; Maximum number of selectable items, will close multi selector when maximum items number is reached
    onOpen: PropTypes.func, // function run when menu is opened
    onClose: PropTypes.func, // function run when menu is closed
    closeOnClear: PropTypes.bool,
    activeKey: PropTypes.any, // highlight the item with this key
    modal: PropTypes.bool, // prevents body scrolling if true
    right: PropTypes.bool, // makes dropdown align-right
    /**
     *
     * component responsible for rendering. Below render functions are defaulted into more a friendly
     * shape. They may of course be ignored and totally implemented
     * see SelectorRenderer.propTypes
     */
    Render: PropTypes.func,

    placeholder: PropTypes.any, // what to show if nothing is selected
    noOptionsText: PropTypes.any, // component to show if there's nothing to select
    noMoreOptionsText: PropTypes.any, // component to show if there's nothing more to select
    renderMultiSelected: PropTypes.func, // custom function to summarize multi selection (if more than one)
    renderSelectedOption: PropTypes.func, // custom selected-option rendering function. Defaults to renderOption prop
    renderOption: PropTypes.func, // custom option rendering function. Defaults to rendering the option.value, or if falsey, the actual option
    className: PropTypes.string,
    showChevron: PropTypes.bool, // shows down icon instead of x for dropdown
  };

  static defaultProps = {
    placeholder: <span className="text-muted">Select...</span>,
    renderOption: (opt) => (opt && opt.value ? opt.value : opt),
    getKey: (opt) => {
      const key = (opt && opt.key) || (opt && opt.value) || opt;
      return key;
    },
    right: false,
    disabled: false,
    required: false,
    multi: false,
    modal: false,
    options: [],
    onSelected: (x) => {},
    noOptionsText: (
      <DropdownItem disabled>
        <span className="text-muted">No Results</span>
      </DropdownItem>
    ),
    noMoreOptionsText: (
      <DropdownItem disabled>
        <span className="text-muted">No More Results</span>
      </DropdownItem>
    ),
    renderMultiSelected: (items) => items.length + " Selections",
    dataTestId: "dropdown",
    Render: DropdownSelectorRenderer,
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
    this.computeCachedValues(this.props.selected, this.props.options);
  }

  toggle = () => {
    let isOpen = !this.state.isOpen;
    this.setState({ isOpen });
    if (isOpen && this.props.modal) {
      document.documentElement.style.overflow = "hidden";
    } else if (this.props.modal) {
      document.documentElement.style.overflow = null;
    }
    if (isOpen && this.props.onOpen) this.props.onOpen();
    if (!isOpen && this.props.onClose) this.props.onClose();
  };

  unselectedOptions = [];
  selectedArray = [];

  componentWillReceiveProps(nextProps, _) {
    this.computeCachedValues(nextProps.selected, nextProps.options);
  }

  /**
   *
   * undefined or other falsey value - []
   * [value] for all non arrays
   * otherwise return the array
   * @param value - falsey | value | array of values
   */
  static toArray(value) {
    if (value === undefined || value === null || value === "") {
      return [];
    } else if (typeof value === "string") {
      return [value];
    } else if (Array.isArray(value) || Number.isInteger(value.length)) {
      return Array.from(value);
    } else {
      return [value];
    }
  }

  /**
   * returns values from right that do not have keys (defined by keyFn) that appear in left.
   * Retains order of right
   * @param keyFn - {function}
   * @param left - {Array.<{}>}
   * @param right - {Array.<{}>}
   */
  static rightOuterByKey(keyFn, left, right, getLeftKeys = true) {
    if (!right || !right.length) {
      return right || [];
    } else {
      const leftKeys = {};
      for (let x of left) {
        leftKeys[getLeftKeys ? keyFn(x) : x] = true;
      }
      return right.filter((x) => !leftKeys[keyFn(x)]);
    }
  }

  computeCachedValues(selected, options) {
    // normalize selection to an array for easier logic between multi/single select mode

    if (this.props.selectByKey) {
      this.selectedArray = Selector.toArray(selected)
        .map((selection) =>
          options.find(
            (option) =>
              this.props.getKey(option) != null &&
              `${this.props.getKey(option)}`.toLowerCase() === `${selection}`.toLowerCase()
          )
        )
        .filter((x) => x !== undefined);
    } else {
      this.selectedArray = Selector.toArray(selected);
    }
    // unselected options for dividing up multi select list
    this.unselectedOptions = Selector.rightOuterByKey(this.props.getKey, this.selectedArray, options);
  }

  get selection() {
    return Selection(this.props);
  }

  onSelected(items) {
    this.selection.onSelected(items);
  }

  addSelection = (item) => {
    this.selection.addSelection(this.selectedArray, item);
  };

  removeSelection = (item) => {
    this.selection.removeSelection(this.selectedArray, item);
    if (this.props.closeOnClear) this.toggle();
  };

  clearSelections = () => {
    this.selection.clearSelections();
  };

  render() {
    const RenderComponent = this.props.Render;

    const {
      multi,
      getKey,
      renderOption,
      renderSelectedOption,
      noOptionsText,
      noMoreOptionsText,
      activeKey,
      disabled,
      required,
      placeholder,
      renderMultiSelected,
      options,
      showChevron,
      ...rest
    } = this.props;

    const { isOpen } = this.state;

    return (
      <RenderComponent
        {...{
          ...rest,
          isOpen,
          multi,
          getKey,
          selections: this.selectedArray,
          options: multi ? this.unselectedOptions : options,
          renderOption,
          renderSelectedOption: renderSelectedOption || renderOption,
          noOptions:
            this.unselectedOptions.length !== 0
              ? undefined
              : this.selectedArray.length === 0
              ? noOptionsText
              : noMoreOptionsText,
          addSelection: this.addSelection,
          removeSelection: this.removeSelection,
          clearSelections: this.clearSelections,
          activeKey,
          disabled,
          required,
          placeholder,
          onToggle: this.toggle,
          renderMultiSelected,
          className: this.props.className,
          showChevron,
        }}
      />
    );
  }
}
