import LoginStore from "../../services/session/LoginStore";

function mergeRequestOptions(a, b) {
  // does an inclusive merge on headers
  return Object.assign({}, a, b, {
    headers: Object.assign({}, a.headers, b.headers),
  });
}

function req(path, options = {}) {
  const defaults = {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: LoginStore.token,
    },
  };

  // if options defines headers, that Authorization will be stomped over.
  // Not sure how I feel about that
  return new Request(path, mergeRequestOptions(defaults, options));
}

function json(path, options = {}) {
  if (options.body && typeof options.body !== "string") {
    options.body = JSON.stringify(options.body);
  }

  return req(
    path,
    mergeRequestOptions(
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      },
      options
    )
  );
}

req.json = json;

export default req;
