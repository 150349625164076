import React from "react";

const ExportButton = (props) => {
  return (
    <span className="btn btn-outline-secondary">
      <i className="fas fa-cloud-download fa-spacer-right"></i>
      <span>Print Report</span>
      {props.children}
    </span>
  );
};

export default ExportButton;
