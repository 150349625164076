import React from "react";
import classnames from "classnames";

const iconAndTextColor = {
  error: "text-danger fas fa-exclamation-triangle",
  info: "text-info fas fa-info-circle",
  success: "text-success far fa-smile",
  warning: "text-warning fas fa-exclamation-triangle",
};
const closeButtonStyle = {
  fontSize: "1.1rem",
};
export default function BootstrapToast(props) {
  /*
    if you pass message to the addToast helper it converts to to children, you can not manually specify children,
    but you can pass another value, in this case "message" in the options it will add it as props
   */
  const { level, title, onDismiss, transitionState, children, message, containerClassNames } = props;
  return (
    <div
      className={`toast ${
        !transitionState || transitionState === "entered" ? "show" : ""
      } fade d-flex flex-row align-items-top ${classnames(containerClassNames)}`}
    >
      <div>
        <div className="toast-header text-align-center border-bottom-0 pb-0">
          {level && <i className={`${iconAndTextColor[level]}`}></i>}
          {title && <strong className="ml-1 content-spacer-right">{title}</strong>}
        </div>
        <div className="toast-body pt-1">
          {children}
          {message}
        </div>
      </div>
      <div className="ml-auto pt-2">
        <button
          type="button"
          className="mb-1 px-2 close"
          aria-label="Close"
          onClick={(id) => onDismiss(id)}
          style={closeButtonStyle}
        >
          <i className="far fa-times"></i>
        </button>
      </div>
    </div>
  );
}
