/**
 * returns a function that memoizes the results of fn. Additionally, will not cache promises if they resolve to an error.
 * Returned function has an invalidate property, that may be invoked in order to entirely clear the cache;
 * @param fn - a function that accepts any number of args.
 *             Memoization will be unique by json serialization of arguments, so args should not be anything fancy
 * @param timeout - max time a result is memoized for
 * @param maxSize - max number of results to memoize. After this is exceeded, further invocations are not cached until
 *                  there is space available again
 */
import { PromiseFactory } from "../async";

export function memoize<Input extends any[], Result>(
  fn: PromiseFactory<Input, Result>,
  { timeout = 10 * 60 * 1000, maxSize = 1000 }
): PromiseFactory<Input, Result> & {
  invalidate(): void;
} {
  let cacheMap: Record<string, Promise<Result>> = {};
  let size = 0;
  let timers: ReturnType<typeof setTimeout>[] = [];
  function withCachedResult(...args: Input): Promise<Result> {
    let k = JSON.stringify(args);
    if (k in cacheMap) {
      return cacheMap[k];
    } else if (size > maxSize) {
      return fn(...args);
    } else {
      let value = fn(...args);
      cacheMap[k] = value;
      value.catch((ex) => {
        delete cacheMap[k];
      });
      const timer = setTimeout(() => {
        let idx = timers.indexOf(timer);
        if (idx > -1) {
          timers.splice(idx, 1);
        }
        size += -1;
        delete cacheMap[k];
      }, timeout);
      timers.push(timer);
      return value;
    }
  }
  withCachedResult.invalidate = () => {
    size = 0;
    cacheMap = {};
    timers = [];
    for (const t of timers.slice()) {
      clearTimeout(t);
    }
  };
  return withCachedResult;
}
