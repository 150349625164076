import React from "react";
import "./_sentimentTag.css";

const SentimentTag = (props) => {
  const { topic } = props;
  const sentimentClass = {
    VeryPositive: "sentiment-green",
    Positive: "sentiment-green",
    Neutral: "bg-warning",
    Negative: "sentiment-red",
    VeryNegative: "sentiment-red",
  };
  return (
    <span className={"badge text-white cm-sentiment-tag " + sentimentClass[topic.sentimentClassification]}>
      {topic.segment}
      {props.children}
    </span>
  );
};

export default SentimentTag;
