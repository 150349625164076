const colorList = Array(12)
  .fill(null)
  .map((x, i) => `var(--color-${i + 1})`);

/**
 * function/file to return color variables from theme.
 *
 * To be enhanced as needed:
 *
 * request a number of colors
 * request an order to the colors (arrange colors to encourage similar colors to be nearby or far apart. Arrangement depends on the number of colors)
 * whether to actualize the colors, or just retun the css variable names
 *
 * This may all be overkill
 **/
export default function colors({ nColors = 12 }) {
  return colorList.slice(0, nColors);
}
