import * as React from "react";

export class ForgotPasswordView extends React.Component {
  render() {
    const { username, errorMessage, emailSent, onSubmit, logoUrl, onUsernameChanged } = this.props;

    return (
      <div className="d-flex h-100 align-items-center justify-content-center">
        <div className="card w-100 card-login">
          <div className="card-header">
            <h1 className="text-center card-title-lg">Reset Password</h1>
          </div>
          <div className="card-body card-footerless">
            {!!logoUrl && (
              <figure className="logo-container" className={!!logoUrl ? logoUrl : ""}>
                {!!logoUrl && <img src={logoUrl} alt="" className="img-responsive" />}
              </figure>
            )}
            <p>Please enter your username and email. You will be contacted shortly with password reset instructions.</p>
            {!!errorMessage && (
              <div className="alert alert-danger">
                <i className="fas fa-exclamation-circle fa-status"></i>
                {errorMessage}
              </div>
            )}
            {emailSent && (
              <div className="alert alert-success">
                <i className="fas fa-check-circle fa-status"></i>We sent you an email with password reset instructions.
              </div>
            )}
            <form
              name="f.passwordResetEmailForm"
              className="form-horizontal"
              role="form"
              onSubmit={onSubmit}
              noValidate
            >
              <div className="form-group">
                <div className="input-group">
                  <span className="input-group-prepend" id="basic-addon1">
                    <i className="d-flex fas fa-user input-group-text"></i>
                  </span>
                  <input
                    className="form-control"
                    value={username}
                    onChange={onUsernameChanged}
                    type="username"
                    name="username"
                    id="username"
                    placeholder="Username"
                    required
                    autoFocus
                  />
                </div>
                <div></div>
              </div>
              <div className="form-group">
                <input type="submit" disabled={emailSent || !username} className="btn btn-secondary" value="Submit" />
              </div>
              <a href="/#/login">Return to login screen</a>
              <br />
            </form>
          </div>
        </div>
      </div>
    );
  }
}
