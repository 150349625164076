import client from "../session/apiClient";
import presignedExport from "../auth/presignedExport";
import { LOCATION_PATCH_TYPES } from "../../modules/locations/pages/PatchTypes";
import { DisplayAlternateId, FAC } from "../../modules/app/site/featureAccessControl";

export function countLocations(groupId = null, accountId = null) {
  return client.get("/v5/locationsCount", { params: { groupId, accountId } }).then((x) => x.data);
}

export function getLocations({ groupId, accountId, offset, limit, locationId }) {
  return client.get("/v5/locations", { params: { groupId, accountId, offset, limit, locationId } }).then((x) => x.data);
}

export function getLocation(locationId, refresh) {
  return client.get("/location/" + locationId).then((response) => response.data);
}

export function getLocationV6(locationId, primaryLocation, refresh) {
  return client.get("/v6/location/" + locationId, { params: { primaryLocation } }).then((response) => response.data);
}

export function getLocationsInGroup(groupId) {
  return client.get("/location/group/" + encodeURIComponent(groupId)).then((response) => {
    let mappedLocations = {};
    response.data.locations.map((location) => (mappedLocations[location.locationId] = location));
    return mappedLocations;
  });
}

/**
 *
 * @param locationIds The list of location ids to examine
 * @param propertyName The property to examine
 * @returns a list of distinct values of the property for the specified locations
 */
export function getLocationsProperty({ locationIds, propertyName }) {
  return client
    .post("/location/property", {
      locationIds: locationIds,
      propertyName: propertyName,
    })
    .then((resp) => resp.data);
}

export function getLocationNotificationSettings(groupId) {
  return client.get("/location/" + groupId + "/notificationSettings").then((response) => response.data);
}

export function updateNotificationSettings(groupId, notificationUpdates) {
  return client
    .post(`/location/${groupId}/notificationSettings/update`, { notificationUpdates })
    .then((response) => response.data);
}

export function getLocationsForAccount(accountId) {
  return client.get("/location/account/" + encodeURIComponent(accountId)).then((response) => response.data.locations);
}

export function getLocationsAndGroups({ locationId, includeGroupLocationIds = false, search = "" } = {}) {
  return client
    .get("/location/search/all", {
      params: {
        search,
        includeGroupLocationIds: includeGroupLocationIds,
        ...(!!locationId ? { locationId } : {}),
      },
    })
    .then((response) => {
      return response.data;
    });
}

/**
 *
 * @param {string} entityId - prefixed entity id string
 * @return {Promise<LocationGroup|null>}
 */
export function getEntity(entityId) {
  return client.get("/location/locationGroupDetail/" + encodeURIComponent(entityId)).then((x) => x.data.group || null);
}

/**
 * @typedef {object} PaginationConfig
 * @property {string} [q=""]
 * @property {number} [offset=0]
 * @property {number} [limit=1000]
 */

/**
 *
 * @param {PaginationConfig} [config={}]
 * @return {Promise<{all: LocationGroup[], accounts: LocationGroup[]}>}
 */
export function getAccounts({ q = "", offset = 0, limit = 1000 } = {}) {
  return client
    .get("/location/search/accounts", {
      params: {
        q,
        offset,
        limit,
      },
    })
    .then((r) => r.data);
}

/**
 * @param {string} groupId
 * @return {Promise<{accountId: string, groupName: string, id: string}>}
 */
export function getGroup(groupId) {
  return client.get(`/v5/group/${groupId}`).then((r) => r.data);
}

/**
 * @param {PaginationConfig} [config={}]
 * @return {Promise<{groups: LocationGroup[]}>}
 */
export function getGroups({ q = "", offset = 0, limit = 1000 } = {}) {
  return client
    .get("/location/search/groups", {
      params: {
        q,
        offset,
        limit,
      },
    })
    .then((r) => r.data);
}

/**
 * @param {PaginationConfig & {locationId?: string}} [config={}]
 * @return {Promise<{locations: LocationGroup[], total: number}>}
 */
export function searchLocations({ locationId, q = "", offset = 0, limit = 1000 } = {}) {
  return client
    .get("/location/search/locations", {
      params: {
        q,
        ...(!!locationId ? { locationId } : {}),
        limit,
        offset,
      },
    })
    .then((r) => r.data);
}

export function deleteSchedule(jobId) {
  return client.delete(`/internal/jobs/location/${jobId}`);
}

/**
 *
 * @param jobType: String - location | group - currently only location is a valid type
 * @param statuses: Array of strings - valid statuses Processed, NotProcessed. See LocationUpdateSimplifiedStatus.scala
 */
export function getJobIds(jobType = "location", statuses = ["NotProcessed"]) {
  return client.get(`/internal/jobs/${jobType}/`, { params: { status: statuses } });
}

export function getJobsWithDetails(config = {}, statuses = ["NotProcessed"]) {
  return client.get(`/internal/jobs/location/details`, { params: { ...config, status: statuses } }).then((r) => r.data);
}

/**
 *
 * @param id: String - jobId
 */
export function getLocationUpdateJobById(id) {
  return client.get(`/internal/jobs/location/${id}`).then((r) => r.data);
}

/**
 * Deactivates locations (soft-deletes locations)
 * @param locationIds - single or multiple locationIds
 * @returns {*}
 */
export function deactivateLocation(locationIds) {
  return client
    .delete(`/v5/locations`, {
      params: {
        locationId: locationIds.join(","),
      },
    })
    .then((r) => r.data);
}

/**
 * Reactivates soft-deleted locations
 * @param locationIds -  single or multiple locationIds
 * @returns {*}
 */
export function reactivateLocation(locationIds) {
  return client
    .put(`/v5/locations/restore`, { locationIds: locationIds.map((id) => parseInt(id)) })
    .then((r) => r.data);
}

/**
 * Triggers location refresh
 */
export function refreshLocation(locationId) {
  return client.post(`/admin/location/${locationId}/refresh`, {}).then((r) => r.data);
}

/**
 * @typedef {object} InternalLocationsConfig
 * @property {string|undefined} [entityId=undefined] - prefixed entity id string. Ex: e.g. Location-1234 or Group-4567 or Account-9012 or User-3456.
 * @property {string|undefined} [lastModifiedFrom=undefined] - Last Modified From Date.
 * @property {string|undefined} [lastModifiedTo=undefined] - Last Modified To Date.
 * @property {'Enabled'|'Disabled'} [status='Enabled'] - Status to include only enabled locations or only disabled locations.
 * @property {string} [sortField='businessName'] - Field name used for sorting.
 * @property {'DESC' | 'ASC'} [sortOrder='DESC'] - Sort Order. Ascending or Descending.
 * @property {number} [offset='name'] - The offset/cursor for pagination.
 * @property {number} [limit='name'] - The max number of results to return.
 */

/**
 * Gets Base Location data with filters and pagination
 *
 * @param {InternalLocationsConfig} config
 */
export function getInternalLocations(config = {}) {
  return client.get("/internal/locations", { params: { ...config } }).then((r) => r.data);
}

export function exportLocations({ accountId, locationQuery, locationIds, groupId, includeDeletedLocations = false }) {
  locationIds = groupId && !locationIds ? ["group-" + groupId] : locationIds;
  return presignedExport.downloadExport("/v5/export/locations", {
    accountId,
    locationQuery,
    locationIds,
    includeDeletedLocations,
    includeAlternateId: DisplayAlternateId(),
  });
}

export function exportGroups({ accountId, locationQuery, locationIds, groupId }) {
  return presignedExport.downloadExport("/v5/export/groupsToLocations", {
    accountId,
    locationQuery,
    groupId,
    locationIds,
    includeAlternateId: DisplayAlternateId(),
  });
}

export function exportCompetitors({ accountId, locationQuery, locationIds, groupId, includeDeletedLocations = false }) {
  locationIds = groupId && !locationIds ? ["group-" + groupId] : locationIds;
  return presignedExport.downloadExport("/v5/export/competitor", {
    accountId,
    locationQuery,
    locationIds,
    includeDeletedLocations,
  });
}

export function validateBulkLocationJob({ updates }) {
  const job = { updates };
  return client.post("/internal/jobs/location/validate", job).then((r) => r.data.errors);
}

export function validatePatch({ patch, patchType, validationCount }) {
  switch (patchType) {
    case LOCATION_PATCH_TYPES.CORE:
      return client
        .post("/internal/locations/patch/validation?checkForWarnings=true", patch)
        .then((r) => [...r.data.errors]);
    case LOCATION_PATCH_TYPES.ATTRIBUTES:
      return client
        .post("/internal/locations/attributes/patch/validation?checkForWarnings=true", patch)
        .then((r) => [...r.data.errors]);
    default:
      return client
        .post(`/internal/locations/validation?checkForWarnings=true&validationCount=${validationCount}`, patch)
        .then((r) => [...r.data.errors]);
  }
}

export function createCoreLocation({ patch }) {
  return client.post("/v6/location", patch);
}

export function patchCoreLocation({ patch }) {
  return client.patch("/v6/location", patch);
}

export function getAddressSchema(country) {
  return client.get(`/internal/address/schema/${country}`).then((r) => r.data);
}
