import * as mobx from "mobx";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { PortalScopeContext } from "./RegisterPortal";

/**
 * `RenderToPortal` renders children to a distant location. The distant location must be set up
 * with `RegisterPortal`, and share a string `id`
 */
export default function RenderToPortal({ id, recoverInline, children }) {
  const { portals, outer } = useContext(PortalScopeContext);
  const [portal, setPortal] = useState(undefined);
  useEffect(() => {
    return mobx.reaction(() => (portals.has(id) ? portals.get(id) : outer[id]), setPortal, {
      fireImmediately: true,
    });
  }, [outer[id]]);
  if (portal) {
    return ReactDOM.createPortal(children, portal);
  }

  if (portal === null) {
    return <span />; // portal registered, but still waiting on ref
  } else if (recoverInline) {
    return children;
  } else {
    return <span />;
  }
}

RenderToPortal.propTypes = {
  /** id of the registered portal to attach to */
  id: PropTypes.string.isRequired,
  /** show the children inline if no registered portal */
  recoverInline: PropTypes.bool,
  /** content to attach to the portal */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};
