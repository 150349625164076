import { action, computed, observable, makeObservable, IReactionDisposer } from "mobx";
import * as mobx from "mobx";
import sessionStore from "../session/SessionStore";
import {
  entityIdStringToStructuredEntityId,
  getLegacyAll,
  getUserEntityId,
  toEntityId,
  toLegacyGroupId,
} from "./entityIdConversions";
import accessStore from "./AccessStore";
import { USER_TYPE } from "./entityTypes";
import { getEntity } from "./api";
import { LocationGroup } from "./LocationTypes";
import { IObservableStream } from "mobx-utils";

/**
 * keeps global state of which group entity is selected.
 * Shared across pages.
 * Keeps an entity id of the selected group (e.g. location, user, group, or account)
 *  Location-1234, User-45678, Group-5b8efb0a2c5b53fd7eeb8034, Account-5b8efb122c5b53fd7eeb8035
 *
 * Since (at least currently) pages deal with their url quite params differently,
 * offers a lower level set of interfaces for push/pulling the group id from the url parameter.
 * These need to be disposed of, or else components will be push/pulling conflicts
 *
 * Global location dropdown reads and writes to this store
 *
 **/
export class SelectedLocationsStore {
  // All observables should only be mutated via accessors

  /** the canonical selected location or location group for the application,
   * e.g. Location-1234 or Group-4567 or Account-9012 or User-3456.
   * Undefined value should be interpreted as "All of the users current locations." (e.g. "User-$currentUserId")
   * May be updated directly to update the application's location filter.
   * Value should be observed in components */
  selectedLocations: string | null = null;

  isLoadingEntity: boolean = true;

  /**
   * @deprecated("use the more consistently named entity id strings")
   * returns a legacy group id like
   * legacy-1234, all-QAmonitor, 6078d1652ae54f93b2d8849a, account-6078d1652ae54f93b2d8849a
   */
  get legacyGroupId(): string | null {
    return this.selectedLocations && toLegacyGroupId(this.selectedLocations);
  }

  /**
   * @deprecated("use the more consistently named entity id strings")
   * returns a legacy group id like
   * legacy-1234, all-QAmonitor, 6078d1652ae54f93b2d8849a, account-6078d1652ae54f93b2d8849a
   */
  get legacyGroupIdOrAll(): string {
    return this.selectedLocations ? toLegacyGroupId(this.selectedLocations) : getLegacyAll();
  }

  get selectedEntity(): LocationGroup | null {
    return (this.selectedLocations && accessStore.byEntityId[this.selectedLocations]) || accessStore.allGroup;
  }

  /**
   * like selectedLocations, but always returns a string, defaulting to "User-$currentUserId"
   */
  get selectedLocationOrUser(): string {
    return this.selectedLocations ? this.selectedLocations : getUserEntityId();
  }

  get selectedEntityType(): string {
    return this.selectedLocations ? entityIdStringToStructuredEntityId(this.selectedLocations).entityType : USER_TYPE;
  }

  /**
   * returns the id part of the selected locations entity id, e.g. for Location-1234 returns "1234"
   * Does not wait for data to be fetched from the server (will return null if the "All" group is selected)
   */
  get selectedId(): string | null {
    return this.selectedLocations && entityIdStringToStructuredEntityId(this.selectedLocations).id;
  }

  /**
   * ideally consumer should just do this themselves. Requiring mobx in dashboard code is causing multiple instances though
   * @returns {IObservableStream<T>}
   */
  onSelectedEntityChange(reaction: (arg: LocationGroup | null) => void): IReactionDisposer {
    return mobx.reaction(() => this.selectedEntity, reaction, { fireImmediately: true });
  }

  constructor() {
    makeObservable(this, {
      selectedLocations: observable,
      isLoadingEntity: observable,
      legacyGroupId: computed,
      legacyGroupIdOrAll: computed,
      selectedEntity: computed,
      selectedLocationOrUser: computed,
      selectedEntityType: computed,
      selectedId: computed,
      setSelectedLocations: action,
      setSelectedLocationGroup: action,
      resolveSelection: action,
      clearSelectionIfOneOf: action,
    });

    // clears selection on logout
    const subscription = mobx.reaction(
      () => sessionStore.sessionLoaded,
      (isLoaded) => {
        if (!isLoaded) {
          this.setSelectedLocations(null);
        }
      }
    );

    this.dispose = () => subscription();
  }

  dispose = () => {};

  setLegacyGroupId(groupId: string | null): void {
    this.setSelectedLocations(groupId && toEntityId(groupId));
  }

  /**
   *
   * @param selectedLocations - entityId string of selection
   */
  setSelectedLocations(selectedLocations: string | null) {
    if (!selectedLocations || typeof selectedLocations === "string") {
      this.selectedLocations = selectedLocations || null;
    } else {
      throw new Error("setSelectedLocations expected null|undefined|string, got: " + typeof selectedLocations);
    }
  }

  setSelectedLocationGroup(locationGroup: LocationGroup | null) {
    locationGroup && accessStore.cache(locationGroup);
    this.setSelectedLocations(locationGroup && locationGroup.entityIdString);
  }

  _isResolving: null | Promise<LocationGroup | null> = null;

  /** ensures that the current selection has an {LocationGroup} entry in the AccessStore */
  resolveSelection(): Promise<LocationGroup | null> {
    if (this.selectedEntity) {
      return Promise.resolve(this.selectedEntity);
    } else if (this._isResolving) {
      return this._isResolving;
    } else {
      this._isResolving = getEntity(this.selectedLocationOrUser);
      return this._isResolving
        .then((x) => {
          x && accessStore.cache(x);
          return x;
        })
        .finally(() => (this._isResolving = null));
    }
  }

  // disable existing selection for components that aren't compatible (e.g. accounts for review store comparison report)
  clearSelectionIfOneOf(types: string[]) {
    if (this.selectedLocations && types.indexOf(this.selectedLocations.split("-")[0]) > -1) {
      this.setSelectedLocations(null);
    }
  }
}

export default new SelectedLocationsStore();
