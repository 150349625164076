/** interface over window localStorage in case we need to modify or make more compatible */

export function getItem(k) {
  return window.localStorage.getItem(k);
}

export function setItem(key, value) {
  return window.localStorage.setItem(key, value);
}

export function removeItem(key) {
  window.localStorage.removeItem(key);
}

export function getObject(k) {
  return JSON.parse(window.localStorage.getItem(k));
}

export function setObject(key, object) {
  return window.localStorage.setItem(key, JSON.stringify(object));
}
