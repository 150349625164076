export const ACCOUNT_TYPE = "Account";
export const ALL_TYPE = "All";
export const GROUP_TYPE = "Group";
export const LEGACY_TYPE = "Legacy";
export const LOCATION_TYPE = "Location";
export const USER_TYPE = "User";
export const LOCATION = "LOCATION";
export const GROUP = "GROUP";
export const ACCOUNT = "ACCOUNT";
export const ALL = "ALL";
