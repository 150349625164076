import React from "react";

import emitter from "../eventEmitter";
import { REQUEST_MODAL, CLOSE_MODAL } from "./eventNames";

/******************************
 **   STEPS TO ADD A MODAL   **
 * 1. Drop <ModalComponent /> as top level DOM element in your container component
 * 2. Children any level can than use the modalService, or directly assemble to add/remove the modal
 *****************************/

const CLOSE_TIMER = 500;

class ModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      component: null,
      closeTimer: null,
    };

    this.changeModal = this.changeModal.bind(this);
    this.removeModal = this.removeModal.bind(this);
  }

  changeModal(nextComponent) {
    const { component, closeTimer } = this.state;

    if (component) {
      clearTimeout(closeTimer);
      //allow for transition

      let newCloseTimer;
      if (nextComponent) {
        newCloseTimer = setTimeout(() => {
          this.setState({ component: nextComponent });
        }, CLOSE_TIMER);
      }
      this.setState({
        component: null,
        closeTimer: newCloseTimer,
      });
    } else {
      this.setState({ component: nextComponent, closeTimer: null });
    }
  }

  removeModal() {
    this.changeModal(null);
  }

  componentWillMount() {
    emitter.on(REQUEST_MODAL, this.changeModal);
    emitter.on(CLOSE_MODAL, this.removeModal);
  }

  componentWillUnmount() {
    emitter.removeListener(REQUEST_MODAL, this.changeModal);
    emitter.removeListener(CLOSE_MODAL, this.removeModal);
  }

  render() {
    return this.state.component || <div />;
  }
}

export default ModalComponent;
