import React from "react";
import PropTypes from "prop-types";
import { VariableGroupEditor } from "./VariableGroupEditor";
import { ALL_VARIABLES } from "../AllVariables";
import "./editor.scss";

// TODO: Delete Me/Hide me once we have our themes settled. Or maybe only visible if super admin?
export class AllVariablesEditor extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    invalid: PropTypes.object,
    variables: PropTypes.object,
    sidebar: PropTypes.any,
  };

  render() {
    return ALL_VARIABLES.map((group, i) => (
      <VariableGroupEditor
        key={`variable_group_${i}`}
        onChange={this.props.onChange}
        invalid={this.props.invalid}
        variables={this.props.variables}
        sidebar={this.props.sidebar}
        group={group}
      />
    ));
  }
}
