import client from "../../services/session/apiClient";
import { LBTSAllowed } from "../app/site/featureAccessControl";

//sorry for this, but should make ids a little more forgiving
const getId = (post) => {
  if (typeof post._id === "string") {
    return post._id;
  } else if (post._id && post._id.$oid) {
    return post._id.$oid;
  } else {
    throw "Unable to locate post id";
  }
};

export function getSearchFilters() {
  return client.get("/v5/socialSearchFilters").then((r) => r.data);
}

export function saveSearchFilters(searchFilter) {
  return client.post("/v5/socialSearchFilters", searchFilter).then((r) => r.data);
}

export function deleteSearchFilters(searchFilterId) {
  return client.delete(`/v5/socialSearchFilters/${searchFilterId}`).then((r) => r.data);
}

export function postsNeedingApproval(queryParams) {
  return Promise.resolve({ posts: [] });
}

export const createPost = async (post) => {
  try {
    const response = await client.post("/chatter/publishing/posts", post);
    return response.data;
  } catch (e) {
    const SECEXC = "No credentials in your account have permission to publish to page.";

    let errorMsg = "We were not able to publish your post.";
    if (e.response.data && e.response.data.exception) errorMsg = e.response.data.exception;
    if (e.response.data && e.response.data.error) errorMsg = e.response.data.error;

    if (errorMsg.includes(SECEXC)) {
      throw new Error(SECEXC); // More user-friendly and standardized.
    } else {
      throw e;
    }
  }
};

export function interpolatePost(post, queryParams) {
  return client
    .post("/chatter/publishing/posts/interpolate?", post, { params: queryParams })
    .then((response) => response.data);
}

export function updatePost(post) {
  //just a bandaid until can update socket and http json objects to have the same format
  return client
    .put(`/chatter/publishing/posts/${getId(post)}`, { ...post, _id: getId(post) })
    .then((response) => response.data);
}

export function saveNote(post, note) {
  return client.put(`/chatter/publishing/posts/${getId(post)}/notes`, note).then((response) => response.data);
}

export function deletePost(post) {
  return client.delete(`/chatter/publishing/posts/${getId(post)}`);
}

export function publishingCredentials() {
  return client.get(`/chatter/publishing/credentials`).then((response) => response.data);
}

export function getPublishingDestinationsGroups({ providers, text }) {
  return client.get(`/chatter/publishing/destinationsV2/groups`, { params: { providers, text } }).then((r) => r.data);
}

export function getPublishingDestinationsPages({ providers, text, offset, limit }) {
  return client
    .get(`/chatter/publishing/destinationsV2/pages`, {
      params: {
        providers,
        text,
        offset,
        limit,
      },
    })
    .then((r) => r.data);
}

export function getPublishingDestinationsLocations({ providers, text, offset, limit }) {
  return client
    .get(`/chatter/publishing/destinationsV2/locations`, {
      params: {
        providers,
        text,
        offset,
        limit,
      },
    })
    .then((r) => r.data);
}

export function getPublishingDestinations(accountId, params) {
  return client.get(`/chatter/publishing/destinations/${accountId}`, { params }).then((r) => r.data);
}

export const getAvailableAppleCallToActions = async (publishingDestinations) => {
  const response = await client.post(`/chatter/publishing/apple/availableCallsToAction`, publishingDestinations);
  return response.data;
};

export const getHashtagMetricsList = async (hashtagMetricsParams) => {
  const response = await client.post("/social/hashtagMetrics/list", hashtagMetricsParams);
  return response.data;
};
