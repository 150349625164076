export function cssVar(variable) {
  return getComputedStyle(document.documentElement).getPropertyValue(variable);
}

window.cssVar = cssVar;

// TODO - I think we should just use cssVar directly, and not have these helpers.
// Should make variables more searcheable by ("cssVar" and directly by their variable name, e.g. "--gray-600")
export const gray600 = () => cssVar("--gray-600");

export const positive = () => cssVar("--positive");
export const neutral = () => cssVar("--neutral");
export const negative = () => cssVar("--negative");
export const cardBG = () => cssVar("--card-bg");
