import React, { Component } from "react";
import SimpleMDE from "simplemde";
import "simplemde/dist/simplemde.min.css";
import PropTypes from "prop-types";
import "./markdownEditor.scss";

/**
 *
 *
 * A lightweight wrapper around simplemde (Simple Markdown Editor).
 * This could be made more configurable. SimpleMDE has a lot of options you can pass it.
 *
 */
export class MarkdownEditor extends Component {
  static propTypes = {
    autofocus: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    hideToolbar: PropTypes.bool,
    placeHolder: PropTypes.string,
  };

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    const previewChanged = this.props.disabled !== prevProps.disabled;
    let configChanged =
      prevProps.hideToolbar !== this.props.hideToolbar || prevProps.placeHolder !== this.props.placeHolder;
    if ((previewChanged && this.element) || configChanged) {
      this.rebuildEditor(this.element);
    }
    let valueChanged = this.editor && this.props.value !== this.editor.value();
    if (valueChanged) {
      this.editor.value(this.props.value);
      if (this.props.disabled) {
        // hacky force redraw. double toggling preview works but its pretty flickery
        this.editor.togglePreview();
        this.editor.togglePreview();
      }
    }
  }

  rebuildEditor(element) {
    this.onChangeOff();
    const isPreviewing = this.props.disabled;
    this.element = element;
    this.editor && this.editor.toTextArea(); // destroy previous

    this.editor = new SimpleMDE({
      element,
      initialValue: this.props.value,
      spellChecker: false, // spell checker is crappy... would be nice if it plugged into the system spell checker like a <textarea> would
      status: false, // hide wordcount info at the bottom
      placeholder: this.props.placeHolder,
      toolbar:
        isPreviewing || this.props.hideToolbar
          ? false
          : [
              "bold",
              "italic",
              "strikethrough",
              "heading",
              // "heading-smaller", "heading-bigger", "heading-1", "heading-2", "heading-3",
              // "code",
              "quote",
              "unordered-list",
              "ordered-list",
              "clean-block",
              "link",
              //"image",
              "table",
              "horizontal-rule",
              "preview",
              // "side-by-side",
              "fullscreen",
              "guide",
            ],
    });

    if (isPreviewing) {
      this.editor.togglePreview();
    } else {
      this.editor.codemirror.on("change", this.onChange);
    }
  }

  onChangeOff() {
    this.editor && this.editor.codemirror.off(this.onChange);
  }

  setRef = (element) => {
    if (!this.editor && element) {
      this.rebuildEditor(element);
    }
  };

  onChange = () => {
    this.props.onChange && this.props.onChange(this.editor.value());
  };

  componentWillUnmount() {
    this.onChangeOff();
  }

  render() {
    const { className, style } = this.props;
    return (
      <div style={{ ...style }} className={`simplemde ${className || ""}`}>
        <textarea ref={this.setRef} />
      </div>
    );
  }
}
