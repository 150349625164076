import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Selector } from "..";

export default function PersonaForm({ username, onSubmit, onCancel }) {
  const validSalesRoles = [
    "C-Suite Executive",
    "VP/Director",
    "Sales Manager",
    "Business Development",
    "Account Manager",
    "Sales Rep",
    "Other",
  ];

  const validMarketingRoles = [
    "C-Suite Executive",
    "VP/Director",
    "Marketing Manager",
    "Account Manager",
    "SEO Specialist",
    "Social Media",
    "Marketing Specialist",
    "Customer Service",
    "Brand Manager",
    "Other",
  ];

  const validOperationsRoles = [
    "C-Suite Executive",
    "VP/Director",
    "Operations Manager",
    "Regional Manager",
    "Location/Store Manager",
    "Customer Service",
    "Other",
  ];

  const validProductRoles = [
    "C-Suite Executive",
    "VP/Director",
    "IT Manager",
    "Product Manager",
    "Business Analyst",
    "Other",
  ];

  const validDepartments = {
    Sales: validSalesRoles,
    Marketing: validMarketingRoles,
    Operations: validOperationsRoles,
    "Product/IT": validProductRoles,
    Other: ["Other"],
  };
  const otherDepot = validDepartments[validDepartments.length - 1];

  const validationSchema = Yup.object({
    department: Yup.string().test("departmentTest", "Please select a valid department", function (item) {
      return !!validDepartments[item];
    }),
    role: Yup.string().test("roleTest", `Please select a valid role`, function (item) {
      if (this.parent.department !== "Other") {
        return !!this.parent.department && validDepartments[this.parent.department].indexOf(item) >= 0;
      } else {
        return !!this.parent.department && this.parent.department.trim().length;
      }
    }),
  });

  const { handleSubmit, handleChange, values, errors, handleReset } = useFormik({
    initialValues: {
      department: "",
      role: "",
      otherRole: undefined,
    },
    validationSchema,
    onSubmit(values) {
      return onSubmit(values);
    },
  });

  return (
    <div className="card">
      <div className="card-header">
        <h2 className="card-title-lg">Hi {username}</h2>
        <h6 className="card-subtitle content-spacer-top">
          To help improve your experience, we would like to learn more about you.
        </h6>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="card-body">
          <div className="d-flex flex-column content-spacer-top">
            <span>
              What department do you currently work in at your company? <span className="text-danger">*</span>
            </span>
            <div className="col-6 content-spacer-top pl-0">
              <Selector
                selected={values.department}
                options={Object.keys(validDepartments)}
                placeholder="Choose Department"
                onSelected={(x) => {
                  // Reset to ensure the rest of the form is cleared so a new, valid option will be selected
                  handleReset();
                  handleChange({
                    type: "any",
                    target: {
                      name: "department",
                      value: x,
                    },
                  });
                }}
              />
              <span className="text-danger">{values.department && errors.department ? errors.department : null}</span>
            </div>
          </div>
          {!!values.department && (
            <div className="d-flex flex-column content-spacer-top">
              <span>
                What role do you play within your department? <span className="text-danger">*</span>
              </span>
              <div className="col-6 content-spacer-top pl-0">
                {values.department !== "Other" && (
                  <Selector
                    selected={values.role}
                    options={validDepartments[values.department]}
                    placeholder="Choose Role"
                    onSelected={(x) => {
                      handleChange({
                        type: "any",
                        target: {
                          name: "role",
                          value: x,
                        },
                      });
                    }}
                  />
                )}
                {values.department === "Other" && (
                  <input
                    className="form-control"
                    type="text"
                    value={values.role}
                    onChange={(e) => handleChange({ type: "any", target: { name: "role", value: e.target.value } })}
                  />
                )}
                <span className="text-danger">{values.role && errors.role ? errors.role : null}</span>
              </div>
            </div>
          )}
          {!!values.role}
        </div>
        <div className="card-footer card-padding-bottom">
          <div className="d-flex flex-row justify-content-end">
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => {
                handleReset();
                return onCancel();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-secondary control-spacer-left"
              disabled={!values.department || !values.role}
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
