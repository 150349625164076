import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Popover, PopoverBody } from "reactstrap";
import { SketchPicker } from "react-color";

const ThemePrimaryColorButton = (props) => {
  const { name, color, label, size, pickerDisabled, onColorSelected } = props;
  const [colorPicked, setColorPicked] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const popoverRef = useRef();

  useEffect(() => {
    if (!popoverOpen && colorPicked) onColorSelected({ name, value: colorPicked });
  }, [popoverOpen, colorPicked]);

  useEffect(() => {
    // Reset the color when you cancel the theme changes.
    if (color !== colorPicked && colorPicked !== null) {
      setColorPicked(color);
    }
  }, [color]);

  const togglePopover = (e) => {
    // Not sure why this is necessary, but Popover is triggering this function multiple times causing extra render cycles and flickering the UI.
    if (!popoverOpen || !!e) setPopoverOpen(!popoverOpen);
  };

  const handleColorPicking = () => {
    if (pickerDisabled) {
      setColorPicked(color);
    } else {
      togglePopover();
    }
  };

  const renderLabel = () => (
    <div className={"clickable"} onClick={handleColorPicking}>
      {label}
    </div>
  );

  const renderCircle = () => {
    const height = size;
    const width = size;
    const isVar = !!color && !!color.match(new RegExp("^\\$.*$"));
    const renderSafeColor = isVar ? `var(--${color.replace("$", "")})` : color;

    return (
      <div onClick={handleColorPicking}>
        <span
          ref={popoverRef}
          aria-label={label}
          className="d-inline-block rounded-circle clickable"
          style={{
            height,
            width,
            border: `var(--border-width) solid var(--card-bg-contrast)`,
            backgroundColor: colorPicked ? colorPicked : renderSafeColor,
          }}
        />
      </div>
    );
  };

  const renderPopover = () => {
    if (pickerDisabled) return undefined;
    return (
      <Popover placement="bottom" isOpen={popoverOpen} target={popoverRef} toggle={togglePopover} trigger={"legacy"}>
        <PopoverBody className="p-0 bg-white">
          <SketchPicker
            className="shadow-none"
            color={colorPicked ? colorPicked : color}
            presetColors={[]}
            onChangeComplete={(value) => {
              const colorValue =
                value.rgb.a < 1 ? `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, ${value.rgb.a})` : value.hex;
              setColorPicked(colorValue);
            }}
          />
        </PopoverBody>
      </Popover>
    );
  };

  return (
    <div className="mb-2 text-center">
      {renderCircle()}
      {renderLabel()}
      {renderPopover()}
    </div>
  );
};

ThemePrimaryColorButton.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  pickerDisabled: PropTypes.bool,
  onColorSelected: PropTypes.func,
};

export default ThemePrimaryColorButton;
