import { action, makeObservable, observable } from "mobx";
import { RiskCategory } from "../api";

export interface RiskCatogoryStoreState {
  riskCategories: RiskCategory[];
}
export class RiskCategoryStore {
  state: RiskCatogoryStoreState = {
    riskCategories: [],
  };

  constructor() {
    makeObservable(this, {
      state: observable,
      setState: action,
    });
  }

  setState(updates: Partial<RiskCatogoryStoreState>, callback?: () => void) {
    Object.assign(this.state, updates);
    callback && callback();
  }
}

export default new RiskCategoryStore();
