import React from "react";
import { action, makeAutoObservable } from "mobx";
import defaultSessionStore from "../session/SessionStore";
import {
  dismissNotification,
  loadNotification,
  unreadNotification,
  userNotificationPreferences,
} from "../../modules/notifications/list/notificationApi";

export class NotificationsStore {
  pageSize = 100;

  isInitializing = false;

  isLoading = false;

  hasMore = true;

  notifications = [];

  notificationPreferences;

  get countUnread() {
    return this.notifications.reduce((sum, notification) => sum + notification.count, 0);
  }

  constructor() {
    makeAutoObservable(this);
  }

  init() {
    this.isInitializing = true;
    return this.loadNotifications();
  }

  loadNotifications() {
    if (this.isLoading) return;
    const { user } = defaultSessionStore;
    this.isLoading = true;

    return Promise.all([
      unreadNotification().then(
        action("updateLoading", (resp) => {
          this.notifications = resp.notifications.filter((notification) => !!notification.trigger);
          this.isLoading = false;
        })
      ),
      userNotificationPreferences(user.userId).then(
        action("updateUserNotificationPreferences", (resp) => {
          this.notificationPreferences = resp.notificationPreferences;
        })
      ),
    ]);
  }

  loadNotification(notificationId) {
    return loadNotification(notificationId);
  }

  dismissNotification(notification) {
    const notificationIndex = this.notifications.findIndex((n) => n === notification);
    if (notificationIndex !== -1) {
      this.notifications.splice(notificationIndex, 1);
    }
    return dismissNotification(notification._id);
  }

  dismissAll() {
    const action = Promise.all(this.notifications.map((notification) => dismissNotification(notification._id)));
    this.notifications = [];
    return action;
  }

  processSocketNotification(notification) {
    const notificationIndex = this.notifications.findIndex((n) => n._id === notification._id);
    if (notificationIndex === -1) {
      this.notifications.unshift(notification);
    } else {
      this.notifications[notificationIndex] = notification;
    }
  }
}

export default new NotificationsStore();
