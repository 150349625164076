import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";
import { GradientPickerPopover } from "react-linear-gradient-picker";
import { useEffect } from "react";
const rgbToRgba = (rgb, a = 1) => rgb.replace("rgb(", "rgba(").replace(")", `, ${a})`);

const WrapperPropTypes = {
  onSelect: PropTypes.func,
};

const WrappedSketchPicker = ({ onSelect, ...rest }) => {
  return (
    <SketchPicker
      {...rest}
      color={rgbToRgba(rest.color, rest.opacity)}
      disableAlpha={true}
      presetColors={[]}
      onChange={(c) => onSelect(c.hex)}
    />
  );
};

const defaultAngle = 120;
const defaultPallete = [
  { offset: "0.00", color: "#CDD7E1" },
  { offset: "0.77", color: "#044982" },
];

const toLinearGradient = (pallet, angle) => {
  const colorsString = pallet
    .map((colorAndStop) => `${colorAndStop.color} ${(colorAndStop.offset * 100).toFixed(2)}%`)
    .join(", ");
  return `linear-gradient(${angle}deg, ${colorsString})`;
};

const fromLinearGradient = (linearGradient) => {
  // TODO - there has to be a more regex way to get the values out of the gradient
  //grab the values, multiple stops are supported but only one linear gradient at this time
  let values = linearGradient
    .substr(0, linearGradient.length - 1)
    .replace("linear-gradient(", "")
    .split(",")
    .map((str) => str.trim());
  const angle = parseInt(values.splice(0, 1)[0].replace("deg", ""));
  const palette = values.map((cssColor) => {
    const [color, cssOffset] = cssColor.split(" ");
    const offset = parseInt(cssOffset) / 100;
    return { offset, color };
  });

  return { angle, palette };
};

export const GradientSelector = ({ name, value, onChange }) => {
  let angle;
  let palette;

  const wrapperRef = useRef(null);

  // initial value is none for some themes
  if (value.indexOf("linear-gradient") === -1) {
    onChange(toLinearGradient(defaultPallete, defaultAngle));
    angle = defaultAngle;
    palette = defaultPallete;
  } else {
    try {
      const parsed = fromLinearGradient(value);
      angle = parsed.angle;
      palette = parsed.palette;
    } catch {
      angle = defaultAngle;
      palette = defaultPallete;
    }
  }
  const [open, setOpen] = useState(false);
  const handleClickOutside = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <div id="gradientSelectorContainer" ref={wrapperRef}>
      <GradientPickerPopover
        {...{
          open,
          setOpen,
          angle,
          setAngle: (value) => onChange(name, toLinearGradient(palette, value)),
          showAnglePicker: true,
          width: 220,
          paletteHeight: 32,
          palette,
          onPaletteChange: (value) => onChange(name, toLinearGradient(value, angle)),
        }}
      >
        <WrappedSketchPicker />
      </GradientPickerPopover>
    </div>
  );
};
