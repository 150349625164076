import * as React from "react";
import PropTypes from "prop-types";
import LoginStore from "../LoginStore";
import FullPageSpinner from "./FullPageSpinner";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import { getQueryObject } from "../../../util/UrlFragmentSearch";
import { useCallback, useEffect } from "react";

export interface LoginWithSSOTokenProps {
  persistent?: boolean;
  ssoToken: string;
  loginWithToken?: (token: string, opts: { persistent?: boolean }) => Promise<void>;
}

const LoginWithSSOToken: React.FC<LoginWithSSOTokenProps> = ({
  ssoToken,
  persistent = false,
  loginWithToken = LoginStore.tryLoginSSO,
}) => {
  const [didErr, setDidErr] = React.useState(false);

  const location = useLocation();
  const history = useHistory();
  const tryLogin = useCallback(async () => {
    setDidErr(false);
    try {
      await loginWithToken(ssoToken, {
        persistent,
      });

      const query = getQueryObject(location.search);
      if (query["redirect"] && typeof query["redirect"] === "string") {
        history.push(query["redirect"]);
      } else if (location.pathname.match(/^\/login/)) {
        history.push("/");
      } else {
        const modifiedSearch = (location.search || "").replace(/ssoToken=[\w\-]+&?/, "");
        history.push(location.pathname + modifiedSearch);
      }
    } catch (ex) {
      console.error(ex);
      setDidErr(true);
    }
  }, [ssoToken, persistent, didErr, location]);

  useEffect(() => {
    tryLogin();
  }, []);

  if (didErr) {
    return (
      <div data-testid="sso-failed" className="h-100 d-flex flex-column">
        <div className="alert alert-danger m-auto">
          Authentication Failed{" "}
          <a className="link" onClick={tryLogin} data-testid="sso-failed-retry">
            Try Again?
          </a>
        </div>
      </div>
    );
  } else {
    return <FullPageSpinner />;
  }
};

export default LoginWithSSOToken;
