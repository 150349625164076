import React, { Component } from "react";
import PropTypes from "prop-types";
import { toJS } from "mobx";

class InputList extends Component {
  static propTypes = {
    containerClassNames: PropTypes.string,
    placeholder: PropTypes.string,
    onListUpdate: PropTypes.func.isRequired,
    values: PropTypes.arrayOf(PropTypes.string),
  };
  state = {
    inputValue: "",
  };

  onInputChange(e) {
    this.setState({ inputValue: e.target.value });
  }

  onInputKeyPress(e) {
    switch (e.key) {
      case "Enter":
        const { inputValue } = this.state;
        const { onListUpdate, values } = this.props;
        const inputValueTrimmed = inputValue.trim();

        if (!values.find((v) => v === inputValueTrimmed)) {
          onListUpdate([...values, inputValueTrimmed]);
        }

        this.setState({ inputValue: "" });
        break;
    }
  }

  onValueRemoved(value) {
    const { values, onListUpdate } = this.props;
    onListUpdate(values.filter((v) => v !== value));
  }

  render() {
    const { containerClassNames, label, placeholder, values } = this.props;

    return (
      <>
        <div className={containerClassNames || "input-group"}>
          <input
            placeholder={placeholder || "Press Enter to Add"}
            value={this.state.inputValue}
            className="form-control"
            onChange={(e) => this.onInputChange(e)}
            onKeyPress={(e) => this.onInputKeyPress(e)}
          />
        </div>
        <div className="my-4 d-flex flex-wrap">
          {values.map((v) => (
            <span
              key={v}
              className="mr-2 mb-2 p-2"
              style={{ border: "var(--border)", borderRadius: "var(--border-radius)" }}
            >
              {v}
              <button type="button" className="close ml-2" aria-label="Close" onClick={() => this.onValueRemoved(v)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </span>
          ))}
        </div>
      </>
    );
  }
}

export default InputList;
