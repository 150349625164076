import * as _ from "lodash";
import { CancellationException, failPreviousUnresolvedPromises, stallUnresolvedPromises } from "../../util/async";
/**
 * @typedef {Object}    searchCache
 * @property {function} searchCache.getImmediate
 * @property {function} searchCache.getDebounced
 * @property {function} searchCache.getMore
 */

/**
 * @typedef {function} getSearchResults
 * @param {{query: string, offset: number}} options
 * @returns {
 *  Promise.<{items: Array.<*>, hasMore: boolean}> |
 *  Promise.<Array.<*>> |
 *  {items: Array.<*>, hasMore: boolean} |
 *  Array.<*>
 *  }
 */

const never = new Promise(() => {});

/**
 *
 * Utility object to keep cache of async search results, and handle pagination
 *
 * @param {getSearchResults} getSearchResults
 * @param {function} onSearchResults
 * @param {number} debounce
 * @param {any} [additional=null] - initial "additional", otherwise null
 * @param {boolean} disableCaching
 * @return {searchCache}
 */
export function SearchCache({
  getSearchResults,
  onSearchResults = () => {},
  debounce,
  additional = null,
  disableCaching,
}) {
  let cache = {};
  let lastQuery = "";
  let fetchingMore = false;

  const onlyLatest = stallUnresolvedPromises((query) => {
    lastQuery = query;
    fetchingMore = false;
    return query in cache && !disableCaching
      ? Promise.resolve(cache[query])
      : Promise.resolve(getSearchResults({ query: query, offset: 0, additional })).then((result) => {
          if (!result) {
            console.warn("got undefined or null result for search query");
          }
          const wrapped = "items" in result ? result : { items: result, hasMore: false };
          cache[query] = wrapped;
          return wrapped;
        });
  });

  const getImmediate = (query) => {
    const promise = onlyLatest(query).catch((ex) => {
      if (ex.name === CancellationException) {
        return never;
      } else {
        throw ex;
      }
    });
    promise.then((results) => onSearchResults(results));
    return promise;
  };

  function getMore() {
    const myQuery = lastQuery;
    if (!(myQuery in cache)) {
      throw new Error('cannot call getMore before any search results found for "' + myQuery + '"');
    }
    if (fetchingMore) {
      throw new Error("already fetching more");
    }

    const { hasMore: hadMore, items: lastItems, additional: lastAdditional } = cache[myQuery];
    if (!hadMore) {
      // console.warn('called searchCache.getMore(), but there are no more results')
      return Promise.resolve({ items: lastItems, hasMore: false, additional: lastAdditional });
    } else {
      fetchingMore = true;
      const results = Promise.resolve(
        getSearchResults({ query: myQuery, offset: lastItems.length, additional: lastAdditional })
      ).then(({ items, hasMore, additional }) => {
        cache[myQuery] = { hasMore, items: lastItems.concat(items), additional };
        if (myQuery !== lastQuery) {
          throw new Error(
            `query changed from ${myQuery} to ${lastQuery}. Bailing, as more results are no longer relevant`
          );
        }
        return cache[lastQuery];
      });
      results.finally(() => (fetchingMore = false));
      results.then((result) => onSearchResults(result, true));
      return results;
    }
  }
  function invalidateCache() {
    cache = {};
  }

  return {
    getImmediate: getImmediate,
    getDebounced: _.debounce(getImmediate, debounce),
    getMore: getMore,
    invalidateCache,
  };
}
