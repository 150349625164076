const storageBackend = localStorage;

// TODO this is currently too leaky with localStorage, and burns through the storage quota

/**
 * cleans out caches for previous tokens. More of a hack fix for the above storage pattern problems
 */
export function removeExcessLocalStorageCaches() {
  const sessionToken = sessionStorage.getItem("ssoToken");
  const primaryToken = localStorage.getItem("token");
  const tokens = [sessionToken, primaryToken].filter((x) => !!x);
  for (const key of Object.keys(storageBackend)) {
    const hasUUID = key.match(/.*[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
    const isThisUUID = tokens.some((token) => key.includes(token));
    if (hasUUID && !isThisUUID) {
      storageBackend.removeItem(key);
    }
  }
}

export default function (key) {
  return {
    get() {
      return JSON.parse(storageBackend.getItem(key));
    },
    getOrElse(defaultValue) {
      const cache = storageBackend.getItem(key);
      if (cache) {
        return JSON.parse(cache);
      } else {
        return defaultValue;
      }
    },
    hasValue() {
      const cache = storageBackend.getItem(key);
      return !!cache;
    },
    set(value) {
      storageBackend.setItem(key, JSON.stringify(value));
    },
  };
}
