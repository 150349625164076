import * as React from "react";
import { RouteComponentProps } from "react-router";
import * as H from "history";

export interface SiteMap {
  siteMapTitle?: string;
  drillIn?: boolean;
  routes: SiteMapNode[];
}

export interface RouteNode {
  /** e.g. "/some/path" */
  path: string;
  /** a react component constructor / function */
  component: React.ComponentType<RouteComponentProps>;
  /** if the route should only match an exact path, not a prefix */
  exact?: boolean;
  /** route should be excluded due to lacking permissions */
  notPermitted?: boolean;
  /** tracking event name to record when route is clicked */
  eventName?: string;
  /** sub pages that are inherently children of this route, e.g. a list page of "/people" might have a "/people/:personId" drill-in page */
  drillInPages?: DrillInRouteNode[];
  /** if the route should only be accessible by a user or account with specific permissions */
  isAccessible?: () => boolean;
}

export interface DrillInRouteNode extends RouteNode {
  // optionally set a title on the drill in pages.
  // Can also be set with <UsePageTitle/><PageTitle/>My Title</PageTitle></UsePageTitle>
  title?: string;
}

export interface SiteMapNode {
  /**
   * path that this node links to. If no children are defined on this node, needs to have a
   * corresponding route with the exact same path in order to be rendered, otherwise assumes
   * that the route is blocked for access control
   */
  path: string;
  /** title to be displayed in menu */
  title: string | (() => string);
  /**
   * icon to use in menu item
   * You can either use a classname to specify a font awesome icon or a SVG react component
   *
   */
  icon?: string | React.FunctionComponent<React.SVGAttributes<SVGElement>>;

  // Title text used for accessibility
  altText?: string;

  /**
   * true if this item should "portal" to another route rather than navigating the browser
   */
  isConfigPortal?: boolean;

  /** causes no title to be displayed (must be manually marked up) */
  hasPageTitleOverride?: boolean;
  /**
   * controls highlight state of the menu item. May be required if there's an index style
   * route path that is a prefix with other routes' paths
   */
  exact?: boolean;
  /** array of child menu nodes. Only three levels of menu nodes are supported */
  children?: SiteMapNode[]; // children are recursive siteMapNode's
  /**
   * the default route to redirect to should the user be at the root of the navigation
   * if no url is specified, then the first available route will be used
   */
  defaultUrl?: string;
  /**
   * Used in L2 navigation to partition links into sections. The Section Tag is the label for the section.
   * All nodes with a same sectionTag are grouped into the same section. Nodes without a sectionTag
   * are assigned into a "Default" section. If a node has a single section, it is not displayed in the UI.
   */
  sectionTag?: string;
  helpIconMessage?: string;
}

export interface NavigationNode {
  isSection: false;
  path: string; // path to link to
  sectionTag?: string; // a section to group this node under
  exact: boolean; // for routing purposes. Not sure if this is needed?
  title: string | (() => string); // title to be shown
  icon?: string | React.FunctionComponent<React.SVGAttributes<SVGElement>>; // icon to be rendered with the title
  active: boolean; // whether this node is active
  altText?: string; // Title text used for accessibility
}

export interface NavigationSection {
  isSection: true;
  title: string;
  hideTitle: boolean;
  children: NavigationNode[];
}

export function isNavigationSection(nav: NavigationNode | NavigationSection): nav is NavigationSection {
  return nav.isSection;
}

export interface AppNavigatorContextValue {
  /** nav items at each supported depth. Filtered to those that should be displayed in a nav list */
  // TODO - this L1 (Level 1) through L3 abstraction isn't quite fitting. Some sitemaps want to render a non-nested list of sectioned content
  //      - while others want expandable sections. Serve a similar purpose of grouping. Its more like a
  //        L1 and L2 only that is the consistent model. In the main navigation, L1s are just the accordion titles
  //        in the settings section, the sitemap is sectioned, and the section titles serve as the grouping
  navL1: NavigationSection[];
  navL2: NavigationSection[];
  navL3: NavigationNode[];
  /** route config for the currently active route (includes stuff like page title and icon) */
  activeNode?: ActiveNavigationNode;
  /** Passed to be able to identify which sitemap we are using in the layout.
   * index based ids, with the "primary" site map being 0
   */
  siteMapId: number;
  /** Used to set the last primary sitemap location */
  location?: H.Location;
  /** TODO - change this to checkpointSitemapLocation(): void and ditch the location attribute */
  setPreviousPrimarySitemapLocation: (location: string) => void;
  /** ??? */
  previousPrimarySitemapLocation?: string;
  /** whether this site map is a secondary one (i.e siteMapId !== 0).  */
  isDrillInSiteMap: boolean;
  /** a title to display for the secondary site map */
  siteMapTitle?: string;
}

export interface ActiveNavigationNode {
  path: string;
  title?: string;
  hasPageTitleOverride?: boolean;
  parent?: string;
  isDrillInPage?: boolean;
}

export const EMPTY_NAVIGATION_CONTEXT: AppNavigatorContextValue = {
  navL1: [],
  navL2: [],
  navL3: [],
  activeNode: undefined,
  siteMapId: 0,
  location: undefined,
  setPreviousPrimarySitemapLocation: () => {},
  previousPrimarySitemapLocation: undefined,
  isDrillInSiteMap: false,
};
