import axios from "axios";
import config from "../../../config";
import baseClient, { getAuthToken } from "../../../services/session/apiClient";

const client = axios.create({ ...baseClient.defaults, baseURL: config.notificationsApiUrl });
client.interceptors.request.use((cfg) => {
  cfg.headers.set("Authorization", getAuthToken());
  return cfg;
});
export default client;
