import React from "react";
import PropTypes from "prop-types";
import RenderToPortal from "../../../components/portals/RenderToPortal";
import { BACK_BUTTON } from "../navigator/navigatorPortalNames";
import RegisterPortal from "../../../components/portals/RegisterPortal";
import { Link } from "react-router-dom";
import { AppNavigatorContext } from "../../app/navigator/AppNavigator";

export default function UseBackButton({ to, useBrowserBack, label }) {
  return (
    <RenderToPortal id={BACK_BUTTON}>
      <div
        style={{
          fontSize: "1.5rem",
        }}
      >
        <AppNavigatorContext.Consumer>
          {(context) => {
            if ((!context.activeNode || !context.activeNode.parent) && !useBrowserBack && !to) return null;
            else if (!useBrowserBack) {
              return (
                <Link
                  to={to !== undefined ? to : context.activeNode.parent}
                  title={context.activeNode?.title}
                  className="text-body card-spacer-right"
                >
                  <i className="fas fa-arrow-left"></i> {label}
                </Link>
              );
            } else {
              return (
                <Link onClick={() => history.back()} title={label} className="text-body card-spacer-right">
                  <i className="fas fa-arrow-left"></i> {label}
                </Link>
              );
            }
          }}
        </AppNavigatorContext.Consumer>
      </div>
    </RenderToPortal>
  );
}

UseBackButton.propTypes = {
  to: PropTypes.string,
  useBrowserBack: PropTypes.bool,
  label: PropTypes.string,
};

UseBackButton.defaultProps = {
  useBrowserBack: false,
};

/** Back Button (if any) will be rendered to this element. Can register multiple times, and the last declared portal will win */
export function RegisterBackButton() {
  return <RegisterPortal id={BACK_BUTTON} />;
}
