import * as React from "react";
import PropTypes from "prop-types";
import { PlainLogin } from "./PlainLogin";
import { LoginView } from "../../../uiMigration/LoginView";

const link = PropTypes.shape({
  enabled: PropTypes.bool,
  url: PropTypes.string,
  label: PropTypes.string,
});

export const renderUsernamePasswordProps = {
  username: PropTypes.string,
  onUsernameChanged: PropTypes.func,
  password: PropTypes.string,
  onPasswordChanged: PropTypes.func,
  isKnownInvalid: PropTypes.bool,
  errorMessage: PropTypes.string,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func,
  passwordResetUrl: link,
  registrationUrl: link,
  termsOfService: link,
  logoUrl: PropTypes.string,
};

export class GenericLoginWithPasswordData extends React.Component {
  static propTypes = {
    onLoginSuccess: PropTypes.func.isRequired,
    brandConfig: PropTypes.object.isRequired,
    logoUrl: PropTypes.string,
    /** @typedef {function({username: string, password: string}): Promise.<false | {errorMessage: string, isKnownInvalid: boolean}>} */
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    username: "",
    password: "",
    isKnownInvalid: false,
    isSubmitting: false,
  };

  tryLogin = (e) => {
    const { username, password, isKnownInvalid, isSubmitting } = this.state;
    if (!isKnownInvalid && !isSubmitting) {
      this.setState({ isSubmitting: true });

      this.props
        .onSubmit({ username, password })
        .then((loggedIn) => {
          if (loggedIn === true) {
            this.props.onLoginSuccess();
          } else {
            this.setState({
              isKnownInvalid: loggedIn && loggedIn.isKnownInvalid,
              errorMessage: loggedIn && loggedIn.errorMessage,
              isSubmitting: false,
            });
          }
        })
        .catch((ex) => {
          console.error("kaboom", ex);
          this.setState({
            isKnownInvalid: false,
            errorMessage: (ex && ex.message) || "Authentication Error.",
            isSubmitting: false,
          });
        });
    }
    e && e.preventDefault();
  };

  setUsername = (e) => this.setState({ username: e.target.value, isKnownInvalid: false });
  setPassword = (e) => this.setState({ password: e.target.value, isKnownInvalid: false });

  render() {
    const { tryLogin, setUsername, setPassword } = this;
    const { username, password, isKnownInvalid, isSubmitting, errorMessage } = this.state;
    /** @type {BrandConfig} */
    const bc = this.props.brandConfig;
    return (
      <LoginView
        username={username}
        password={password}
        isKnownInvalid={isKnownInvalid}
        errorMessage={errorMessage}
        isSubmitting={isSubmitting}
        passwordResetUrl={bc.passwordReset}
        registrationUrl={bc.registrationUrl}
        termsOfService={bc.termsOfService}
        logoUrl={bc.restrictions.hideLogo ? undefined : bc.logoUrl}
        onUsernameChanged={setUsername}
        onPasswordChanged={setPassword}
        onSubmit={tryLogin}
      />
    );
  }
}
