import { ProviderImage } from "../../components";
import React from "react";
import * as PropTypes from "prop-types";
import { observer } from "mobx-react";
import ProvidersStore from "./ProvidersStore";

export class SelectedProviders extends React.Component {
  static propTypes = { providers: PropTypes.arrayOf(PropTypes.string) };

  render() {
    const { providers, size, activeSize } = this.props;
    return (
      <div className="d-flex align-items-center">
        {providers.map((p) => (
          <span key={p} className="selector-selection-badge content-spacer-right">
            <ProviderItem provider={p} size={size} />
          </span>
        ))}
      </div>
    );
  }
}

export const ProviderName = observer(
  class ProviderName extends React.Component {
    static propTypes = {
      provider: PropTypes.string.isRequired,
    };

    render() {
      return ProvidersStore.displayNameFor(this.props.provider);
    }
  }
);

export class ProviderItem extends React.Component {
  static propTypes = {
    provider: PropTypes.string.isRequired,
    style: PropTypes.object,
  };
  render() {
    const { provider, size } = this.props;
    return (
      <span className="d-flex align-items-center">
        <ProviderImage className="d-flex align-self-center" size={size ? size : "sm"} provider={provider} />{" "}
        <div className="d-flex ml-1">
          <ProviderName provider={provider} />
        </div>
      </span>
    );
  }
}
