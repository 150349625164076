import * as FAC from "../../modules/app/site/featureAccessControl";

const formatImgURL = (str: string) => str.replace(/ /g, "").replace(".com", "");
const svgFilePath = "/images/providers/";
const pngFilePath = "/images/reviews/";

/**
 * Generate url for provider icon image
 * DFL-740: another hacky solution to show survey provider icon for review
 * builder when survey feature flag is enabled.
 */
export function generateProviderUrl(provider: string, assetUrl: string, hasSvg: boolean = false): string {
  const filePath = hasSvg ? svgFilePath : pngFilePath;
  const extension = hasSvg ? "svg" : "png";

  if (provider === "REVIEWBUILDER" && FAC.surveysAllowed()) {
    return `${assetUrl}${svgFilePath}surveys.svg`;
  } else {
    return `${assetUrl}${filePath}${formatImgURL(provider.toLowerCase())}.${extension}`;
  }
}
