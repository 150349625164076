import * as React from "react";
import * as PropTypes from "prop-types";
import { debounce } from "lodash";

/**
 * Text inputs inside of cards will have the same background as the body background by default. Inputs outside of cards are considered "floating" and need to be wrapped in a `.floating-control` class to give them the card background. The class cascades, so you can add it to the parent of a number of text inputs.
 */

export class BufferedTextSearch extends React.Component {
  static propTypes = {
    autoSubmit: PropTypes.bool,
    autoSubmitDuration: PropTypes.number,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onValueChanged: PropTypes.func,
    onValueSubmitted: PropTypes.func,
    icon: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    onValueSubmitted: () => {},
    autoSubmitDuration: 1000,
    className: "form-control",
  };

  constructor(props) {
    super(props);
    this.state = {
      lastValue: props.value,
      value: props.value,
    };

    if (this.props.autoSubmitDuration) {
      this.scheduleSubmit = debounce(this.scheduleSubmit, this.props.autoSubmitDuration);
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.value !== this.props.value && !this.isValueControlled && this.value === this.state.lastValue) {
      this.setState({
        lastValue: nextProps.value,
        value: nextProps.value,
      });
    }
  }

  get isValueControlled() {
    return !!("value" in this.props && this.props.onValueChanged);
  }

  onValueChanged = (e) => {
    this.setValue(e.target.value);
  };
  setValue = (value) => {
    if (this.isValueControlled) {
      this.props.onValueChanged(value);
    } else {
      this.setState({ value: value });
    }
    if (this.props.autoSubmit) {
      this.scheduleSubmit();
    }
  };

  scheduleSubmit = () => {
    this.submit(this.value);
  };

  onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.submit(e.target.value);
    } else if (e.key === "Escape") {
      e.preventDefault();
      if (this.value !== this.state.lastValue) {
        this.setValue(this.state.lastValue);
      }
    }
  };

  onBlur = (e) => this.submit(this.value);

  submit(value) {
    if (value !== this.state.lastValue) {
      let cancel = this.scheduleSubmit.cancel;
      cancel && cancel();
      this.setState({ lastValue: value });
      this.props.onValueSubmitted(value);
    }
  }

  get value() {
    return this.isValueControlled ? this.props.value : this.state.value;
  }

  render() {
    return (
      <input
        className={this.props.className}
        placeholder={this.props.placeholder}
        onKeyDown={this.onKeyDown}
        onBlur={this.onBlur}
        type="text"
        value={this.value || ""}
        onChange={this.onValueChanged}
      />
    );
  }
}
