import React from "react";

const ListingRowPhotoCount = ({ listing, providers }) => {
  const displaysImages =
    providers &&
    providers[listing.contentProvider] &&
    providers[listing.contentProvider].displaySettings.displaysImages;
  const photoCount = !displaysImages ? "N/A" : listing.stats.photoCount;

  return <span>{photoCount}</span>;
};

export default ListingRowPhotoCount;
