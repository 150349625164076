import PropTypes from "prop-types";
import * as React from "react";
import { useContext } from "react";
import BrandConfigStore from "../services/config/BrandConfigStore";
import SessionStore from "../services/session/SessionStore";
import LoginStore from "../services/session/LoginStore";
import ThemeStore from "../services/config/ThemeStore";

export const shape = PropTypes.shape({
  hideNotifications: PropTypes.bool.isRequired,
  hideWelcomeText: PropTypes.bool.isRequired,
  hideApplicationBar: PropTypes.bool.isRequired,
  hideEmailAlerts: PropTypes.bool.isRequired,
  hideMyAccount: PropTypes.bool.isRequired,
  hideLogout: PropTypes.bool.isRequired,
  logoUrl: PropTypes.string,
  hideLogo: PropTypes.bool.isRequired,
});

/**
 * @typedef {object} NavPreferences
 * @property hideEmailAlerts: boolean
 * @property hideManageGroups: boolean
 * @property hideLogout: boolean
 * @property hideNotifications: boolean
 * @property hideMyAccount: boolean
 * @property hideManageLocations: boolean
 * @property userFirstName: string
 * @property userLastName: string
 * @property userFullName: string
 * @property userEmail: string
 * @property hideWelcomeText: boolean
 * @property hideApplicationBar: boolean
 * @property hideLogo: boolean
 * @property logoUrl?: string
 * @property hasOptedIntoNewLayout: boolean
 */

/**
 *
 * @returns {NavPreferences}
 */
export function compute() {
  const config = BrandConfigStore.brandConfig || {};
  const logoUrl = ThemeStore.logoUrl;
  const restrictions = config.restrictions || {};
  const isIframe = BrandConfigStore.isIframe;
  const user = SessionStore.user;
  const permissions = (user && user.permissions) || [];

  return {
    hideNotifications: restrictions.hideNotificationsSettings,
    hideWelcomeText: restrictions.hideWelcomeText,
    hideApplicationBar: restrictions.hideApplicationBar,
    hideEmailAlerts: restrictions.hideEmailAlertsAdmin || !permissions.includes("ShowEmailAlerts"),
    hideMyAccount: restrictions.hideAccount || permissions.includes("ReadOnly"),
    hideLogout: restrictions.hideLogout || (restrictions.hideLogoutButtonOnSSO && LoginStore.isSSO),
    logoUrl: logoUrl,
    hideLogo: restrictions.hideLogo || isIframe,
    hideManageLocations: !["AddLocation", "EditLocation", "DeleteLocation"].find((x) => permissions.includes(x)),
    hideManageGroups: !permissions.includes("GroupManagement"),
    userFirstName: (user && user.firstName) || "",
    userLastName: (user && user.lastName) || "",
    userFullName: `${user.firstName}${user.firstName ? " " : ""}${user.lastName}`,
    userEmail: user.email,
    hasOptedIntoNewLayout: SessionStore.userPreferences.hasOptedIntoNewLayout || false,
    hideHelpCenter: config.restrictions.hideHelpCenter,
  };
}

/**
 * this is a bit of a hack to inject custom preferences in for testing without having to rely on mobx store.
 * A provider can set the context value to a {NavPreferences}
 * @type {ContextType<NavPreferences | undefined>}
 * */
export const NavPreferencesContext = React.createContext(undefined);

export const NavPreferencesProvider = ({ prefs, children }) => {
  return <NavPreferencesContext.Provider value={prefs}>{children}</NavPreferencesContext.Provider>;
};

NavPreferencesProvider.propTypes = {
  prefs: shape,
};

/**
 *
 * Components consuming this react hook should ensure that they are observers in order to subscribe to updates of the
 * NavPreferences
 * @returns {NavPreferences}
 */
export function useNavPreferences() {
  const context = useContext(NavPreferencesContext);
  if (context) {
    return context;
  } else {
    return compute();
  }
}
