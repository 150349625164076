import apiClient from "./apiClient";
import qs from "qs";
import config from "../../config";
import SessionStore from "./SessionStore";

/**
 * Responsible for managing oauth login flow between 3rd party sites
 *
 * 1) Opens a child loading window immediately (so as to not prompt pop-up confirmation)
 * 2) fetches and redirects child window to provider site based on server's app token. Starts monitoring child window for closure
 * 3) user accepts, enters username password on provider site
 * 4) provider site redirects back to the dashboard with a token in the url. Dashboard adds and redirects to front end app
 *    for a whitelabeled confirmation view. Child window shows error or closes itself
 * 5) Once child window is closed, resolves the promise so credentials can be refetched
 */

export function requestUserAccessFromProvider(provider) {
  let featureString =
    window.top !== window
      ? `rel=opener,location=1,status=1,width=${Math.min(600, window.outerWidth)},height=${Math.min(
          600,
          window.outerHeight
        )}`
      : undefined;

  // open child window before async stuff so that the opener attribute exists
  let childWindow = window.open(
    `${config.apiUrl}/auth/initiate/${provider}?userId=${SessionStore.user.userId}`,
    "_blank",
    featureString
  );

  return new Promise((resolve, reject) => {
    const start = new Date().valueOf();
    const maxTime = 60 * 60 * 1000;
    const timer = setInterval(() => {
      const now = new Date().valueOf();
      if (now - start > maxTime) {
        clearInterval(timer);
        reject(new Error(`max time of ${maxTime}ms exceeded`));
      } else if (childWindow.closed) {
        clearInterval(timer);
        resolve(undefined);
      }
    }, 200);
  });
}

/**
 * extracts error code from the callback url, if any
 */
export function extractErrorMessage(url) {
  let qsMatches = url.match(/\?(.*)#/),
    query = {};
  if (qsMatches?.length > 1) {
    query = qs.parse(qsMatches[1]);
  }
  let errorCode = query.error_code;
  let errorMessage = query.error_message;
  if (errorCode || errorMessage) {
    return [errorCode, errorMessage].filter((x) => x).join(": ");
  }
}
