import React from "react";
import PropTypes from "prop-types";
import { Component } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export class Confirm extends Component {
  static propTypes = {
    onContinue: PropTypes.func,
    onCancel: PropTypes.func,
    toggle: PropTypes.func,
    header: PropTypes.any,
    message: PropTypes.any,
    visible: PropTypes.bool,
    cancelText: PropTypes.string,
    showCancelButton: PropTypes.bool,
    confirmText: PropTypes.string,
    zIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };
  static defaultProps = {
    cancelText: "Cancel",
    showCancelButton: true,
  };

  hide = () => {
    this.props.onCancel && this.props.onCancel();
    this.props.toggle();
  };

  continue = () => {
    this.props.onContinue && this.props.onContinue();
    this.props.toggle();
  };

  render() {
    return (
      <Modal toggle={this.hide} isOpen={this.props.visible} zIndex={this.props.zIndex}>
        <ModalHeader>{this.props.header || "Continue?"}</ModalHeader>
        <ModalBody>{this.props.message || "Really Continue?"}</ModalBody>
        <ModalFooter>
          {this.props.showCancelButton && (
            <Button className="mr-0" color="secondary" outline={true} onClick={this.hide}>
              {this.props.cancelText}
            </Button>
          )}
          <Button className="control-spacer-left" color="secondary" onClick={this.continue}>
            {this.props.confirmText ? this.props.confirmText : "Continue"}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
