import * as mobx from "mobx";
import { runInAction, toJS } from "mobx";
import CreateOrEditStore from "./CreateOrEditStore";
import {
  deleteNotificationTrigger,
  getNotificationTrigger,
  listEventTypes,
  listNotificationTriggers,
} from "./notificationTriggersApi";
import { recordEvent } from "../../app/plugins/metricPlugin";
import BrandConfigStore from "../../../services/config/BrandConfigStore";

const RESULTS_PER_PAGE = 10;

//TODO - Toast Notifications for CUD methods
export class NotificationTriggersStore {
  //TODO - maybe bring loading and saving into one var and keep an enum of states
  totalNotificationTriggers = 0;
  currentPage = 0;
  notificationTriggers = [];
  notificationTrigger = undefined;
  loadingNotificationTriggerList = false;
  loadingNotificationTypes = false;
  loadingNotificationTrigger = false;
  hasMore = false;
  errors = undefined;
  savingNotificationTrigger = false;
  saveOrEditStore;
  selected = [];
  eventTypes = [];

  constructor() {
    mobx.makeAutoObservable(this, {}, { autoBind: true });
  }

  /**
   * loads a list of notifications
   */
  loadNotificationTriggers = () => {
    this.loadingNotificationTriggerList = true;
    return listNotificationTriggers({ limit: RESULTS_PER_PAGE, offset: RESULTS_PER_PAGE * this.currentPage })
      .then((resp) => {
        runInAction(() => {
          this.hasMore = resp.totalCount > RESULTS_PER_PAGE;
          this.notificationTriggers = resp.notificationTriggers.map((trigger) =>
            this.buildNotificationTriggerViewModel(trigger)
          );
          this.totalNotificationTriggers = resp.totalCount;
          if (this.notificationTriggers.length === 0 && this.currentPage > 0) {
            this.goToPage(this.currentPage - 1);
          }
        });
      })
      .catch((ex) =>
        runInAction(() => {
          //TODO - what kind of errors?
          this.errors = ex.data;
        })
      )
      .finally(() =>
        runInAction(() => {
          this.loadingNotificationTriggerList = false;
        })
      );
  };

  loadListEventTypes = () => {
    this.loadingNotificationTypes = true;
    return listEventTypes()
      .then((resp) => {
        runInAction(() => {
          // Sort event types by alphabetical order
          resp.sort((a, b) => (a.eventType < b.eventType ? -1 : 0));
          this.eventTypes = resp;
        });
      })
      .catch((ex) =>
        runInAction(() => {
          this.errors = ex.data;
        })
      )
      .finally(() =>
        runInAction(() => {
          this.loadingNotificationTypes = false;
        })
      );
  };

  buildNotificationTriggerViewModel = (trigger) => {
    switch (trigger.eventType) {
      case EventType.REVIEW_RECEIVED:
        if (trigger.criteria && trigger.criteria.reviewRecommendations) {
          trigger.criteria.reviewStars = [
            ...(trigger.criteria.reviewStars || []),
            ...trigger.criteria.reviewRecommendations,
          ];
        }
        break;
      default:
      // no mutations
    }
    return trigger;
  };

  get totalPages() {
    return Math.ceil(this.totalNotificationTriggers / RESULTS_PER_PAGE);
  }

  /**
   * Navigate to a page
   * @param {number} page the page to navigate
   */
  goToPage(page) {
    this.currentPage = page - 1;
    this.loadNotificationTriggers();
  }

  loadNotificationTrigger(notificationId) {
    this.loadingNotificationTrigger = true;
    getNotificationTrigger(notificationId)
      .then((notificationTrigger) => {
        runInAction(() => {
          this.loadingNotificationTrigger = false;
          this.notificationTrigger = notificationTrigger;
        });
      })
      .catch((ex) => {
        this.loadingNotificationTrigger = false;
        //TODO - aside from 404 what else?
        this.errors = ex.data;
      })
      .finally(() =>
        runInAction(() => {
          this.loadingNotificationTrigger = false;
        })
      );
  }

  /**
   * @param {boolean} checked
   * @param {string} notificationTriggerId
   */
  toggleSelection(checked, notificationTriggerId) {
    if (checked) {
      this.selected.push(notificationTriggerId);
    } else {
      const index = this.selected.findIndex((id) => id === notificationTriggerId);
      if (index !== -1) {
        this.selected.splice(index, 1);
      }
    }
  }

  get selectedLookup() {
    const map = this.selected.reduce((m, current) => {
      m[current] = true;
      return m;
    }, {});
    return map;
  }

  get hasSelection() {
    return !!this.selected.length;
  }

  deleteSelected() {
    this.loadingNotificationTrigger = true;
    return Promise.all(this.selected.map((id) => deleteNotificationTrigger(id))).then(() => {
      this.selected = [];
      return this.loadNotificationTriggers();
    });
  }

  // Create or Edits store
  createNewEventTrigger() {
    this.saveOrEditStore = new CreateOrEditStore(this.eventTypes);
  }

  editEventTrigger(trigger) {
    this.saveOrEditStore = new CreateOrEditStore(this.eventTypes, toJS(trigger));
  }

  cancelChanges() {
    this.saveOrEditStore = undefined;
  }

  saveChanges() {
    return this.saveOrEditStore.saveNotificationTrigger(this.saveOrEditStore.triggerForApi);
  }

  transformEventTypesToSelectOptions = () => {
    return this.eventTypes
      .filter((eventType) => eventType.active)
      .map((e) => {
        let eventTypeView = toJS(e);
        return {
          key: eventTypeView.eventType,
          value: eventTypeView.displayName.name,
          automaticName: eventTypeView.automaticName.name,
        };
      });
  };
}

export const NotificationChannel = {
  DASHBOARD_NOTIFICATION: "DashboardNotification",
  WEB_BROWSER_NOTIFICATION: "WebBrowserNotification",
  EMAIL: "Email",
  PHONE_CALL: "PhoneCall",
  FACEBOOK_MESSENGER: "FacebookMessenger",
  PUSH_NOTIFICATION: "PushNotification",
  SMS: "Sms",
};

export const EventType = {
  REVIEW_RECEIVED: "ReviewReceived",
  REVIEW_UPDATED: "ReviewUpdated",
  RESPOND_TO_REVIEW: "ReviewRespondedTo",
  GOOGLE_QUESTIONS_AND_ANSWERS: "GoogleQuestionAndAnswer",
  CREDENTIAL_EXPIRED: "CredentialExpired",
  LISTING_INFORMATION_UPDATED: "ListingInformationUpdated",
  LISTING_INFORMATION_NEEDS_REVIEW: "ListingInformationNeedsReview", // If listing mismatches occur
  LISTING_RANK_CHANGED: "ListingRankingChanged",
  WORKFLOW_TASK_APPROVAL_NEEDED: "WorkflowTaskApprovalNeeded",
  WORKFLOW_TASK_CREATED: "WorkflowTaskCreated", //Prompts users to assign workflow task to a user
  WORKFLOW_TASK_OVERDUE: "WorkflowTaskOverdue",
  WORKFLOW_TASK_ASSIGNED: "WorkflowTaskAssignedToYou", //Prompts user to assign workflow task
  WORKFLOW_TASK_COMMENTED_ON: "WorkflowTaskCommentedOn", //Allows workflow task manager to monitor workflow task progress
  WORKFLOW_TASK_COMPLETE: "WorkflowTaskCompleted",
  WORKFLOW_TASK_UNAPPROVED: "WorkflowTaskUnapproved",
  SOCIAL_POST_CREATED: "SocialPostCreated",
  SOCIAL_POST_RESPONDED_TO: "SocialPostRespondedTo",
  SOCIAL_POST_FAILED: "SocialPostFailed",
  SOCIAL_POST_PUBLISHED: "SocialPostPublished",
  SOCIAL_COMMENT_RECEIVED: "SocialCommentReceived",
};

export const UserPermission = {
  SHOW_REVIEWS: "ShowReviews",
  SHOW_CHATTER: "ShowChatter",
  SOCIAL_COMMENTER: "SocialCommenter",
  SOCIAL_POSTER: "SocialPoster",
  APPROVE_REVIEW_RESPONSE: "ApproveReviewResponse",
  TASK_ADMIN: "TaskAdmin",
};

export const LocationCriteriaFields = {
  locations: [],
};

export const ReviewRatingOptions = [
  { key: 1, value: "★" },
  { key: 2, value: "★★" },
  { key: 3, value: "★★★" },
  { key: 4, value: "★★★★" },
  { key: 5, value: "★★★★★" },
  { key: "Recommended", value: "Recommended" },
  { key: "NotRecommended", value: "Not Recommended" },
];

export default new NotificationTriggersStore();
