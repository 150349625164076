import React from "react";

const FoldyThemePreview = ({ logoUrl, variables = {} }) => {
  //bg was set on the body element so lets do some inline styles
  const isGradientBg = !!(variables["$enable-background-gradient"] === "true");
  const bg = isGradientBg ? variables["$body-bg-gradient"] : variables["$body-bg"];
  const maybeBG = bg || [];
  const styles = {};
  if (isGradientBg) {
    styles.backgroundImage = maybeBG;
    styles.backgroundAttachment = "cover";
  } else {
    styles.backgroundColor = maybeBG[0] === "$" ? `var(--${bg.substr(1, bg.length)})` : maybeBG;
  }
  return (
    <div id="foldyThemePreview" className="foldy d-flex flex-column theme-preview w-100 card-spacer-top" style={styles}>
      <div className="main-header foldy-layout-main-header-top-wrapper" style={{ zIndex: 10 }}>
        <div
          className="w-100 p-1 d-flex align-items-center d-flex main-header-top foldy-layout-main-header-top align-items-baseline"
          style={{ height: "25px" }}
        >
          <div style={{ width: 30, height: 15 }}>
            <img src={logoUrl} className="logo-preview" />
          </div>
          <div className="ml-auto d-flex flex-row pr-1">
            {new Array(3).fill(undefined).map((x, i) => (
              <div
                key={`header_${i}`}
                className="ml-1"
                style={{ backgroundColor: "var(--component-active-bg)", width: 10, height: 10, borderRadius: 5 }}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="d-flex align-items-stretch">
        <div
          className="col-2 foldy-nav-wrapper px-0 align-items-stretch"
          style={{ width: "60px", height: "200px !important", top: "auto" }}
        >
          <div className="foldy-nav w-100 h-100">
            <div className="nav nav-pills nav-stacked foldy-nav-inner px-1 mt-2 d-flex flex-column">
              {new Array(2).fill(undefined).map((x, i) => (
                <div key={`nav_${i}`} className="mock-nav-item mock-nav-item-1" />
              ))}
              {new Array(3).fill(undefined).map((x, i) => (
                <div key={`nav_${i}`} className="mock-nav-item mock-nav-item-2" />
              ))}
              {new Array(3).fill(undefined).map((x, i) => (
                <div key={`nav_${i}`} className="mock-nav-item mock-nav-item-3" />
              ))}
              <div key={`nav_active`} className="w-100 mock-nav-item mock-nav-item-active" />
              {new Array(4).fill(undefined).map((x, i) => (
                <div key={`nav_${i}`} className="mock-nav-item mock-nav-item-4" />
              ))}
              {new Array(3).fill(undefined).map((x, i) => (
                <div key={`nav_${i}`} className="mock-nav-item mock-nav-item-5" />
              ))}
            </div>
          </div>
        </div>

        <div className="col-10 h-100 m-0 p-0 pb-2">
          <div className="d-flex flex-row flex-wrap align-items-center sticky-page-header-wrapper mb-2">
            <h1 className="p-2" style={{ fontSize: 12 }}>
              Page Title
            </h1>
          </div>
          <div className="row px-2 mb-3">
            <div className="col">
              <div className="card">
                <div className="card-header p-1">
                  <h2 style={{ fontSize: 8 }}>Card Title</h2>
                </div>
                <div className="card-body"></div>
                <div className="card-footer d-flex justify-content-end p-1">
                  <div style={{ fontSize: 3 }} className="py-1 px-4 btn btn-sm btn-outline-secondary">
                    &nbsp;
                  </div>
                  <div style={{ fontSize: 3 }} className="py-1 px-4 btn btn-sm btn-secondary ml-1">
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row px-2">
            <div className="col-6">
              <div className="card">
                <div className="card-header p-1" style={{ fontSize: 8 }}>
                  &nbsp;
                </div>
                <div className="card-body"></div>
                <div className="card-footer d-flex justify-content-end p-1">
                  <div style={{ fontSize: 3 }} className="py-1 px-3 btn btn-sm btn-secondary">
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card">
                <div className="card-header p-1" style={{ fontSize: 8 }}>
                  &nbsp;
                </div>
                <div className="card-body"></div>
                <div className="card-footer d-flex justify-content-end p-1">
                  <div style={{ fontSize: 3 }} className="py-1 px-3 btn btn-sm btn-secondary">
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoldyThemePreview;
