import * as mobx from "mobx";
import * as _ from "lodash";
import { getQueryObject, setQueryParams } from "./UrlFragmentSearch";
import moment from "moment";

interface UrlMapper {
  /** function to print a value to a semi-raw url param (as parsed by qs library) */
  toQuery: (value: any) => any;
  /** function to parse a value from the semi-raw url param (as parsed by qs library) */
  fromQuery: (value: any) => any;
}

export interface FieldDescriptor extends UrlMapper {
  /** field to set on the observable */
  observableFieldName: string;
  /** field name to set in the url params */
  urlParamName: string;
  /** whether the descriptor should transform the default value via conversions when sending to the the url (defaults to false) */
  serializeDefault: boolean;
  /** if no query param is undefined, this is the empty value (e.g. for arrays, should be `null` or `[]`?) */
  defaultValue?: any;
}

export type FieldDescriptorBuilder = Partial<FieldDescriptor> &
  UrlMapper & {
    /** whether the field should always be set with the default value if absent */
    required?: boolean;
    /** copy with updated default value */
    withDefaultValue: (value: any) => FieldDescriptorBuilder;
    /** copy with updated field name */
    withObservableFieldName: (observableFieldName: string) => FieldDescriptorBuilder;
    /** copy with updated url param name */
    withUrlParamName: (urlParamName: string) => FieldDescriptorBuilder;
  };
interface ThrottleOption {
  /** max frequency in milliseconds to push or pull changes to/from url to observable */
  throttle?: number;
}
interface FireImmediatelyOption {
  /** should a sync occur when this function is first called */
  fireImmediately?: boolean;
}

interface DebugOption {
  /** log debug information about sync */
  debug?: boolean;
}

interface PullFirstOption {
  /** should set observable with defined url params first */
  pullFirst?: boolean;
}

interface SetDefaultIfNotInUrlOption {
  /** if true, enforces all keys to exist in the url query object if not yet defined */
  setDefaultIfNotInUrl?: boolean;
}

/**
 *
 * continuously pushes any modifications defined in fieldDescriptors from the observable to the url
 *
 *
 * <code>
 *   const observable = mobx.observable({foo: "foo", bar: [1,2,3], ignoreme: "ignored"})
 *   pushToUrl(observable, FieldDescriptors.build({
 *    foo: FieldType.string,
 *    bar: FieldType.arrayOf(FieldType.int)
 *   }))
 *  // will set query to ?foo=foo&bar[]=1&bar[]=2&bar[]=3
 *  observable.foo = "blah"
 *  observable.bar.push(1000)
 *  // will set query to ?foo=blah&bar[]=1&bar[]=2&bar[]=3&bar[]=1000
 * </code
 *
 *
 * @returns {function} - disposer function to stop updates
 */
export function pushToUrl(
  observable: mobx.IObservable & any,
  fieldDescriptors: FieldDescriptor[],
  {
    throttle = 200,
    /** should a sync occur when this function is first called */
    fireImmediately = true,
    /** log debug information about sync */
    debug = false,
  }: ThrottleOption & FireImmediatelyOption & DebugOption = {}
): () => void {
  let pendingUpdates = fireImmediately ? [...fieldDescriptors] : [];

  const doPush = () => {
    const params: any = {};
    applyFiltersToToQueryObject(pendingUpdates, observable, params, { debug });
    setQueryParams(params);
    pendingUpdates = [];
  };

  const throttledPushUrl = _.throttle(doPush, throttle, { leading: false });

  const stopPushes = fieldDescriptors.map((descriptor) => {
    const { toQuery, observableFieldName } = descriptor;
    return mobx.reaction(
      () => {
        const js = mobx.toJS(observable[observableFieldName]);
        if (js === null || js === undefined) {
          return null;
        } else {
          try {
            return toQuery(js); // simpler type for comparison to not trigger infinite set loops with new references
          } catch (e) {
            throw Error(
              `failed to serialize ${JSON.stringify(js)} with ${JSON.stringify(descriptor)}: Cause: ${
                (e as any)?.message || e
              }`
            );
          }
        }
      },
      (value) => {
        if (pendingUpdates.indexOf(descriptor) === -1) {
          pendingUpdates.push(descriptor);
          throttledPushUrl();
        }
      },
      {
        fireImmediately: false,
        equals: mobx.comparer.structural,
      }
    );
  });

  doPush();

  return function dispose() {
    for (let cancel of stopPushes) {
      cancel();
    }
    throttledPushUrl.cancel();
  };
}

/** mutate the qs parseable query object with serialized updates from the observable */
export function applyFiltersToToQueryObject(
  fieldDescriptors: FieldDescriptor[],
  filters: any,
  query: any,
  { debug = false }: DebugOption = {}
): void {
  for (let descriptor of fieldDescriptors) {
    const { toQuery } = descriptor;
    const value = mobx.toJS(filters[descriptor.observableFieldName]);
    if (
      value === null ||
      value === undefined ||
      (!descriptor.serializeDefault && _.isEqual(value, descriptor.defaultValue))
    ) {
      if (debug) console.info("observable->url (no value)", descriptor.urlParamName, value);
      query[descriptor.urlParamName] = undefined;
    } else {
      const serialized = value === undefined ? undefined : toQuery(value);
      if (debug) console.info("observable->url", descriptor.urlParamName, serialized, "(", value, ")");
      query[descriptor.urlParamName] = serialized;
    }
  }
}

/**
 * applies query parameters from the url, mutating the observable. Runs once synchronously.
 */
export const applyQueryToObservable = mobx.action(
  "updateFromUrl",
  (
    observable: mobx.IObservable & any,
    fieldDescriptors: FieldDescriptor[],
    query: any,
    {
      setDefaultIfNotInUrl = true,
      debug = false,
    }: {
      /** if false, only sets the observable from fields that exist in the url params */
      setDefaultIfNotInUrl?: boolean;
    } & DebugOption = {}
  ): void => {
    for (let descriptor of fieldDescriptors) {
      const { fromQuery, toQuery, urlParamName, observableFieldName } = descriptor;
      let inUrl = urlParamName in query;
      let queryValue;
      if (inUrl) {
        queryValue = query[urlParamName];
      } else {
        queryValue = toQuery(descriptor.defaultValue);
      }

      let observableSerialized = observable[observableFieldName];
      if (!(observableSerialized === undefined || observableSerialized === null)) {
        observableSerialized = toQuery(observableSerialized);
      }

      const wouldChangeQuery = !_.isEqual(queryValue, observableSerialized);

      if (wouldChangeQuery && (setDefaultIfNotInUrl || inUrl)) {
        const newValue = !inUrl ? descriptor.defaultValue : fromQuery(queryValue);
        if (debug) console.info("url->observable", observableFieldName, newValue);
        observable[observableFieldName] = newValue;
      }
    }
  }
);

/**
 * watches url and pulls changes, setting values in observable, with mappings defined by fieldDescriptors
 *
 * continuously pulls any modifications defined in fieldDescriptors from the url and applies them to the observable
 *
 *
 * <code>
 *   const observable = mobx.observable({foo: "foo", bar: [1,2,3], ignoreme: "ignored"})
 *   // url query is ?foo=blah&bar[]=5&ignoreMe="wontbeset"
 *   pullFromUrl(observable, FieldDescriptors.build({
 *    foo: FieldType.string,
 *    bar: FieldType.arrayOf(FieldType.int)
 *   }))
 *  // observable is now
 *  console.log(mobx.toJS(observable)
 *  // { foo: "blah", bar: [5], ignoreme: "ignored" }
 *  window.location.hash = foo=blahblah&bar[]=5&bar[]=20&ignoreMe="wontbeset"
 *  console.log(mobx.toJS(observable)
 *  // { foo: "blahblah", bar: [5, 20], ignoreme: "ignored" }
 * </code*
 *
 * @returns {function} - disposer function to stop updates
 */
export function pullFromUrl(
  observable: mobx.IObservable & any,
  fieldDescriptors: FieldDescriptor[],
  { fireImmediately = true, debug = false }: FireImmediatelyOption & DebugOption
): () => void {
  const stopPullUrl = doOnSearchChange(
    (search) => {
      return applyQueryToObservable(observable, fieldDescriptors, search, { debug });
    },
    { fireImmediately }
  );

  return function dispose() {
    stopPullUrl();
  };
}

/**
 *
 * combines pullFromUrl and pushToUrl in one,
 * doing an initial partial pull from the url (only for fields that are defined) if pullFirst is true
 * @returns disposer function to stop updates
 */

export function bindWithUrl(
  observable: mobx.IObservable & any,
  fieldDescriptors: FieldDescriptor[],
  { throttle = 200, pullFirst, debug = false }: PullFirstOption & ThrottleOption & DebugOption = {}
) {
  const nopush = bindWithUrlSafe(observable, fieldDescriptors, {
    throttle,
    pullFirst,
    debug,
  });

  const nopull = pullFromUrl(observable, fieldDescriptors, {
    fireImmediately: true, // theoretically should be in sync already, but this should expose any hidden serialize/deserialize infinite loops sooner
    debug,
  });

  return function bindWithUrlDisposer() {
    nopush();
    nopull();
  };
}

/**
 *
 * initializes once from the url immediately on invocation, and then persists observable state only one way to the url.
 * Use this if its not important that the user can edit the url directly without refreshing the whole page.
 *
 * This is much safer regarding feedback loops, especially with multiple binds or chained observable reactions
 *
 * @returns disposer function to stop updates
 */

export function bindWithUrlSafe(
  observable: mobx.IObservable & any,
  fieldDescriptors: FieldDescriptor[],
  { throttle = 200, pullFirst, debug = false }: ThrottleOption & PullFirstOption & DebugOption = {}
) {
  if (pullFirst) {
    applyQueryToObservable(observable, fieldDescriptors, getQueryObject(), {
      setDefaultIfNotInUrl: false,
      debug,
    });
  }
  return pushToUrl(observable, fieldDescriptors, {
    fireImmediately: true,
    throttle: throttle,
    debug,
  });
}

/** run the callback with the qs parsed search object from the url hash on any hashchange
 * @returns disposer function
 */
function doOnSearchChange(fn: (query: any) => void, { fireImmediately = false }: FireImmediatelyOption): () => void {
  const onHashChange = () => {
    fn(getQueryObject());
  };
  window.addEventListener("hashchange", onHashChange);
  if (fireImmediately) {
    onHashChange();
  }
  return () => window.removeEventListener("hashchange", onHashChange);
}

/**
 * Builders for fieldDescriptor
 * and arrays of fieldDescriptor
 */
export const FieldDescriptors = {
  /**
   * builds an array of field descriptors from the mappings
   * @param mappings {Object.<string,partialFieldDescriptor>}
   * @returns {Array.<fieldDescriptor>}
   */
  build(mappings: Record<string, FieldDescriptorBuilder>): FieldDescriptor[] {
    return Object.entries(mappings).map(([fieldName, partial]) => {
      return this.of(fieldName, { ...partial });
    });
  },
  /**
   * returns copied mappings with defaultValue set to the values specified in defaults
   *
   * Useful if you want the url params to not show any value when the filters value is already the default
   *
   * @param mappings {Object.<string,any>}
   * @param defaults {Object.<string, any>}
   * @returns {Object.<string,any>}
   */
  applyDefaults(mappings: Record<string, FieldDescriptorBuilder>, defaults: Record<string, any> = {}) {
    const builder: Record<keyof typeof mappings, FieldDescriptorBuilder> = {};
    for (let [k, v] of Object.entries(mappings)) {
      if (k in defaults) {
        builder[k] = v.withDefaultValue(defaults[k]);
      } else {
        builder[k] = v;
      }
    }
    return builder;
  },

  /**
   *
   * builds a fieldDescriptor from a FieldDescriptorBuilder
   */
  of(
    fieldName: string,
    {
      observableFieldName,
      urlParamName,
      toQuery,
      fromQuery,
      defaultValue,
      required,
      serializeDefault = false,
    }: FieldDescriptorBuilder
  ): FieldDescriptor {
    return {
      observableFieldName: observableFieldName || fieldName,
      urlParamName: urlParamName || fieldName,
      toQuery: (val) => {
        if ((val === null || val === undefined) && !required) {
          return defaultValue;
        } else {
          return toQuery(val);
        }
      },
      fromQuery: (query) => {
        if ((query === null || query === undefined) && !required) {
          return defaultValue;
        } else {
          return fromQuery(query);
        }
      },
      serializeDefault: serializeDefault,
      defaultValue: defaultValue,
    };
  },
};

/**
 *
 * (partial) FieldDescriptor builders with standard (de)serialization logic.
 * Use FieldDescriptors.of or FieldDescriptors.build to ensure all needed values are present
 * e.g.
 *
 * <pre><code>
 *    bindWithUrl(myObservable, [
 *      FieldDescriptors.of('myObservableFieldName' FieldType.int.withUrlParamName('queryParam'))
 *    ])
 * </code></pre>
 */
export const FieldType = {
  /**
   * @return {partialFieldDescriptor}
   */
  of: ({
    toQuery,
    fromQuery,
    defaultValue,
    required = false,
  }: {
    toQuery: (value: any) => any;
    fromQuery: (value: any) => any;
    defaultValue?: any;
    required?: boolean;
  }): FieldDescriptorBuilder => {
    return {
      toQuery,
      fromQuery,
      defaultValue,
      required,
      withDefaultValue(x) {
        return { ...this, defaultValue: x };
      },
      withObservableFieldName(observableFieldName) {
        return { ...this, observableFieldName };
      },
      withUrlParamName(urlParamName) {
        return { ...this, urlParamName };
      },
    };
  },
  get string(): FieldDescriptorBuilder {
    return this.of({
      toQuery: (string) => string,
      fromQuery: (string) => string,
    });
  },
  get nonEmptyString(): FieldDescriptorBuilder {
    return this.of({
      toQuery: (string) => string || undefined,
      fromQuery: (string) => string || undefined,
    });
  },
  get float(): FieldDescriptorBuilder {
    return this.of({
      toQuery: (number) => number.toString(),
      fromQuery: (string) => Number.parseFloat(string),
    });
  },

  get bool(): FieldDescriptorBuilder {
    return this.of({
      toQuery: (bool) => bool.toString(),
      fromQuery: (string) => string.toLowerCase() === "true",
    });
  },

  get int(): FieldDescriptorBuilder {
    return this.of({
      toQuery: (int) => int.toString(),
      fromQuery: (string) => Number.parseInt(string),
    });
  },

  get json(): FieldDescriptorBuilder {
    return this.of({
      toQuery: (obj) => JSON.stringify(obj),
      fromQuery: (str) => JSON.parse(str),
    });
  },

  // supplied parser must toQuery / fromQuery via strings in order to work sanely
  // (arrays of arrays will not work so great)
  arrayOf(parser: UrlMapper): FieldDescriptorBuilder {
    if (!parser) throw new Error("expected parser");
    return this.of({
      toQuery: (array) => array.map(parser.toQuery),
      fromQuery: (maybeArray) => {
        if (!maybeArray) {
          return [];
        } else if (!Array.isArray(maybeArray)) {
          return [parser.fromQuery(maybeArray)];
        } else {
          return maybeArray.map(parser.fromQuery);
        }
      },
    }).withDefaultValue([]);
  },

  // qs puts arrays in new keys (in a few different ways),
  // foo[0]=one&foo[1]=two or
  // foo[]=one&foo[]=two or
  // foo=one&foo=two.
  // they all tend to have some issues with empty arrays. Easier to just comma delimit them
  // supplied parser must toQuery / fromQuery via strings in order to work sanely
  delimitedArrayOf(parser: UrlMapper, delimiter: string = ","): FieldDescriptorBuilder {
    return this.of({
      toQuery: (array) => array.map(parser.toQuery).join(delimiter),
      fromQuery: (string) => {
        if (!string.length) {
          return [];
        }
        if (Array.isArray(string)) {
          return string;
        } else {
          return string.split(delimiter).map(parser.fromQuery);
        }
      },
    }).withDefaultValue([]);
  },
  dateMilliseconds(precisionMilliseconds = 1000): FieldDescriptorBuilder {
    return this.of({
      toQuery: (date) => date && date.valueOf() / precisionMilliseconds,
      fromQuery: (string) => new Date(Number.parseInt(string) * precisionMilliseconds),
    });
  },
  dateString(format = "YYYY-MM-DDTHH:mm:SS"): FieldDescriptorBuilder {
    let momentFormat = this.moment(format);
    return this.of({
      toQuery: (date) => momentFormat.toQuery(moment(date)),
      fromQuery: (string) => {
        let asMoment = momentFormat.fromQuery(string);
        return asMoment && asMoment.format(format);
      },
    });
  },
  moment(format = "YYYY-MM-DDTHH:mm:SS"): FieldDescriptorBuilder {
    return this.of({
      toQuery: (m) => {
        const dt = moment(m);
        return dt.isValid() ? dt.format(format) : undefined;
      },
      fromQuery: (str) => {
        const dt = moment(str);
        return dt.isValid() ? dt : null;
      },
    });
  },
};
