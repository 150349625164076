import React from "react";
import PropTypes from "prop-types";
import "./HorizontalPageFiltersLayout.scss";
import { ClearFiltersButton } from "./PageFilters";
import { GLOBAL_FILTER } from "../navigator/navigatorPortalNames";
import RenderToPortal from "../../../components/portals/RenderToPortal";

/** A layout with horizontal page filters */
export default function HorizontalPageFiltersLayout({ onClearFilters, children, global }) {
  const childrenArray = React.Children.toArray(children);
  const globalArray = React.Children.toArray(global);
  if (!childrenArray.concat(globalArray).length) {
    return null;
  }
  const filters = React.Children.toArray(children);
  const main = filters.filter((x) => !x.props || !x.props.secondary);
  const secondary = filters.filter((x) => x.props && x.props.secondary);
  return (
    <React.Fragment>
      <RenderToPortal id={GLOBAL_FILTER}>{global}</RenderToPortal>
      {!!main.length && (
        <div className="card filters-row filter-grid card-spacer-bottom">
          {main}
          {onClearFilters ? <ClearFiltersButton onClick={() => onClearFilters()} /> : null}
        </div>
      )}
      {!!secondary.length && (
        <div className="d-flex flex-column flex-md-row justify-content-between card-spacer-bottom">
          <span className="col-md-8 px-0 ml-auto">
            <div className="rounded-0-top filter-grid secondary-filters py-0 bg-transparent floating-control">
              {secondary}
            </div>
          </span>
        </div>
      )}
    </React.Fragment>
  );
}

HorizontalPageFiltersLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};
