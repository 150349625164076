import React from "react";
import { Modal } from "reactstrap";
import SessionStore from "../../services/session/SessionStore";
import { observer } from "mobx-react";
import PersonaForm from "./PersonaForm";
import FeedbackThankYou from "./FeedbackThankYou";
import { upsertUserPreferences } from "../../services/users/usersApi";

export const PersonaFormModal = observer(
  class PersonaFormModal extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        modal: true,
        submitted: false,
        notLoginRoute: this.props.location.pathname !== "/",
      };
    }

    closeModal = () => {
      this.setState({ modal: false });
    };

    render() {
      const { notLoginRoute } = this.state;
      const impersonated = !!SessionStore.user.impersonatedByUser;
      const reseller = !!SessionStore.brandConfig.hostname || SessionStore.user.reseller;
      const alreadyComplete = !this.state.submitted && !!SessionStore.userPreferences.persona;

      if (notLoginRoute || impersonated || reseller || alreadyComplete) return null;

      return (
        <Modal toggle={this.closeModal} isOpen={this.state.modal} size="md" className="bg-transparent">
          {!this.state.submitted ? (
            <PersonaForm
              onCancel={this.closeModal}
              onSubmit={(x) => {
                upsertUserPreferences({ userId: SessionStore.user.userId, userPreferences: { persona: x } }).then(
                  (userPreferences) =>
                    this.setState({ submitted: true }, () => (SessionStore.userPreferences = userPreferences))
                );
              }}
              username={SessionStore.user ? SessionStore.user.username : ""}
            />
          ) : (
            <FeedbackThankYou onClose={this.closeModal} />
          )}
        </Modal>
      );
    }
  }
);
