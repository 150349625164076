import * as React from "react";

export class PasswordResetView extends React.Component {
  render() {
    const {
      password,
      onPasswordChanged,
      invalidPassword,
      confirmPassword,
      onConfirmPasswordChanged,
      passwordsDoNotMatch,
      errorMessage,
      onSubmit,
      logoUrl,
      success,
    } = this.props;

    return (
      <div className="d-flex h-100 align-items-center justify-content-center">
        <div className="card w-100 card-login">
          <div className="card-header">
            <h1 className="text-center card-title-lg">Reset Password</h1>
          </div>
          <div className="card-body card-footerless">
            {!!logoUrl && (
              <figure className={`${logoUrl ? "hasLogo" : ""} logo-container`}>
                {!!logoUrl && <img src={logoUrl} alt="" className="img-fluid" />}
              </figure>
            )}
            <p>Please enter your new password in the box below.</p>
            {!!errorMessage && (
              <div className="alert alert-danger">
                <i className="fas fa-exclamation-circle fa-status"></i>
                {errorMessage}
              </div>
            )}
            {!!success && (
              <div className="alert alert-success">
                <i className="fas fa-check-circle fa-status"></i>Your password has been reset.{" "}
                <a href="/#/login" className="link-inverse">
                  Click here to login
                </a>
              </div>
            )}

            <form name="f.passwordResetForm" className="form-horizontal" role="form" onSubmit={onSubmit}>
              <div className={invalidPassword ? "form-group has-error" : "form-group"}>
                <div className="input-group">
                  <span className="input-group-prepend">
                    <i className="d-flex fas fa-lock-alt input-group-text"></i>
                  </span>
                  <input
                    className="form-control"
                    value={password}
                    type="password"
                    name="password"
                    id="passwordReset"
                    onChange={onPasswordChanged}
                    placeholder="Password"
                    required
                    autoFocus
                  />
                </div>
                <div>
                  {invalidPassword && <p className="help-block">Password must be a minimum of 6 characters!</p>}
                </div>
              </div>
              <div className={invalidPassword || passwordsDoNotMatch ? "form-group has-error px-0" : "form-group px-0"}>
                <div className="input-group">
                  <span className="input-group-prepend">
                    <i className="d-flex fas fa-lock-alt input-group-text"></i>
                  </span>
                  <input
                    disabled={success}
                    className="form-control"
                    value={confirmPassword}
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    onChange={onConfirmPasswordChanged}
                    required
                  />
                </div>
                <div>{passwordsDoNotMatch && <p className="help-block">Passwords do not match!</p>}</div>
              </div>
              <div className="form-group">
                <input
                  disabled={success || invalidPassword || passwordsDoNotMatch}
                  type="submit"
                  className="btn btn-secondary"
                  value="Submit"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
