import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import FeaturesStore from "./FeaturesStore";
import AppNavigator from "./navigator/AppNavigator";
import SessionStore from "../../services/session/SessionStore";
import applyRouteAccessControl from "./site/applyRouteAccessControl";
import appRoutesList from "./site/appRoutesList";
import foldyDefaultSiteMap from "./site/sitemaps/foldyDefaultSiteMap";
import foldySettingsSiteMap from "./site/sitemaps/foldySettingsSiteMap";
import FullPageSpinner from "../../services/session/components/FullPageSpinner";
import { getItem } from "../../services/storage/localStorage";
import { FoldyContextProvider, FoldyLayout } from "./layout/FoldyLayout";
import { billingAdminSiteMap } from "../admin";
import { FeatureFlagStore } from "../../services/config/FeatureFlagStore";

const AppRoutes = observer((props) => {
  const [unleashTimedOut, setUnleashTimedOut] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      // Don't spin forever if Unleash won't load
      if (!FeatureFlagStore.isReady) setUnleashTimedOut(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);
  if (!SessionStore.sessionLoaded || (!unleashTimedOut && !FeatureFlagStore.isReady)) {
    return <FullPageSpinner />;
  } else {
    return (
      <AppNavigator
        key="foldy-layout"
        siteMapTemplate={foldyDefaultSiteMap}
        secondarySiteMaps={[foldySettingsSiteMap, billingAdminSiteMap]}
        routes={applyRouteAccessControl(appRoutesList)}
        Layout={FoldyLayout}
        LayoutContextProvider={FoldyContextProvider}
        defaultUrl={resolveTabToRedirectTo()}
      />
    );
  }
});

function resolveTabToRedirectTo() {
  const shouldRestore = (SessionStore.userPreferences && SessionStore.userPreferences.stickyUrl) || false;
  const userId = SessionStore.user.userId;
  const firstTab = FeaturesStore.firstEnabledRoute();

  if (shouldRestore) {
    const maybeStoredLocation = getItem(`RESTORE_URL_${userId}`);
    if (!!maybeStoredLocation) {
      const redirect = JSON.parse(maybeStoredLocation).hash;
      if (!!redirect && redirect !== "#/") return redirect.charAt(0) === "#" ? redirect.substring(1) : redirect;
    }
  }
  return firstTab;
}

export default AppRoutes;
