import React, { useState } from "react";
import { Modal } from "reactstrap";

const ModalWrapper = ({ closeModal, children, size, backdrop = true, ...props }) => {
  const [isHidden, setIsHidden] = useState(false);
  const toggle = () => setIsHidden(!isHidden);

  return (
    <div className="cm-modal-wrapper">
      <Modal size={size} isOpen={!isHidden && !!children} toggle={toggle} backdrop={backdrop}>
        {children}
      </Modal>
    </div>
  );
};

export default ModalWrapper;
