import * as React from "react";
import { PasswordResetView } from "./PasswordResetView";
import PropTypes from "prop-types";
import * as SessionApi from "../SessionApi";
import * as UrlFragmentSearch from "../../../util/UrlFragmentSearch";
import BrandConfigStore from "../../config/BrandConfigStore";
import { observer } from "mobx-react";
import LoginStore from "../LoginStore";

/**
 * manages form state for resetting password. Actual rendering happens in PasswordResetView
 */
export const ResetPasswordData = observer(
  class ResetPasswordData extends React.Component {
    static propTypes = {
      onSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
      super(props);
      let options = UrlFragmentSearch.getQueryObject();
      let token = options["pwResetToken"];

      this.state = {
        email: "",
        password: "",
        confirmPassword: null,
        token: token,
        errorMessage: null,
        isSubmitting: null,
        success: null,
        passwordDirty: false,
        confirmPasswordDirty: false,
      };
    }

    onPasswordChanged = (e) => {
      this.setState({ password: e.target.value }, () => this.setState({ passwordDirty: true }));
    };

    onConfirmPasswordChanged = (e) => {
      this.setState({ confirmPassword: e.target.value }, () => this.setState({ confirmPasswordDirty: true }));
    };

    onSubmit = (e) => {
      e && e.preventDefault();
      this.setState({ isSubmitting: true, errorMessage: null, success: false });
      SessionApi.resetPassword(this.state.password, this.state.token)
        .then(() => {
          this.setState({ success: true, isSubmitting: false });
        })
        .catch(() => {
          this.setState({ errorMessage: "There was an unexpected server error!" });
        });
    };

    render() {
      const { password, passwordDirty, confirmPassword, confirmPasswordDirty, errorMessage, isSubmitting, success } =
        this.state;
      const { onPasswordChanged, onConfirmPasswordChanged, onSubmit } = this;
      const brandConfig = BrandConfigStore.brandConfig;
      const invalidPassword = passwordDirty && password.length < 6;
      const passwordsDoNotMatch = confirmPasswordDirty && password !== confirmPassword;
      return (
        <PasswordResetView
          password={password}
          invalidPassword={invalidPassword}
          confirmPassword={confirmPassword}
          passwordsDoNotMatch={passwordsDoNotMatch}
          errorMessage={errorMessage}
          isSubmitting={isSubmitting}
          logoUrl={!brandConfig || brandConfig.restrictions.hideLogo ? undefined : brandConfig.logoUrl}
          success={success}
          onPasswordChanged={onPasswordChanged}
          onConfirmPasswordChanged={onConfirmPasswordChanged}
          onSubmit={onSubmit}
        />
      );
    }
  }
);
