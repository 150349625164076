import * as React from "react";
import LoginStore from "../LoginStore";
import { Redirect } from "react-router-dom";

/** route that can be navigated to in order to log the user out */
export class LogoutView extends React.Component {
  constructor(props) {
    super(props);
    LoginStore.logout();
  }

  render() {
    return <Redirect to="/" />;
  }
}
