import { action, observable, computed, toJS, makeObservable } from "mobx";
import * as mobx from "mobx";
import qs from "qs";
import api from "./api";
import { WorkflowTaskStore } from "./task/WorkflowTaskStore";
import defaultSessionStore from "../../services/session/SessionStore";
import { isEqual } from "lodash";
import { FieldDescriptors, FieldType } from "../../util/urlSync";
import selected from "../../services/locations/SelectedLocationsStore";
import { CancellationException, failPreviousUnresolvedPromises } from "../../util/async";
import { statuses } from "./models";
import { modifyTaskDescriptions } from "./helpers";

export const defaultFilters = {
  query: "",
  taskType: [],
  provider: [],
  priority: [],
  status: statuses.map((s) => s.value).filter((status) => status !== "Complete"),
  assigneeId: [],
  dueAfter: undefined,
  dueBefore: undefined,
};

export const defaultPagination = {
  offset: 0,
  limit: 20,
  sortField: "createdDate",
  sortOrder: "DESC",
};

class WorkflowDomainStore {
  static urlFilterTypes = FieldDescriptors.build(
    FieldDescriptors.applyDefaults(
      {
        locationGroupId: FieldType.delimitedArrayOf(FieldType.string),
        taskType: FieldType.delimitedArrayOf(FieldType.string),
        provider: FieldType.delimitedArrayOf(FieldType.string),
        priority: FieldType.delimitedArrayOf(FieldType.int),
        status: FieldType.delimitedArrayOf(FieldType.string),
        assigneeId: FieldType.delimitedArrayOf(FieldType.string),
        dueAfter: FieldType.moment("YYYY-MM-DD"), // server chokes on empty string params, which is what null will serialize to
        dueBefore: FieldType.moment("YYYY-MM-DD"),
        sortField: FieldType.string,
        sortOrder: FieldType.string,
      },
      defaultFilters
    )
  );

  static urlPaginationTypes = FieldDescriptors.build(
    FieldDescriptors.applyDefaults(
      {
        offset: FieldType.int,
        sortField: FieldType.string,
        sortOrder: FieldType.string,
      },
      defaultPagination
    )
  );

  constructor(sessionStore) {
    makeObservable(this, {
      isLoading: observable,
      isLoadingStats: observable,
      stats: observable,
      tasks: observable,
      didErr: observable,
      filters: observable,
      pagination: observable,
      totalTasks: observable,
      hasModifiedFilters: computed,
      onNewTaskAdded: action,
      downloadTasks: action,
      getTasks: action,
      getCountSuccess: action.bound,
      getTaskSuccess: action.bound,
      replaceTask: action.bound,
      getStatusSuccess: action.bound,
      totalPages: computed,
      onPageUpdate: action.bound,
      onSortField: action,
      onFiltersUpdate: action.bound,
      _resetPageAndOffset: action.bound,
      resetFilters: action.bound,
    });

    this.sessionStore = sessionStore || defaultSessionStore;
    this.workflowApi = api;

    this.locationIdReaction = mobx.reaction(
      () => selected.selectedLocations,
      () => {
        this._resetPageAndOffset();
        this.getTasks(true);
      }
    );
    this.filterReaction = mobx.reaction(
      () => {
        return toJS(this.filters);
      },
      (params) => {
        this.getTasks(true);
      },
      {
        equals: mobx.comparer.structural,
        delay: 100,
      }
    );
    this.pagingReaction = mobx.reaction(
      () => toJS(this.pagination),
      (pagination) => {
        this.getTasks(false);
      },
      {
        equals: mobx.comparer.structural,
        delay: 100,
      }
    );
  }

  getParams() {
    const jsFilters = mobx.toJS(this.filters);
    const jsPagination = mobx.toJS(this.pagination);
    const dueAfterAdjusted = this.filters.dueAfter && this.filters.dueAfter.startOf("day");
    const dueBeforeAdjusted = this.filters.dueBefore && this.filters.dueBefore.endOf("day");

    const params = {
      offset: jsPagination.offset,
      sortField: jsPagination.sortField,
      sortOrder: jsPagination.sortOrder,
      query: jsFilters.query,
      taskType: jsFilters.taskType,
      priority: jsFilters.priority,
      provider: jsFilters.provider,
      status: jsFilters.status,
      assigneeId: jsFilters.assigneeId,
      dueAfter: dueAfterAdjusted ? dueAfterAdjusted.toDate() : undefined,
      dueBefore: dueBeforeAdjusted ? dueBeforeAdjusted.toDate() : undefined,
      locationId: selected.selectedLocations || undefined,
    };
    return params;
  }

  isLoading = true;
  isLoadingStats = true;
  stats;
  tasks = [];
  didErr = false;

  filters = defaultFilters;
  pagination = {
    offset: 0,
    limit: 20,
    sortField: "createdDate",
    sortOrder: "DESC",
  };

  totalTasks = 0;

  dispose() {
    this.locationIdReaction && this.locationIdReaction();
    this.filterReaction && this.filterReaction();
    this.pagingReaction && this.pagingReaction();
  }

  get hasModifiedFilters() {
    return !isEqual(toJS(this.filters), defaultFilters);
  }

  onNewTaskAdded(newTask) {
    this.tasks.unshift(newTask);
  }

  _fetchTasks = new failPreviousUnresolvedPromises((params) => {
    return this.workflowApi.searchTask(params);
  });

  downloadTasks() {
    const params = qs.stringify(this.getParams(), { arrayFormat: "repeat" });

    return this.workflowApi.presign(`/v5/workflow/tasks/download?${params}`).then((resp) => {
      window.location = resp.data.url + `&bewit=${resp.data.token}`;
    });
  }

  // OK: we want to not re-request stats or counts if this is just a pagination change
  getTasks = (loadStats = false) => {
    const params = this.getParams();
    this.isLoading = true;
    this.didErr = false;

    if (loadStats) {
      this.workflowApi.fetchTaskCount(params).then(this.getCountSuccess);
      this.isLoadingStats = true;
      this.getStats();
    }

    return this._fetchTasks(params)
      .then(this.getTaskSuccess)
      .catch((ex) => {
        if (ex && ex.name === CancellationException) {
          // whatevs
        } else {
          console.error("failed to fetch tasks", ex);
          mobx.runInAction(() => {
            this.didErr = true;
            this.isLoading = false;
          });
        }
      });
  };

  getCountSuccess(count) {
    this.totalTasks = count;
  }

  getTaskSuccess(response) {
    this.tasks = modifyTaskDescriptions(response.data.tasks);
    this.isLoading = false;
    return response;
  }

  replaceTask(task) {
    const oldTaskIndex = this.tasks.findIndex((t) => t._id === task._id);

    if (oldTaskIndex !== -1) {
      this.tasks.splice(oldTaskIndex, 1, task);
    }
  }

  getStats = new failPreviousUnresolvedPromises(() => {
    return this.workflowApi.getStats(this.getParams()).then(this.getStatusSuccess);
  });

  getStatusSuccess(response) {
    this.stats = response.data;
    this.isLoadingStats = false;
    return response;
  }

  get totalPages() {
    if (!this.totalTasks) return 0;
    return Math.ceil(this.totalTasks / this.pagination.limit);
  }

  onPageUpdate(page) {
    this.pagination.offset = (page - 1) * this.pagination.limit;
  }

  onSortField(sortField) {
    const sortOrder = this.pagination.sortField === sortField && this.pagination.sortOrder === "ASC" ? "DESC" : "ASC";
    this.pagination.sortField = sortField;
    this.pagination.sortOrder = sortOrder;
    this.pagination.offset = 0;
  }

  onFiltersUpdate(filters) {
    this._resetPageAndOffset();
    Object.assign(this.filters, filters);
  }

  _resetPageAndOffset() {
    this.pagination.offset = 0;
  }
  // prefer passing the observable task over the id to the store,
  // so that updates can be propagated back to the list.
  edit({ taskId, taskTemplate }) {
    const task = taskId && this.tasks.find((x) => x.prefixedFriendlyId === taskId);
    return new WorkflowTaskStore({
      task,
      taskId,
      taskTemplate,
      onTaskAdded: (add) => this.onNewTaskAdded(add),
    });
  }

  deleteTask = (taskId) => {
    return api.deleteTask(taskId).then(() => {
      this.getTasks();
      this.getStats();
    });
  };

  resetFilters() {
    Object.assign(this.filters, defaultFilters);
  }
}

export default WorkflowDomainStore;
